import React from "react";

import { Button } from "@mui/material";
import Grid from "@mui/material/Grid";

import { useCreateContract } from "../CreateContractProvider";
import { steps } from "./steps";

type Props = {
  isStepValid: boolean,
  children?: React.ReactNode
};

export const CreateContractStepActions: React.FC<Props> = ({
  isStepValid,
  children
}) => {
  const { dialog, stepper: { activeStep, next, back } } = useCreateContract();

  return (
    <Grid item xs={12} sx={{ display: "flex", justifyContent: "end", alignItems: "center" }}>
      <Button onClick={dialog.cancel}>Cancel</Button>
      <Button disabled={activeStep === 0} onClick={back}>Back</Button>
      {children}
      { (activeStep !== steps.length - 1) &&
        <Button
          onClick={next}
          disabled={!isStepValid}>
          Next
        </Button>
      }
    </Grid>
  );
};