import React, { ReactNode } from "react";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Grid,
  Typography
} from "@mui/material";
import { Breakpoint } from "@mui/system";

export type ConfirmationDialogProps = {
  title: string,
  isConfirmationDialogOpen: boolean,
  isConfirmDisabled: boolean,
  children: ReactNode,
  size?: Breakpoint | false,
  onClose: () => void,
  onConfirm: () => void
};
const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  title,
  isConfirmationDialogOpen,
  isConfirmDisabled,
  children,
  size = "sm", 
  onClose,
  onConfirm
}) => {
  return <Dialog
    fullWidth
    maxWidth={size}
    open={isConfirmationDialogOpen}
    onClose={onClose}
  >
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>
      <DialogContent sx={{ mx: 2 }}>
        <Grid item xs={12}>
          {children}
          <Grid item xs={12} sx={{ mt: 2 }}>
            <Typography variant="body1">Kindly confirm or cancel the action.</Typography>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>
          Cancel
        </Button>
        <Button
          autoFocus
          disabled={isConfirmDisabled}
          onClick={onConfirm}>
          Confirm
        </Button>
      </DialogActions>

    </DialogContent>
  </Dialog>;
};

export default ConfirmationDialog;
