import React from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import ConfirmationDialog, { ConfirmationDialogProps } from "../../../components/dialog/ConfirmationDialog";
import { orderLinesRenderer } from "../renderers";
import { Order } from "../types";

type ConfirmationProps = Pick<ConfirmationDialogProps,
    "isConfirmationDialogOpen" | "isConfirmDisabled" | "onClose" | "onConfirm">;
type CancelOrderConfirmationDialogProps = ConfirmationProps & {
  orders: Order[]
};
export const CancelOrderConfirmationDialog: React.FC<CancelOrderConfirmationDialogProps> = ({
  orders,
  ...props
}) => {
  return <ConfirmationDialog
    title="Orders to be canceled:"
    {...props}>
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>Order ID</TableCell>
          <TableCell>Order lines</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {orders && orders.map((order, index) => (
          <TableRow key={index}>
            <TableCell>{order.id}</TableCell>
            <TableCell>{orderLinesRenderer(order)}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </ConfirmationDialog>;
};