import React from "react";

import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import { head } from "lodash-es";
import { DateField, FunctionField, ReferenceField, SimpleShowLayout, TextField, useShowController } from "react-admin";

import { OrderDetails } from "../../../components/order/OrderDetails";
import { User } from "../../../components/user/types";
import { Resources } from "../../../resources";
import { Customer } from "../../../utils/commons";
import {
  customerRenderer, enumRenderer, usernameRenderer
} from "../../../utils/field-renderers";
import { OfferType } from "../../offers/types";
import { Order, OrderCancelReason, OrderStatus } from "../types";

export const OrderMainTab: React.FC = () => {
  const { record: order } = useShowController<Order>();

  if (!order) {
    return <></>;
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <SimpleShowLayout>
          <TextField source="id" label="ID"/>
          <TextField source="transactionId" label="Transaction ID"/>
          <ReferenceField
            label="Customer"
            reference={Resources.Customers}
            source="customerId"
            link={false}
            sortable={false}>
            <FunctionField render={(customer: Customer) => customerRenderer(customer)}/>
          </ReferenceField>
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={4}>
        <SimpleShowLayout>
          <FunctionField
            label="Status"
            render={() => enumRenderer(order?.orderStatus, OrderStatus)}
          />
          <FunctionField
            label="Type"
            render={(record: Order) => enumRenderer(head(record.orderLines)?.offer.offerType, OfferType)}
          />
          <FunctionField
            label="Cancel Reason"
            render={() => enumRenderer(order?.cancelReason, OrderCancelReason)}
          />
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={4}>
        <SimpleShowLayout>
          <ReferenceField
            label="Created by"
            reference={Resources.Users}
            source="createdBy"
            sortable={false}
            link={false}>
            <FunctionField render={(user: User) => usernameRenderer(user)}/>
          </ReferenceField>
          <DateField
            source="timeCreated"
            label="Creation date"
            showTime/>
          <DateField
            source="timeCompleted"
            label="Completion date"
            showTime/>
        </SimpleShowLayout>
      </Grid>

      <Grid item xs={8}>
        <SimpleShowLayout>
          <Typography variant="h6">Order details:</Typography>
          {order?.orderLines && <OrderDetails
            contract={order.contract}
            orderLines={order.orderLines}
            currency={order.currency}
            orderAmount={order.amount}
          />}
        </SimpleShowLayout>
      </Grid>
    </Grid>
  );
};