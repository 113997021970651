import React, { useEffect, useState } from "react";

import StoveIcon from "@mui/icons-material/OutdoorGrill";
import { RaRecord } from "ra-core";
import { AutocompleteArrayInput } from "react-admin";

import { buildLabel } from "../../filter/filter-utils";
import { useStoveCollections } from "./use-stove-collections";

export const useStoveCollectionFilter = (label = "Stove Collection", source = "stoveCollectionIds") => {
  const { stoveCollections } = useStoveCollections();
  const [ choices, setChoices ] = useState<RaRecord[]>([]);

  useEffect(() => {
    const choices = stoveCollections.map((collection) =>
      ({ id: collection.id, name: collection.name }))
      .sort((a, b) => a.name.localeCompare(b.name));
    setChoices(choices);
  }, [stoveCollections]);

  return <AutocompleteArrayInput key="stoveCollections"
    label={buildLabel({ label: label, Icon: StoveIcon })}
    source={source}
    variant="filled"
    choices={choices} />;
};