import * as React from "react";
import { useEffect, useState } from "react";

import HelpIcon from "@mui/icons-material/Help";
import {
  Box,
  FormControl,
  FormControlLabel,
  FormGroup, InputLabel,
  MenuItem,
  Select,
  Switch,
  Tooltip,
  Typography
} from "@mui/material";
import Grid from "@mui/material/Grid";
import { constant, groupBy, isNil } from "lodash-es";
import moment from "moment";
import { useDataProvider, useNotify, useRedirect } from "react-admin";
import { CategoricalChartFunc } from "recharts/types/chart/generateCategoricalChart";

import { StoveCollection } from "../../components/stove/stovecollection/types";
import { Resources } from "../../resources";
import { ChartAvgGroupingConfiguration } from "./charts/ChartAvgGroupingConfiguration";
import { DashboardLineCharts } from "./charts/DashboadLineChart";
import { DashboardBarChart } from "./charts/DashboardBarChart";
import { DashboardChartsFilters } from "./DashboardChartsFilters";
import styles from "./styles.module.scss";
import {
  ChartsBucketData,
  ChartsData,
  ChartsDataResponse,
  DashboardChartFilters,
  PelletsBucketDataResponse,
  ReportGrouping, StovesReportResponse
} from "./types";

const toChartsData = (data: { date: string, name: string}[]): ChartsData[] => {
  return Object.entries(groupBy(data, "date")).map(([date, data]) => ({
    date: moment(date, "YYYY-MM").format("MMM YY"),
    data: data.reduce((acc, item: any) => ({ ...acc, [item.name]: item }), {})
  }));
};

const toChartsBucketData = (data: { bucketName: string, name: string}[]): ChartsBucketData[] => {
  return Object.entries(groupBy(data, "bucketName")).map(([bucketName, data]) => ({
    bucketName,
    data: data.reduce((acc, item: any) => ({ ...acc, [item.name]: item }), {})
  }));
};

type Props = {
  isExternalCompanyAdmin: boolean
};

export const DashboardCharts: React.FC<Props> = ({ isExternalCompanyAdmin }) => {
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const redirect = useRedirect();
  const [customerData, setCustomerData] = useState<ChartsData[] | undefined>();
  const [pelletsData, setPelletsData] = useState<ChartsData[] | undefined>();
  const [pelletsBucketData, setPelletsBucketData] = useState<ChartsBucketData[] | undefined>();
  const [connectivityBucketData, setConnectivityBucketData] = useState<ChartsBucketData[] | undefined>();
  const [portfolioChangeBucketData, setPortfolioChangeBucketData] = useState<ChartsBucketData[] | undefined>();

  const [includeFirstOrders, setIncludeFirstOrders] = useState(false);
  const [grouping, setGrouping] = useState<ReportGrouping>(ReportGrouping.TOTAL);
  const [collections, setCollections] = useState<StoveCollection[]>([]);

  const [countries, setCountries] = useState<string[]>();
  const [locationIds, setLocationIds] = useState<number[]>();
  const [companyIds, setCompanyIds] = useState<number[]>();
  const [stoveCollectionIds, setStoveCollectionIds] = useState<number[]>([]);
  const [startDate, setStartDate] = useState<string>();
  const [endDate, setEndDate] = useState<string>();
  const [nftEnabled, setNftEnabled] = useState<boolean | null>(null);

  useEffect(() => {
    dataProvider.getList<StoveCollection>(Resources.StoveCollections,
      {
        filter: {},
        pagination: { page: 1, perPage: 100 },
        sort: { field: "id", order: "ASC" }
      })
      .then((response) => {
        setCollections(response.data);
        return response;
      });
  }, [dataProvider]);
  
  useEffect(() => {
    if (!countries && !locationIds && !companyIds) return;

    setCustomerData(undefined);
    setPelletsData(undefined);

    const request =
        { countries, locationIds, companyIds, startDate, endDate, grouping, includeFirstOrders, nftEnabled };
    dataProvider.getManyByUrlWithBody("pellets/reports", request)
      .then((response: { data: ChartsDataResponse} ) => {
        setCustomerData(toChartsData(response.data.customersData.data));
        setPelletsData(toChartsData(response.data.pelletsData.data));
        return response;
      })
      .catch(() => {
        notify("Failed to get charts data", { type: "error" });
      });
  }, [dataProvider, notify, includeFirstOrders,
    grouping, countries, locationIds,
    companyIds, startDate, endDate,
    nftEnabled]);

  useEffect(() => {
    if (!countries && !locationIds && !companyIds) return;

    setPelletsBucketData(undefined);
    setPortfolioChangeBucketData(undefined);

    const request = { countries, locationIds, companyIds, includeFirstOrders, nftEnabled };
    dataProvider.getManyByUrlWithBody("pellets/reports/buckets", request)
      .then((response: { data: PelletsBucketDataResponse} ) => {
        setPelletsBucketData(toChartsBucketData(response.data.data));
        setPortfolioChangeBucketData(toChartsBucketData(response.data.portfolioChangeData));
        return response;
      })
      .catch(() => {
        notify("Failed to get charts data", { type: "error" });
      });
  }, [dataProvider, notify, includeFirstOrders, countries, locationIds, companyIds, nftEnabled]);

  useEffect(() => {
    if (!countries && !locationIds && !companyIds) return;

    setConnectivityBucketData(undefined);

    const request = { countries, locationIds, companyIds, nftEnabled, stoveCollectionIds };
    dataProvider.getManyByUrlWithBody("connectivity/reports/buckets", request)
      .then((response: { data: StovesReportResponse} ) => {
        setConnectivityBucketData(toChartsBucketData(response.data.connectivityData));
        return response;
      })
      .catch(() => {
        notify("Failed to get charts data", { type: "error" });
      });
  }, [dataProvider, notify, countries, locationIds, companyIds, nftEnabled, stoveCollectionIds]);

  const onFiltersChange = (filters: DashboardChartFilters) => {
    setCountries(filters.countries);
    setLocationIds(filters.locationIds);
    setCompanyIds(filters.companyIds);
    setStartDate(filters.startDate);
    setEndDate(filters.endDate);
    setStoveCollectionIds(filters.stoveCollectionIds);
    if (filters.nftEnabled === "") {
      setNftEnabled(null);
    } else if (filters.nftEnabled === "true") {
      setNftEnabled(true);
    } else if (filters.nftEnabled === "false") {
      setNftEnabled(false);
    }
  };

  const handleChartClick = (activeLabel: string | undefined, filters: any) => {
    if (!activeLabel) return;

    const displayedFilters = Object.entries(filters)
      .filter(([, value]) => !isNil(value))
      .reduce((acc, [key]) => ({ ...acc, [key]: true }), {});

    const displayedFiltersQuery = encodeURIComponent(JSON.stringify(displayedFilters));
    const filtersQuery = encodeURIComponent(JSON.stringify(filters));
    redirect(`/profile-performance?displayedFilters=${displayedFiltersQuery}&filter=${filtersQuery}`);
  };

  const onBucketsChartClick: CategoricalChartFunc = (data) => {
    const activeLabel = data?.activeLabel;
    const filters = {
      bucketName: activeLabel,
      includeFirstOrders,
      countries: countries,
      cityIds: locationIds,
      companyIds: companyIds,
      nftEnabled: nftEnabled,
      customerGroups: ["UTILITY"]
    };
    handleChartClick(activeLabel, filters);
  };

  const onConnectivityBucketsChartClick: CategoricalChartFunc = (data) => {
    const activeLabel = data?.activeLabel;
    const filters = {
      connectivityLevels: activeLabel,
      countries: countries,
      cityIds: locationIds,
      companyIds: companyIds,
      nftEnabled: nftEnabled,
      stoveCollectionIds: stoveCollectionIds
    };
    handleChartClick(activeLabel, filters);
  };
  return (
    <Grid container
      spacing={2}
      sx={{ my: 1, overflow: "hidden" }}
      className={styles.dashboardCharts}
    >
      <Grid item xs={12} sx={{ display: "flex", alignItems: "end", justifyContent: "space-between" }}>
        <Typography variant="subtitle2" color="secondary">CUSTOMERS STATISTICS</Typography>
        <DashboardChartsFilters onFiltersChange={onFiltersChange} isExternalCompanyAdmin={isExternalCompanyAdmin}/>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <DashboardBarChart
              title={"New customers"}
              chartsData={customerData}
              stacked
              syncId={"customers"}
              dataKey={"date"}
              property={"totalRegistered"} />
          </Grid>
          <Grid item xs={4}>
            <DashboardLineCharts
              title={"Repossessed customers"}
              chartsData={customerData}
              syncId={"customers"}
              dataKey={"date"}
              property={"totalRepossessed"}
              tooltipFormatter={(value: any, name: any, props: any) => {
                return `${value} (${props.payload.data[name].totalRepossessedPercentages}%)`;
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <DashboardLineCharts
              title={"Total active customers"}
              chartsData={customerData}
              syncId={"customers"}
              dataKey={"date"}
              property={"totalActive"} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
        <Typography variant="subtitle2" color="secondary">PELLETS STATISTICS</Typography>
        <FormGroup sx={{ ml: 4 }}>
          <FormControlLabel
            control={
              <Switch checked={includeFirstOrders}
                onChange={() => setIncludeFirstOrders(!includeFirstOrders)}
                size={"small"}/>
            }
            label={
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography variant={"body2"}>Include new customers</Typography>
                <Tooltip
                  title="Whether to include the very first pellet purchase of each customer in the statistics">
                  <HelpIcon color="secondary" fontSize="small" sx={{ ml: 1 }}/>
                </Tooltip>
              </Box>
            } />
        </FormGroup>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <DashboardLineCharts
              title={"Total pellet sales per month, tons"}
              chartsData={pelletsData}
              syncId={"pellets"}
              dataKey={"date"}
              property={"totalPellets"} />
          </Grid>
          <Grid item xs={4}>
            <DashboardLineCharts
              title={"Average kg per day per customer"}
              chartsData={pelletsData}
              syncId={"pellets"}
              dataKey={"date"}
              property={"avgPellets"}
              additionalLevers={
                <ChartAvgGroupingConfiguration onChange={(grouping) => setGrouping(grouping)} />
              }
            />
          </Grid>
          <Grid item xs={4}>
            <DashboardBarChart
              title={"Customers per kg per month"}
              chartsData={pelletsBucketData}
              dataKey={"bucketName"}
              property={"customers"}
              onClick={onBucketsChartClick}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle2" color="secondary">PORTFOLIO CHANGE ANALYSIS</Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <DashboardLineCharts
              title={"Average kg per day per customer (if X kg category excluded)"}
              chartsData={portfolioChangeBucketData}
              syncId={"buckets"}
              dataKey={"bucketName"}
              property={"avgPellets"}
              tooltipFormatter={(value: any, name: any, props: any) => {
                return `${value} (${props.payload.data[name].customers} customers)`;
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <DashboardLineCharts
              title={"Pellets loss, tons (if X kg category excluded)"}
              chartsData={portfolioChangeBucketData}
              syncId={"buckets"}
              dataKey={"bucketName"}
              property={"pelletsLoss"}
              tooltipFormatter={(value: any, name: any, props: any) => {
                return `${value} (${props.payload.data[name].customers} customers)`;
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
        <Typography variant="subtitle2" color="secondary">STOVES STATISTICS</Typography>
        <FormControl
          sx={{ ml: 4, width: "200px" }}
          size="small"
          className={"MuiFormControl-default"}
        >
          <InputLabel id="stoveCollectionIds" sx={{ width: "100%" }}>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              Collections
            </Box>
          </InputLabel>
          <Select
            IconComponent={constant(null)}
            labelId={"stoveCollectionIds"}
            label="Collections"
            name="stoveCollectionIds"
            value={stoveCollectionIds}
            onChange={(event) => {
              const selectedIds = Array.isArray(event.target.value)
                ? event.target.value.map(Number)
                : [];
              setStoveCollectionIds(selectedIds);
            }}
            multiple={true}
          >
            {collections.map((collection) => (
              <MenuItem key={collection.id} value={collection.id}>
                {collection.name}
              </MenuItem>
            ))}
          </Select>

        </FormControl>
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <DashboardBarChart
              title={"Customers stoves connectivity"}
              chartsData={connectivityBucketData}
              dataKey={"bucketName"}
              property={"count"}
              onClick={onConnectivityBucketsChartClick}
            />
          </Grid>
        </Grid>
      </Grid>

    </Grid>
  );
};