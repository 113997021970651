export const CHART_COLORS = [
  "#007bff",
  "#fd7e14",
  "#28a745",
  "#6610f2",
  "#dc3545",
  "#343a40"
];

export const BUCKETS = [
  "0",
  "0-10",
  "10-15",
  "15-20",
  "20-25",
  "25-30",
  "30-35",
  "35-40",
  "40+"
];