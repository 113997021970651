import * as React from "react";
import { useEffect, useState } from "react";

import LanguageIcon from "@mui/icons-material/Language";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import SearchIcon from "@mui/icons-material/Search";
import StoreIcon from "@mui/icons-material/Store";
import { Box, IconButton, ToggleButton, ToggleButtonGroup, Tooltip } from "@mui/material";
import { isEmpty } from "lodash-es";
import moment from "moment";
import * as yup from "yup";
import { ArraySchema } from "yup";

import { useCompanies } from "../../components/company/use-companies";
import DateTimeFormInput from "../../components/form/input/datetime/DateTimeFormInput";
import { FormSelect, FormSelectMenuItem } from "../../components/form/input/select/FormSelect";
import { FormProps, useForm } from "../../components/form/use-form";
import { yupDate } from "../../components/form/validation";
import { LocationLevel } from "../../components/location/types";
import { useLocations } from "../../components/location/use-locations";
import { SECONDARY_COLOR } from "../../core/theme/theme";
import { Country } from "../../utils/commons";
import { DateTimeFormat, formatDate } from "../../utils/date-time-utils";
import { countryNameRenderer } from "../../utils/field-renderers";
import { DashboardChartFilters } from "./types";
import { CHART_COLORS } from "./utils";

type Props = {
  onFiltersChange: (filters: DashboardChartFilters) => void,
  isExternalCompanyAdmin: boolean
};

enum LocationType {
  Country = "Country",
  City = "City",
  Company = "Company"
}

const nftMenuItems: FormSelectMenuItem[] = [
  { value: "true", label: "NFT" },
  { value: "false", label: "Non-NFT" }
];

const initialFilters: DashboardChartFilters = {
  nftEnabled: "",
  countries: ["ZM", "MW"],
  locationIds: [],
  companyIds: [],
  stoveCollectionIds: [],
  startDate: formatDate(moment().subtract(1, "year").startOf("month"), DateTimeFormat.DATE_ISO),
  endDate: formatDate(moment().endOf("month"), DateTimeFormat.DATE_ISO)
};

const validationSchema = yup.object({
  countries: yup.array().of(yup.string())
    .max(CHART_COLORS.length).label("Countries")
    .when(["locationIds", "companyIds"], {
      is: (locationIds: number[], companyIds: number[]) => isEmpty(locationIds) && isEmpty(companyIds),
      then: (schema: ArraySchema<any>) => schema.min(1, "Countries, Cities or Companies must be selected").required()
    }),
  locationIds: yup.array().of(yup.number()).max(CHART_COLORS.length).label("Cities"),
  companyIds: yup.array().of(yup.number()).max(CHART_COLORS.length).label("Companies"),
  startDate: yupDate().required().label("Period Start"),
  endDate: yupDate().required().label("Period End")
});

const countriesMenuItems = Object.entries(Country).map(([value, label]) => ({ value, label }));

const buildSelect = (name: string, label: string, form: FormProps<any>, menuItems: FormSelectMenuItem[]) => {
  return (<FormSelect name={name}
    label={label}
    clearable={false}
    multiple={true}
    form={form}
    size="small"
    menuItems={menuItems}/>
  );
};

export const DashboardChartsFilters: React.FC<Props> = ({
  onFiltersChange,
  isExternalCompanyAdmin = true
}) => {
  const { locations } = useLocations();
  const { companies } = useCompanies();
  const [locationType, setLocationType] = useState<LocationType>(LocationType.Country);
  const [citiesMenuItems, setCitiesMenuItems] = useState<FormSelectMenuItem[]>([]);
  const [companiesMenuItems, setCompaniesMenuItems] = useState<FormSelectMenuItem[]>([]);
  const form = useForm<DashboardChartFilters>(initialFilters, validationSchema);

  const onFilterApply = () => {
    const filters: DashboardChartFilters = {
      countries: locationType == LocationType.Country ? form.value.countries : undefined,
      locationIds: locationType == LocationType.City ? form.value.locationIds : undefined,
      companyIds: locationType == LocationType.Company ? form.value.companyIds : undefined,
      stoveCollectionIds: form.value.stoveCollectionIds,
      nftEnabled: form.value.nftEnabled,
      startDate: moment(form.value.startDate).startOf("month").format(DateTimeFormat.DATE_ISO),
      endDate: moment(form.value.endDate).endOf("month").format(DateTimeFormat.DATE_ISO)
    };
    onFiltersChange(filters);
  };

  useEffect(() => {
    const sortedCities = locations.filter((location) => location.level == LocationLevel.City)
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((location) => ({ value: location.id, label: `${location.name} (${countryNameRenderer(location)})` }));
    setCitiesMenuItems(sortedCities);
  }, [locations]);

  useEffect(() => {
    const sortedCompanies = companies.filter((company) => company.type === "DISTRIBUTION_PARTNER")
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((company) => ({ value: company.id, label: company.name }));
    setCompaniesMenuItems(sortedCompanies);
  }, [companies]);

  useEffect(() => {
    onFilterApply();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLocationTypeChange = (event: any, newValue: LocationType) => setLocationType(newValue);

  const buildLocationSelector = () => {
    switch (locationType) {
    case LocationType.Country:
      return buildSelect("countries", "Countries", form, countriesMenuItems);
    case LocationType.City:
      return buildSelect("locationIds", "Cities", form, citiesMenuItems);
    case LocationType.Company:
      return buildSelect("companyIds", "Companies", form, companiesMenuItems);
    }
  };

  const buildNftSelector = () => {
    return (
      <FormSelect
        name="nftEnabled"
        label="NFT Filter"
        clearable={true}
        multiple={false}
        form={form}
        size="small"
        menuItems={nftMenuItems}
      />
    );
  };

  return (
    <Box sx={{ display: "flex", alignItems: "start" }}>
      {
        !isExternalCompanyAdmin &&
        <>
          <ToggleButtonGroup
            sx={{ ml: 2 }}
            value={locationType}
            exclusive
            size="small"
            onChange={handleLocationTypeChange}
          >
            <ToggleButton value={LocationType.Country}>
              <Tooltip title="County view">
                <LanguageIcon />
              </Tooltip>
            </ToggleButton>
            <ToggleButton value={LocationType.City}>
              <Tooltip title="City view">
                <LocationCityIcon />
              </Tooltip>
            </ToggleButton>
            <ToggleButton value={LocationType.Company}>
              <Tooltip title="Company view">
                <StoreIcon />
              </Tooltip>
            </ToggleButton>
          </ToggleButtonGroup>
          <Box sx={{ ml: 2, width: 250 }}>{buildLocationSelector()}</Box>
        </>
      }
      <Box sx={{ ml: 2, display: "flex" }}>
        <DateTimeFormInput
          name="startDate"
          label="Period Start"
          fullWidth={false}
          form={form}
          size={"small"}
          onlyDate
          datePickerProps={{
            showMonthYearPicker: true,
            dateFormat: "MMM yyyy"
          }}
          sx={{ width: 150 }}
          minDateTime={moment("2022-01-01")}
          maxDateTime={moment(form.value.endDate)}
        />
        <Box sx={{ ml: 1 }}>
          <DateTimeFormInput
            name="endDate"
            label="Period End"
            fullWidth={false}
            form={form}
            size={"small"}
            onlyDate
            datePickerProps={{
              showMonthYearPicker: true,
              dateFormat: "MMM yyyy"
            }}
            sx={{ width: 150 }}
            minDateTime={moment(form.value.startDate)}
            maxDateTime={moment().endOf("month")}
          />
        </Box>
      </Box>
      <Box sx={{ ml: 2, width: 120 }}>{buildNftSelector()}</Box>
      <Tooltip title="Apply filters">
        <Box>
          <IconButton
            disabled={!form.isValid}
            sx={{ ml: 2, border: `1px solid ${SECONDARY_COLOR}` }}
            onClick={onFilterApply}
          >
            <SearchIcon />
          </IconButton>
        </Box>
      </Tooltip>
    </Box>
  );
};