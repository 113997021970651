export enum ReportGrouping {
  TOTAL,
  MONTHLY
}

export type ChartsData = {
  date: string,
  data: Record<string, any>
};

export type ChartsBucketData = {
  bucketName: string,
  data: Record<string, any>
};

type CustomerDataItem = {
  name: string,
  date: string,
  totalRegistered: number,
  totalRepossessed: number,
  totalRepossessedPercentages: number
};

type CustomerData = {
  data: CustomerDataItem[]
};

type PelletDataItem = {
  name: string,
  date: string,
  totalPellets: number,
  avgPellets: number
};

type PelletData = {
  data: PelletDataItem[]
};

export type ChartsDataResponse = {
  customersData: CustomerData,
  pelletsData: PelletData
};

type PelletsBucketData = {
  name: string,
  bucketName: string,
  customers: number
};

type ConnectivityBucketData = {
  name: string,
  bucketName: string,
  count: number
};

type PortfolioChangeBucketData = {
  name: string,
  bucketName: string,
  customers: number,
  avgPellets: number,
  pelletsLoss: number
};

export type PelletsBucketDataResponse = {
  data: PelletsBucketData[],
  portfolioChangeData: PortfolioChangeBucketData[]
};

export type StovesReportResponse = {
  connectivityData: ConnectivityBucketData[]
};

export type DashboardChartFilters = {
  countries?: string[],
  locationIds?: number[],
  companyIds?: number[],
  stoveCollectionIds: number[],
  nftEnabled?: string,
  startDate: string,
  endDate: string
};