import React from "react";

import { Divider, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";

import { Contract } from "../../pages/contracts/types";
import { dealName } from "../../pages/offers/renderers";
import { OrderLine } from "../../pages/orders/types";

type OrderDetailsProps = {
  orderLines?: OrderLine[],
  contract?: Contract,
  orderAmount?: number,
  currency: string
};
export const OrderDetails: React.FC<OrderDetailsProps> = ({ orderLines, contract, orderAmount, currency }) => {

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={3}><Typography variant="subtitle1">Offer name</Typography></Grid>
        <Grid item xs={3}><Typography variant="subtitle1">Discount</Typography></Grid>
        <Grid item xs={2}><Typography variant="subtitle1">Quantity</Typography></Grid>
        <Grid item xs={2}><Typography variant="subtitle1">Price per unit</Typography></Grid>
        <Grid item xs={2}><Typography variant="subtitle1">Subtotal</Typography></Grid>
      </Grid>
      <Divider/>
      {
        contract && (<>
          <Grid container spacing={2}>
            <Grid item xs={3}><Typography> {dealName(contract)}</Typography></Grid>
            <Grid item xs={3}><Typography>-</Typography></Grid>
            <Grid item xs={2}><Typography>1</Typography></Grid>
            <Grid item xs={2}><Typography>{orderAmount}{" "}{currency}</Typography></Grid>
            <Grid item xs={2}><Typography>{orderAmount}{" "}{currency}</Typography></Grid>
          </Grid>
          <Divider/>
        </>)
      }
      {orderLines && orderLines.map((line, index) => (
        <React.Fragment key={index}>
          <Grid container spacing={2} key={index}>
            <Grid item xs={3}><Typography>{line.offer.name}</Typography></Grid>
            <Grid item xs={3}><Typography>{line.discount?.name || "-"}</Typography></Grid>
            <Grid item xs={2}><Typography>{line.quantity}</Typography></Grid>
            <Grid item xs={2}>
              <Typography sx={{ mb: 2 }} >
                {line.offer.price ? (
                  line.discount ? (
                    <>
                      <del>{line.offer.price}{" "}{currency}</del> {" "}
                      {line.amount / line.quantity}{" "}{currency}
                    </>
                  ) : (
                    <>{line.offer.price}{" "}{currency}</>
                  )
                ) : (
                  <>{line.amount} {currency}</>
                )}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography sx={{ mb: 2 }} >
                {line.offer.price ? (
                  line.discount ? (
                    <>
                      <del>{line.quantity * line.offer.price}{" "}{currency}</del> {" "}
                      {line.amount}{" "}{currency}
                    </>
                  ) : (
                    <>{line.quantity * line.offer.price}{" "}{currency}</>
                  )
                ) : (
                  <>{line.quantity * line.amount} {currency}</>
                )}
              </Typography>
            </Grid>
          </Grid>
          <Divider/>
        </React.Fragment>
      ))}
      <Grid container spacing={2}>
        <Grid item xs={3}></Grid>
        <Grid item xs={3}></Grid>
        <Grid item xs={2}></Grid>
        <Grid item xs={2}>
          <Typography variant="subtitle1" align="right">Total:</Typography>
        </Grid>
        <Grid item xs={2}>
          {orderAmount}{" "}{currency}
        </Grid>
      </Grid>
    </>
  );
};