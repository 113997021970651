import React from "react";

import Alert from "@mui/material/Alert";
import Grid from "@mui/material/Grid";

import { FullCustomer } from "../../../utils/commons";

export const InactiveCustomerAlert: React.FC<{customer: FullCustomer}> = ({ customer }) => {
  return (
    <Grid item xs={12}>
      <Alert severity="warning">
          The target customer with reference number {customer?.contractReference} is not Active
      </Alert>
    </Grid>);
};