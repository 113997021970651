import React from "react";

import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@mui/material";
import Grid from "@mui/material/Grid";

type AdjustmentApproveProps = {
  onClose: () => void,
  onConfirm: (adjustmentStatus: string) => void,
  isProcessing: boolean,
  isOpened: boolean
};

export const AdjustmentApproveDialog: React.FC<AdjustmentApproveProps> = ({
  onClose,
  onConfirm,
  isProcessing,
  isOpened
}) => {

  return <Dialog
    fullWidth
    open={isOpened}
    onClose={onClose}
  >
    <DialogTitle>Approve adjustments</DialogTitle>
    <DialogContent>
      <Grid container spacing={2} sx={{ mt:1 }}>
        <Grid item xs={12}>
          <DialogContentText>
            Please approve or reject selected adjustments
          </DialogContentText>
        </Grid>
      </Grid>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose}>
        {"Cancel"}
      </Button>
      <Button
        disabled={isProcessing}
        onClick={() => onConfirm("REJECTED")}>
        {isProcessing ?
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <CircularProgress color="secondary" size={20}/> Processing
          </Box>
          : "Reject"
        }
      </Button>
      <Button
        disabled={isProcessing}
        onClick={() => onConfirm("APPROVED")}>
        {isProcessing ?
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <CircularProgress color="secondary" size={20}/> Processing
          </Box>
          : "Approve"
        }
      </Button>
    </DialogActions>
  </Dialog>;
};