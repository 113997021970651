import { UserRoles } from "../../core/providers/auth/roles";

export const USER_ROLES: Record<keyof typeof UserRoles, string> = {
  [UserRoles.ROLE_SUPAMOTO_ADMIN]: "SupaMoto Admin",
  [UserRoles.ROLE_EXTERNAL_COMPANY_ADMIN]: "External Company Admin",
  [UserRoles.ROLE_READ_ONLY]: "Read-only (no export)",
  [UserRoles.ROLE_STOCK_MANAGER]: "Stock Manager",
  [UserRoles.ROLE_API]: "API user",
  [UserRoles.ROLE_DELIVERY_MANAGER]: "Delivery Manager",
  [UserRoles.ROLE_SHOP_KEEPER]: "Shop Keeper",
  [UserRoles.ROLE_SALES]: "Sales"
};
