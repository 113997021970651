import React, { useState } from "react";

import DownloadIcon from "@mui/icons-material/Download";
import { Box, Button, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import Grid from "@mui/material/Grid";
import { isEmpty } from "lodash-es";
import { FilterButton, TopToolbar } from "react-admin";

import samplingDataTemplate from "../../assets/templates/sampling_data_template.csv";
import { ImportDataButton } from "../../components/list/import/ImportDataButton";
import { UserRoles } from "../../core/providers/auth/roles";
import { SECONDARY_COLOR } from "../../core/theme/theme";
import { useCheckAccess } from "../../utils/use-check-access";

export const SamplingsListActions: React.FC = () => {
  const { hasAccess } = useCheckAccess(UserRoles.ROLE_SUPAMOTO_ADMIN);

  const [stoveUid, setStoveUid] = useState("");
  const [stoveModel, setStoveModel] = useState("");

  const isFormValid = () => !isEmpty(stoveUid) && !isEmpty(stoveModel);
  const onDialogClose = () => {
    setStoveUid("");
    setStoveModel("");
  };

  return (
    <TopToolbar sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
      <FilterButton/>
      <ImportDataButton
        resource={() => `samplings/${stoveModel}/${stoveUid}`}
        disabled={!hasAccess}
        isDataValid={isFormValid}
        onDialogClose={onDialogClose}
        buttonLabel="Import sampling data"
        buttonTooltip="Upload sampling data obtained directly from the stove's hard drive"
        dialogTitle="Import sampling data"
        dialogContentText={
          <>
            <Box sx={{ fontSize: 14, color: SECONDARY_COLOR }}>
              This feature allows you to upload sampling data obtained directly from the stove&apos;s hard drive.
              Below you can download the CSV file template containing sample data.
            </Box>
            <Box sx={{ mb:2 }}>
              <Button
                sx={{
                  textTransform: "none",
                  fontSize: 14,
                  fontWeight: 600,
                  letterSpacing: "0.25px"
                }}
                variant="text"
                startIcon={<DownloadIcon/>}
                href={samplingDataTemplate}
                download="example.csv"
              >
                Download template
              </Button>
            </Box>
            <Box sx={{ mb: 2 }}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    label="Stove UID"
                    fullWidth
                    required
                    type="number"
                    variant="outlined"
                    value={stoveUid}
                    onChange={(e) => setStoveUid(e.target.value)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel id="stove-mode">Stove Model *</InputLabel>
                    <Select
                      labelId="stove-mode"
                      label="Stove Model *"
                      value={stoveModel}
                      onChange={(event) => setStoveModel(event.target.value as string)}
                    >
                      <MenuItem value="MIMIMOTO">Mimimoto</MenuItem>
                      <MenuItem value="PCB">PCB</MenuItem>
                      <MenuItem value="SUPAMOTO">Supamoto</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Box>
          </>
        }
      />
    </TopToolbar>
  );
};
