export type CollectionPartnerStockBalance = {
  id: number,
  stockBalances: Record<string, Array<StockBalance>>
};

export type StockBalance = {
  stockItemId: number,
  name: string,
  quantity: number,
  weight: number,
  criticalThreshold: number,
  warningThreshold: number
};

export enum StockItemType {
  PELLETS = "Pellets",
  STOVE = "Stove",
  ACCESSORY = "Accessory"
}