import { useCallback, useState } from "react";

import { useDataProvider, useNotify, useRefresh, useUnselectAll } from "react-admin";

import { Resources } from "../../../../resources";

type HookActions = {
  createDelivery: (data: any, onSuccess: () => void) => void,
  isLoading: boolean
};

export const useCreateDelivery = (type: "individual" | "group"): HookActions => {
  const [ isLoading, setIsLoading ] = useState(false);
  const refresh = useRefresh();
  const unselectAllOrders = useUnselectAll(Resources.Orders);
  const dataProvider = useDataProvider();
  const notify = useNotify();

  const createDelivery = useCallback((data: any, onSuccess: () => void) => {
    setIsLoading(true);
    return dataProvider.create(`${Resources.Deliveries}/${type}`, { data })
      .then((response) => {
        refresh();
        notify("The delivery order has been submitted");
        unselectAllOrders();
        onSuccess();
        setIsLoading(false);

        return response;
      }).catch((error: any) => {
        setIsLoading(false);

        const errorCode = error.body?.errorCode;
        if (errorCode && errorCode === "deliveryOrder.already_exists") {
          notify("A delivery order has been already created for this order", { type: "warning" });
          refresh();
        } else {
          notify("Error: failed to create delivery order", { type: "error" });
        }
      });
  }, [dataProvider, notify, refresh, type, unselectAllOrders]);

  return {
    createDelivery,
    isLoading
  };
};
