import React from "react";

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { useListContext, useNotify, useRefresh, useUnselectAll, useUpdateMany } from "react-admin";

import { HTTP_METHOD } from "../../../core/providers/data/rest-data-provider";

type Props = {
  isDialogOpen: boolean,
  onDialogClose: () => void
};

export const CreateFreshdeskContactAction: React.FC<Props> = ({ isDialogOpen, onDialogClose }) => {
  const { selectedIds, resource } = useListContext();
  const refresh = useRefresh();
  const notify = useNotify();
  const unselectAll = useUnselectAll(resource);
  const [updateMany] = useUpdateMany(
    `${resource}/contacts`,
    { ids: selectedIds, data: {}, meta: { httpMethod: HTTP_METHOD.POST } },
    {
      onSuccess: () => {
        refresh();
        notify("Contacts have been created");
        unselectAll();
      },
      onError: () => notify("Error: failed to create contacts", { type: "error" })
    }
  );

  const handleConfirm = () => {
    updateMany();
    onDialogClose();
  };

  return (
    <Dialog open={isDialogOpen} onClose={onDialogClose}>
      <DialogTitle>Create contacts</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to create contacts in the Freshdesk for the selected customer?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onDialogClose}>
          Cancel
        </Button>
        <Button onClick={handleConfirm}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};
