import {
  baseMultilineTextInputFilter
} from "../../components/filter/base-multiline-text-input-filter";
import { countriesFilter } from "../../components/filter/customer-filters";
import { enumSelectFilter } from "../../components/filter/enum-select-filter";
import { buildLabel, CustomFilterIcon } from "../../components/filter/filter-utils";
import { useStockItemFilter } from "../../components/stock-item/use-stock-item-filter";
import { CustomerGroupType } from "../../utils/commons";
import { OfferStatus, OfferType } from "./types";

export const useOfferFilters = () => {
  return [
    baseMultilineTextInputFilter({
      source: "name",
      label: buildLabel({ label: "Name", Icon: CustomFilterIcon })
    }),
    countriesFilter(true, CustomFilterIcon),
    enumSelectFilter({
      source: "availableFor",
      enumObj: CustomerGroupType,
      label: buildLabel({ label: "Available for", Icon: CustomFilterIcon }),
      alwaysOn: true
    }),
    enumSelectFilter({
      source: "types",
      enumObj: OfferType,
      label: buildLabel({ label: "Type", Icon: CustomFilterIcon }),
      alwaysOn: true
    }),
    useStockItemFilter(),
    enumSelectFilter({
      source: "statuses",
      enumObj: OfferStatus,
      label: buildLabel({ label: "Status", Icon: CustomFilterIcon }),
      alwaysOn: true
    })
  ];
};