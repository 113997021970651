import { CarbonCreditClaim } from "../../components/carbon-credit-claims/types";
import { CollectionPartner } from "../../components/collections/types";
import { Contract } from "../contracts/types";
import { Discount } from "../discounts/types";
import { Offer } from "../offers/types";

export enum OrderStatus {
  NEW = "New",
  SENT_FOR_DELIVERY = "Pending delivery",
  DELIVERED = "Delivered",
  CANCELED = "Cancelled",
  PICKED_UP = "Picked up",
  COMPLETED = "Completed"
}

export enum DeliveryOrderResourceType {
  CUSTOMER = "CUSTOMER",
  GROUP_LEADER = "GROUP_LEADER",
}

export enum OrderHistoryAction {
  CREATED = "Created",
  UPDATED = "Updated",
  SENT_FOR_DELIVERY = "Sent for delivery",
  DELIVERED = "Delivered",
  PICKED_UP = "Picked up",
  CANCELED = "Cancelled"
}

export type DeliveryOrder = {
  id: number,
  resourceId: number,
  resourceType: DeliveryOrderResourceType
};

export type OrderHistory = {
  id: number,
  action: keyof typeof OrderHistoryAction,
  contributedBy: number,
  eventTime: string
};

export type ModifyOrderHistoryPayload = {
  orderLines?: OrderLine[],
  contract?: Contract,
  currency: string,
  transactionId: string,
  paidAmount: number
};

export type ModifyOrderHistory = OrderHistory & {
  payload: ModifyOrderHistoryPayload
};

export type SentForDeliveryOrderHistoryPayload = {
  shipdayOrderNo: number,
  collectionPartnerId: number
};

export type SentForDeliveryOrderHistory = OrderHistory & {
  payload: SentForDeliveryOrderHistoryPayload
};

export type DeliveredOrderHistoryPayload = {
  driverName: string
};

export type DeliveredOrderHistory = OrderHistory & {
  payload: DeliveredOrderHistoryPayload
};

export type CanceledOrderHistoryPayload = {
  reason?: string
};

export type CanceledOrderHistory = OrderHistory & {
  payload: CanceledOrderHistoryPayload
};

export type PickedUpOrderHistoryPayload = {
  collectionPartnerId: number
};

export type PickedUpOrderHistory = OrderHistory & {
  payload: PickedUpOrderHistoryPayload
};

export type AnyOrderHistory =
    ModifyOrderHistory|
    SentForDeliveryOrderHistory|
    DeliveredOrderHistory|
    CanceledOrderHistory|
    PickedUpOrderHistory;

export type OrderLine = {
  quantity: number,
  amount: number,
  offer: Offer,
  discount?: Discount
};

export type Order = {
  id: number,
  transactionId: string,
  customerId: number,
  createdBy: number,
  amount: number,
  currency: string,
  orderStatus: keyof typeof OrderStatus,
  cancelReason: keyof typeof OrderCancelReason,
  orderLines: OrderLine[],
  contract: Contract,
  timeCreated: string,
  timeCompleted: string,
  pickUpOwnerId?: number,
  deliveryOrder?: DeliveryOrder,
  collectionPartner?: CollectionPartner,
  carbonCreditClaim: CarbonCreditClaim
};

export enum OrderCancelReason {
  WRONG_CUSTOMER = "Wrong Customer",
  WRONG_AMOUNT = "Wrong Amount",
  WRONG_BAG_SIZE = "Wrong Bag Size",
  WRONG_ORDER = "Wrong Order",
  WRONG_PICK_UP_LOCATION = "Wrong Pick-up Location",
  WRONG_ACCOUNT = "Wrong Account",
  CLIENT_ALREADY_PICKED_UP = "Client already picked up",
  CALLS_NOT_ANSWERED_AFTER_MAKING_PAYMENT = "Calls not answered after making payment"
}
