import React, { useEffect, useState } from "react";

import AddIcon from "@mui/icons-material/Add";
import {
  Button,
  Tooltip
} from "@mui/material";
import { useNotify } from "ra-core";
import {
  GetListParams,
  useDataProvider,
  useRefresh
} from "react-admin";

import { CollectionPartner } from "../../../components/collections/types";
import { CreateStockEvent } from "../../../components/stock-events/types";
import { StockItem } from "../../../components/stock-item/types";
import { UserRoles } from "../../../core/providers/auth/roles";
import { Resources } from "../../../resources";
import { useCheckAccess } from "../../../utils/use-check-access";
import { CreateStockEventConfirmationDialog } from "./CreateStockEventConfirmationDialog";
import { CreateStockEventDialog } from "./CreateStockEventDialog";

type Props = {
  variant?: "text" | "outlined" | "contained"
};

export const CreateStockEventButton: React.FC<Props> = ({ variant }) => {
  const dataProvider = useDataProvider();
  const [collectionPartners, setCollectionPartners] = useState<CollectionPartner[]>([]);
  const [stockItems, setStockItems] = useState<StockItem[]>([]);
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false);
  const [createStockEvent, setCreateStockEvent] = useState<CreateStockEvent>();
  const refresh = useRefresh();
  const notify = useNotify();
  const [isCreationRequestInProgress, setIsCreationRequestInProgress] = useState(false);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const { hasAccess } = useCheckAccess([
    UserRoles.ROLE_SUPAMOTO_ADMIN, UserRoles.ROLE_STOCK_MANAGER
  ]);

  useEffect(() => {
    const params = { pagination: { page: 1, perPage: 400 }, filter: { statuses: ["ACTIVE"] } } as GetListParams;

    dataProvider.getList<CollectionPartner>(Resources.CollectionPartners, params)
      .then((response) => {
        setCollectionPartners(response.data);
        return response;
      });
  }, [dataProvider]);

  useEffect(() => {
    const params = { pagination: { page: 1, perPage: 1000 } } as GetListParams;

    dataProvider.getList<StockItem>(Resources.StockItems, params)
      .then((response) => {
        setStockItems(response.data);
        return response;
      });
  }, [dataProvider]);

  const handleClose = () => {
    setIsFormOpen(false);
    setIsConfirmationDialogOpen(false);
  };

  const handleSubmit = (data: CreateStockEvent) => {
    setCreateStockEvent(data);
    setIsConfirmationDialogOpen(true);
  };

  const handleConfirm = () => {
    if (createStockEvent) {
      submitForm(createStockEvent);
    }
  };

  const prepareDataBeforeSubmit = (data: any) => {
    if (data.type != "TRANSFER_OUT") {
      data.relatedCollectionPartnerId = undefined;
    }

    if (data.type != "ADJUSTMENT_INCREASE" && data.type != "ADJUSTMENT_DECREASE") {
      data.adjustmentReason = undefined;
    }
  };

  const submitForm = (data: CreateStockEvent) => {
    setIsCreationRequestInProgress(true);
    prepareDataBeforeSubmit(data);
    setIsConfirmationDialogOpen(false);

    dataProvider.createManyByUrlWithBody("stock-events", data)
      .then((response: any) => {
        setIsCreationRequestInProgress(false);
        refresh();
        notify("Stock Events was successfully created");
        setIsFormOpen(false);
        return response;
      }).catch(() => {
        setIsCreationRequestInProgress(false);
        notify("The internal server error occurred", { type: "error" });
      });
  };

  return (
    <>
      <Tooltip title="Manually create a stock event">
        <span>
          <Button
            color="primary"
            variant={variant}
            disabled={!hasAccess}
            onClick={() => setIsFormOpen(true)}
            startIcon={<AddIcon/>}
          >
            Create Stock Event
          </Button>
        </span>
      </Tooltip>
      <CreateStockEventDialog
        onClose={handleClose}
        onConfirm={handleSubmit}
        isProcessing={isCreationRequestInProgress}
        isOpened={isFormOpen}
        collectionPartners={collectionPartners}
      />
      {createStockEvent &&
        <CreateStockEventConfirmationDialog
          createStockEvent={createStockEvent}
          stockItems={stockItems}
          isConfirmDisabled={isCreationRequestInProgress}
          isConfirmationDialogOpen={isConfirmationDialogOpen}
          onClose={handleClose}
          onConfirm={handleConfirm}/>
      }
    </>
  );
};