import { useCallback, useEffect, useState } from "react";

import { isNil } from "lodash-es";
import { useNotify } from "ra-core";
import { useDataProvider, useStore } from "react-admin";

import { Discount } from "../../pages/discounts/types";
import { Resources } from "../../resources";

const collectDiscountsByOffer = (discounts: Discount[]) => {
  const discountsByOffer: Record<number, Discount[]> = {};
  discounts.forEach((discount) => {
    discount.offerIds.forEach((offerId) => {
      if (discountsByOffer[offerId]) {
        discountsByOffer[offerId].push(discount);
      } else {
        discountsByOffer[offerId] = [discount];
      }
    });
  });

  return discountsByOffer;
};

export const useGetDiscountsByOffer = () => {
  const dataProvider = useDataProvider();
  const notify = useNotify();

  const [isLoading, setIsLoading] = useState(false);
  const [discounts, setDiscounts] = useStore<Discount[]>("api.discounts", undefined);
  const [discountsByOffer, setDiscountsByOffer] = useStore<Record<number, Discount[]>>("api.discounts_offer", {});

  useEffect(() => {
    if (isNil(discounts)) {
      setIsLoading(true);
      dataProvider.getList<Discount>(Resources.Discounts, {
        pagination: {
          page: 1,
          perPage: 200
        },
        sort: {
          field: "name",
          order: "ASC"
        },
        filter: { statuses: ["ACTIVE"] }
      }).then(( response ) => {
        const allDiscounts = response.data;
        setDiscounts(allDiscounts);
        setDiscountsByOffer(collectDiscountsByOffer(allDiscounts));

        return response;
      }).catch(() => notify("Error: failed to retrieve discounts", { type: "error" }))
        .finally(() => setIsLoading(false));
    }
  }, [dataProvider, discounts, notify, setDiscounts, setDiscountsByOffer]);

  const getDiscountsByOffer = useCallback((offerId?: number) => {
    return isNil(offerId) ? [] : discountsByOffer[offerId] || [];
  }, [discountsByOffer]);

  return {
    discounts: isNil(discounts) ? [] : discounts,
    getDiscountsByOffer,
    isLoading
  };
};