import * as React from "react";

import { Create, useCreate } from "react-admin";

import { DeliveryGroupForm } from "./DeliveryGroupsEdit";

export const DeliveryGroupsCreate: React.FC = () => {
  const [ create ] = useCreate();

  return (
    <Create redirect="list">
      <DeliveryGroupForm submit={create}/>
    </Create>
  );
};
