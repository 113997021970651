export enum CompanyType {
  SAAS = "SaaS",
  DISTRIBUTION_PARTNER = "Distribution Partner"
}

export type Company = {
  id: number,
  name: string,
  timeCreated: string,
  numberOfStoves: number,
  type: keyof typeof CompanyType
};

export const COMPANY_CHOICES = Object.entries(CompanyType).map(([key, value]) => ({ id: key, name: value }));
