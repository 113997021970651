export enum DealType {
  CONTRACT = "CONTRACT",
  PRODUCT = "PRODUCT"
}
export type CreateOrderLine = {
  dealId: number | string,
  quantity?: number,
  amount?: number,
  discountId?: number | string,
  dealType: DealType
};

export type CreateOrderRequest = {
  customerId: number,
  orderLines: CreateOrderLine[]
};