export enum Resources {
  Users = "users",
  Companies = "companies",
  Deliveries = "deliveries",
  DeliveryGroups = "delivery-groups",
  Stoves = "stoves",
  CookingSessions = "cookings",
  SamplingData = "samplings",
  Customers = "customers",
  ProfilePerformance = "profile-performance",
  Payments = "payments",
  Orders = "orders",
  Offers = "offers",
  Locations = "locations",
  CollectionPartners = "collection-partners",
  StockBalances = "stock-balances",
  StockEvents = "stock-events",
  StockItems = "stock-items",
  Discounts = "discounts",
  StoveCollections = "stoves/stove-collections",
  Devices = "stoves/devices",
  Contracts = "contracts",
  Adjustments = "adjustments"
}
