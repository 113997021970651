import React from "react";

import Grid from "@mui/material/Grid";
import {
  Datagrid,
  FunctionField,
  ListContextProvider, ReferenceField,
  SimpleShowLayout, TextField,
  useList,
  useRecordContext
} from "react-admin";
import { Link } from "react-router-dom";

import { PRIMARY_COLOR } from "../../core/theme/theme";
import { Resources } from "../../resources";
import { enumChipRenderer } from "../../utils/field-renderers";
import { buildOfferName } from "../offers/renderers";
import {
  ConditionType, conditionTypeChipColors,
  ContractDetail
} from "./types";

export const sortContractDetails = (details: ContractDetail[]) => {
  return details.sort((a: ContractDetail, b: ContractDetail) => (a.id > b.id ? -1 : 1));
};

export const ContractDetailsList: React.FC = () => {
  const currentContractRecord = useRecordContext();
  const sortedDetails = sortContractDetails(currentContractRecord?.details);

  const listContext = useList({ data: sortedDetails });

  return (
    <Grid container spacing={2}>
      <Grid item xs={10}>
        <SimpleShowLayout>
          <ListContextProvider value={listContext}>
            <Datagrid bulkActionButtons={false}>
              <FunctionField label="Offer" render={(detail: ContractDetail) => buildOfferName(detail.offer)}/>
              <FunctionField label="Discount" render={(detail: ContractDetail) => detail.discount ? detail.discount.name : "-"}/>
              <TextField source="quantity" label="Quantity" sortable={false}/>
              <ReferenceField
                label="Device"
                reference={Resources.Devices}
                source="instanceId"
                link={false}>
                <FunctionField source="instanceId" label="Device" render={(record: any) =>
                  <Link to={`/stoves/devices/${record.id}/show`}
                    style={{ color: PRIMARY_COLOR, textDecoration: "none" }}
                  >{record.deviceId}</Link>
                }/>
              </ReferenceField>
              <FunctionField label="Condition Type"
                render={(record: ContractDetail) =>
                  record.conditionType ?
                    enumChipRenderer(record.conditionType, ConditionType, conditionTypeChipColors) : <>-</>}/>
            </Datagrid>
          </ListContextProvider>
        </SimpleShowLayout>
      </Grid>
    </Grid>
  );
};