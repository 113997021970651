import { CarbonCreditClaimStatus } from "../../components/carbon-credit-claims/types";
import {
  useCollectionPartnerFilter
} from "../../components/collections/use-collection-partner-filter";
import {
  baseMultilineTextInputFilter
} from "../../components/filter/base-multiline-text-input-filter";
import { enumSelectFilter } from "../../components/filter/enum-select-filter";
import { buildLabel, CustomFilterIcon } from "../../components/filter/filter-utils";
import { useDateRangeFilters } from "../../components/filter/use-date-range-filters";
import { useNumberRangeFilters } from "../../components/filter/use-number-range-filters";
import { useStockItemFilter } from "../../components/stock-item/use-stock-item-filter";
import { useUserFilter } from "../../components/user/use-user-filter";
import { UserRoles } from "../../core/providers/auth/roles";
import { useCheckAccess } from "../../utils/use-check-access";
import { useNumberList } from "../companies/use-number-list";
import { OfferType } from "../offers/types";
import { OrderCancelReason, OrderStatus } from "./types";

export const useOrderFilters = () => {
  const { textToArray, arrayToText } = useNumberList();

  const { hasAccess: isAdmin } = useCheckAccess(UserRoles.ROLE_SUPAMOTO_ADMIN);
  
  return [
    baseMultilineTextInputFilter({
      source: "orderIds",
      label: buildLabel({ label: "Order IDs", Icon: CustomFilterIcon }),
      parse: textToArray,
      format: arrayToText
    }),
    baseMultilineTextInputFilter({
      source: "transactionIds",
      label: buildLabel({ label: "Transaction IDs", Icon: CustomFilterIcon })
    }),
    baseMultilineTextInputFilter({
      source: "deliveryOrderIds",
      label: buildLabel({ label: "Delivery Order IDs", Icon: CustomFilterIcon }),
      parse: textToArray,
      format: arrayToText
    }),
    useCollectionPartnerFilter(),
    enumSelectFilter({
      source: "offerTypes",
      enumObj: OfferType,
      label: buildLabel({ label: "Order type", Icon: CustomFilterIcon })
    }),
    useStockItemFilter(),
    ...useNumberRangeFilters("bagSizeGOE",
      "Bag size (>=)",
      "bagSizeLOE",
      "Bag size (<=)"),
    enumSelectFilter({
      source: "statuses",
      enumObj: OrderStatus,
      label: buildLabel({ label: "Order status", Icon: CustomFilterIcon })
    }),
    enumSelectFilter({
      source: "cancelReasons",
      enumObj: OrderCancelReason,
      label: buildLabel({ label: "Cancel Reason", Icon: CustomFilterIcon })
    }),
    useUserFilter("Created by", "createdByIds"),
    ...useNumberRangeFilters("amountGOE", "Amount (>=)", "amountLOE", "Amount (<=)"),
    ...useDateRangeFilters({
      lowerBoundSource: "timeCreatedFrom",
      lowerBoundLabel: "Creation date (>=)",
      upperBoundSource: "timeCreatedTo",
      upperBoundLabel: "Creation date (<=)",
      alwaysOn: true
    }),
    ...useDateRangeFilters({
      lowerBoundSource: "timeCompletedFrom",
      lowerBoundLabel: "Completion date (>=)",
      upperBoundSource: "timeCompletedTo",
      upperBoundLabel: "Completion date (<=)"
    }),
    ...(isAdmin ? [enumSelectFilter({
      source: "carbonCreditClaimStatuses",
      enumObj: CarbonCreditClaimStatus,
      label: buildLabel({ label: "Carbon credit claim status", Icon: CustomFilterIcon })
    })] : [])
  ];
};