import { ThemeOptions, createTheme } from "@mui/material";

export const PRIMARY_COLOR = "#f68220";
export const SECONDARY_COLOR = "#999999";

export const defaultTheme: ThemeOptions = createTheme({
  typography: {
    fontFamily: "Roboto"
  },
  palette: {
    primary: {
      main: PRIMARY_COLOR
    },
    secondary: {
      main: SECONDARY_COLOR
    }
  }
});
