import * as React from "react";

import { Auth } from "./Auth";
import { AuthForm } from "./AuthForm";
import { SetPasswordForm } from "./SetPasswordForm";

export const SetPassword: React.FC = () => {
  const formComponent = <AuthForm formComponent={<SetPasswordForm />}/>;
  return (
    <Auth formComponent={formComponent}/>
  );
};
