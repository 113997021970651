import { useEffect, useCallback } from "react";

import { useDataProvider, useNotify, useStore } from "react-admin";

import { AdjustmentCountsResponse } from "./types";

const useAdjustmentsCount = () => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const [count, setCount] = useStore<number | undefined>("api.adjustments-count", undefined);

  const fetchCount = useCallback(() => {
    dataProvider.getManyByUrl("adjustments/count?status=NEW")
      .then((response: { data: AdjustmentCountsResponse }) => {
        setCount(response.data.count);
        return response;
      })
      .catch(() => {
        notify("Error: failed to retrieve adjustments count", { type: "error" });
      });
  }, [dataProvider, setCount, notify]);

  useEffect(() => {
    if (count === undefined) {
      fetchCount();
    }
  }, [count, fetchCount]);

  return { count, fetchCount };
};

export default useAdjustmentsCount;
