import React from "react";

import { NullableBooleanInput } from "react-admin";

import {
  baseMultilineTextInputFilter
} from "../../components/filter/base-multiline-text-input-filter";
import { enumSelectFilter } from "../../components/filter/enum-select-filter";
import { buildLabel, CustomFilterIcon } from "../../components/filter/filter-utils";
import { useDateRangeFilters } from "../../components/filter/use-date-range-filters";
import { useNumberRangeFilters } from "../../components/filter/use-number-range-filters";
import { useUserFilter } from "../../components/user/use-user-filter";
import { AllTelcos } from "./types";

export const usePaymentFilters = () => {
  return [
    baseMultilineTextInputFilter({
      source: "telcoTransactionIds",
      label: buildLabel({ label: "Transaction IDs", Icon: CustomFilterIcon }),
      alwaysOn: true
    }),
    enumSelectFilter({
      source: "providers",
      enumObj: AllTelcos,
      label: buildLabel({ label: "Providers", Icon: CustomFilterIcon })
    }),
    ...useDateRangeFilters({
      lowerBoundLabel: "Transaction date (>=)",
      upperBoundLabel: "Transaction date (<=)",
      alwaysOn: true
    }),
    ...useDateRangeFilters({
      lowerBoundSource: "timeCreatedFrom",
      lowerBoundLabel: "Registration date (>=)",
      upperBoundSource: "timeCreatedTo",
      upperBoundLabel: "Registration date (<=)"
    }),
    baseMultilineTextInputFilter({
      source: "payers",
      label: buildLabel({ label: "Payer", Icon: CustomFilterIcon })
    }),
    baseMultilineTextInputFilter({
      source: "banks",
      label: buildLabel({ label: "Bank", Icon: CustomFilterIcon })
    }),
    useUserFilter("Created by", "createdByIds"),
    ...useNumberRangeFilters("amountGOE", "Amount (>=)", "amountLOE", "Amount (<=)"),
    <NullableBooleanInput key="isOrphaned"
      label={buildLabel({ label: "Is Orphaned", Icon: CustomFilterIcon })}
      variant="filled"
      source="isOrphaned"/>,
    <NullableBooleanInput key="isRefunded"
      label={buildLabel({ label: "Is Refunded", Icon: CustomFilterIcon })}
      variant="filled"
      source="isRefunded"/>
  ];
};