import React, { useState } from "react";

import ThumbsUpDownIcon from "@mui/icons-material/ThumbsUpDown";
import { Button, Tooltip } from "@mui/material";
import { useNotify, useRefresh, useUnselectAll, useUpdateMany } from "react-admin";

import { Resources } from "../../resources";
import { usePageSafeSelector } from "../../utils/use-page-safe-selector";
import { AdjustmentApproveDialog } from "./AdjustmentApprovalDialog";
import { Adjustment } from "./types";
import useAdjustmentsCount from "./useAdjustmentsCount";

type AdjustmentApproveButtonProps = {
  disabled: boolean
};

export const AdjustmentApproveButton: React.FC<AdjustmentApproveButtonProps> = ({
  disabled
}) => {
  const [ updateMany, { isLoading: isProcessing } ] = useUpdateMany();
  const refresh = useRefresh();
  const notify = useNotify();
  const unselect = useUnselectAll(Resources.Adjustments);
  const { fetchCount } = useAdjustmentsCount();

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { data: adjustments } = usePageSafeSelector<Adjustment>();
  const isAllowedToApprove = adjustments?.every((adjustment) => adjustment.status === "NEW");
  const handleCancel = () => {
    setIsDialogOpen(false);
    unselect();
  };

  const handleConfirm = (status: string) => {
    return updateMany(Resources.Adjustments,
      {
        ids: adjustments?.map((adjustment) => adjustment.id) || [],
        data: {
          status: status
        }
      },
      {
        onSuccess: () => {
          refresh();
          notify("Adjustment has been successfully updated");
          fetchCount();
          handleCancel();
        },
        onError: () => notify("Error: failed to update an adjustment", { type: "error", autoHideDuration: 5000 })
      }
    );
  };

  return (
    <>
      <Tooltip title="Approve/reject an adjustment">
        <span>
          <Button
            color="primary"
            disabled={disabled || !isAllowedToApprove}
            onClick={() => setIsDialogOpen(true)}
            startIcon={<ThumbsUpDownIcon/>}>
            Approve/Reject
          </Button>
        </span>
      </Tooltip>
      <AdjustmentApproveDialog
        isProcessing={isProcessing}
        isOpened={isDialogOpen}
        onClose={handleCancel}
        onConfirm={handleConfirm}
      />
    </>
  );
};