import React, { useState } from "react";

import DownloadIcon from "@mui/icons-material/Download";
import { Button } from "@mui/material";
import { SortPayload, useDataProvider } from "react-admin";

type ButtonProps = {
  resource: string,
  disabled: boolean,
  filterValues: any,
  sort: SortPayload
};

export const ServerSideExportButton: React.FC<ButtonProps> = ({ resource, disabled, filterValues, sort }) => {
  const dataProvider = useDataProvider();
  const [isExporting, setExporting] = useState(false);

  const onClick = () => {
    setExporting(true);
    return dataProvider.exportFile(resource, filterValues, sort)
      .finally(() => setExporting(false));
  };

  return (
    <Button
      size="small"
      onClick={onClick}
      disabled={disabled || isExporting}
      startIcon={<DownloadIcon />}
    >
      {isExporting ? "Downloading..." : "Export"}
    </Button>
  );
};
