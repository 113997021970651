import { EnumChipColors } from "../../utils/field-renderers";
import { Discount } from "../discounts/types";
import { Offer } from "../offers/types";
import { CreateOrderLine } from "../orders/actions/create/types";

export type ContractLine = {
  offerId: number | string,
  quantity: number,
  amount: number,
  discountId?: number | string,
  instanceId?: number,
  conditionType?: ConditionType
};

export type ContractForm = {
  contractType?: ContractType,
  startDate: Date,
  duration: number,
  downPayment: number,
  totalAmount: number,
  fixedTotalAmount: boolean,
  details: ContractLine[],
  addons: CreateOrderLine[]
};

export type Contract = {
  id: number,
  customerId: number,
  createdBy: number,
  type: keyof typeof ContractType,
  status: keyof typeof ContractStatus,
  duration?: number,
  downPayment: number,
  effectiveDate: string,
  totalAmount: number,
  paidAmount: number,
  details: ContractDetail[],
  timeCreated: string,
  defaultDate: string
};

export type ContractDetail = {
  id: number,
  contractId: number,
  instanceId?: number,
  conditionType?: keyof typeof ConditionType,
  quantity: number,
  offer: Offer,
  discount: Discount
};

export enum ContractStatus {
  ACTIVE = "Active",
  WRITTEN_OFF = "Written Off",
  REPOSSESSED = "Repossessed",
  CANCELLED = "Cancelled"
}

export const contractStatusChipColors: Record<keyof typeof ContractStatus, EnumChipColors> = {
  ACTIVE: "success",
  WRITTEN_OFF: "error",
  REPOSSESSED: "error",
  CANCELLED: "warning"
};

export enum ContractType {
  UTILITY = "Utility",
  NON_UTILITY = "Non Utility"
}

export enum ContractPaymentStatus {
  COMPLETED = "Completed",
  INCOMPLETE = "Incomplete"
}

export const contractPaymentStatusChipColors: Record<keyof typeof ContractPaymentStatus, EnumChipColors> = {
  COMPLETED : "success",
  INCOMPLETE : "warning"
};

export enum ConditionType {
  NEW = "New",
  PRE_OWNED = "Pre-owned"
}

export const conditionTypeChipColors: Record<keyof typeof ConditionType, EnumChipColors> = {
  NEW : "success",
  PRE_OWNED : "warning"
};