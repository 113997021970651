import { baseMultilineTextInputFilter } from "../../components/filter/base-multiline-text-input-filter";
import { countriesFilter } from "../../components/filter/customer-filters";
import { enumSelectFilter } from "../../components/filter/enum-select-filter";
import { buildLabel, CustomFilterIcon } from "../../components/filter/filter-utils";
import { DiscountStatus, DiscountType } from "./types";

export const filters = [
  countriesFilter(true, CustomFilterIcon),
  enumSelectFilter({
    source: "statuses",
    enumObj: DiscountStatus,
    label: buildLabel({ label: "Status", Icon: CustomFilterIcon }),
    alwaysOn: true
  }),
  baseMultilineTextInputFilter({
    source: "name",
    label: buildLabel({ label: "Name", Icon: CustomFilterIcon })
  }),
  enumSelectFilter({
    source: "types",
    enumObj: DiscountType,
    label: buildLabel({ label: "Type", Icon: CustomFilterIcon }),
    alwaysOn: false
  })
];