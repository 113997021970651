import React from "react";

import {
  CreateButton,
  FilterButton,
  SelectColumnsButton,
  TopToolbar,
  useListContext
} from "react-admin";

import { UserRoles } from "../../../core/providers/auth/roles";
import { useCheckAccess } from "../../../utils/use-check-access";
import { ServerSideExportButton } from "../export/ServerSideExportButton";

type ListActionsProps = {
  filteringEnabled?: boolean,
  exportingEnabled?: boolean,
  selectColumnsEnabled?: boolean,
  createEnabled?: boolean,
  additionalExportRoles?: UserRoles[],
  children?: JSX.Element
};

export const PresetListActions: React.FC<ListActionsProps> = ({
  filteringEnabled = true,
  exportingEnabled = true,
  selectColumnsEnabled = false,
  createEnabled = false,
  additionalExportRoles = [],
  children
}) => {
  const { resource, filterValues, sort, data } = useListContext();
  const { hasAccess } = useCheckAccess([UserRoles.ROLE_SUPAMOTO_ADMIN, ...additionalExportRoles]);

  return (
    <TopToolbar sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
      {children && children}
      {selectColumnsEnabled && <SelectColumnsButton />}
      {filteringEnabled && <FilterButton disableSaveQuery/>}
      {exportingEnabled && <ServerSideExportButton
        resource={resource}
        disabled={!data?.length || !hasAccess}
        filterValues={filterValues}
        sort={sort}
      />}
      {createEnabled && <CreateButton/>}
    </TopToolbar>
  );
};
