import React, { useState } from "react";

import RemoveShoppingCartIcon from "@mui/icons-material/RemoveShoppingCart";
import {
  Button,
  Tooltip
} from "@mui/material";
import { useNotify } from "ra-core";
import { useRefresh, useUnselectAll, useUpdateMany } from "react-admin";
import * as yup from "yup";

import { useForm } from "../../../components/form/use-form";
import { Resources } from "../../../resources";
import { usePageSafeSelector } from "../../../utils/use-page-safe-selector";
import { Order, OrderCancelReason } from "../types";
import { CancelOrderConfirmationDialog } from "./CancelOrderConfirmationDialog";
import { CancelOrderDialog } from "./CancelOrderDialog";

type Props = {
  disabled: boolean
};

export type CancellationFormState = {
  cancelReason: OrderCancelReason | ""
};

const initialFormState: CancellationFormState = {
  cancelReason: ""
};

export const CancelOrderStatusButton: React.FC<Props> = ({
  disabled
}) => {
  const refresh = useRefresh();
  const unselect = useUnselectAll(Resources.Orders);
  const notify = useNotify();
  const form = useForm<CancellationFormState>(initialFormState, yup.object({}));
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false);
  const { data: orders } = usePageSafeSelector<Order>();

  const handleCancel = () => {
    setIsDialogOpen(false);
    setIsConfirmationDialogOpen(false);
    form.reset();
  };

  const [ update, { isLoading: isProcessing } ] = useUpdateMany(Resources.Orders,
    {
      ids: orders?.map((order) => order.id) || [],
      data: {
        status: "CANCELED",
        cancelReason: form.value.cancelReason
      }
    }, {
      onSuccess: () => {
        refresh();
        notify("Orders have been successfully updated");
        unselect();
        handleCancel();
      },
      onError: () => notify("Error: failed to update orders", { type: "error" })
    });
  const handleConfirm = () => {
    setIsConfirmationDialogOpen(false);
    update();
  };

  return (
    <>
      <Tooltip title="Cancel an order">
        <span>
          <Button
            color="primary"
            disabled={disabled}
            onClick={() => setIsDialogOpen(true)}
            startIcon={<RemoveShoppingCartIcon/>}>
            Cancel
          </Button>
        </span>
      </Tooltip>
      <CancelOrderDialog
        isProcessing={isProcessing}
        isOpened={isDialogOpen}
        onClose={handleCancel}
        onConfirm={() => setIsConfirmationDialogOpen(true)}
        form={form}/>
      <CancelOrderConfirmationDialog
        orders={orders || []}
        onConfirm={handleConfirm}
        isConfirmationDialogOpen={isConfirmationDialogOpen}
        isConfirmDisabled={isProcessing}
        onClose={() => setIsConfirmationDialogOpen(false)}
      />
    </>
  );
};