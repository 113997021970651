import React from "react";

import { styled } from "@mui/material/styles";

import { AuthFormProps, AuthClasses, PREFIX } from "./types";

export const Auth: React.FC<AuthFormProps> = ({ formComponent }) => {
  return (
    <Root>
      {formComponent}
    </Root>
  );
};

const Root = styled("div", {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root
})(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  minHeight: "100vh",
  height: "1px",
  alignItems: "center",
  justifyContent: "flex-start",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  backgroundImage:
      "radial-gradient(circle at 50% 14em, #313264 0%, #00023b 60%, #00023b 100%)",

  [`& .${AuthClasses.card}`]: {
    minWidth: 300,
    marginTop: "6em"
  },
  [`& .${AuthClasses.avatar}`]: {
    margin: "1em",
    display: "flex",
    justifyContent: "center"
  },
  [`& .${AuthClasses.icon}`]: {
    // @ts-ignore
    backgroundColor: theme.palette.secondary[500]
  }
}));
