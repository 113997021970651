import { useEffect } from "react";

import { concat, isNil } from "lodash-es";
import { GetListParams, useDataProvider, useStore } from "react-admin";

import { Resources } from "../../resources";
import { User } from "./types";

export const SYSTEM_USER: User = {
  id: 0,
  username: "System",
  role: "none",
  accessType: "WEB",
  status: "ACTIVE",
  timeCreated: "-"
};

export const useUsers = () => {
  const dataProvider = useDataProvider();
  const [users, setUsers] = useStore<User[]>("api.users", undefined);

  useEffect(() => {
    if (isNil(users)) {
      const params = { pagination: { page: 1, perPage: 1000 } } as GetListParams;
      dataProvider.getList<User>(Resources.Users, params)
        .then((response) => {
          setUsers(concat(SYSTEM_USER, response.data));
          return response;
        });
    }
  }, [dataProvider, users, setUsers]);

  return { users: isNil(users) ? [] : users };
};
