import React, { useEffect } from "react";

import AddIcon from "@mui/icons-material/Add";
import { Button, InputAdornment, TextField } from "@mui/material";
import Grid from "@mui/material/Grid";
import { isNil } from "lodash-es";

import { useGetDiscountsByOffer } from "../../../../components/discount/use-get-discounts-by-offer";
import { FormProps } from "../../../../components/form/use-form";
import { calculatePrice } from "../../../../components/offer/utils";
import { OrderLine } from "../../../../components/order/OrderLine";
import { CountryCurrency, Customer } from "../../../../utils/commons";
import { Contract } from "../../../contracts/types";
import { Discount } from "../../../discounts/types";
import { Offer } from "../../../offers/types";
import { calcTotalPrice, CreateOrderForm } from "./create-order-form";
import { CreateOrderLine } from "./types";

type Props = {
  customer?: Customer,
  offers: Offer[],
  isOffersLoading: boolean,
  discounts: Discount[],
  isDiscountsLoading: boolean,
  contracts: Contract[],
  isContractsLoading: boolean,
  form: FormProps<CreateOrderForm>
};

export const CreateOrUpdateOrderDetailsForm: React.FC<Props> = ({
  customer,
  offers,
  isOffersLoading,
  contracts,
  isContractsLoading,
  form
}) => {
  const { discounts } = useGetDiscountsByOffer();

  useEffect(() => {
    form.value.orderLines.forEach((orderLine, index) => {
      const offer = offers.find((offer) => offer.id === orderLine.dealId);
      if (offer && !isNil(offer.price)) {
        const discount = discounts.find((discount) => discount.id === orderLine.discountId);
        const price = discount ? calculatePrice(offer, discount) : offer.price;
        form.setValueByLabel(`orderLines[${index}].amount`, price);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container spacing={2}>
      {
        form.value.orderLines.map((orderLine: CreateOrderLine, index: number) => {
          const offerOrContract = offers.find((offer) => offer.id === orderLine.dealId) ||
              contracts.find((contract) => contract.id === orderLine.dealId);
          return (
            <OrderLine
              key={index}
              identifierName="dealId"
              itemKey={index}
              propertyName="orderLines"
              customer={customer}
              offers={offers}
              contracts={contracts}
              selectedOffer={offerOrContract}
              isOffersLoading={isOffersLoading || isContractsLoading}
              form={form}
            />
          );
        })
      }
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={8}></Grid>
          <Grid item xs={2}>
            <TextField
              label="Total price"
              variant="outlined"
              disabled
              fullWidth
              value={calcTotalPrice(form.value.orderLines)}
              InputProps={{
                endAdornment: <InputAdornment position="end">{CountryCurrency[customer?.country || ""]}</InputAdornment>
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sx={{ display: "flex", justifyContent: "start", alignItems: "center" }}>
        <Button variant="outlined"
          disabled={form.value.orderLines.length >= 10}
          onClick={() => {
            form.setValueByLabel(`orderLines[${form.value.orderLines.length}]`, { dealId: "" });
          }}
          endIcon={<AddIcon />}>
          Add line
        </Button>
      </Grid>
    </Grid>
  );
};