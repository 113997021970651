import React, { useState } from "react";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Chip, Dialog,
  DialogActions,
  DialogContent,
  DialogTitle, Typography
} from "@mui/material";
import Grid from "@mui/material/Grid";
import {
  Datagrid,
  FunctionField,
  ListContextProvider,
  TextField,
  useList
} from "react-admin";

import { EnumChipColors, enumChipRenderer } from "../../utils/field-renderers";
import { DeliveryOption, RecommendedLocation } from "./types";

type CustomerRecommendedLocationProps = {
  recommendedLocations: RecommendedLocation[]
};

const deliveryOptionsChipColors: Record<keyof typeof DeliveryOption, EnumChipColors> = {
  DELIVERY: "info",
  PICK_UP: "success"
};

const convertToKilometer = (distanceInMeters: number) => {
  return (distanceInMeters / 1000).toFixed(2);
};

const prepareOptionLabel = (location: RecommendedLocation) => {
  return `${location.collectionPartner.code} ${DeliveryOption[location.deliveryOption]} ` +
  `(${convertToKilometer(location.distance)} km)`;
};

export const CustomerRecommendedLocationsGrid: React.FC<CustomerRecommendedLocationProps> = ({
  recommendedLocations
}) => {
  const listContext = useList({ data: recommendedLocations.slice(0, 5) });

  return (
    <ListContextProvider value={listContext}>
      <Datagrid bulkActionButtons={false}>
        <TextField source="priority" label="Priority" sortable={false}/>
        <TextField source="collectionPartner.code" label="Code" sortable={false}/>
        <TextField source="collectionPartner.name" label="Name" sortable={false}/>
        <FunctionField
          label="Option"
          render={(location: RecommendedLocation) =>
            enumChipRenderer(location.deliveryOption, DeliveryOption, deliveryOptionsChipColors)}/>
        <FunctionField
          label="Disatance, km"
          render={(location: RecommendedLocation) => convertToKilometer(location.distance)}/>
      </Datagrid>
    </ListContextProvider>
  );
};

export const CustomerRecommendedLocationAccordion: React.FC<CustomerRecommendedLocationProps> = ({
  recommendedLocations
}) => {

  const bestLocation = recommendedLocations[0];
  const bestLocationLabel = `${bestLocation.collectionPartner.code} ${bestLocation.collectionPartner.name} - ` +
      `${DeliveryOption[bestLocation.deliveryOption]} (${convertToKilometer(bestLocation.distance)} km)`;
  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}>
        <Grid container spacing={1} alignItems="center">
          <Grid item>
            <Typography sx={{ fontWeight: 500 }}>Recommended location: </Typography>
          </Grid>
          <Grid item>
            <Typography>{bestLocationLabel}</Typography>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <CustomerRecommendedLocationsGrid recommendedLocations={recommendedLocations}/>
      </AccordionDetails>
    </Accordion>
  );
};

export const CustomerRecommendedLocationChip: React.FC<CustomerRecommendedLocationProps> = ({
  recommendedLocations
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const handleClose = () => {
    setIsDialogOpen(false);
  };
  const bestLocation = recommendedLocations[0];

  return (
    <>
      <Chip
        label={prepareOptionLabel(bestLocation)}
        color={bestLocation.deliveryOption === "DELIVERY" ? "info" : "success"}
        variant="outlined"
        size="small"
        onClick={() => setIsDialogOpen(true)}
      />
      <Dialog open={isDialogOpen} onClose={handleClose} fullWidth>
        <DialogTitle>Recommended Locations</DialogTitle>
        <DialogContent>
          <CustomerRecommendedLocationsGrid recommendedLocations={recommendedLocations}/>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};