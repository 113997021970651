import * as React from "react";

import { Edit, useUpdate } from "react-admin";

import { OffersForm, OperationType } from "./OffersForm";

export const OffersEdit: React.FC = () => {
  const [ update ] = useUpdate();

  return (
    <Edit
      mutationMode="pessimistic"
      queryOptions={{ cacheTime: 0, staleTime: 0 }}
    >
      <OffersForm operationType={OperationType.UPDATE} submit={update}/>
    </Edit>
  );
};
