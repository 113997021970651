import React, { useEffect, useState } from "react";

import CustomerIcon from "@mui/icons-material/Person";
import { RaRecord } from "ra-core";
import { AutocompleteArrayInput } from "react-admin";

import { Country, CustomerGroupType, CustomerStatus } from "../../utils/commons";
import { useCompanies } from "../company/use-companies";
import { useLocationFilter } from "../location/location-filters";
import { LocationFilterSource, LocationLevel } from "../location/types";
import { baseMultilineTextInputFilter } from "./base-multiline-text-input-filter";
import { enumSelectFilter } from "./enum-select-filter";
import { buildLabel } from "./filter-utils";
import { useDateRangeFilters } from "./use-date-range-filters";

export const countriesFilter = (alwaysOn: boolean, Icon = CustomerIcon) => enumSelectFilter({
  source: "countries",
  enumObj: Country,
  label: buildLabel({ label: "Country", Icon }),
  alwaysOn
});

export const customerGroupFilter = (alwaysOn: boolean) => enumSelectFilter({
  source: "customerGroups",
  enumObj: CustomerGroupType,
  label: buildLabel({ label: "Customer group", Icon: CustomerIcon }),
  alwaysOn
});

export const customerStatusFilter = () => enumSelectFilter({
  source: "customerStatuses",
  enumObj: CustomerStatus,
  label: buildLabel({ label: "Customer status", Icon: CustomerIcon })
});

export const customerContractFilter = (alwaysOn: boolean) => {
  return baseMultilineTextInputFilter({
    source: "contracts",
    label: buildLabel({ label: "Customer contract", Icon: CustomerIcon }),
    alwaysOn
  });
};

export const customerNameFilter = () => {
  return baseMultilineTextInputFilter({
    source: "customerNames",
    label: buildLabel({ label: "Customer name", Icon: CustomerIcon })
  });
};

export const customerPhoneNumberFilter = () => {
  return baseMultilineTextInputFilter({
    source: "customerPhoneNumbers",
    label: buildLabel({ label: "Customer phone number", Icon: CustomerIcon })
  });
};

export const useCompanyFilter = () => {
  const { companies } = useCompanies();
  const [ choices, setChoices ] = useState<RaRecord[]>([]);

  useEffect(() => {
    const choices = companies.map((company) => ({ id: company.id, name: company.name }));
    setChoices(choices);
  }, [companies]);

  return <AutocompleteArrayInput key="company"
    label={buildLabel({ label: "Company", Icon: CustomerIcon })}
    source="companyIds"
    variant="filled"
    choices={choices} />;
};

type CustomerFiltersProps = {
  countriesAlwaysOn?: boolean,
  groupsAlwaysOn?: boolean,
  contractAlwaysOn?: boolean,
  registrationAlwaysOn?: boolean
};

export const useCustomerFilters = ({
  countriesAlwaysOn = true,
  groupsAlwaysOn = false,
  contractAlwaysOn = false,
  registrationAlwaysOn = false
}: CustomerFiltersProps) => {
  return [
    countriesFilter(countriesAlwaysOn),
    useLocationFilter({ level: LocationLevel.Province, label: "Province", source: LocationFilterSource.Province, icon: CustomerIcon }),
    useLocationFilter({ level: LocationLevel.City, label: "City", source: LocationFilterSource.City, icon: CustomerIcon }),
    useLocationFilter({ level: LocationLevel.Area, label: "Area", source: LocationFilterSource.Area, icon: CustomerIcon }),
    useCompanyFilter(),
    customerGroupFilter(groupsAlwaysOn),
    customerStatusFilter(),
    customerContractFilter(contractAlwaysOn),
    customerNameFilter(),
    customerPhoneNumberFilter(),
    ...useDateRangeFilters({
      lowerBoundSource: "customerRegistrationDateTimeGOE",
      lowerBoundLabel: "Customer registration date (>=)",
      upperBoundSource: "customerRegistrationDateTimeLOE",
      upperBoundLabel: "Customer registration date (<=)",
      icon: CustomerIcon,
      alwaysOn: registrationAlwaysOn
    })
  ];
};
