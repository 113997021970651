import React, { FC, useEffect, useState } from "react";

import { Box, CircularProgress } from "@mui/material";
import Alert from "@mui/material/Alert";
import Grid from "@mui/material/Grid";
import Skeleton from "@mui/material/Skeleton";
import { head } from "lodash-es";
import moment from "moment";
import { useDataProvider } from "react-admin";
import { CartesianGrid, Legend, Line, LineChart, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import * as yup from "yup";

import DateTimeFormInput from "../../../components/form/input/datetime/DateTimeFormInput";
import { FormSelect } from "../../../components/form/input/select/FormSelect";
import { useForm } from "../../../components/form/use-form";
import { Stove, StoveStatus } from "../../../utils/commons";
import { DateTimeFormat } from "../../../utils/date-time-utils";
import { enumRenderer } from "../../../utils/field-renderers";
import { FullStove } from "../StoveShow";

type DisplayMode = "device" | "customer";
type StoveUsageTabProps = {
  stoves?: FullStove[] | Stove[],
  displayMode: DisplayMode
};

type StoveActivityItem = {
  date: string,
  heartbeats: number,
  cookingSessions: number,
  communicationEvents: number
};

type GetStoveActivityResponse = {
  data: StoveActivityItem[]
};

type ReferenceLineData = {
  timeCreated: string,
  customerName: string
};
type CustomerLabelProps = {
  viewBox: { x: number, y: number },
  customerName: string
};

const getSelectedStoveByDefault = (stoves?: Stove[]): string | undefined => {
  const hasStoves = stoves?.length;
  if (hasStoves) {
    const activeStoves = stoves?.filter((stove) => stove.status === "ACTIVE" as keyof typeof StoveStatus) || stoves;
    return head(activeStoves.map((stove) => String(stove.id)));
  }

  return "";
};

const mapStoveActivity = (data: StoveActivityItem[]): StoveActivityItem[] => {
  return data.map((item) => ({
    date: moment(item.date).format("DD.MM.YY"),
    heartbeats: item.heartbeats > 0 ? 1 : 0,
    cookingSessions: item.cookingSessions,
    communicationEvents: item.communicationEvents > 0 ? 1 : 0
  }));
};

const CustomerLabel: FC<CustomerLabelProps> = ({ viewBox, customerName }) => {
  const { x, y } = viewBox;
  return (
    <text x={x + 10} y={y + 20} fill="black" textAnchor="right">
      {customerName}
    </text>
  );
};

export const StoveUsageTab: React.FC<StoveUsageTabProps> = ({
  displayMode,
  stoves
}) => {
  const dataProvider = useDataProvider();
  const [stoveActivity, setStoveActivity] = useState<StoveActivityItem[]>([]);
  const [referenceLines, setReferenceLines] = useState<ReferenceLineData[]>([]);
  const [isStoveActivityLoading, setStoveActivityLoading] = useState(false);
  const initialFormState = {
    stoveId: getSelectedStoveByDefault(stoves),
    dateFrom: moment().subtract(3, "month").format(DateTimeFormat.DATE_ISO),
    dateTo: moment().startOf("day").format(DateTimeFormat.DATE_ISO)
  };
  const form = useForm(initialFormState, yup.object({}));

  const menuItems = stoves?.map((stove) => ({
    value: stove.id,
    label: `${stove.nativeId} (${enumRenderer(stove.status, StoveStatus)})`
  })) || [];

  const stoveIds = displayMode === "device" ? stoves?.map((stove) => stove.id).join(",") : form.value.stoveId;

  useEffect(() => {
    if (!stoveIds) return;

    setStoveActivityLoading(true);
    dataProvider.getManyByUrl(
      `stoves/activity?stoveIds=${stoveIds}&from=${form.value.dateFrom}&to=${form.value.dateTo}`)
      .then((response: GetStoveActivityResponse) => {
        setStoveActivity(mapStoveActivity(response.data));
        setStoveActivityLoading(false);

        if (displayMode === "device" && stoves && stoves.length > 0) {
          setReferenceLines(stoves.map((stove) => {
            if ("customer" in stove) {
              return {
                timeCreated: moment(stove.timeCreated).format("DD.MM.YY"),
                customerName: `${stove.customer.firstName} ${stove.customer.lastName}`
              };
            }
            return {
              timeCreated: moment(stove.timeCreated).format("DD.MM.YY"),
              customerName: "Unassigned"
            };
          }).filter((line): line is ReferenceLineData => Boolean(line)));
        }

        return response;
      })
      .catch(() => setStoveActivityLoading(false));

  }, [form.value, dataProvider, stoves, stoveIds, displayMode]);

  const isStovesLoading = !stoves;
  if (!isStovesLoading && !stoves?.length) {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Alert severity="info">No data found</Alert>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container spacing={2} sx={{ pt: 2, pb: 3 }}>
      {
        isStovesLoading ?
          <Grid item xs={12}>
            <Skeleton animation="wave"/>
            <Skeleton animation="wave"/>
            <Skeleton animation="wave"/>
            <Skeleton animation="wave"/>
          </Grid> :
          <>
            <Grid item xs={12}>
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                {displayMode === "customer" && (
                  <FormSelect name="stoveId"
                    label="Stove"
                    clearable={false}
                    fullWidth={false}
                    form={form}
                    menuItems={menuItems}/>
                )}
                <DateTimeFormInput
                  name="dateFrom"
                  label="Start Date"
                  fullWidth={false}
                  form={form}
                  onlyDate
                  minDateTime={moment("2020-01-01")}
                  maxDateTime={moment(form.value.dateTo)}
                />
                <DateTimeFormInput
                  name="dateTo"
                  label="End Date"
                  fullWidth={false}
                  form={form}
                  onlyDate
                  minDateTime={moment(form.value.dateFrom)}
                  maxDateTime={moment().endOf("day")}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ height: "400px", position: "relative" }}>
                <ResponsiveContainer width="100%" height="100%">
                  {
                    stoveActivity.length ?
                      <LineChart
                        data={stoveActivity}
                        margin={{
                          top: 5,
                          right: 30,
                          left: 20,
                          bottom: 5
                        }}
                      >
                        <CartesianGrid strokeDasharray="3 3"/>
                        <XAxis dataKey="date" />
                        <YAxis label={{ value: "Count", angle: -90, position: "insideLeft" }}/>
                        <Tooltip/>
                        <Legend/>
                        <Line type="monotone"
                          name="Heartbeats"
                          dataKey="heartbeats"
                          stroke="#fd5901"/>
                        <Line type="monotone"
                          name="Cooking sessions"
                          dataKey="cookingSessions"
                          stroke="#249ea0"/>
                        <Line type="monotone"
                          name="Communications"
                          dataKey="communicationEvents"
                          activeDot={{ r: 6 }}
                          stroke="#faab36"/>
                        {referenceLines.map((line, index) =>
                          <ReferenceLine
                            key={index}
                            x={line.timeCreated}
                            stroke="black"
                            label={(props) => <CustomerLabel {...props} customerName={line.customerName}/>}
                          />
                        )}
                      </LineChart> : <></>
                  }
                </ResponsiveContainer>
                {
                  isStoveActivityLoading &&
                        <Box sx={{
                          width: "100%",
                          height: "100%",
                          position: "absolute",
                          top: "0",
                          backgroundColor: "rgba(255, 255, 255, 0.6)"
                        }}>
                          <CircularProgress sx={{
                            position: "absolute",
                            left: "50%",
                            top: "50%"
                          }}/>
                        </Box>
                }
              </Box>
            </Grid>
          </>
      }
    </Grid>
  );
};