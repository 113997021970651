import React from "react";

import { Datagrid, DateField, List, NumberField, TextField } from "react-admin";

import { useCustomerFilters } from "../../components/filter/customer-filters";
import { useStoveFilters } from "../../components/filter/stove-filters";
import { useDateRangeFilters } from "../../components/filter/use-date-range-filters";
import { useNumberRangeFilters } from "../../components/filter/use-number-range-filters";
import { PresetListActions } from "../../components/list/actions/PresetListActions";
import { DefaultPagination } from "../../components/pagination/DefaultPagination";
import { UserRoles } from "../../core/providers/auth/roles";

export const CookingSessionsList: React.FC = () => {
  const filters = [
    ...useCustomerFilters({}),
    ...useStoveFilters({
    }),
    ...useDateRangeFilters({}),
    ...useNumberRangeFilters("cookingDurationGOE", "Duration, minutes (>=)",
      "cookingDurationLOE", "Duration, minutes (<=)")
  ];

  return (
    <List filters={filters}
      actions={<PresetListActions
        additionalExportRoles={[UserRoles.ROLE_EXTERNAL_COMPANY_ADMIN]}
      />}
      pagination={<DefaultPagination/>}
      perPage={25}
      sort={{ field: "id", order: "DESC" }}>
      <Datagrid bulkActionButtons={false} rowClick="show">
        <TextField source="id"/>
        <TextField source="nativeStoveId" label="Stove UID"/>
        <TextField source="stoveModel" label="Model" sortable={false}/>
        <TextField source="timeDuration" label="Duration" sortable={false}/>
        <NumberField source="duration" label="Duration, sec"/>
        <DateField source="timeFrom" label="Start date/time" showTime/>
        <DateField source="timeTo" label="End date/time" showTime/>
      </Datagrid>
    </List>
  );
};
