export enum UserAccessType {
  WEB = "Web",
  PROGRAMMATIC = "Programmatic"
}

export enum UserStatus {
  ACTIVE = "Active",
  INACTIVE = "Inactive"
}

export type User = {
  id: number,
  username: string,
  role: string,
  accessType: keyof typeof UserAccessType,
  status: keyof typeof UserStatus,
  timeCreated: string
};