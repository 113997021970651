import React, { SyntheticEvent, useCallback, useEffect, useState } from "react";

import { AutocompleteChangeReason } from "@mui/base/useAutocomplete/useAutocomplete";
import SwapVerticalCircleIcon from "@mui/icons-material/SwapVerticalCircle";
import {
  Alert,
  Autocomplete,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Tooltip,
  Typography
} from "@mui/material";
import Grid from "@mui/material/Grid";
import { debounce, isEmpty } from "lodash-es";
import { useDataProvider, useNotify } from "react-admin";
import { Link } from "react-router-dom";
import * as yup from "yup";

import { ProcessingButton } from "../../../components/button/processing/ProcessingButton";
import { FormSelect } from "../../../components/form/input/select/FormSelect";
import { useForm } from "../../../components/form/use-form";
import { FullCustomer, Stove, SwapDeviceReason } from "../../../utils/commons";
import { FullStove } from "../../stoves/StoveShow";
import { Contract, ContractDetail } from "../types";

type SwapDeviceProps = {
  contract: Contract,
  customer: FullCustomer,
  disabled: boolean,
  onContractChange: (contractId: number) => void
};

type SwapDeviceFormState = {
  reason: SwapDeviceReason | "",
  nativeId: number | null
};

const initialFormState: SwapDeviceFormState = {
  reason: "",
  nativeId: null
};
const validationSchema = yup.object({
  reason: yup.string().required()
});

const swapReasons = Object.entries(SwapDeviceReason)
  .map(([key, value]) => {
    return { value: key, label: value };
  })
  .sort((a, b) => a.label.localeCompare(b.label));

export const SwapDeviceButton: React.FC<SwapDeviceProps> = ({
  contract,
  customer,
  disabled,
  onContractChange
}) => {
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const form = useForm<SwapDeviceFormState>(initialFormState, validationSchema);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [isSwapping, setIsSwapping] = useState(false);
  const [stoves, setStoves] = useState<readonly FullStove[]>([]);
  const [uniqueDeviceIds, setUniqueDeviceIds] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [activeStove, setActiveStove] = useState<Stove | undefined>();
  const [selectedStove, setSelectedStove] = useState<FullStove | undefined>();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchStoves = useCallback(debounce((value: string) => {
    setIsLoading(true);

    dataProvider.getList("stoves", {
      pagination: {
        page: 1,
        perPage: 10
      },
      sort: {
        field: "id",
        order: "DESC"
      },
      filter: {
        queryText: [value]
      }
    }).then((response) => {
      setStoves(response.data);
      setUniqueDeviceIds(Array.from(new Set(response.data.map((stove) => stove.deviceId))));
      return response;
    }).catch(() => {
      notify("Error: failed to retrieve stoves", { type: "error" });
    }).finally(() => {
      setIsLoading(false);
    });
  }, 500), [dataProvider, notify]);

  useEffect(() => {
    if (customer && customer.stoves) {
      setActiveStove(customer.stoves.find((stove: Stove) =>
        stove.status === "ACTIVE" &&
          contract.details.some((detail: ContractDetail) => detail.instanceId === stove.instanceId)
      ));
    }
  }, [customer, contract.details]);

  useEffect(() => {
    if (isEmpty(inputValue) || inputValue.length < 3) return;
    fetchStoves(inputValue);
  }, [inputValue, fetchStoves]);

  const handleCancel = () => {
    setIsFormOpen(false);
    setSelectedStove(undefined);
  };
  const handleConfirm = () => {
    setIsSwapping(true);
    dataProvider.updateManyByUrlWithBody(`contracts/${contract.id}/swap`, {
      nativeId: selectedStove?.nativeId,
      reason: form.value.reason
    }).then((response: any) => {
      notify("Device has been successfully swapped");
      onContractChange(contract.id);
      handleCancel();
      return response;
    }).catch(() => {
      notify("Error: failed to swap device", { type: "error" });
    }).finally(() => setIsSwapping(false));
  };

  const onChange = (event: SyntheticEvent, value: string, reason: AutocompleteChangeReason) => {
    if (reason === "selectOption" && value) {
      const stove = stoves.find((stove) => stove.deviceId === value
          && stove.status === "ACTIVE")
          || stoves.find((stove) => stove.deviceId === value);

      setSelectedStove(stove);
      setInputValue(value.toString());
    }
  };

  return (
    <>
      <Tooltip title="Swap Device: Available only for contracts with stoves.">
        <span>
          <Button
            color="primary"
            variant={"text"}
            disabled={disabled}
            onClick={() => setIsFormOpen(true)}
            startIcon={<SwapVerticalCircleIcon/>}>
            {"Swap Device"}
          </Button>
        </span>
      </Tooltip>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={isFormOpen}
        onClose={handleCancel}
      >
        <DialogTitle>Swap device</DialogTitle>
        <DialogContent>
          <DialogContent sx={{ mx: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="body1" align="justify">
                  {`You are trying to swap the device for the customer with contract number 
               ${customer?.contractReference}. The customer currently owns a stove with UID: `}
                  {activeStove ? (
                    <Link to={`/stoves/devices/${activeStove.instanceId}/show`}>
                      {activeStove.nativeId}
                    </Link>
                  ) : (
                    "doesn't own any stove"
                  )}
                  {". After pressing \"Swap\", the device will be replaced with a new one."}
                </Typography>
              </Grid>
              <Grid item xs={12} sx={{ mt: 2 }}>
                <FormSelect
                  name="reason"
                  label="Swapping Reason *"
                  form={form}
                  menuItems={swapReasons}/>
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  disableClearable
                  loading={isLoading}
                  inputValue={inputValue}
                  options={uniqueDeviceIds}
                  getOptionLabel={(deviceId: string) => `${deviceId}`}
                  onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                  }}
                  onChange={onChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Enter Device ID *"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </>
                        )
                      }}
                    />
                  )}
                />
              </Grid>
              {selectedStove && selectedStove.customer && selectedStove.status === "ACTIVE" &&
                  (
                    <Grid item xs={12}>
                      <Alert severity="warning">
                        You cannot assign
                        <Link to={`/stoves/devices/${selectedStove?.instanceId}/show`}>
                          {" " + selectedStove.deviceId}
                        </Link>. It is already assigned to a customer.
                      </Alert>
                    </Grid>
                  )
              }
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancel}>
              Cancel
            </Button>
            <ProcessingButton
              onClick={handleConfirm}
              disabled={!form.isValid ||
                  Boolean(selectedStove && selectedStove.customer && selectedStove.status === "ACTIVE")}
              isProcessing={isSwapping}
            />
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
};