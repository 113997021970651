import { useEffect } from "react";

import { isNil } from "lodash-es";
import { GetListParams, useDataProvider, useStore } from "react-admin";

import { Resources } from "../../resources";
import { StockItem } from "./types";

export const useStockItems = () => {
  const dataProvider = useDataProvider();
  const [stockItems, setStockItems] = useStore<StockItem[]>("api.stock-items", undefined);

  useEffect(() => {
    if (isNil(stockItems)) {
      const params = { pagination: { page: 1, perPage: 500 } } as GetListParams;
      dataProvider.getList<StockItem>(Resources.StockItems, params)
        .then((response) => {
          setStockItems(response.data);
          return response;
        });
    }
  }, [dataProvider, stockItems, setStockItems]);

  return { stockItems: isNil(stockItems) ? [] : stockItems };
};