import { groupBy, head, isNil, sumBy } from "lodash-es";
import * as yup from "yup";

import { yupDate, yupNumber } from "../../../../components/form/validation";
import { Order } from "../../types";

export type DeliveryOrderItem = {
  amount?: number,
  quantity?: number,
  unitPrice?: number
};

export type DeliveryDetails = {
  orderIds: number[],
  /* Customer id / Delivery group id */
  resourceId: number | string,
  clientName?: string,
  phoneNumber?: string,
  address: string,
  latitude?: number,
  longitude?: number,
  deliveryDateTime: string,
  pickupCollectionPartnerId: number | string,
  orderItems: DeliveryOrderItem[],
  deliveryInstructions?: string
};

export type CreateIndividualDeliveryRequest = {
  deliveries: DeliveryDetails[]
};

export const orderItemValidationSchema = yup.object({
  amount: yupNumber().required().label("Amount").positive(),
  quantity: yupNumber().required().label("Quantity").positive(),
  unitPrice: yupNumber().required().label("Price per unit")
});

export const deliveryDetailsValidationSchema = yup.object({
  clientName: yup.string().trim().required().min(3).max(250).label("Customer Name"),
  phoneNumber: yup.string().trim().required().min(10).max(100).label("Phone Number"),
  address: yup.string().trim().max(250).label("Address").when(["latitude", "longitude"], {
    is: (latitude: number, longitude: number) => isNil(latitude) || isNil(longitude),
    then: (schema) => schema.min(10).required("Address or Latitude and Longitude must be provided")
  }),
  latitude: yupNumber().min(-90).max(90).label("Latitude"),
  longitude: yupNumber().min(-180).max(180).label("Longitude"),
  deliveryDateTime:  yupDate().required().label("Delivery Date Time"),
  pickupCollectionPartnerId: yupNumber((schema) => (`${schema.label} must be selected`)).required().label("Pickup Location"),
  orderItems: yup.array().of(orderItemValidationSchema).min(1).label("Order Lines"),
  deliveryInstructions: yup.string().trim().max(250).label("Instructions")
});

export const buildDeliveryOrderItemsByOrders = (orders: Order[]): DeliveryOrderItem[] => {
  const pelletsOrderLines = orders.flatMap((order) => order.orderLines)
    .filter((orderLine) => orderLine.offer.offerType === "PELLETS");
  const orderItems = Object.entries(groupBy(pelletsOrderLines, "offer.id"))
    .map(([, orderLines]) => {
      const offer = head(orderLines)?.offer;
      const quantity = sumBy(orderLines, "quantity") || 1;

      return {
        amount: offer?.bagSize,
        quantity: quantity,
        unitPrice: offer?.price
      };
    });
  return orderItems.length ? orderItems : [{}];
};
