import * as React from "react";

import { isNil, uniq } from "lodash-es";
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

import { ChartsBucketData, ChartsData } from "../types";
import { CHART_COLORS } from "../utils";
import { DashboardChartContainer } from "./DashboardChartContainer";

type Props = {
  title: string,
  chartsData: ChartsData[] | ChartsBucketData[] | undefined,
  property: string,
  dataKey: string,
  additionalLevers?: React.ReactNode,
  syncId?: string,
  tooltipFormatter?: (value: any, name: any, props: any) => string
};

export const DashboardLineCharts: React.FC<Props> = ({
  title,
  chartsData,
  property,
  dataKey,
  additionalLevers,
  syncId,
  tooltipFormatter
}) => {
  const dataKeys = uniq(chartsData?.flatMap((item) => Object.keys(item.data))).sort();

  return (
    <DashboardChartContainer
      title={title}
      isLoading={isNil(chartsData)}
      chartType={"line"}
      additionalLevers={additionalLevers}
    >
      <ResponsiveContainer width="100%" height="100%">
        <LineChart data={chartsData} syncId={syncId}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey={dataKey} />
          <YAxis />
          <Tooltip formatter={tooltipFormatter ?
            (value, name, props) => tooltipFormatter(value, name, props) :
            undefined}/>
          <Legend />
          {
            dataKeys.map((key, index) => (
              <Line key={key}
                type="monotone"
                stroke={CHART_COLORS[index]}
                dataKey={(obj: ChartsData | ChartsBucketData) => obj.data[key][property]}
                name={key}
              />
            ))
          }
        </LineChart>
      </ResponsiveContainer>
    </DashboardChartContainer>
  );
};