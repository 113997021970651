import React, { useEffect, useState } from "react";

import { useDataProvider } from "react-admin";

import { UserRoles } from "../../core/providers/auth/roles";
import { useCheckAccess } from "../../utils/use-check-access";
import { AdjustmentApproveButton } from "./AdjustmentApproveButton";

export const AdjustmentsBulkActions = () => {
  const { hasAccess: isAdmin } = useCheckAccess(UserRoles.ROLE_SUPAMOTO_ADMIN);
  const dataProvider = useDataProvider();
  const [hasApproveAccess, setHasApproveAccess] = useState(false);

  useEffect(() => {
    if (isAdmin) {
      dataProvider.getManyByUrl("users/adjustment-approve-access")
        .then((response: { data: boolean}) => {
          setHasApproveAccess(response.data);
          return response;
        })
        .catch(() => {
          setHasApproveAccess(false);
        });
    }
  }, [isAdmin, dataProvider]);

  return (
    <>
      <AdjustmentApproveButton disabled={!isAdmin || !hasApproveAccess} />
    </>);
};