import React from "react";

import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Box, Button, Typography } from "@mui/material";
import moment, { Moment } from "moment";
import DatePicker, { ReactDatePickerProps } from "react-datepicker";

import styles from "./styles.module.scss";

type DateTimePickerProps = {
  renderInput?: React.ReactElement,
  value: Moment|null,
  minDateTime?: Moment,
  maxDateTime?: Moment,
  onlyDate?: boolean,
  onClose?: () => void,
  onBlur?: () => void,
  onChange: (date: Date) => void,
  datePickerProps?: Partial<ReactDatePickerProps>
};

const header = ({
  date,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled
}: any) => (
  <Box
    sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
  >
    <Button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
      <KeyboardArrowLeftIcon />
    </Button>
    <Typography sx={{ fontWeight: "bold" }}>
      {moment(date).format("MMM yyyy")}
    </Typography>
    <Button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
      <KeyboardArrowRightIcon />
    </Button>
  </Box>
);

const DateTimeInput: React.FC<DateTimePickerProps> = ({
  renderInput,
  value,
  onBlur,
  minDateTime,
  maxDateTime,
  onlyDate,
  onClose,
  onChange,
  datePickerProps = {}
}) => {
  const filterPassedTime = (time: Date): boolean => {
    let isValid = true;
    if (minDateTime) {
      isValid = isValid && minDateTime.isSameOrBefore(time);
    }

    if (maxDateTime) {
      isValid = isValid && maxDateTime.isSameOrAfter(time);
    }

    return isValid;
  };

  return (
    <Box>
      <DatePicker
        showTimeSelect={!onlyDate}
        selected={value?.isValid() ? value?.toDate() : null}
        onChange={onChange}
        onCalendarClose={onClose}
        calendarStartDay={1}
        timeFormat="HH:mm"
        dateFormat={onlyDate ? "dd/MM/yyyy" : "dd/MM/yyyy HH:mm"}
        popperClassName={styles.popper}
        timeClassName={() => styles.time}
        renderCustomHeader={header}
        filterTime={filterPassedTime}
        minDate={minDateTime && new Date(minDateTime.valueOf())}
        maxDate={maxDateTime && new Date(maxDateTime.valueOf())}
        onBlur={onBlur}
        disabledKeyboardNavigation
        customInput={renderInput}
        {...datePickerProps}
      />
    </Box>
  );
};

export default DateTimeInput;
