import * as React from "react";
import { useEffect, useState } from "react";

import Grid from "@mui/material/Grid";
import { ListControllerResult } from "ra-core/dist/cjs/controller/list/useListController";
import {
  Datagrid, FunctionField,
  Labeled,
  ListContextProvider,
  Show,
  SimpleShowLayout,
  TextField,
  useDataProvider,
  useShowController
} from "react-admin";

import { CollectionPartner, CollectionPartnerType } from "../../components/collections/types";
import { CollectionPartnerStockBalance, StockItemType } from "../../components/stock/types";
import { Resources } from "../../resources";
import {
  countryNameRenderer,
  enumIconRenderer, enumRenderer,
  geolocationRenderer
} from "../../utils/field-renderers";
import { stockItemIcons } from "../stock-items/renders";

const useListContext = (data: any[], filteredData: any[]) => {
  return {
    data: filteredData,
    total: data.length,
    page: 1,
    perPage: data.length,
    filterValues: {},
    displayedFilters: {},
    sort: {}
  } as unknown as ListControllerResult;
};
export const StockBalanceShow: React.FC = () => {
  const { record: stockBalance } = useShowController<CollectionPartnerStockBalance>();
  const dataProvider = useDataProvider();
  const [collectionPartner, setCollectionPartner] =
      useState<CollectionPartner| null>(null);

  useEffect(() => {
    if (stockBalance?.id) {
      dataProvider.getOne(Resources.CollectionPartners, { id: stockBalance.id })
        .then(({ data }) => setCollectionPartner(data));
    }
  }, [stockBalance, dataProvider]);

  const stockBalancesData = Object.entries(stockBalance?.stockBalances || {}).flatMap(([type, balances]) =>
    balances.map((stockBalance) => ({ ...stockBalance, type }))
  );

  const listContext = useListContext(stockBalancesData, stockBalancesData.filter((record) => record.type === "PELLETS" || record.quantity !== 0));
  return (
    <Show queryOptions={{ cacheTime: 0, staleTime: 0 }}>
      <SimpleShowLayout>
        {collectionPartner && (
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Labeled>
                <TextField source="code" label="Code" record={collectionPartner} />
              </Labeled>
            </Grid>
            <Grid item xs={4}>
              <Labeled>
                <TextField source="name" label="Name" record={collectionPartner} />
              </Labeled>
            </Grid>
            <Grid item xs={4}>
              <Labeled>
                <FunctionField label="Type"
                  render={() => enumRenderer(collectionPartner.type, CollectionPartnerType)}
                />
              </Labeled>
            </Grid>
            <Grid item xs={4}>
              <Labeled>
                <FunctionField label="Country" render={() => countryNameRenderer(collectionPartner)}
                />
              </Labeled>
            </Grid>
            <Grid item xs={4}>
              <Labeled>
                <TextField source="address" label="Address" record={collectionPartner} />
              </Labeled>
            </Grid>
            <Grid item xs={4}>
              <Labeled>
                <FunctionField label="Geolocation"
                  render={() => geolocationRenderer(collectionPartner)}
                />
              </Labeled>
            </Grid>
            <Grid item xs={12}>
              <ListContextProvider value={listContext}>
                <Datagrid>
                  <TextField source="name" label="Name" />
                  <TextField source="quantity" label="Quantity" />
                  <FunctionField label="Type"
                    source= "type"
                    render={(record: { type: StockItemType }) =>
                      enumIconRenderer(record.type, StockItemType, stockItemIcons)}
                  />
                </Datagrid>
              </ListContextProvider>
            </Grid>
          </Grid>
        )}
      </SimpleShowLayout>
    </Show>
  );
};