import React, { memo, ReactNode } from "react";

import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  CircularProgress,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel
} from "@mui/material";
import { InputProps } from "@mui/material/Input";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { constant, get, noop } from "lodash-es";

import { ChangeEvent } from "../../use-form";
import { FormInputProps } from "../types";
import { equalityFormCheck } from "../utils";

export type FormSelectMenuItem = {
  label: string | ReactNode,
  value: string | number | undefined
};

export type FormSelectProps = FormInputProps & {
  menuItems: FormSelectMenuItem[],
  clearable?: boolean,
  multiple?: boolean,
  isLoading?: boolean,
  inputProps?: InputProps["inputProps"],
  onChange?: (newValue: any) => void
};

export const FormSelect: React.FC<FormSelectProps> = memo<FormSelectProps>(({
  name,
  label,
  form,
  menuItems,
  clearable = true,
  multiple = false,
  isLoading = false,
  disabled = false,
  fullWidth = true,
  sx = {},
  size,
  inputProps = {},
  onChange = noop
}) => {
  const { value, error, handleChange, handleBlurValidate, setValueByLabel } = form;
  const handleClear = () => setValueByLabel(name, "");
  const changeHandler = (e: ChangeEvent) => {
    const newValue = handleChange(e);
    onChange(newValue);
  };

  const items = menuItems.map((item) => {
    return <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>;
  });

  return (
    <FormControl fullWidth={fullWidth}
      sx={{ position: "relative" }}
      error={Boolean(get(error, name))}
      className={`MuiFormControl-${size ?? "default"}`}
    >
      <InputLabel id={`id-${name}`} sx={{ width: "100%" }}>
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
          {label} {isLoading && <CircularProgress color="inherit" size={20} />}
        </Box>
      </InputLabel>
      <Select
        sx={{ ...sx }}
        IconComponent={constant(null)}
        labelId={`id-${name}`}
        label={label}
        name={name}
        value={get(value, name)}
        onChange={changeHandler}
        onBlur={handleBlurValidate}
        inputProps={{ ...inputProps }}
        multiple={multiple}
        size={size}
        disabled={disabled || isLoading}
      >
        {items}
      </Select>
      {(clearable && get(value, name) !== "") && (
        <IconButton
          aria-label="close"
          color="inherit"
          sx={{ p: 0.5, position: "absolute", right: 0, top: "28px", transform: "translate(-5px, -50%)" }}
          onClick={handleClear}
        >
          <CloseIcon/>
        </IconButton>
      )}
      <FormHelperText>{get(error, name)}</FormHelperText>
    </FormControl>
  );
}, equalityFormCheck);
