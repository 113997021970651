import React from "react";

import CustomerIcon from "@mui/icons-material/Person";
import { isNil } from "lodash-es";
import {
  BooleanField,
  DatagridConfigurable,
  DateField,
  FunctionField,
  List,
  NullableBooleanInput,
  TextField
} from "react-admin";

import { useCustomerFilters } from "../../components/filter/customer-filters";
import { enumSelectFilter } from "../../components/filter/enum-select-filter";
import { buildLabel } from "../../components/filter/filter-utils";
import { useDateRangeFilters } from "../../components/filter/use-date-range-filters";
import { PresetListActions } from "../../components/list/actions/PresetListActions";
import { DefaultPagination } from "../../components/pagination/DefaultPagination";
import { Customer, CustomerGroupType, CustomerType } from "../../utils/commons";
import {
  capitalizeRenderer,
  countryNameRenderer,
  enumRenderer,
  geolocationRenderer
} from "../../utils/field-renderers";
import CustomersBulkActions from "./bulk-actions/CustomersBulkActions";
import { customerBalanceRenderer, freshdeskContactUrlRenderer } from "./renderers";

export const CustomersList: React.FC = () => {
  const filters = [
    ...useCustomerFilters({
      contractAlwaysOn: true,
      registrationAlwaysOn: true
    }),
    ...useDateRangeFilters({
      lowerBoundSource: "inactivatedDateTimeGOE",
      lowerBoundLabel: "Customer repossession date (>=)",
      upperBoundSource: "inactivatedDateTimeLOE",
      upperBoundLabel: "Customer repossession date (<=)",
      icon: CustomerIcon
    }),
    enumSelectFilter({
      source: "customerTypes",
      enumObj: CustomerType,
      label: buildLabel({ label: "Customer type", Icon: CustomerIcon })
    }),
    <NullableBooleanInput
      key="isFullyPaid"
      label={buildLabel({ label: "Fully Paid", Icon: CustomerIcon })}
      variant="filled"
      source="isFullyPaid"/>
  ];

  return (
    <List filters={filters}
      actions={<PresetListActions selectColumnsEnabled/>}
      pagination={<DefaultPagination/>}
      perPage={25}
      exporter={false}
      sort={{ field: "timeRegistered", order: "DESC" }}>
      <DatagridConfigurable bulkActionButtons={<CustomersBulkActions/>}
        isRowSelectable={(record: Customer) => isNil(record.freshdeskId)}
        rowClick="show">
        <TextField source="contractReference"/>
        <TextField source="firstName" sortable={false}/>
        <TextField source="lastName" sortable={false}/>
        <FunctionField label="Freshdesk contact"
          render={(record: Customer) => freshdeskContactUrlRenderer(record)}/>
        <FunctionField label="Type"
          render={(record: Customer) => enumRenderer(record.type, CustomerType)}/>
        <FunctionField label="Group"
          render={(record: Customer) => enumRenderer(record.groupType, CustomerGroupType)}/>
        <FunctionField label="Country" sortable={true} sortBy="country"
          render={(record: Customer) => countryNameRenderer(record)}/>
        <FunctionField label="State"
          render={(record: Customer) => capitalizeRenderer(record.customerStatus)}/>
        <BooleanField label="Fully Paid" source="isFullyPaid"/>
        <FunctionField label="Geolocation"
          render={(record: Customer) => geolocationRenderer(record)}/>
        <FunctionField label="Balance"
          render={(record: Customer) => customerBalanceRenderer(record)}/>
        <DateField source="timeRegistered" label="Registration date" showTime/>
        <DateField source="timeInactivated" label="Repossession date" showTime/>
      </DatagridConfigurable>
    </List>
  );
};
