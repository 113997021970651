import * as React from "react";
import { useEffect, useState } from "react";

import { head } from "lodash-es";
import { useDataProvider, useNotify } from "react-admin";

import { Resources } from "../../../resources";
import { FullCustomer } from "../../../utils/commons";
import { usePageSafeSelector } from "../../../utils/use-page-safe-selector";
import { Order } from "../types";
import { CreateOrUpdateOrderButton } from "./create/CreateOrUpdateOrderButton";

const EditOrderButton: React.FC = () => {
  const notify = useNotify();

  const dataProvider = useDataProvider();
  const { data } = usePageSafeSelector<Order>();
  const [order, setOrder] = useState<Order | undefined>();
  const [customer, setCustomer] = useState<FullCustomer | undefined>();
  const [disable, setDisable] = useState<boolean>(false);

  useEffect(() => {
    const order = head(data);
    setOrder(order);
    const customerId = order?.customerId;
    if (customerId) {
      dataProvider.getOne<FullCustomer>(Resources.Customers, { id: customerId })
        .then(( response ) => {
          setCustomer(response.data);
          return response;
        })
        .catch(() => {
          notify("Error: failed to retrieve customer", { type: "error" });
        });
    }
    setDisable(data.length > 1 || order?.orderStatus !== "NEW");
  }, [data, dataProvider, notify]);

  return (
    <div>
      <CreateOrUpdateOrderButton
        variant="text"
        order={order}
        disabled={disable}
        predefinedCustomer={customer}
      />
    </div>
  );
};

export default EditOrderButton;