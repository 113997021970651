import React from "react";

import { Alert, Box, Grid } from "@mui/material";
import { isNil } from "lodash-es";

import { Customer } from "../../../utils/commons";
import { Payment } from "../types";

export type InsufficientFundsAlertProps = {
  customerPayments: {
    contractReference: string,
    requiredAmount: number,
    actualAmount: number,
    currency: string
  }[]
};

export const hasEnoughFunds = (
  customer?: Customer,
  amount?: number) => {
  return (customer?.wallet?.amount ?? 0) >= (amount ?? 0);
};

export const calculateInsufficientFundDetails = (payments: Payment[], customers: Record<number, Customer>) => {
  if (!Object.keys(customers).length) {
    return { customerPayments: [] };
  }
  const customerPayments = payments.flatMap((payment) =>
    payment.transactions
      .filter((transaction) =>
        transaction.activeAssignment &&
          !isNil(customers[transaction.customerId]) &&
          !hasEnoughFunds(customers[transaction.customerId], transaction.amount))
      .map((transaction) => {
        const customer = customers[transaction.customerId];
        return {
          contractReference: customer.contractReference,
          requiredAmount: payment.amount,
          actualAmount: customer.wallet.amount,
          currency: payment.currency
        };
      })
  );
  return { customerPayments };
};

const InsufficientFundsAlert: React.FC<InsufficientFundsAlertProps> = ({ customerPayments }) => {
  return (
    <Grid item xs={12} >
      <Alert severity="warning">
        Insufficient funds in the customer&apos;s wallets:<br/>
        {customerPayments.map(({ contractReference, requiredAmount, actualAmount, currency }, index) => (
          <Box key={index} sx={{ mt: 1 }}>
                Customer: <strong>{contractReference}</strong><br />
                Required amount: <strong>{requiredAmount} {currency}</strong><br />
                Actual amount in wallet:<strong>{actualAmount} {currency}</strong>
          </Box>
        ))}
      </Alert>
    </Grid>
  );
};

export default InsufficientFundsAlert;