import { PropsWithChildren } from "react";

import { isEqual, pick } from "lodash-es";

import { FormInputProps } from "./types";

type Props = Readonly<PropsWithChildren<FormInputProps & any>>;

export const equalityFormCheck = (prevProps: Props, nextProps: Props) => {
  const name = nextProps.name;
  const dependencies = nextProps.dependencies || [];
  const fieldsToCheck = [name, ...dependencies];

  const { form: prevForm, ...restPrevProps } = prevProps;
  const { form: nextForm, ...restNextProps } = nextProps;

  if (!isEqual(restPrevProps, restNextProps)) return false;
  return ["value", "error"]
    .every((formField) => {
      const prevValues = pick(prevForm[formField], fieldsToCheck);
      const nextValues = pick(nextForm[formField], fieldsToCheck);

      return isEqual(prevValues, nextValues);
    });
};
