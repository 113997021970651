import React from "react";

import { Typography } from "@mui/material";
import {
  Datagrid,
  DateField,
  EditButton,
  FunctionField,
  List,
  ReferenceArrayField,
  SimpleShowLayout,
  TextField
} from "react-admin";

import {
  CollectionPartner,
  CollectionPartnerStatus,
  CollectionPartnerType
} from "../../components/collections/types";
import { PresetListActions } from "../../components/list/actions/PresetListActions";
import { DefaultPagination } from "../../components/pagination/DefaultPagination";
import { UserRoles } from "../../core/providers/auth/roles";
import { Resources } from "../../resources";
import {
  countryNameRenderer,
  EnumChipColors,
  enumChipRenderer,
  enumRenderer,
  geolocationRenderer, locationHierarchyRenderer
} from "../../utils/field-renderers";
import { useCheckAccess } from "../../utils/use-check-access";
import { roleRenderer } from "../users";
import { useCollectionsFilters } from "./use-collections-filters";

const statusChipColors: Record<keyof typeof CollectionPartnerStatus, EnumChipColors> = {
  ACTIVE: "success",
  INACTIVE: "secondary"
};

const CollectionDetails: React.FC = () => {
  return (
    <SimpleShowLayout>
      <ReferenceArrayField
        label="Users"
        reference={Resources.Users}
        source="userIds">
        <Datagrid
          bulkActionButtons={false}
          empty={<Typography variant="body2">No users assigned to this collection point</Typography>}>
          <TextField source="id"/>
          <TextField source="username"/>
          <FunctionField render={roleRenderer} label="Role" sortBy="role"/>
          <DateField source="timeCreated" showTime/>
        </Datagrid>
      </ReferenceArrayField>
    </SimpleShowLayout>
  );
};

export const CollectionPartnersList: React.FC = () => {
  const { hasAccess } = useCheckAccess([UserRoles.ROLE_SUPAMOTO_ADMIN]);
  const filters = useCollectionsFilters();

  return (
    <List filters={filters}
      pagination={<DefaultPagination/>}
      filterDefaultValues={{ statuses: "ACTIVE" }}
      actions={<PresetListActions createEnabled={hasAccess}/>}
      perPage={25}
      exporter={false}>
      <Datagrid
        bulkActionButtons={false}
        expand={<CollectionDetails/>}
      >
        <TextField source="code" sortable={false}/>
        <TextField source="name"/>
        <FunctionField label="Type"
          render={(record: CollectionPartner) => enumRenderer(record.type, CollectionPartnerType)}/>
        <FunctionField label="Country" sortBy="country"
          render={(record: CollectionPartner) => countryNameRenderer(record) || "-"}/>
        <FunctionField label="Location" sortBy="location"
          render={(record: CollectionPartner) => locationHierarchyRenderer(record.locationsHierarchy)}/>
        <TextField source="address" sortable={false}/>
        <TextField source="phoneNumber" sortable={false}/>
        <FunctionField label="Geolocation"
          render={(record: CollectionPartner) => geolocationRenderer(record)}/>
        <FunctionField label="Total users"
          render={(record: CollectionPartner) => record.userIds?.length}/>
        <FunctionField
          label="Status"
          render={(record: CollectionPartner) => {
            return enumChipRenderer(record.status, CollectionPartnerStatus, statusChipColors);
          }}
        />
        <DateField label="Registration date" source="timeCreated" showTime/>
        {hasAccess && <EditButton/>}
      </Datagrid>
    </List>
  );
};