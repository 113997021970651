import React from "react";

import ConfirmationDialog, { ConfirmationDialogProps } from "../../../components/dialog/ConfirmationDialog";
import { StockEventSummaryDialog } from "../../../components/stock-events/StockEventSummaryDialog";
import {
  CreateStockEvent,
  StockEventShort
} from "../../../components/stock-events/types";
import { StockItem } from "../../../components/stock-item/types";

type ConfirmationProps = Pick<ConfirmationDialogProps,
    "isConfirmationDialogOpen" | "isConfirmDisabled" | "onClose" | "onConfirm">;

type CreateStockEventConfirmationDialogProps = ConfirmationProps & {
  createStockEvent: CreateStockEvent,
  stockItems: StockItem[]
};

const getStockItemName = (stockItems: StockItem[], id: number) => {
  const item = stockItems.find((item) => item.id === id);
  return item ? item.name : undefined;
};

export const CreateStockEventConfirmationDialog: React.FC<CreateStockEventConfirmationDialogProps> = ({
  createStockEvent,
  stockItems,
  ...props
}) => {
  const stockEvents = createStockEvent
    .stockItems.map((stockItem, index) => {
      return {
        ...createStockEvent,
        ...stockItem,
        id: -index
      };
    });
  const totalEvents = stockEvents.flatMap((event) => {
    if (event.type === "TRANSFER_OUT") {
      const transferInEvent: StockEventShort = {
        ...event,
        type: "TRANSFER_IN",
        collectionPartnerId: event.relatedCollectionPartnerId,
        relatedCollectionPartnerId: event.collectionPartnerId

      };
      return [event, transferInEvent];
    } else {
      return event;
    }
  });
  return <ConfirmationDialog
    title="Stock Events to be created:"
    {...props}
    isConfirmDisabled={false}
    size="md">
    {<StockEventSummaryDialog
      fieldsToIgnore={["id"]}
      stockEvents={
        totalEvents.map((stockEvent) => ({
          ...stockEvent,
          itemName: getStockItemName(stockItems, stockEvent.stockItemId)
        }))
      }
    />}
  </ConfirmationDialog>;
};