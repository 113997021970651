import * as React from "react";

import Grid from "@mui/material/Grid";
import moment from "moment";
import { ListControllerResult } from "ra-core/dist/cjs/controller/list/useListController";
import {
  Datagrid,
  DateField,
  Labeled,
  ListContextProvider,
  NumberField,
  Show,
  SimpleShowLayout,
  TextField,
  useShowContext
} from "react-admin";

export const CookingSessionsShow: React.FC = () => {

  const CookingSessionsShowLayout = () => {
    const { record } = useShowContext();

    const samplingData = (record?.samplingData || []).sort(
      (a: any, b: any) => moment(new Date(a.timeRecorded)).diff(moment(new Date(b.timeRecorded)))
    );

    const listContext = {
      data: samplingData,
      total: samplingData.length,
      page: 1,
      perPage: samplingData.length,
      filterValues: {},
      sort: {}
    };
    return (
      <SimpleShowLayout>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Labeled>
              <TextField source="id"/>
            </Labeled>
          </Grid>
          <Grid item xs={4}>
            <Labeled>
              <TextField source="nativeStoveId" label="Stove UID"/>
            </Labeled>
          </Grid>
          <Grid item xs={4}>
            <Labeled>
              <TextField source="stoveModel" label="Stove model"/>
            </Labeled>
          </Grid>
          <Grid item xs={4}>
            <Labeled>
              <NumberField source="duration" label="Duration, sec"/>
            </Labeled>
          </Grid>
          <Grid item xs={4}>
            <Labeled>
              <DateField source="timeFrom" label="Start date/time" showTime/>
            </Labeled>
          </Grid>
          <Grid item xs={4}>
            <Labeled>
              <DateField source="timeTo" label="End date/time" showTime/>
            </Labeled>
          </Grid>
          <Grid item xs={12}>
            <ListContextProvider value={listContext as ListControllerResult}>
              <Datagrid>
                <TextField source="id"/>
                <TextField source="eventType"/>
                <NumberField source="fanSpeed1" label="Fan speed"/>
                <NumberField source="batteryVoltage"/>
                <NumberField source="latitude"/>
                <NumberField source="longitude"/>
                <NumberField source="worktime"/>
                <DateField source="timeRecorded" label="Time recorded" showTime/>
                <DateField source="timeCreated" label="Time saved" showTime/>
              </Datagrid>
            </ListContextProvider>
          </Grid>
        </Grid>
      </SimpleShowLayout>
    );
  };

  return (
    <Show queryOptions={{ cacheTime: 0, staleTime: 0 }}>
      <CookingSessionsShowLayout/>
    </Show>
  );
};
