const AUTH_TOKEN_KEY = "auth-token";

type UserView = {
  token: string,
  id: number,
  email: string,
  roles: string[]
};

export default {
  save(user: UserView) {
    localStorage.setItem(AUTH_TOKEN_KEY, JSON.stringify(user));
  },

  get(): UserView | null {
    const user = localStorage.getItem(AUTH_TOKEN_KEY);
    return user ? JSON.parse(user) : null;
  },

  invalidate() {
    localStorage.removeItem(AUTH_TOKEN_KEY);
  }
};
