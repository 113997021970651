import React, { useState } from "react";

import FlashlightOffIcon from "@mui/icons-material/FlashlightOff";
import FlashlightOnIcon from "@mui/icons-material/FlashlightOn";
import { Button } from "@mui/material";

type Props = {
  onClick: () => void
};

export const HighlightWarningButton: React.FC<Props> = ({
  onClick
}) => {
  const [isEnabled, setEnabled] = useState(true);

  const handleClick = () => {
    setEnabled((prevState) => !prevState);

    onClick();
  };

  return (
    <Button
      size="small"
      onClick={handleClick}
      startIcon={isEnabled ? <FlashlightOffIcon /> : <FlashlightOnIcon />}
    >
      {isEnabled ? "Disable highlighting" : "Enable highlighting"}
    </Button>
  );
};
