import React, { useState } from "react";

import DownloadIcon from "@mui/icons-material/Download";
import { Box, Button, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import Grid from "@mui/material/Grid";
import { isEmpty } from "lodash-es";

import paymentsDataTemplate from "../../../assets/templates/payments_data.csv";
import { ImportDataButton } from "../../../components/list/import/ImportDataButton";
import { UserRoles } from "../../../core/providers/auth/roles";
import { SECONDARY_COLOR } from "../../../core/theme/theme";
import { Country } from "../../../utils/commons";
import {
  generateFormSelectChoicesFromEnum
} from "../../../utils/field-renderers";
import { useCheckAccess } from "../../../utils/use-check-access";
import { MWTelcos, MZTelcos, ZMTelcos } from "../types";

type TelcoMenuItem = {
  label: string,
  value: string
};

const countryMenuItems = Object.entries(Country).map(([key, value]) => (
  <MenuItem key={key} value={key}>{value}</MenuItem>
));

const telcoMenuItems = (country: string): TelcoMenuItem[] => {
  switch (country) {
  case "ZM":
    return generateFormSelectChoicesFromEnum(ZMTelcos);
  case "MW":
    return generateFormSelectChoicesFromEnum(MWTelcos);
  case "MZ":
    return generateFormSelectChoicesFromEnum(MZTelcos);
  default: return [];
  }
};

export const ImportPaymentsButton = () => {
  const { hasAccess } = useCheckAccess(UserRoles.ROLE_SUPAMOTO_ADMIN);

  const [country, setCountry] = useState("");
  const [telco, setTelco] = useState("");

  const isFormValid = () => !isEmpty(country) && !isEmpty(telco);
  const onDialogClose = () => {
    setCountry("");
    setTelco("");
  };

  return (
    <ImportDataButton
      resource={() => `payments/upload?country=${country}&operator=${telco}`}
      disabled={!hasAccess}
      isDataValid={isFormValid}
      onDialogClose={onDialogClose}
      buttonLabel="Import payments"
      buttonTooltip="Bulk import of payments"
      dialogTitle="Import payments"
      dialogContentText={
        <>
          <Box sx={{ fontSize: 14, color: SECONDARY_COLOR }}>
            Sometimes due to issues on Telco, Telerivet, or our side, payments may be missing in the Admin Panel.
            This feature allows you to import payments obtained directly from the Telco.
            Below you can download the CSV file template containing sample data.
          </Box>
          <Box sx={{ mb:2 }}>
            <Button
              sx={{
                textTransform: "none",
                fontSize: 14,
                fontWeight: 600,
                letterSpacing: "0.25px"
              }}
              variant="text"
              startIcon={<DownloadIcon/>}
              href={paymentsDataTemplate}
              download="example.csv"
            >
                  Download template
            </Button>
          </Box>
          <Box sx={{ mb: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel id="country">Country *</InputLabel>
                  <Select
                    labelId="country"
                    label="Country *"
                    value={country}
                    onChange={(event) => {
                      setCountry(event.target.value as string);
                      setTelco("");
                    }}
                  >
                    {countryMenuItems}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel id="telco">Telco *</InputLabel>
                  <Select
                    labelId="telco"
                    label="Telco *"
                    value={telco}
                    disabled={isEmpty(country)}
                    onChange={(event) => setTelco(event.target.value as string)}
                  >
                    {telcoMenuItems(country).map((item, index) => (
                      <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Box>
        </>
      }
    />
  );
};
