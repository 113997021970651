import React from "react";

import { TextInput } from "react-admin";

import { InputFilterProps } from "./types";

export const baseMultilineTextInputFilter = ({
  source, key, label, parse, format, alwaysOn
}: InputFilterProps) => {
  return (
    <TextInput
      source={source}
      key={key || source}
      label={label}
      multiline
      variant="filled"
      parse={parse}
      format={format}
      alwaysOn={alwaysOn}
      sx={{
        minWidth: "240px",
        "& .MuiFilledInput-input": {
          maxHeight: "100px",
          overflowY: "auto !important"
        }
      }}
    />);
};
