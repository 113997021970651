export enum UserRoles {
  ROLE_SUPAMOTO_ADMIN = "ROLE_SUPAMOTO_ADMIN",
  ROLE_EXTERNAL_COMPANY_ADMIN = "ROLE_EXTERNAL_COMPANY_ADMIN",
  ROLE_READ_ONLY = "ROLE_READ_ONLY",
  ROLE_API = "ROLE_API",
  ROLE_DELIVERY_MANAGER = "ROLE_DELIVERY_MANAGER",
  ROLE_STOCK_MANAGER = "ROLE_STOCK_MANAGER",
  ROLE_SALES = "ROLE_SALES",
  ROLE_SHOP_KEEPER = "ROLE_SHOP_KEEPER"
}
