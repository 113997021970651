import { ContractLine } from "./types";

export const getUtilityContractTotalAmount = (country: string, group: string): number => {
  const key = `${country}:${group}`;
  return utilityContractTotalAmounts[key] || 0;
};

export const calcTotalAmount = (contractLines: ContractLine[]) => {
  return contractLines.reduce((total: number, line: ContractLine) => {
    return total + ((line.amount || 0) * (line.instanceId ? 1 : (line.quantity || 0)));
  }, 0);
};

const utilityContractTotalAmounts: { [key: string]: number } = {
  "ZM:UTILITY": 120,
  "ZM:STAFF": 84,
  "MW:UTILITY": 8000,
  "MW:STAFF": 5600
};
