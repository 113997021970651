import { isNil } from "lodash-es";
import * as yup from "yup";

import { yupDate, yupNumber } from "../../components/form/validation";
import { CreateOrderLine } from "../orders/actions/create/types";
import { ContractForm, ContractLine } from "./types";

export const initialState = {
  startDate: new Date(),
  downPayment: 0,
  duration: 0,
  totalAmount: 0,
  fixedTotalAmount: false,
  details: [{ offerId: "", discountId: "" } as ContractLine],
  addons: [{ dealId: "", discountId: "" } as CreateOrderLine]
};

const contractLineValidationSchema = yup.object({
  offerId: yupNumber((schema) => (`${schema.label} must be selected`)).required().label("Offer"),
  quantity: yupNumber().integer().label("Quantity")
    .when("instanceId", {
      is: (value: any) => isNil(value),
      then: (schema) => schema.required().positive(),
      otherwise: (schema) => schema.optional()
    })
});

export const productsAndDiscountsValidationSchema = yup.object({
  details: yup.array().of(contractLineValidationSchema).min(1).label("Detail"),
  totalAmount: yupNumber().integer().required().label("Total Price").min(0)
});

export const termsAndConditionsValidationSchema = yup.object({
  startDate: yupDate().label("Effective Date").required(),
  downPayment: yupNumber().integer().required().label("Down Payment")
    .min(0)
    .test("less-or-equal-to-totalAmount",
      "The Down Payment must be less or equal to the Total Price",
      (value, context) => isNil(value) ? true : (context.parent as ContractForm).totalAmount >= value)
});

export const addonLineValidationSchema = yup.object({
  dealId: yupNumber((schema) => (`${schema.label} must be selected`)).optional().label("Offer"),
  quantity: yupNumber().integer().label("Quantity")
    .when("dealId", {
      is: (value: any) => isNil(value),
      then: (schema) => schema.optional(),
      otherwise: (schema) => schema.required().positive()
    })
});

export const addonsValidationSchema = yup.object({
  addons: yup.array().of(addonLineValidationSchema).optional().label("Addons")
});

export const validationSchema = productsAndDiscountsValidationSchema
  .concat(termsAndConditionsValidationSchema)
  .concat(addonsValidationSchema);