import React, { useEffect, useState } from "react";

import { RaRecord } from "ra-core";
import { AutocompleteArrayInput } from "react-admin";

import { usernameRenderer } from "../../utils/field-renderers";
import { buildLabel, CustomFilterIcon } from "../filter/filter-utils";
import { useUsers } from "./use-users";

export const useUserFilter = (label = "Users", source = "userIds") => {
  const { users } = useUsers();
  const [ choices, setChoices ] = useState<RaRecord[]>([]);

  useEffect(() => {
    const choices = users.map((user) => ({ id: user.id, name: usernameRenderer(user) }))
      .sort((a, b) => a.name.localeCompare(b.name));
    setChoices(choices);
  }, [users]);

  return <AutocompleteArrayInput key="users"
    label={buildLabel({ label: label, Icon: CustomFilterIcon })}
    source={source}
    variant="filled"
    choices={choices} />;
};