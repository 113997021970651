import React, { useEffect, useState } from "react";

import LockOpenIcon from "@mui/icons-material/LockOpen";
import { Button, Tooltip } from "@mui/material";
import { BulkDeleteWithConfirmButton } from "react-admin";

import { UserRoles } from "../../../core/providers/auth/roles";
import { Device } from "../../../utils/commons";
import { useCheckAccess } from "../../../utils/use-check-access";
import { usePageSafeSelector } from "../../../utils/use-page-safe-selector";
import { LockStoveAction } from "./LockStoveAction";

const StoveBulkActions = () => {
  const { hasAccess } = useCheckAccess([UserRoles.ROLE_SUPAMOTO_ADMIN, UserRoles.ROLE_EXTERNAL_COMPANY_ADMIN]);
  const [isLockDialogOpen, setIsLockDialogOpen] = useState(false);
  const [changeUnlockUntilDisable, setChangeUnlockUntilDisable] = useState(true);
  const [isNonPcbStoveSelected, setIsNonPcbStoveSelected] = useState(true);
  const { data } = usePageSafeSelector<Device>();

  useEffect(() => {
    setIsNonPcbStoveSelected(data.some((stove) => stove.model !== "PCB"));
    setChangeUnlockUntilDisable(!data || data.every((device) =>
      device.stoves.every((stove) => stove.status !== "ACTIVE" || stove.connectionType === "NOT_CONNECTED")
    ));
  }, [data]);

  return (
    <>
      <Tooltip title="Lock or unlock active connected stoves">
        <span>
          <Button
            color="error"
            disabled={!hasAccess || changeUnlockUntilDisable}
            onClick={() => setIsLockDialogOpen(true)}
            startIcon={<LockOpenIcon/>}>
              Lock/Unlock
          </Button>
        </span>
      </Tooltip>
      <Tooltip title={"Delete stoves"}>
        <div>
          <BulkDeleteWithConfirmButton
            disabled={!hasAccess || isNonPcbStoveSelected}
            mutationMode="pessimistic"
            size="medium"/>
        </div>
      </Tooltip>
      <LockStoveAction
        isDialogOpen={isLockDialogOpen}
        onDialogClose={() => setIsLockDialogOpen(false)}
      />
    </>
  );
};

export default StoveBulkActions;
