import * as React from "react";

import { Box, Typography } from "@mui/material";
import { Chrono } from "react-chrono";

import { AllStoveInactivationReason, StoveModel, StoveStatus } from "../../../utils/commons";
import { countryNameRenderer } from "../../../utils/field-renderers";
import { FullStove } from "../StoveShow";

type StoveHistoryTabProps = {
  stoves?: FullStove[]
};

export const StoveHistoryTab: React.FC<StoveHistoryTabProps> = ({
  stoves
}) => {
  const data = stoves?.sort((a, b) => new Date(b.timeCreated).getTime() - new Date(a.timeCreated).getTime()).
    map((stove) => ({
      title: `${new Date(stove.timeCreated).toLocaleDateString()}-${stove.timeInactivated ? new Date(stove.timeInactivated).toLocaleDateString() : "now"}`,
      id: stove.id,
      stoveUID: stove.nativeId,
      customerName: stove?.customer && `${stove.customer.firstName} ${stove.customer.lastName}` || "Unassigned",
      status: StoveStatus[stove.status],
      country: stove.country,
      stoveType: StoveModel[stove.model],
      repossessionReason: stove.inactivationReason ? AllStoveInactivationReason[stove.inactivationReason] : undefined
    }));

  const items = (data || []).map((item) => ({
    title: item.title,
    cardDetailedText: (
      <>
        <Typography variant="body1" component="div">Internal ID: {item.id}</Typography>
        <Box height="5px" />
        <Typography variant="body1" component="div">Stove UID: {item.stoveUID}</Typography>
        <Box height="5px" />
        <Typography variant="body1" component="div">Customer: {`${item.customerName}`}</Typography>
        <Box height="5px" />
        <Typography variant="body1" component="div">Type: {item.stoveType}</Typography>
        <Box height="5px" />
        <Typography variant="body1" component="div">Country: {countryNameRenderer(item)}</Typography>
        <Box height="5px" />
        <Typography variant="body1" component="div">Status: {item.status} {item.repossessionReason ? `(${item.repossessionReason})` : ""}</Typography>
        <Box height="20px" />
      </>
    )
  }));

  return (
    <Chrono
      mode="VERTICAL"
      disableToolbar="true"
      showNavigation={false}
      theme={{
        primary: "gray",
        secondary: "white",
        cardBgColor: "white",
        cardTitleColor: "black",
        titleColor: "black",
        titleColorActive: "secondary"
      }}
      items={items}
    />
  );
};