import * as React from "react";

import { CardContent } from "@mui/material";
import { useNotify, useSafeSetState } from "ra-core";
import { TextInput, useDataProvider } from "react-admin";
import { FieldValues } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import authTokenStorage from "./../../core/providers/auth/auth-token-storage";
import { ButtonWithLoader } from "./ButtonWithLoader";
import { AuthClasses, StyledForm } from "./types";
import { passwordsMatchValidation, validateEmail, validatePassword } from "./validation";

export const SignUpForm = () => {
  const [loading, setLoading] = useSafeSetState(false);
  const navigate = useNavigate();
  const dataProvider = useDataProvider();
  const notify = useNotify();

  const submit = (formData: FieldValues) => {
    setLoading(true);

    dataProvider.signUp(formData.email, formData.password)
      .then((response: any) => {
        authTokenStorage.save(response.data);
        navigate("/");
        return true;
      })
      .catch((data: any) => {
        setLoading(false);

        const errorCode = data.body?.errorCode;
        if (errorCode === "users.already_exists") {
          notify(`Email ${formData.email} already registered`, { type: "error" });
        } else {
          notify("Failed to sign up", { type: "error" });
        }
      });
  };

  return (
    <StyledForm
      onSubmit={submit}
      mode="onChange"
      noValidate
    >
      <CardContent className={AuthClasses.content}>
        <TextInput
          autoFocus
          source="email"
          label="Email"
          validate={validateEmail}
          fullWidth
        />
        <TextInput
          source="password"
          label="Password"
          type="password"
          validate={validatePassword}
          fullWidth
        />
        <TextInput
          source="repassword"
          label="Repeat password"
          type="password"
          validate={[...validatePassword, passwordsMatchValidation]}
          fullWidth
        />
        <ButtonWithLoader loading={loading} buttonLabel="Sign Up"/>
      </CardContent>
    </StyledForm>
  );
};