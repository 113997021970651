import { isEmpty, isNil, pick, omit } from "lodash-es";
import { PaginationPayload, SortPayload } from "ra-core";

import { LocationFilterSource } from "../../../components/location/types";

export class GetListQueryBuilder {
  private pagination = "";
  private sort = "";
  private filter = "";

  public withPagination(pagination?: PaginationPayload): GetListQueryBuilder {
    if (pagination && !isEmpty(pagination)) {
      this.pagination = `page=${pagination.page - 1}&size=${pagination.perPage}`;
    }

    return this;
  }

  public withSort(sort?: SortPayload): GetListQueryBuilder {
    if (sort && !isEmpty(sort)) {
      this.sort += `sort=${sort.field},${sort.order}`;
    }

    return this;
  }

  public withFilter(filter?: any): GetListQueryBuilder {
    if (filter && !isEmpty(filter)) {
      const locationFilters = pick(filter, Object.values(LocationFilterSource));
      if (!isEmpty(locationFilters)) {
        filter = omit(filter, Object.values(LocationFilterSource));
        filter.locationIds = Object.values(locationFilters).flat();
      }

      this.filter = Object.keys(filter)
        .filter((filterName) => !isNil(filter[filterName]))
        .map((filterName) => `${filterName}=${encodeURIComponent(filter[filterName])}`)
        .join("&");
    }

    return this;
  }

  public build(): string {
    return [this.pagination, this.sort, this.filter]
      .filter((part) => !isEmpty(part))
      .join("&");
  }
}
