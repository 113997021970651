import React, { useEffect, useState } from "react";

import { RaRecord } from "ra-core";
import { AutocompleteArrayInput } from "react-admin";

import { buildLabel, CustomFilterIcon } from "../filter/filter-utils";
import { useStockItems } from "./use-stock-items";

export const useStockItemFilter = (alwaysOn = false,
  label = "Stock Items",
  source = "stockItemIds") => {
  const { stockItems } = useStockItems();
  const [ choices, setChoices ] = useState<RaRecord[]>([]);

  useEffect(() => {
    const choices = stockItems
      .map((item) =>
        ({ id: item.id, name: item.name }))
      .sort((a, b) => a.name.localeCompare(b.name));
    setChoices(choices);
  }, [stockItems]);

  return <AutocompleteArrayInput key="stockItems"
    label={buildLabel({ label: label, Icon: CustomFilterIcon })}
    source={source}
    variant="filled"
    alwaysOn={alwaysOn}
    choices={choices} />;
};