import React from "react";

import { SelectArrayInput } from "react-admin";

import { InputFilterProps } from "./types";

export type EnumSelectFilterProps = InputFilterProps & {
  enumObj: Record<string, string>
};

export const enumSelectFilter = ({ source, key, label, enumObj, alwaysOn }: EnumSelectFilterProps) => {
  const choices = Object.entries(enumObj).map(([key, value]) => ({ id: key, name: value }));

  return <SelectArrayInput
    source={source}
    key={key || source}
    label={label}
    variant="filled"
    choices={choices}
    alwaysOn={alwaysOn}
  />;
};
