import React, { SyntheticEvent, useEffect, useState } from "react";

import {
  AutocompleteChangeReason,
  AutocompleteInputChangeReason
} from "@mui/base/useAutocomplete/useAutocomplete";
import { Autocomplete, CircularProgress } from "@mui/material";
import { sortBy } from "lodash-es";
import { useDataProvider, useNotify } from "react-admin";

import { FormTextField } from "../../../../components/form/input/text-field/FormTextField";
import { FormProps } from "../../../../components/form/use-form";
import { Resources } from "../../../../resources";
import { countryNameRenderer } from "../../../../utils/field-renderers";
import { DeliveryGroup } from "../../../delivery-groups/DeliveryGroupsList";

type Props = {
  form: FormProps<any>,
  onValueSelected: (value: DeliveryGroup) => void
};

export const DeliveryGroupsAutocomplete: React.FC<Props> = ({
  form,
  onValueSelected
}) => {
  const [inputValue, setInputValue] = useState("");
  const [deliveryGroups, setDeliveryGroups] = useState<readonly DeliveryGroup[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const dataProvider = useDataProvider();
  const notify = useNotify();

  useEffect(() => {
    setIsLoading(true);
    dataProvider.getList(Resources.DeliveryGroups, {
      pagination: {
        page: 1,
        perPage: 100
      },
      sort: {
        field: "name",
        order: "ASC"
      },
      filter: {
        name: inputValue
      }
    }).then((response) => {
      setIsLoading(false);
      setDeliveryGroups(sortBy(response.data, ["country", "name"]));
      return response;
    }).catch(() => {
      notify("Error: failed to retrieve delivery groups", { type: "error" });
    });
  }, [dataProvider, inputValue, notify]);

  const onChange = (event: SyntheticEvent, value: DeliveryGroup, reason: AutocompleteChangeReason) => {
    if (reason === "selectOption") {
      form.setValueByLabel("resourceId", value.id);
      onValueSelected(value);
    }
  };

  const onInputChange = (event: React.SyntheticEvent, value: string, reason: AutocompleteInputChangeReason) => {
    if (reason === "input") {
      setInputValue(value);
    }
  };

  return (
    <Autocomplete
      loading={isLoading}
      options={deliveryGroups}
      onChange={onChange}
      onInputChange={onInputChange}
      getOptionLabel={(option: DeliveryGroup) => option.name}
      isOptionEqualToValue={(option: DeliveryGroup, value: DeliveryGroup) => option.name === value.name}
      filterOptions={(x) => x}
      groupBy={(option) => countryNameRenderer(option) || ""}
      disableClearable
      renderInput={(params) => (
        <FormTextField name="resourceId"
          label="Delivery group *"
          inputProps={params?.inputProps}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            )
          }}
          form={form}
        />
      )}
    />
  );
};
