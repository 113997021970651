import React from "react";

import { Datagrid, EditButton, FunctionField, List, TextField } from "react-admin";

import { PresetListActions } from "../../components/list/actions/PresetListActions";
import { DefaultPagination } from "../../components/pagination/DefaultPagination";
import { StockItem, StockItemStatus } from "../../components/stock-item/types";
import { StockItemType } from "../../components/stock/types";
import { UserRoles } from "../../core/providers/auth/roles";
import {
  EnumChipColors,
  enumChipRenderer,
  enumIconRenderer
} from "../../utils/field-renderers";
import { useCheckAccess } from "../../utils/use-check-access";
import { filters } from "./filters";
import { availableForCountriesRenderer, availableForRenderer, stockItemIcons } from "./renders";

const statusChipColors: Record<keyof typeof StockItemStatus, EnumChipColors> = {
  ACTIVE: "success",
  INACTIVE: "warning"
};

export const StockItemList: React.FC = () => {
  const { hasAccess } = useCheckAccess(UserRoles.ROLE_SUPAMOTO_ADMIN);

  return (<List
    filters={filters}
    actions={<PresetListActions exportingEnabled={false} createEnabled={hasAccess}/>}
    pagination={<DefaultPagination/>}
    perPage={25}
    hasCreate={true}>
    <Datagrid bulkActionButtons={false} size="medium">
      <TextField source="id"/>
      <TextField source="name"/>
      <FunctionField label="Type"
        render={(record: StockItem) => enumIconRenderer(record.type, StockItemType, stockItemIcons)}/>
      <TextField source="weight" label="Bag size, kg"/>
      <FunctionField label="Available For Collection Types" sortable={true}
        render={(record: StockItem) => availableForRenderer(record.availableFor)}/>
      <FunctionField
        label="Available For Countries"
        render={(record: StockItem) =>
          record.restrictionCountries.length === 0 ? "All" : availableForCountriesRenderer(record.restrictionCountries)
        }
      />
      <FunctionField
        label="Status"
        render={(record: StockItem) => {
          return enumChipRenderer(record.status, StockItemStatus, statusChipColors);
        }}
      />
      {hasAccess && <EditButton/>}
    </Datagrid>
  </List>);
};