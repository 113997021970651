import React from "react";

import { CreateContractButton } from "../actions/CreateContractButton";
import { CreateContractProvider } from "../CreateContractProvider";

export const ListCreateContractButton: React.FC = () => {
  return (
    <CreateContractProvider>
      <CreateContractButton variant="text" forceEnabled/>
    </CreateContractProvider>
  );
};