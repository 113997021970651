import * as React from "react";
import { useState } from "react";

import { isNil } from "lodash-es";
import {
  maxLength, maxValue,
  minLength, minValue, number,
  required,
  SaveButton, SelectArrayInput, SelectInput,
  SimpleForm,
  TextInput,
  Toolbar, useNotify,
  useRecordContext, useRedirect
} from "react-admin";

import { CollectionPartnerType } from "../../components/collections/types";
import {
  StockItem,
  StockItemStatus
} from "../../components/stock-item/types";
import { StockItemType } from "../../components/stock/types";
import { Resources } from "../../resources";
import { Country } from "../../utils/commons";
import { generateChoicesFromEnum } from "../../utils/field-renderers";

const bagSizeRequiredValidation = (value: number | undefined, stockItem: StockItem) => {
  const isPelletsItem = stockItem?.type === "PELLETS";
  if (isPelletsItem) {
    return isNil(value) ? "Bag size is required for pellets item" : undefined;
  }

  return undefined;
};
const bagSizeValidation = [bagSizeRequiredValidation, number(), minValue(1), maxValue(1000)];
export enum OperationType {
  CREATE,
  UPDATE
}

type Props = {
  operationType: OperationType,
  submit: (resource: any, data: any, options: any) => void
};

export const StockItemForm: React.FC<Props> = ({
  operationType,
  submit
}) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const record = useRecordContext<StockItem>();

  const idParams = record ? { id: record.id } : {};
  const isCreate = operationType === OperationType.CREATE;

  const [isLoading, setIsLoading] = useState(false);
  const [stockItemType, setStockItemType] = useState<keyof typeof StockItemType | undefined>(record?.type);

  const submitForm = (data: any) => {
    setIsLoading(true);
    submit(Resources.StockItems, { ...idParams, data }, {
      onError: (error: any) => {
        const errorCode = error.body?.errorCode;
        if (errorCode && errorCode === "stockItem.already_exists") {
          notify("A stock item with this name already exists", { type: "warning" });
        } else if (errorCode && errorCode === "stockItem.active_offer_exists") {
          notify("Cannot inactivate item. Active offer for this item exists", { type: "warning" });
        } else {
          notify("The internal server error occurred", { type: "error" });
        }
        setIsLoading(false);
      },
      onSuccess: () => {
        setIsLoading(false);
        redirect("list", Resources.StockItems);
        notify("The stock item has been submitted");
      }
    });
  };

  return (<SimpleForm
    sx={{ maxWidth: 600 }}
    toolbar={<Toolbar><SaveButton disabled={isLoading}/></Toolbar>}
    sanitizeEmptyValues
    warnWhenUnsavedChanges
    onSubmit={submitForm}>
    <TextInput source="name"
      fullWidth
      disabled={!isCreate}
      validate={[required(), minLength(2), maxLength(50)]}/>
    <SelectInput
      disabled={!isCreate}
      source="type"
      fullWidth
      validate={[required()]}
      choices={generateChoicesFromEnum(StockItemType)}
      onChange={(event) => setStockItemType(event.target.value as keyof typeof StockItemType)}
    />
    <SelectArrayInput
      label="Available for collection type"
      source="availableFor"
      defaultValue={record?.availableFor}
      choices={generateChoicesFromEnum(CollectionPartnerType)}
      validate={[required()]}
      fullWidth
      sx={{
        "& .MuiInputBase-root": {
          height: "50px",
          display: "flex",
          alignItems: "center"
        }
      }}
    />
    {stockItemType && stockItemType === "PELLETS" &&
    <TextInput
      label="Bag size"
      source="weight"
      validate={bagSizeValidation}
      disabled={!isCreate}
    />
    }
    <SelectArrayInput
      label="Available for countries (All if not specified)"
      source="restrictionCountries"
      defaultValue={record?.restrictionCountries}
      choices={generateChoicesFromEnum(Country)}
      fullWidth
      sx={{
        "& .MuiInputBase-root": {
          height: "50px",
          display: "flex",
          alignItems: "center"
        }
      }}
    />
    {!isCreate &&
        <SelectInput
          source="status"
          validate={[required()]}
          choices={generateChoicesFromEnum(StockItemStatus)}
          fullWidth/>
    }
  </SimpleForm>);
};