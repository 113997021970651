import * as React from "react";

import { Show, Tab, TabbedShowLayout, useShowContext } from "react-admin";

import { Customer, Device, Stove } from "../../utils/commons";
import { StoveHistoryTab } from "./tabs/StoveHistoryTab";
import { StoveMainTab } from "./tabs/StoveMainTab";
import { StoveUsageTab } from "./tabs/StoveUsageTab";

export type FullStove = Stove & {
  customer: Customer,
  companyId: number
};

export const StoveShow: React.FC = () => {
  const StoveShowLayout = () => {
    const { record } = useShowContext<Device>();

    return (
      <TabbedShowLayout>
        <Tab label="Main">
          <StoveMainTab/>
        </Tab>
        <Tab label="Usage" path="usage">
          <StoveUsageTab displayMode={"device"} stoves={record && record.stoves}/>
        </Tab>
        <Tab label="Stove history" path="history">
          <StoveHistoryTab stoves={record?.stoves}></StoveHistoryTab>
        </Tab>
      </TabbedShowLayout>
    );
  };

  return (
    <Show queryOptions={{ cacheTime: 0, staleTime: 0 }}>
      <StoveShowLayout/>
    </Show>
  );
};
