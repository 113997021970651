import { CollectionPartner } from "../collections/types";

export type CustomerRecommendedLocations = {
  customerId: number,
  recommendedLocations: RecommendedLocation[]
};

export type RecommendedLocation = {
  id: number,
  customerId: number,
  collectionPartner: CollectionPartner,
  deliveryOption: keyof typeof DeliveryOption,
  distance: number,
  priority: number
};

export enum DeliveryOption {
  PICK_UP = "Pick-up",
  DELIVERY = "Delivery"
}
