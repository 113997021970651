import * as React from "react";

import Alert from "@mui/material/Alert";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Skeleton from "@mui/material/Skeleton";
import { Title } from "react-admin";

import { useCompanies } from "../../components/company/use-companies";
import { useLocations } from "../../components/location/use-locations";
import { UserRoles } from "../../core/providers/auth/roles";
import { useCheckAccess } from "../../utils/use-check-access";
import { DashboardCharts } from "./DashboardCharts";

export default () => {
  const { hasAccess, isExternalCompanyAdmin } = useCheckAccess([
    UserRoles.ROLE_SUPAMOTO_ADMIN, UserRoles.ROLE_READ_ONLY, UserRoles.ROLE_EXTERNAL_COMPANY_ADMIN
  ]);
  useLocations();
  useCompanies();

  return !hasAccess ?
    <Container>
      <Title title="Welcome to the Admin Panel"/>
      <Paper sx={{ mt: 5, p: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Alert severity="info">
              Welcome onboard! Feel free to explore any resources you can find on the left side of the screen.
            </Alert>
          </Grid>
          <Grid item xs={4}>
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </Grid>
        </Grid>
      </Paper>
    </Container>
    : <DashboardCharts isExternalCompanyAdmin={isExternalCompanyAdmin}/>;
};
