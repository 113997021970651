import React from "react";

import { Box, Button, Typography } from "@mui/material";
import { Accept, useDropzone } from "react-dropzone";

type DropzoneProps = {
  accept?: Accept,
  disabled?: boolean,
  title?: string,
  buttonTitle?: string,
  onDrop: (files: File[]) => void
};

export const Dropzone: React.FC<DropzoneProps> = ({ title, buttonTitle, onDrop, accept, disabled }) => {
  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    onDropAccepted: onDrop, maxFiles: 1, accept, disabled
  });

  return (
    <Box
      data-test-id="dropzone"
      sx={{
        p: 3.5,
        pb: 4,
        backgroundImage: "url(\"data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='gainsboro' stroke-width='4' stroke-dasharray='8%2c 14' stroke-dashoffset='22' stroke-linecap='square'/%3e%3c/svg%3e\")",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 2
      }}
      {...getRootProps()}
    >
      <input {...getInputProps()} />

      <Typography sx={{ maxWidth: "390px", textAlign: "center" }}>
        {title ? title : "Drag a file or select via the button"}
      </Typography>
      {Boolean(acceptedFiles?.length) &&
        <Box>
          {acceptedFiles.map((file) =>
            (<Box key={file.name} sx={{ fontSize: 14 }}>{file.name}</Box>))}
        </Box>
      }

      <Button disabled={disabled} variant="contained">
        {buttonTitle ? buttonTitle : "SELECT A FILE"}
      </Button>

    </Box>
  );
};
