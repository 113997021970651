import { CustomerGroupType } from "../../utils/commons";

export enum DiscountType {
  FIXED = "Fixed amount",
  PERCENTAGE = "Percentage"
}

export enum DiscountStatus {
  ACTIVE = "Active",
  INACTIVE = "Inactive"
}

export type Discount = {
  id: number,
  name: string,
  description: string,
  value: number,
  discountType: keyof typeof DiscountType,
  country: string,
  offerIds: number[],
  restrictionGroups: CustomerGroupType[],
  discountStatus: keyof typeof DiscountStatus
};