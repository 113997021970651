import React from "react";

import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import moment, { Moment } from "moment";

import { CountryCurrency, Customer } from "../../utils/commons";
import { DateTimeFormat, formatDate } from "../../utils/date-time-utils";
import { EnumChipColors, enumChipRenderer } from "../../utils/field-renderers";

type PaymentScheduleProps = {
  customer?: Customer,
  duration?: number,
  downPayment?: number,
  effectiveDate: string | Moment,
  totalAmount: number,
  paidAmount?: number
};

type Installment = {
  date: string,
  amount: number,
  paidAmount: number,
  type: string,
  status: string
};

const installmentStatusChipColors: Record<keyof typeof InstallmentStatus, EnumChipColors> = {
  PENDING: "secondary",
  PAID: "success",
  OVERDUE: "error"
};

enum InstallmentStatus {
  PAID = "Paid",
  OVERDUE= "Overdue",
  PENDING= "Pending"
}

const divideWithRemainder = (number: number, parts: number) => {
  const quotient = Math.floor(number / parts);
  const remainder = number % parts;
  const result = [];

  for (let i = 0; i < parts; i++) {
    result.push(quotient);
  }

  if (remainder > 0) {
    result[result.length - 1] += remainder;
  }

  return result;
};

const getInstallmentStatus = (remainingPaidAmount: number, installmentAmount: number,
  currentDate: Moment, date: Moment) => {
  let installmentStatus = remainingPaidAmount >= installmentAmount ?
    InstallmentStatus.PAID : InstallmentStatus.PENDING;
  if (installmentStatus === InstallmentStatus.PENDING && currentDate.isAfter(date)) {
    installmentStatus = InstallmentStatus.OVERDUE;
  }
  return installmentStatus;
};

const PaymentSchedule: React.FC<PaymentScheduleProps> = ({
  customer,
  duration,
  effectiveDate,
  downPayment,
  totalAmount,
  paidAmount
}) => {
  const installments: Installment[] = [];
  let remainingPaidAmount = paidAmount || 0;
  const currentDate = moment().startOf("day");

  if (downPayment) {
    const installmentStatus = getInstallmentStatus(remainingPaidAmount, downPayment,
      currentDate, moment(effectiveDate));
    const paidAmountForInstallment =
        downPayment < remainingPaidAmount ? downPayment : remainingPaidAmount;

    installments.push({
      date: formatDate(effectiveDate, DateTimeFormat.CUSTOM_DATE),
      amount: downPayment,
      paidAmount: paidAmountForInstallment,
      type: "Down Payment",
      status: installmentStatus
    });
    remainingPaidAmount -= downPayment;
  }

  if (duration) {
    const remainingAmount = totalAmount - (downPayment || 0);
    divideWithRemainder(remainingAmount, duration).forEach((installmentAmount, index) => {
      const paymentDate = moment(effectiveDate).add(index + 1, "months");
      const installmentStatus = getInstallmentStatus(remainingPaidAmount, installmentAmount, currentDate, paymentDate);
      const paidAmountForInstallment =
          installmentAmount < remainingPaidAmount ? installmentAmount : remainingPaidAmount;

      installments.push({
        date: formatDate(paymentDate, DateTimeFormat.CUSTOM_DATE),
        amount: installmentAmount,
        paidAmount: Math.max(0, Math.min(installmentAmount, remainingPaidAmount)),
        type: "Contract Payment",
        status: installmentStatus
      });
      remainingPaidAmount -= paidAmountForInstallment;
    });
  }

  if (!customer) {
    return (<></>);
  }
  return (
    <Grid item xs={12} >
      {installments && (<>
        <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>Installments Schedule</Typography>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell>Amount</TableCell>
                <TableCell>Paid Amount</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {installments.map((installment, index) => (
                <TableRow key={index}>
                  <TableCell>{installment.date}</TableCell>
                  <TableCell>{installment.amount}
                    {" "}
                    {CountryCurrency[customer?.country] || ""} </TableCell>
                  <TableCell>{installment.paidAmount}
                    {" "}
                    {CountryCurrency[customer?.country] || ""} </TableCell>
                  <TableCell>{installment.type}</TableCell>
                  <TableCell>
                    {enumChipRenderer(installment.status.toUpperCase(), InstallmentStatus, installmentStatusChipColors)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </>
      )}
    </Grid>
  );
};

export default PaymentSchedule;
