import React, { useEffect, useState } from "react";

import UndoIcon from "@mui/icons-material/Undo";
import {
  Button,
  Tooltip
} from "@mui/material";
import { isEmpty, keyBy, uniq } from "lodash-es";
import { useNotify } from "ra-core";
import {
  useDataProvider,
  useRefresh,
  useUnselectAll
} from "react-admin";
import * as yup from "yup";

import { useForm } from "../../../components/form/use-form";
import { UserRoles } from "../../../core/providers/auth/roles";
import { Resources } from "../../../resources";
import { Customer } from "../../../utils/commons";
import { useCheckAccess } from "../../../utils/use-check-access";
import { usePageSafeSelector } from "../../../utils/use-page-safe-selector";
import { Payment } from "../types";
import { RefundConfirmationDialog } from "./RefundConfirmationDialog";
import { RefundDialog } from "./RefundDialog";

export type PaymentRefundFormState = {
  note: string | ""
};

const initialFormState: PaymentRefundFormState = {
  note: ""
};
const validationSchema = yup.object({
  note: yup.string().required().max(250).label("Note")
});

export const RefundPaymentButton = () => {
  const refresh = useRefresh();
  const dataProvider = useDataProvider();
  const unselect = useUnselectAll(Resources.Payments);
  const notify = useNotify();
  const { hasAccess } = useCheckAccess([
    UserRoles.ROLE_SUPAMOTO_ADMIN
  ]);
  const [isRefundDialogOpen, setIsRefundDialogOpen] = useState(false);
  const [isRefundRequestInProgress, setIsRefundRequestInProgress] = useState(false);
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false);
  const [customers, setCustomers] = useState<Record<number, Customer>>({});
  const form = useForm<PaymentRefundFormState>(initialFormState, validationSchema);
  const { data: payments } = usePageSafeSelector<Payment>();

  useEffect(() => {
    if (isEmpty(payments)) return;

    const sourceCustomerIds = uniq(payments.flatMap((payment) =>
      payment.transactions
        .filter((transaction) => transaction.activeAssignment)
        .map((transaction) => transaction.customerId)
    ));

    if (isEmpty(sourceCustomerIds)) return;

    dataProvider.getMany<Customer>(Resources.Customers, { ids: sourceCustomerIds })
      .then((response) => {
        setCustomers(keyBy(response.data, "id"));
        return response;
      })
      .catch(() => {
        notify("Error fetching customers", { type: "error" });
      });
  }, [dataProvider, notify, payments]);

  const handleCancel = () => {
    setIsConfirmationDialogOpen(false);
    setIsRefundDialogOpen(false);
    form.reset();
  };

  const handleConfirm = () => {
    if (isRefundRequestInProgress) {
      return;
    }
    setIsRefundRequestInProgress(true);
    setIsConfirmationDialogOpen(false);

    dataProvider.createManyByUrlWithBody("payments/refund", {
      paymentIds: payments?.map((payment) => payment.id) || [],
      note: form.value.note
    })
      .then((response: any) => {
        refresh();
        notify("Payments have been successfully refunded");
        unselect();
        handleCancel();
        setIsRefundRequestInProgress(false);
        return response;
      }).catch((data: any) => {
        setIsRefundRequestInProgress(false);
        const errorCode = data.body?.errorCode;
        if (errorCode && errorCode === "wallet.invalid_balance") {
          refresh();
          notify("The payment cannot be refunded due to insufficient funds in the customer's wallet", { type: "warning" });
        } else {
          notify("Error: failed to refund Payments", { type: "error" });
        }
      });
  };

  return (
    <>
      <Tooltip title="Refund an orphaned or not orphaned payment">
        <span>
          <Button
            color="primary"
            disabled={!hasAccess}
            onClick={() => setIsRefundDialogOpen(true)}
            startIcon={<UndoIcon/>}>
              Refund Payment
          </Button>
        </span>
      </Tooltip>
      <RefundDialog
        isOpened={isRefundDialogOpen}
        onClose={() => setIsRefundDialogOpen(false)}
        onConfirm={() => setIsConfirmationDialogOpen(true)}
        isConfirmDisabled={isRefundRequestInProgress || !form.isValid}
        isProcessing={isRefundRequestInProgress}
        payments={payments || []}
        form={form}
        customers={customers}
      />
      <RefundConfirmationDialog
        payments={payments || []}
        onConfirm={handleConfirm}
        isConfirmationDialogOpen={isConfirmationDialogOpen}
        isConfirmDisabled={isRefundRequestInProgress}
        onClose={() => setIsConfirmationDialogOpen(false)}
      />
    </>
  );
};