import * as React from "react";
import { useState } from "react";

import {
  AutocompleteArrayInput,
  maxLength,
  minLength,
  ReferenceArrayInput,
  required,
  SaveButton,
  SelectArrayInput,
  SelectInput,
  SimpleForm,
  TextInput,
  Toolbar,
  useNotify,
  useRecordContext,
  useRedirect
} from "react-admin";
import { useFormContext } from "react-hook-form";

import { Resources } from "../../resources";
import { Country, CustomerGroupType } from "../../utils/commons";
import { generateChoicesFromEnum } from "../../utils/field-renderers";
import { offerNameRenderer } from "../offers/renderers";
import { Offer, OfferStatus, OfferType } from "../offers/types";
import { Discount, DiscountStatus, DiscountType } from "./types";

export enum OperationType {
  CREATE,
  UPDATE
}
type Props = {
  operationType: OperationType,
  submit: (resource: Resources, data: any, options: any) => void
};
type PropsComp = {
  discount: Discount,
  isCreate: boolean
};

const nameValidation = [required(), minLength(3), maxLength(250)];
const descriptionValidation = [required()];
const statusValidation = [required()];
const countryValidation = [required()];
const discountTypeValidation = [required()];
const applicableForOffersValidation = [required()];

const typeChoices = generateChoicesFromEnum(DiscountType);
const countryChoices = generateChoicesFromEnum(Country);
const restrictionGroupsChoices = generateChoicesFromEnum(CustomerGroupType);
const statusChoices = generateChoicesFromEnum(DiscountStatus);

const OfferOption: React.FC = () => {
  const record = useRecordContext<Offer>();
  return offerNameRenderer(record);
};

export const DiscountFormFields: React.FC<PropsComp> = ({
  discount,
  isCreate
}) => {
  const form = useFormContext();
  const [country, setCountry] = useState<string>(discount?.country);
  const offersFilterToQuery = (searchText: any) => ({ name: searchText });

  const matchSuggestion = (filter: string, choice: Offer) => {
    return (
      choice.name.toLowerCase().includes(filter.toLowerCase())
    );
  };

  const valueValidation = (value:number) => {
    if (form.getValues().discountType === "PERCENTAGE") {
      if (value <= 0 || value >= 99) {
        return "Value must be between 0 and 99";
      }
    }
  };

  return (
    <>
      <TextInput source="name" validate={nameValidation} fullWidth/>
      <TextInput source="description" validate={descriptionValidation} fullWidth/>
      <SelectInput
        source="discountType"
        validate={discountTypeValidation}
        disabled={!isCreate}
        choices={typeChoices}
        fullWidth
      />
      <TextInput source="value"
        disabled={!isCreate}
        validate={valueValidation}
        fullWidth/>
      <SelectInput
        source="country"
        disabled={!isCreate}
        choices={countryChoices}
        validate={countryValidation}
        onChange={(event) => {
          setCountry(event.target.value);
          form.setValue("offerIds", []);
        }}
        fullWidth/>
      <ReferenceArrayInput
        source="offerIds"
        name="offerIds"
        reference={Resources.Offers}
        perPage={500}
        filter={{
          statuses: ["ACTIVE"] as Array<keyof typeof OfferStatus>,
          types: ["PELLETS", "ACCESSORY"] as Array<keyof typeof OfferType>,
          countries: [country]
        }}
      >
        <AutocompleteArrayInput
          disabled={!country}
          validate={applicableForOffersValidation}
          name="offerIds"
          label="Available for offers"
          filterToQuery={offersFilterToQuery}
          optionText={<OfferOption />}
          inputText={(record: Offer) => record.name}
          matchSuggestion={matchSuggestion}
          fullWidth
        />
      </ReferenceArrayInput>
      <SelectArrayInput
        label="Available for groups (All if not specified)"
        source="restrictionGroups"
        defaultValue={discount?.restrictionGroups}
        choices={restrictionGroupsChoices}
        fullWidth
        sx={{
          "& .MuiInputBase-root": {
            height: "50px",
            display: "flex",
            alignItems: "center"
          }
        }}
      />
      {!isCreate &&
          <SelectInput
            source="discountStatus"
            choices={statusChoices}
            validate={statusValidation}
            fullWidth/>
      }
    </>
  );
};
export const DiscountsForm: React.FC<Props> = ({
  operationType,
  submit
}) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const record = useRecordContext<Discount>();
  const [isLoading, setIsLoading] = useState(false);

  const idParams = record ? { id: record.id } : {};
  const isCreate = operationType === OperationType.CREATE;

  const submitForm = (data: any) => {
    setIsLoading(true);
    submit(Resources.Discounts, { ...idParams, data }, {
      onError: (error: any) => {
        const errorCode = error.body?.errorCode;
        if (errorCode && errorCode === "discount.invalid_value") {
          notify(error.body.message, { type: "error" });
        } else {
          notify("Error: failed to submit the discount form", { type: "error" });
        }
        setIsLoading(false);
      },
      onSuccess: () => {
        setIsLoading(false);
        redirect("list", Resources.Discounts);
        notify("The discount form has been submitted");
      }
    });
  };

  return (<SimpleForm
    sx={{ maxWidth: 600 }}
    toolbar={<Toolbar><SaveButton disabled={isLoading}/></Toolbar>}
    sanitizeEmptyValues
    warnWhenUnsavedChanges
    onSubmit={submitForm}>
    <DiscountFormFields discount={record} isCreate={isCreate}/>
  </SimpleForm>);
};