import React, { useEffect } from "react";

import { InputAdornment, Slider, TextField, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import { isNil, isNumber } from "lodash-es";
import moment from "moment/moment";

import DateTimeFormInput from "../../../components/form/input/datetime/DateTimeFormInput";
import { FormTextField } from "../../../components/form/input/text-field/FormTextField";
import { CountryCurrency } from "../../../utils/commons";
import { contractDuration } from "../../../utils/field-renderers";
import { useCreateContract } from "../CreateContractProvider";
import PaymentSchedule from "../PaymentSchedule";
import { termsAndConditionsValidationSchema } from "../state";
import { ContractType } from "../types";
import { CreateContractStepActions } from "./CreateContractStepActions";

export const ContractTermsAndConditionsStep: React.FC = () => {
  const { form, customer } = useCreateContract();
  const contractType = form.value.contractType;

  const handleDurationChange = (_: Event, newValue: number | number[]) => {
    if (isNumber(newValue)) {
      form.setValueByLabel("duration", newValue);
    }
  };

  const isStepDataValid = () => {
    return termsAndConditionsValidationSchema.isValidSync(form.value);
  };

  useEffect(() => {
    if (!isNil(contractType) && customer) {
      const defaultDownPayment = Math.trunc(form.value.totalAmount / (form.value.duration + 1));
      form.setValueByLabel("downPayment", defaultDownPayment);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contractType, customer, form.value.totalAmount, form.value.duration]);

  return (
    <Grid item xs={12}>
      <Grid container spacing={2}>
        <Grid item xs={12} sx={{ display: "flex", flexDirection: "row" }} >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="caption">The effective date of the contract and the period through which it must be paid off.</Typography>
            </Grid>
            <Grid item xs={3}>
              <DateTimeFormInput
                name="startDate"
                label="Effective Date *"
                form={form}
                minDateTime={contractType === ContractType.UTILITY ? moment() : undefined}
                maxDateTime={contractType === ContractType.UTILITY ? moment().add(7, "days") : undefined}
                onlyDate
              />
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={6}>
              <Typography id="input-slider" gutterBottom>
                  Duration: {contractDuration(form.value.duration)}
              </Typography>
              <Slider
                disabled={contractType === ContractType.UTILITY}
                sx={{ ml:1 }}
                value={form.value.duration}
                min={1}
                step={1}
                max={24}
                marks
                getAriaValueText={contractDuration}
                valueLabelFormat={contractDuration}
                onChange={handleDurationChange}
                valueLabelDisplay="auto"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="caption">The down payment amount required for the contract to come into effect. It must be less or equal the total cost of all products.</Typography>
        </Grid>
        <Grid item xs={3}>
          <FormTextField
            disabled={contractType === ContractType.UTILITY}
            required
            form={form}
            name="downPayment"
            label="Down Payment"
            type="number"
            InputProps={{
              inputProps: { min: 0 },
              endAdornment: <InputAdornment position="end">{CountryCurrency[customer?.country || ""]}</InputAdornment>
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={3}>
          <FormTextField
            disabled={true}
            required
            form={form}
            name="totalAmount"
            label="Total Contract Amount"
            type="number"
            InputProps={{
              inputProps: { min: 0 },
              endAdornment: <InputAdornment position="end">{CountryCurrency[customer?.country || ""]}</InputAdornment>
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            value= {form.value.totalAmount - form.value.downPayment}
            label="Payment Due"
            required
            InputProps={{
              endAdornment: <InputAdornment position="end">{CountryCurrency[customer?.country || ""]}</InputAdornment>
            }}
            fullWidth
            disabled
          />
        </Grid>
        {
          form.value.totalAmount && (
            <PaymentSchedule
              customer={customer}
              downPayment={form.value.downPayment}
              duration={form.value.duration}
              totalAmount={form.value.totalAmount}
              effectiveDate={moment(form.value.startDate).startOf("day")}
            />
          ) || <></>
        }
        <CreateContractStepActions isStepValid={isStepDataValid()}/>
      </Grid>
    </Grid>
  );
};