import * as yup from "yup";

import { yupNumber } from "../../../../components/form/validation";
import { Offer } from "../../../offers/types";
import { CreateOrderLine, DealType } from "./types";

export type CreateOrderForm = {
  orderLines: CreateOrderLine[],
  contactId?: number
};

export const initialState = {
  orderLines: [{ dealId: "", discountId: "", dealType: DealType.PRODUCT } as CreateOrderLine]
};

export const orderLineValidationSchema = yup.object({
  dealId: yupNumber((schema) => (`${schema.label} must be selected`)).required().label("Offer"),
  quantity: yupNumber().required().label("Quantity").positive(),
  amount: yupNumber().required().label("Price per unit").positive()
});

export const validationSchema = yup.object({
  orderLines: yup.array().of(orderLineValidationSchema).min(1).label("Order Lines")
});

export const calcTotalPrice = (orderLines: CreateOrderLine[]) => {
  return orderLines.reduce((total: number, orderItem: CreateOrderLine) => {
    return total + ((orderItem.amount || 0) * (orderItem.quantity || 0));
  }, 0);
};

export const findOffer = (offers: Offer[] = [], offerId: number | string): Offer => {
  return offers.find((offer: Offer) => offer.id == offerId) as Offer;
};
