import React, { memo } from "react";

import { TextField } from "@mui/material";
import { InputProps } from "@mui/material/Input";
import { InputProps as StandardInputProps } from "@mui/material/Input/Input";
import { InputLabelProps as StandardInputLabelProps } from "@mui/material/InputLabel/InputLabel";
import { get } from "lodash-es";

import { FormInputProps } from "../types";
import { equalityFormCheck } from "../utils";

export type FormTextFieldProps = FormInputProps & {
  required?: boolean,
  inputProps?: InputProps["inputProps"],
  InputProps?: Partial<StandardInputProps>,
  InputLabelProps?: Partial<StandardInputLabelProps>,
  idleHelperText?: string,
  type?: string,
  multiline?: boolean,
  rows?: number
};

export const FormTextField: React.FC<FormTextFieldProps> = memo<FormTextFieldProps>(({
  name,
  label,
  form,
  required = false,
  fullWidth = true,
  sx = {},
  inputProps = {},
  InputProps = {},
  InputLabelProps = {},
  idleHelperText = "",
  type,
  size,
  multiline = false,
  rows,
  dependencies,
  disabled = false
}) => {
  const { value, error, handleChange, handleBlurValidate } = form;

  const props = { ...inputProps };
  if (dependencies?.length) {
    props["data-dependencies"] = dependencies.join(",");
  }

  return (
    <TextField
      name={name}
      label={label}
      variant="outlined"
      fullWidth={fullWidth}
      required={required}
      multiline={multiline}
      disabled={disabled}
      rows={rows}
      type={type}
      size={size}
      sx={{ ...sx }}
      inputProps={props}
      InputProps={{ ...InputProps }}
      InputLabelProps={{ ...InputLabelProps }}
      onChange={handleChange}
      onBlur={handleBlurValidate}
      value={get(value, name) || ""}
      error={Boolean(get(error, name))}
      helperText={get(error, name) ?? idleHelperText}
    />
  );
}, equalityFormCheck);
