import React, { useEffect, useState } from "react";

import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import {
  Datagrid, DateField, FunctionField,
  ListContextProvider,
  TextField,
  useDataProvider,
  useList
} from "react-admin";

import { Order } from "../../pages/orders/types";
import { EnumChipColors, enumChipRenderer } from "../../utils/field-renderers";
import { CarbonCreditClaim, CarbonCreditClaimStatus } from "./types";

type CarbonCreditClaimChipProps = {
  order: Order
};

const carbonCreditStatusChipColors: Record<keyof typeof CarbonCreditClaimStatus, EnumChipColors> = {
  SUBMITTED: "secondary",
  VERIFIED: "success",
  NOT_VERIFIED: "error"
};
export const CarbonCreditClaimChip: React.FC<CarbonCreditClaimChipProps> = ({ order }) => {
  const dataProvider = useDataProvider();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [carbonCreditClaims, setCarbonCreditClaims] = useState<CarbonCreditClaim[]>([]);
  const handleClose = () => {
    setIsDialogOpen(false);
  };

  useEffect(() => {
    if (!isDialogOpen) return;
    dataProvider.getManyByUrl(`orders/${order.id}/carbon-credit-claims`)
      .then((response: { data: CarbonCreditClaim[]}) => {
        setCarbonCreditClaims(response.data);
        return response;
      });
  }, [isDialogOpen, dataProvider, order]);

  const listContext = useList({ data: [...carbonCreditClaims] });
  return (
    <>
      <Box onClick={() => setIsDialogOpen(true)}>
        {enumChipRenderer(order.carbonCreditClaim.status, CarbonCreditClaimStatus, carbonCreditStatusChipColors)}
      </Box>
      <Dialog open={isDialogOpen} onClose={handleClose} fullWidth maxWidth="md">
        <DialogTitle>Carbon credit claims history</DialogTitle>
        <DialogContent>
          <ListContextProvider value={listContext}>
            <Datagrid bulkActionButtons={false}>
              <TextField source="orderId" label="Order ID" sortable={false}/>
              <FunctionField
                source="status"
                label="Status"
                sortable={false}
                render = {(record: CarbonCreditClaim) =>
                  enumChipRenderer(record.status, CarbonCreditClaimStatus, carbonCreditStatusChipColors)}
              />
              <TextField source="cancellationReason" label="Cancellation Reason" sortable={false}/>
              <DateField source="timeSubmitting" label="Submitting at" showTime/>
              <DateField source="timeValidation" label="Validation at" showTime/>
            </Datagrid>
          </ListContextProvider>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};