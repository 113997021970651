import { useEffect } from "react";

import { isNil } from "lodash-es";
import { GetListParams, useDataProvider, useStore } from "react-admin";

import { Resources } from "../../resources";
import { Location } from "./types";

export const useLocations = () => {
  const dataProvider = useDataProvider();
  const [locations, setLocations] = useStore<Location[]>("api.locations", undefined);

  useEffect(() => {
    if (isNil(locations)) {
      const params = { pagination: { page: 1, perPage: 1000 } } as GetListParams;
      dataProvider.getList<Location>(Resources.Locations, params)
        .then((response) => {
          setLocations(response.data);
          return response;
        });
    }
  }, [dataProvider, locations, setLocations]);

  return { locations: isNil(locations) ? [] : locations };
};
