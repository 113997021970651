import React from "react";

import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import moment from "moment/moment";

import DateTimeFormInput from "../../../../components/form/input/datetime/DateTimeFormInput";
import { FormTextField } from "../../../../components/form/input/text-field/FormTextField";
import { FormProps } from "../../../../components/form/use-form";
import { DeliveryDetails } from "./common";

type Props = {
  form: FormProps<DeliveryDetails>,
  children?: JSX.Element
};

export const DeliverToSection: React.FC<Props> = ({
  form,
  children
}) => {
  return (
    <>
      <Typography sx={{ mb: 2, fontWeight: 600 }}>Deliver to:</Typography>
      {children && children}
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormTextField name="clientName" label="Client Name" required form={form}/>
        </Grid>
        <Grid item xs={6}>
          <FormTextField name="phoneNumber" label="Phone number" required form={form}/>
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ mt:1 }}>
        <Grid item xs={12}>
          <FormTextField name="address"
            label="Address"
            form={form}
            dependencies={["latitude", "longitude"]}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ mt:1 }}>
        <Grid item xs={6}>
          <FormTextField name="latitude"
            label="Latitude"
            form={form}
            dependencies={["address", "longitude"]}
          />
        </Grid>
        <Grid item xs={6}>
          <FormTextField name="longitude"
            label="Longitude"
            form={form}
            dependencies={["address", "latitude"]}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ mt:1 }}>
        <Grid item xs={6}>
          <DateTimeFormInput
            name="deliveryDateTime"
            label="Delivery Date and Time *"
            form={form}
            minDateTime={moment()}
          />
        </Grid>
      </Grid>
    </>
  );
};
