import React from "react";

import { NullableBooleanInput, SelectArrayInput, SelectInput } from "react-admin";

import { buildLabel, CustomFilterIcon } from "../../components/filter/filter-utils";
import { useNumberRangeFilters } from "../../components/filter/use-number-range-filters";
import { BUCKETS } from "../dashboard/utils";
import { ConnectivityLevel, OwnershipFilter, PARCategories } from "./types";
import { useTicketStatuses } from "./use-ticket-statuses";

const connectivityLevelFilter = () => {
  const choices = Object.entries(ConnectivityLevel)
    .map(([key, value]) => ({ id: key, name: value }));
  return <SelectArrayInput
    source={"connectivityLevels"}
    key={"connectivityLevels"}
    label={buildLabel({ label: "Connectivity Level", Icon: CustomFilterIcon })}
    variant="filled"
    choices={choices}
  />;
};

const parCategoryFilter = () => {
  const choices = Object.entries(PARCategories)
    .map(([key, value]) => ({ id: key, name: value }));

  return <SelectArrayInput
    source={"parCategories"}
    key={"parCategories"}
    label={buildLabel({ label: "PAR Category", Icon: CustomFilterIcon })}
    variant="filled"
    choices={choices}
  />;
};

const stoveOwnershipFilter = () => {
  const choices = Object.entries(OwnershipFilter).map(([key, value]) => ({ id: key, name: value }));

  return <SelectInput
    sx={{ mb: 0 }}
    source={"ownershipFilter"}
    key={"ownershipFilter"}
    label={buildLabel({ label: "Ownership Filter", Icon: CustomFilterIcon })}
    variant="filled"
    choices={choices}
  />;
};

const bucketsFilter = () => {
  const choices = BUCKETS.map((name) => ({ id: name, name }));

  return <SelectInput
    sx={{ mb: 0 }}
    source={"bucketName"}
    key={"bucketName"}
    label={buildLabel({ label: "Pellets bucket", Icon: CustomFilterIcon })}
    variant="filled"
    choices={choices}
    alwaysOn
  />;
};

const includeFirstOrdersFilter = () => {
  return <NullableBooleanInput
    label={buildLabel({ label: "Include new customers", Icon: CustomFilterIcon })}
    variant="filled"
    source="includeFirstOrders"/>;
};

const useTicketStatusFilter = () => {
  const { ticketStatuses } = useTicketStatuses();
  const choices = ticketStatuses.map((status) => status.name).map((name) => ({ id: name, name }));

  return <SelectArrayInput
    source={"ticketStatuses"}
    key={"ticketStatuses"}
    label={buildLabel({ label: "Ticket statuses", Icon: CustomFilterIcon })}
    variant="filled"
    choices={choices}
  />;
};

export const useProfilePerformanceFilters = () => [
  bucketsFilter(),
  includeFirstOrdersFilter(),
  useTicketStatusFilter(),
  ...useNumberRangeFilters("totalPelletsBagsSizeGOE", "Pellets sum (>=)", "totalPelletsBagsSizeLOE", "Pellets sum (<=)"),
  ...useNumberRangeFilters("totalPelletsPurchaseOverdueDaysGOE", "Arrears (total) (>=)",
    "totalPelletsPurchaseOverdueDaysLOE", "Arrears (total) (<=)", true),
  ...useNumberRangeFilters("lastPelletsPurchaseOverdueDaysGOE", "Arrears (last) (>=)",
    "lastPelletsPurchaseOverdueDaysLOE", "Arrears (last) (<=)"),
  ...useNumberRangeFilters("totalCookingSessionsCountGOE", "Cooking activity (count) (>=)",
    "totalCookingSessionsCountLOE", "Cooking activity (count) (<=)"),
  ...useNumberRangeFilters("totalCookingSessionsDurationGOE", "Cooking activity (duration) (>=)",
    "totalCookingSessionsDurationLOE", "Cooking activity (duration) (<=)"),
  ...useNumberRangeFilters("lastCookingDaysAgoGOE", "Last cooking (>=)", "lastCookingDaysAgoLOE", "Last cooking (<=)"),
  stoveOwnershipFilter(),
  connectivityLevelFilter(),
  parCategoryFilter()
];