import { isString, keyBy, mapValues, toNumber } from "lodash-es";
import * as yup from "yup";
import { BaseSchema, DateSchema, NumberSchema, ValidationError } from "yup";
import { MessageParams } from "yup/lib/types";

export type ValidationProps = {
  data: any,
  schema: BaseSchema,
  path?: string,
  dependencies?: string[]
};

export const validate = ({ data, schema, path, dependencies }: ValidationProps) => {
  try {
    schema.validateSync(data, { abortEarly: false });
  } catch (err) {
    const validationErrors = err as ValidationError;
    const errors = mapValues(keyBy(validationErrors.inner, "path"), "message");

    if (path) {
      const pathErrors = { [path]: errors[path] };

      if (dependencies?.length) {
        dependencies.forEach((dependency) => pathErrors[dependency] = errors[dependency]);
        return pathErrors;
      }

      return pathErrors;
    }

    return errors;
  }
};

export const yupNumber = (
  typeError: (schema: MessageParams) => string = (schema) => (`${schema.label} must be a number`)
): NumberSchema => {
  return yup.number().typeError(typeError).transform((value, originalValue) => {
    if (isNaN(originalValue)) return originalValue;
    if (isString(originalValue) && originalValue.trim() === "") return undefined;

    return toNumber(originalValue);
  });
};

export const yupDate = (): DateSchema => {
  return yup.date().typeError((schema) => (`${schema.label} must be selected`));
};
