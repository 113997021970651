import React from "react";

import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  Divider
} from "@mui/material";
import Grid from "@mui/material/Grid";
import * as yup from "yup";

import { useForm } from "../../../../components/form/use-form";
import { yupNumber } from "../../../../components/form/validation";
import { DeliveryGroup } from "../../../delivery-groups/DeliveryGroupsList";
import { Order } from "../../types";
import {
  buildDeliveryOrderItemsByOrders,
  DeliveryDetails,
  deliveryDetailsValidationSchema
} from "../common/common";
import { DeliverToSection } from "../common/DeliverToSection";
import { OrderDetailsSection } from "../common/OrderDetailsSection";
import { PickUpFromSection } from "../common/PickUpFromSection";
import { useCreateDelivery } from "../common/use-create-delivery";
import { DeliveryGroupsAutocomplete } from "./DeliveryGroupsAutocomplete";

type Props = {
  onConfirm: () => void,
  onClose: () => void,
  orders: Order[]
};

const validationSchema = deliveryDetailsValidationSchema.concat(yup.object({
  resourceId: yupNumber((schema) => (`${schema.label} must be selected`)).required().label("Delivery Group")
}));

export const GroupDelivery: React.FC<Props> = ({
  onConfirm,
  onClose,
  orders
}) => {
  const { createDelivery, isLoading: isDeliveryLoading } = useCreateDelivery("group");

  const initialState: DeliveryDetails = {
    orderIds: [],
    resourceId: "",
    clientName: undefined,
    phoneNumber: undefined,
    address: "",
    latitude: undefined,
    longitude: undefined,
    deliveryDateTime: "",
    pickupCollectionPartnerId: "",
    orderItems: buildDeliveryOrderItemsByOrders(orders),
    deliveryInstructions: undefined
  };
  const form = useForm(initialState, validationSchema, true);

  const onDeliveryGroupSelected = (group: DeliveryGroup) => {
    form.setValueByLabel("clientName", group.groupLeaderName);
    form.setValueByLabel("phoneNumber", group.phoneNumber);
    form.setValueByLabel("address", group.address);
    form.setValueByLabel("latitude", group.latitude);
    form.setValueByLabel("longitude", group.longitude);
  };

  const onDialogConfirm = () => {
    const orderIds = orders.map((order) => order.id);
    const request: DeliveryDetails = {
      ...form.value,
      orderIds
    };
    createDelivery(request, onConfirm);
  };

  return (
    <>
      <DialogContent>
        <DialogContentText>
          To create a delivery task, you need to select a delivery group and
          fill in every required field (marked with *).
        </DialogContentText>
        <Box sx={{ mt:1 }}>
          <Divider sx={{ mb: 2 }}/>
          <DeliverToSection form={form}>
            <>
              <Grid container spacing={2} sx={{ mb: 2 }}>
                <Grid item xs={6}>
                  <DeliveryGroupsAutocomplete form={form} onValueSelected={onDeliveryGroupSelected}/>
                </Grid>
              </Grid>
              <Divider sx={{ mb: 2 }}/>
            </>
          </DeliverToSection>
          <Divider sx={{ my: 2 }}/>
          <PickUpFromSection form={form}/>
          <Divider sx={{ my: 2 }}/>
          <OrderDetailsSection form={form}/>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          Cancel
        </Button>
        <Button
          disabled={!form.isValid || isDeliveryLoading}
          onClick={onDialogConfirm}>
          {isDeliveryLoading ? "Processing" : "Confirm" }
        </Button>
      </DialogActions>
    </>
  );
};
