import * as React from "react";

import { Edit, SelectInput, SimpleForm, TextInput, ReferenceInput } from "react-admin";

import { UserRoles } from "../../core/providers/auth/roles";
import { Resources } from "../../resources";
import { USER_ROLES } from "./constants";

const rolesChoices = Object.keys(USER_ROLES).map((role) => ({ id: role, name: USER_ROLES[role as UserRoles] }));

export const UserEdit = () => (
  <Edit queryOptions={{ cacheTime: 0, staleTime: 0 }}>
    <SimpleForm>
      <TextInput source="id" disabled/>
      <TextInput source="username" disabled/>
      <SelectInput source="role" choices={rolesChoices}/>
      <ReferenceInput reference={Resources.Companies} source="company.id">
        <SelectInput label="Company" optionText="name"/>
      </ReferenceInput>
    </SimpleForm>
  </Edit>
);
