import React, { useState } from "react";

import BlockIcon from "@mui/icons-material/Block";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Grid,
  Tooltip,
  Typography
} from "@mui/material";
import {
  useDataProvider,
  useNotify,
  useRefresh
} from "react-admin";
import * as yup from "yup";

import { FormSelect } from "../../../components/form/input/select/FormSelect";
import { useForm } from "../../../components/form/use-form";
import {
  DecommissionStoveReason,
  Stove
} from "../../../utils/commons";

type DecommissionProps = {
  stove?: Stove,
  disabled: boolean
};

type DecommissionDeviceFormState = {
  reason: DecommissionStoveReason | ""
};

const initialFormState: DecommissionDeviceFormState = {
  reason: ""
};
const validationSchema = yup.object({
  reason: yup.string().required()
});
const decommissionReasons = Object.entries(DecommissionStoveReason).map(([key, value]) => {
  return { value: key, label: value };
})
  .sort((a, b) => a.label.localeCompare(b.label));

export const DecommissionStoveButton: React.FC<DecommissionProps> = ({
  stove,
  disabled
}) => {
  const refresh = useRefresh();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const form = useForm<DecommissionDeviceFormState>(initialFormState, validationSchema);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const handleCancel = () => {
    setIsFormOpen(false);
    form.reset();
  };

  const handleConfirm = () => {
    if (!stove) return;
    setIsFormOpen(false);
    dataProvider.updateManyByUrlWithBody(`stoves/${stove.id}/decommission`, {
      reason: form.value.reason
    }).then((response: any) => {
      refresh();
      notify("Stove has been decommissioned");
      handleCancel();
      return response;
    }).catch(() => {
      notify("Error: failed to decommission stove", { type: "error" });
      handleCancel();
    });
  };
  return (
    <>
      <Tooltip title="Decommission">
        <span>
          <Button
            color="primary"
            variant="text"
            disabled={disabled}
            onClick={() => setIsFormOpen(true)}
            startIcon={<BlockIcon/>}>
            Decommission
          </Button>
        </span>
      </Tooltip>
      {stove && (
        <Dialog
          fullWidth
          maxWidth="sm"
          open={isFormOpen}
          onClose={handleCancel}
        >
          <DialogTitle>Cancel</DialogTitle>
          <DialogContent>
            <DialogContent sx={{ mx: 2 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="body1" align="justify">
                    <>
                      You are trying to decommission the stove with Device ID {stove.deviceId}.
                    </>
                  </Typography>
                </Grid>
                <Grid item xs={12} sx={{ mt: 2 }}>
                  <FormSelect
                    name="reason"
                    label="Deccommision Reason *"
                    form={form}
                    menuItems={decommissionReasons}/>
                </Grid>
              </Grid>
            </DialogContent>

            <DialogActions>
              <Button onClick={handleCancel}>
                Cancel
              </Button>
              <Button
                autoFocus
                disabled={!form.isValid}
                onClick={handleConfirm}>
                Confirm
              </Button>
            </DialogActions>
          </DialogContent>
        </Dialog>)}
    </>
  );
};