import React, { useEffect } from "react";

import DescriptionIcon from "@mui/icons-material/Description";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Step,
  StepLabel,
  Stepper,
  Tooltip,
  Typography
} from "@mui/material";
import Grid from "@mui/material/Grid";
import { isNil } from "lodash-es";

import { CustomerBaseGrid } from "../../../components/customer/CustomerBaseGrid";
import {
  SearchCustomerAutocomplete
} from "../../../components/customer/SearchCustomerAutocomplete";
import { useCustomerEligibleOffers } from "../../../components/offer/use-customer-eligible-offers";
import { UserRoles } from "../../../core/providers/auth/roles";
import { useCheckAccess } from "../../../utils/use-check-access";
import { OFFER_TYPE_PRIORITY } from "../../offers/types";
import { DealType } from "../../orders/actions/create/types";
import { ContractTypeSelector } from "../ContractTypeSelector";
import { useCreateContract } from "../CreateContractProvider";
import { ContractAddonsStep } from "../steps/ContractAddonsStep";
import { ContractProductsAndDiscountsStep } from "../steps/ContractProductsAndDiscountsStep";
import { calculateRemainingBalance, ContractSummaryStep } from "../steps/ContractSummaryStep";
import { ContractTermsAndConditionsStep } from "../steps/ContractTermsAndConditionsStep";
import { steps } from "../steps/steps";
import { ContractType } from "../types";
import { getUtilityContractTotalAmount } from "../utils";

type Props = {
  variant?: "text" | "outlined" | "contained",
  forceEnabled?: boolean
};

export const CreateContractButton: React.FC<Props> = ({
  variant,
  forceEnabled = false
}) => {
  const { form, customer, setCustomer, dialog, stepper: { activeStep } } = useCreateContract();
  const contractType = form.value.contractType;
  const { offers } = useCustomerEligibleOffers(customer?.id);
  const { hasAccess } = useCheckAccess([
    UserRoles.ROLE_SUPAMOTO_ADMIN, UserRoles.ROLE_SALES
  ]);

  useEffect(() => {
    if (customer && contractType === ContractType.UTILITY && offers.length > 0) {
      const totalAmount = getUtilityContractTotalAmount(customer.country, customer.groupType);
      form.setValueByLabel("totalAmount", totalAmount);
      form.setValueByLabel("downPayment", totalAmount);

      const defaultOffers = offers
        .filter((offer) => offer.isDefault === true)
        .sort((a, b) => OFFER_TYPE_PRIORITY[a.offerType] - OFFER_TYPE_PRIORITY[b.offerType]);
      let detailsIndex = 0;
      let addonsIndex = 0;
      defaultOffers.forEach((offer) => {
        if (offer.offerType !== "PELLETS") {
          form.setValueByLabel(`details[${detailsIndex}].offerId`, offer.id);
          if (offer.offerType !== "STOVE") {
            form.setValueByLabel(`details[${detailsIndex}].quantity`, 1);
          }
          detailsIndex++;
        } else {
          form.setValueByLabel(`addons[${addonsIndex}].dealId`, offer.id);
          form.setValueByLabel(`addons[${addonsIndex}].quantity`, 1);
          form.setValueByLabel(`addons[${addonsIndex}].dealType`, DealType.PRODUCT);
          form.setValueByLabel(`addons[${addonsIndex}].amount`, offer.price);
          addonsIndex++;
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer, contractType, offers]);

  return (
    <>
      <Tooltip title="Create Contract">
        <Button
          color="primary"
          variant={variant}
          disabled={!forceEnabled && (!customer || customer.customerStatus != "ACTIVE") || !hasAccess}
          onClick={dialog.open}
          startIcon={<DescriptionIcon />}>
            Create Contract
        </Button>
      </Tooltip>
      { isNil(contractType) ?
        <ContractTypeSelector/> :
        <Dialog
          fullWidth
          maxWidth={isNil(contractType) ? "sm" : "lg"}
          open={dialog.isOpen}
          onClose={dialog.cancel}
        >
          <DialogTitle>Create Contract</DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              {
                isNil(customer) && (
                  <>
                    <Grid item xs={12}>
                      <Typography variant="h6">Customer:</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <SearchCustomerAutocomplete
                        size="medium"
                        filters={{
                          customerStatuses: ["ACTIVE"]
                        }}
                        onCustomerSelected={(customer) => setCustomer(customer)}
                      />
                    </Grid>
                  </>
                )
              }
              {
                !isNil(customer) && (
                  <>
                    <Grid item xs={12}>
                      <CustomerBaseGrid
                        customer={customer}
                        remainingBalance={calculateRemainingBalance(
                          form.value.addons,
                          form.value.downPayment,
                          customer)}/>
                    </Grid>
                    <Grid item xs={12}>
                      <Stepper activeStep={activeStep} sx={{ py: 2 }}>
                        {steps.map((label, index) => (
                          <Step key={index}>
                            <StepLabel>{label}</StepLabel>
                          </Step>
                        ))}
                      </Stepper>
                    </Grid>
                    { activeStep === 0 && <ContractProductsAndDiscountsStep/> }
                    { activeStep === 1 && <ContractTermsAndConditionsStep/> }
                    { activeStep === 2 && <ContractAddonsStep/> }
                    { activeStep === 3 && <ContractSummaryStep/> }
                  </>
                )
              }
            </Grid>
          </DialogContent>
        </Dialog>
      }
    </>
  );
};