import { CollectionPartnerType } from "../collections/types";
import { StockItemType } from "../stock/types";

export type StockItem = {
  id: number,
  name: string,
  type: keyof typeof StockItemType,
  weight: number,
  status: keyof typeof StockItemStatus,
  availableFor: CollectionPartnerType[],
  restrictionCountries: string[]
};

export enum StockItemStatus {
  ACTIVE = "Active",
  INACTIVE = "Inactive"
}