import { useState } from "react";

export const useNumberList = () => {
  const [isEndsWithComma, setEndsWithComma] = useState(false);
  const arrayToText = (nativeStoveIds: number[]) => {
    const joinedIds = nativeStoveIds?.join?.(",") || "";
    return `${joinedIds}${isEndsWithComma ? "," : ""}`;
  };
  const textToArray = (input: string) => {
    setEndsWithComma(input.endsWith(","));

    return input.split(",")
      .map((value) => parseInt(value))
      .filter((value) => !isNaN(value));
  };

  return {
    arrayToText,
    textToArray
  };
};
