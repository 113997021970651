import React from "react";

import { ReconcilePaymentButton } from "./ReconcilePaymentButton";
import { RefundPaymentButton } from "./RefundPaymentButton";
import { SplitPaymentButton } from "./SplitPaymentButton";

const PaymentsBulkActions = () => {
  return (
    <>
      <SplitPaymentButton/>
      <ReconcilePaymentButton/>
      <RefundPaymentButton/>
    </>
  );
};

export default PaymentsBulkActions;
