import React, { useEffect, useState } from "react";

import Alert from "@mui/material/Alert";
import Grid from "@mui/material/Grid";
import Skeleton from "@mui/material/Skeleton";
import { ListControllerResult } from "ra-core/dist/cjs/controller/list/useListController";
import {
  DatagridConfigurable,
  DateField,
  FunctionField,
  ListContextProvider,
  ReferenceField,
  TextField,
  useDataProvider
} from "react-admin";

import { PaymentTransactionsList } from "../../../components/payments/PaymentTransactions";
import { User } from "../../../components/user/types";
import { Resources } from "../../../resources";
import { usernameRenderer } from "../../../utils/field-renderers";
import { Payment } from "../../payments/types";

type GetPaymentTransactionsResponse = {
  data: Payment[]
};

export type CustomerPaymentsTabProps = {
  customerId?: number
};

const EmptyData = () => <Alert severity="info">No payments found</Alert>;

export const CustomerPaymentsTab: React.FC<CustomerPaymentsTabProps> = ({
  customerId
}) => {
  const dataProvider = useDataProvider();
  const [paymentTransactions, setPaymentTransactions] = useState<Payment[]>([]);
  const [isPaymentsLoading, setPaymentsLoading] = useState(false);

  useEffect(() => {
    if (!customerId) return;

    setPaymentsLoading(true);
    dataProvider.getManyByUrl(`customers/${customerId}/payments`)
      .then((response: GetPaymentTransactionsResponse) => {
        setPaymentTransactions(response.data);
        setPaymentsLoading(false);

        return response;
      })
      .catch(() => {
        setPaymentsLoading(false);
      });

  }, [customerId, dataProvider]);

  const context = {
    data: paymentTransactions,
    total: paymentTransactions?.length,
    page: 1,
    perPage: paymentTransactions?.length,
    filterValues: {},
    sort: {}
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {
          isPaymentsLoading ?
            <>
              <Skeleton animation="wave"/>
              <Skeleton animation="wave"/>
              <Skeleton animation="wave"/>
              <Skeleton animation="wave"/>
            </> :
            <ListContextProvider value={context as ListControllerResult}>
              <DatagridConfigurable
                empty={<EmptyData/>}
                expand={<PaymentTransactionsList/>}
              >
                <TextField source="telcoTransactionId"/>
                <TextField source="telco"/>
                <TextField source="paymentReference"/>
                <TextField source="payer" sortable={false}/>
                <FunctionField label="Amount" render={(record: Payment) =>
                  `${record.amount} ${record.currency}`}/>
                <ReferenceField
                  label="Created by"
                  reference={Resources.Users}
                  source="createdBy"
                  sortable={false}
                  link={false}>
                  <FunctionField render={(user: User) => usernameRenderer(user)}/>
                </ReferenceField>
                <DateField source="timePaid" label="Transaction time" showTime/>
              </DatagridConfigurable>
            </ListContextProvider>
        }
      </Grid>
    </Grid>
  );
};
