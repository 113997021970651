import React from "react";

import EventIcon from "@mui/icons-material/Event";
import { Box, InputAdornment, TextField } from "@mui/material";
import { get } from "lodash-es";
import moment, { Moment } from "moment";
import { ReactDatePickerProps } from "react-datepicker";

import { DateTimeFormat, formatDate } from "../../../../utils/date-time-utils";
import DateTimeInput from "../../../datetime/DateTimeInput";
import { FormInputProps } from "../types";

export type DateTimeFormInputProps = FormInputProps & {
  minDateTime?: Moment,
  maxDateTime?: Moment,
  datePickerProps?: Partial<ReactDatePickerProps>,
  onlyDate?: boolean,
  dateTimeFormatter?: (dateRaw: Date | null, onlyDate?: boolean) => string | null
};

const defaultDateTimeFormatter = (dateRaw: Date | null, onlyDate?: boolean) => {
  const date = dateRaw && moment(dateRaw);
  const format = onlyDate ? DateTimeFormat.DATE_ISO : DateTimeFormat.DATE_TIME_ISO;
  return date ? formatDate(date, format) : null;
};

const DateTimeFormInput: React.FC<DateTimeFormInputProps> = ({
  name,
  form,
  fullWidth = true,
  onlyDate,
  minDateTime,
  maxDateTime,
  label,
  datePickerProps,
  sx = {},
  size,
  dateTimeFormatter = defaultDateTimeFormatter
}) => {
  const { value, setValueByLabel, error, handleBlurValidate } = form;

  const selected = get(value, name) ? moment(get(value, name)) : null;

  const setDateValue = (label: string) => (dateRaw: Date | null) => {
    setValueByLabel(label, dateTimeFormatter(dateRaw, onlyDate));
  };

  return (
    <Box>
      <DateTimeInput
        value={selected}
        onlyDate={onlyDate}
        // @ts-ignore
        onBlur={() => handleBlurValidate({ target: { name } })}
        onChange={setDateValue(name)}
        minDateTime={minDateTime}
        maxDateTime={maxDateTime}
        datePickerProps={{ ...datePickerProps }}
        renderInput={<TextField
          sx={{ ...sx }}
          fullWidth={fullWidth}
          label={label}
          InputProps={{
            endAdornment: <InputAdornment position="end"><EventIcon /></InputAdornment>
          }}
          name={name}
          size={size}
          error={Boolean(get(error, name))}
          helperText={get(error, name) ?? ""}
        />}
      />
    </Box>
  );
};

export default DateTimeFormInput;
