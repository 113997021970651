import React from "react";

import { Alert, Divider, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import Skeleton from "@mui/material/Skeleton";
import { concat, findKey, isEmpty } from "lodash-es";
import { useNotify } from "ra-core";
import { useCreate, useGetMany, useRefresh } from "react-admin";

import { ProcessingButton } from "../../../components/button/processing/ProcessingButton";
import { Resources } from "../../../resources";
import { CountryCurrency, Customer } from "../../../utils/commons";
import { DateTimeFormat, formatDate } from "../../../utils/date-time-utils";
import { contractDuration } from "../../../utils/field-renderers";
import { Offer } from "../../offers/types";
import { calcTotalPrice } from "../../orders/actions/create/create-order-form";
import { CreateOrderLine } from "../../orders/actions/create/types";
import { useCreateContract } from "../CreateContractProvider";
import { ContractLine, ContractType } from "../types";
import { calcTotalAmount } from "../utils";
import { CreateContractStepActions } from "./CreateContractStepActions";

const buildOfferLine = (offerId: number | string, quantity: number, offers?: Offer[]) => {
  const offer = offers?.find((offer) => offer.id == offerId);

  return (
    <Grid container spacing={2}>
      <Grid item xs={3}>
        {
          !offers ?
            <Skeleton variant="text" sx={{ fontSize: "1rem" }} /> :
            <Typography variant="caption">{offers?.find((offer) => offer.id == offerId)?.name}</Typography>
        }
      </Grid>
      <Grid item xs={3}>
        {
          !offers ?
            <Skeleton variant="text" sx={{ fontSize: "1rem" }} /> :
            <Typography variant="caption">
              {offer?.price ? `${offer.price} ${offer.country && CountryCurrency[offer.country]} x ${quantity || 1}`
                : `Custom x ${quantity || 1}`}
            </Typography>
        }
      </Grid>
    </Grid>
  );
};

export const calculateRemainingBalance = (addons: CreateOrderLine[],
  downPayment: number,
  customer?: Customer) => {
  return (customer?.wallet?.amount ?? 0) - calcTotalPrice(addons) - downPayment;
};

export const ContractSummaryStep: React.FC = () => {
  const notify = useNotify();
  const refresh = useRefresh();
  const [ create, { isLoading: isCreateLoading } ] = useCreate();
  const { form: { value: formData }, customer, dialog } = useCreateContract();
  const offerIds = concat(formData.details.map((detail) => detail.offerId),
    formData.addons?.map((addon) => addon.dealId));
  const { data: offers } = useGetMany<Offer>(Resources.Offers, { ids: offerIds || [] });

  const handleConfirm = () => {
    const contractData = {
      customerId: customer?.id,
      duration: formData.duration,
      effectiveDate: formatDate(formData.startDate, DateTimeFormat.DATE_ISO),
      downPayment: formData.downPayment,
      totalAmount: formData.totalAmount,
      type: findKey(ContractType, (value) => value === formData.contractType),
      details: formData.details.map((detail) => ({
        offerId: detail.offerId,
        discountId: detail.discountId,
        quantity: detail.quantity,
        instanceId: detail.instanceId
      })),
      addons: formData.addons
        .filter((addon) => addon.dealId)
        .map((addon) => ({
          dealId: addon.dealId,
          discountId: addon.discountId,
          quantity: addon.quantity,
          dealType: "PRODUCT"
        }))
    };

    return create(Resources.Contracts, { data: contractData },
      {
        onSuccess: () => {
          refresh();
          notify("Contract has been successfully created");
          dialog.cancel();
        },
        onError: () => notify("Error: failed to create a contract", { type: "error", autoHideDuration: 5000 })
      });
  };

  const remainingBalance = calculateRemainingBalance(formData.addons, formData.downPayment, customer);
  return (
    <Grid item xs={12}>

      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Typography variant="overline" sx={{ fontWeight: 700 }}>Products and Discounts</Typography>
        </Grid>
        <Grid item xs={12}>
          {
            formData.details.map((line: ContractLine) => {
              return buildOfferLine(line.offerId, line.quantity, offers);
            })
          }
          <Grid container spacing={0}>
            <Grid item xs={3}></Grid>
            <Grid item xs={3}>
              <Typography variant="caption">
                Total: {formData.totalAmount}{" "}{customer?.wallet.currency} {calcTotalAmount(formData.details) !== formData.totalAmount ? "(Manually changed)" : ""}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="overline" sx={{ fontWeight: 700 }}>Terms & Conditions</Typography>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <Typography variant="caption">Contract Effective Date:</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="caption">{formatDate(formData.startDate, DateTimeFormat.CUSTOM_DATE)}</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <Typography variant="caption">Contract Duration:</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="caption">{contractDuration(formData.duration)}</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <Typography variant="caption">Down Payment amount:</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="caption">{formData.downPayment} {" "}{customer?.wallet.currency}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {
          !isEmpty(formData.addons) && formData.addons.some((addon) => addon.dealId) &&
            <Grid item xs={12} sx={{ pt: 1 }}>
              <Typography variant="overline" sx={{ fontWeight: 700 }}>Add-ons</Typography>
              <Grid item xs={12}>
                {
                  formData.addons.map((line: CreateOrderLine) => {
                    return buildOfferLine(line.dealId, line.quantity || 1, offers);
                  })
                }
                <Grid container spacing={0}>
                  <Grid item xs={3}></Grid>
                  <Grid item xs={3}>
                    <Typography variant="caption">Total: {calcTotalPrice(formData.addons)} {" "}{customer?.wallet.currency}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
        }
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={6}><Divider/></Grid>
          </Grid>
          <Grid container spacing={0}>
            <Grid item xs={3}></Grid>
            <Grid item xs={3}>
              <Typography variant="caption" sx={{ fontWeight: "bold" }}>
                Grand Total: {Number(formData.totalAmount) + Number(calcTotalPrice(formData.addons))} {" "}{customer?.wallet.currency}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        {remainingBalance < 0 && (
          <Grid item xs={12}>
            <Alert severity="warning" sx={{ mt: 2 }}>
                The customer does not have enough funds to create the contract.<br/>
                Insufficient amount:&nbsp;
              <strong>
                {0 - remainingBalance} {customer?.wallet?.currency}
              </strong>
            </Alert>
          </Grid>
        )}
        <CreateContractStepActions isStepValid={true}>
          <ProcessingButton
            onClick={handleConfirm}
            disabled={isCreateLoading || remainingBalance < 0}
            isProcessing={isCreateLoading}
            content="Create"
            clickedContent="Creating"
          />
        </CreateContractStepActions>
      </Grid>
    </Grid>
  );
};