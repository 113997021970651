import React, { useState } from "react";

import NoAccountsIcon from "@mui/icons-material/NoAccounts";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Grid,
  Tooltip,
  Typography
} from "@mui/material";
import {
  useDataProvider,
  useNotify,
  useRefresh
} from "react-admin";
import * as yup from "yup";

import { FormSelect } from "../../../components/form/input/select/FormSelect";
import { useForm } from "../../../components/form/use-form";
import { UserRoles } from "../../../core/providers/auth/roles";
import { Customer, RepossessionReason, WriteOffReason } from "../../../utils/commons";
import { useCheckAccess } from "../../../utils/use-check-access";

type WriteOffCustomerProps = {
  customer: Customer
};
type WriteOffFormState = {
  reason: RepossessionReason | ""
};
const writeOffReasonsReasons = Object.entries(WriteOffReason).map(([key, value]) => {
  return { value: key, label: value };
})
  .sort((a, b) => a.label.localeCompare(b.label));

const initialFormState: WriteOffFormState = {
  reason: ""
};
const validationSchema = yup.object({
  reason: yup.string().required()
});

export const WriteOffCustomerButton: React.FC<WriteOffCustomerProps> = ({
  customer
}) => {
  const refresh = useRefresh();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const { hasAccess } = useCheckAccess([
    UserRoles.ROLE_SUPAMOTO_ADMIN
  ]);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const form = useForm<WriteOffFormState>(initialFormState, validationSchema);

  const handleCancel = () => {
    setIsFormOpen(false);
  };

  const handleConfirm = () => {
    setIsFormOpen(false);
    dataProvider.updateManyByUrlWithBody(`customers/${customer.id}/write-off`,
      {
        reason: form.value.reason
      }).then((response: any) => {
      refresh();
      notify("Customer has been successfully written-off");
      handleCancel();
      return response;
    }).catch(() => {
      notify("Error: failed to write-off customer", { type: "error" });
      handleCancel();
    });
  };
  return (
    <>
      <Tooltip title="Write-off">
        <span>
          <Button
            color="primary"
            variant="text"
            disabled={!hasAccess || customer.customerStatus === "INACTIVE" || customer.type === "LEAD"}
            onClick={() => setIsFormOpen(true)}
            startIcon={<NoAccountsIcon/>}>
            Write-off
          </Button>
        </span>
      </Tooltip>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={isFormOpen}
        onClose={handleCancel}
      >
        <DialogTitle>Default</DialogTitle>
        <DialogContent>
          <DialogContent sx={{ mx: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="body1" align="justify">
                    You are trying to write-off the customer
                  {" "}{customer.firstName}{" "}{customer.lastName}.
                  All contracts will be written off, and the stoves will be decommissioned.
                </Typography>
              </Grid>
              <Grid item xs={12} sx={{ mt: 2 }}>
                <FormSelect
                  name="reason"
                  label="Write Reason *"
                  form={form}
                  menuItems={writeOffReasonsReasons}/>
              </Grid>
            </Grid>
          </DialogContent>

          <DialogActions>
            <Button onClick={handleCancel}>
                Cancel
            </Button>
            <Button
              autoFocus
              onClick={handleConfirm}>
                Confirm
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
};