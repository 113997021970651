import React, { useState } from "react";

import GroupAddIcon from "@mui/icons-material/GroupAdd";
import { Button, Tooltip } from "@mui/material";

import { UserRoles } from "../../../core/providers/auth/roles";
import { useCheckAccess } from "../../../utils/use-check-access";
import { CreateFreshdeskContactAction } from "./CreateFreshdeskContactAction";

const CustomersBulkActions = () => {
  const { hasAccess } = useCheckAccess(UserRoles.ROLE_SUPAMOTO_ADMIN);
  const [isContactsDialogOpen, setIsContactsDialogOpen] = useState(false);

  return (
    <>
      <Tooltip title="Create contacts in the Freshdesk">
        <span>
          <Button
            disabled={!hasAccess}
            onClick={() => setIsContactsDialogOpen(true)}
            startIcon={<GroupAddIcon/>}>
              Create contacts
          </Button>
        </span>
      </Tooltip>
      <CreateFreshdeskContactAction
        isDialogOpen={isContactsDialogOpen}
        onDialogClose={() => setIsContactsDialogOpen(false)}
      />
    </>
  );
};

export default CustomersBulkActions;
