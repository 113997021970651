import * as React from "react";

import { isNil, uniq } from "lodash-es";
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { CategoricalChartFunc } from "recharts/types/chart/generateCategoricalChart";

import { ChartsBucketData, ChartsData } from "../types";
import { CHART_COLORS } from "../utils";
import { DashboardChartContainer } from "./DashboardChartContainer";

type Props = {
  title: string,
  chartsData: ChartsData[] | ChartsBucketData[] | undefined,
  property: string,
  dataKey: string,
  syncId?: string,
  stacked?: boolean,
  onClick?: CategoricalChartFunc
};

export const DashboardBarChart: React.FC<Props> = ({
  title,
  chartsData,
  property,
  dataKey,
  syncId,
  stacked = false,
  onClick
}) => {
  const dataKeys = uniq(chartsData?.flatMap((item) => Object.keys(item.data))).sort();

  return (
    <DashboardChartContainer
      title={title}
      isLoading={isNil(chartsData)}
      chartType={"line"}
    >
      <ResponsiveContainer width="100%" height="100%">
        <BarChart data={chartsData} syncId={syncId} onClick={onClick}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey={dataKey} />
          <YAxis />
          <Tooltip />
          <Legend />
          {
            dataKeys.map((key, index) => (
              <Bar key={key}
                fill={CHART_COLORS[index]}
                dataKey={(obj: ChartsData | ChartsBucketData) => obj.data[key][property]}
                stackId={stacked ? "a" : undefined}
                name={key}
              />
            ))
          }
        </BarChart>
      </ResponsiveContainer>
    </DashboardChartContainer>
  );
};