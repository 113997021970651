import * as React from "react";

import { Edit, useUpdate } from "react-admin";

import { CollectionPartnersForm, OperationType } from "./CollectionPartnersForm";

export const CollectionPartnersEdit: React.FC = () => {
  const [ update ] = useUpdate();

  return (
    <Edit
      mutationMode="pessimistic"
      queryOptions={{ cacheTime: 0, staleTime: 0 }}
    >
      <CollectionPartnersForm operationType={OperationType.UPDATE} submit={update}/>
    </Edit>
  );
};
