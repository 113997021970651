import React, { useEffect, useState } from "react";

import MoveUpIcon from "@mui/icons-material/MoveUp";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Tooltip,
  Typography
} from "@mui/material";
import Grid from "@mui/material/Grid";
import { head, isNil } from "lodash-es";
import { useNotify } from "ra-core";
import { useGetOne, useListContext, useRefresh, useUnselectAll, useUpdate } from "react-admin";

import { ProcessingButton } from "../../../components/button/processing/ProcessingButton";
import { CustomerBaseGrid } from "../../../components/customer/CustomerBaseGrid";
import { SearchCustomerAutocomplete } from "../../../components/customer/SearchCustomerAutocomplete";
import { UserRoles } from "../../../core/providers/auth/roles";
import { Resources } from "../../../resources";
import { Customer, FullCustomer } from "../../../utils/commons";
import { customerRenderer } from "../../../utils/field-renderers";
import { useCheckAccess } from "../../../utils/use-check-access";
import { usePageSafeSelector } from "../../../utils/use-page-safe-selector";
import { Payment, PaymentTransaction } from "../types";
import { InactiveCustomerAlert } from "./InactiveCustomerAlert";
import InsufficientFundsAlert, { hasEnoughFunds } from "./InsufficientFundsAlert";

export const ReconcilePaymentButton = () => {
  const refresh = useRefresh();
  const unselect = useUnselectAll(Resources.Payments);
  const notify = useNotify();
  const { selectedIds } = useListContext();
  const { hasAccess } = useCheckAccess([
    UserRoles.ROLE_SUPAMOTO_ADMIN, UserRoles.ROLE_DELIVERY_MANAGER,
    UserRoles.ROLE_SHOP_KEEPER, UserRoles.ROLE_SALES
  ]);
  const [isReconciliationDialogOpen, setIsReconciliationDialogOpen] = useState(false);
  const [isReconciliationRequestInProgress, setIsReconciliationRequestInProgress] = useState(false);
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false);
  const { data } = usePageSafeSelector<Payment>();
  const [payment, setPayment] = useState<Payment | undefined>();
  const [targetCustomer, setTargetCustomer] = useState<FullCustomer | undefined>();
  const [isFormValid, setIsFormValid] = useState(false);
  const [isCustomerActive, setIsCustomerActive] = useState(true);

  const handleCancel = () => {
    setIsConfirmationDialogOpen(false);
    setIsReconciliationDialogOpen(false);
    setTargetCustomer(undefined);
  };

  const [ update, { isLoading: isProcessing } ] = useUpdate();

  const handleConfirm = () => {
    if (isReconciliationRequestInProgress) {
      return;
    }
    setIsReconciliationRequestInProgress(true);
    setIsConfirmationDialogOpen(false);

    return update(Resources.Payments,
      {
        id: payment?.id,
        data: {
          allocations: [{
            customerId: targetCustomer?.id,
            amount: payment?.amount
          }]
        }
      }, {
        onSuccess: () => {
          refresh();
          notify("Payment has been successfully reconciled");
          unselect();
          handleCancel();
          setIsReconciliationRequestInProgress(false);
        },
        onError: (error: any) => {
          const errorCode = error.body?.errorCode;
          if (errorCode && errorCode === "wallet.invalid_balance") {
            refresh();
            notify("The payment cannot be reconciled due to insufficient funds in the customer's wallet", { type: "warning" });
          } else {
            notify("Error: failed to reconcile payment", { type: "error" });
          }
          setIsReconciliationRequestInProgress(false);
        }
      });
  };

  const sourceCustomerId =
      payment?.transactions
        .filter((transaction: PaymentTransaction) => transaction.activeAssignment)
        .map((transaction: PaymentTransaction) => transaction.customerId)?.[0] as number;
  const activeAssignmentCount =
      payment?.transactions
        .filter((transaction: PaymentTransaction) => transaction.activeAssignment)?.length || 0;

  const options = { enabled: !isNil(payment) && !isNil(sourceCustomerId), cacheTime: 0, staleTime: 0 };
  const {
    data: sourceCustomer,
    isLoading: isSourceCustomerLoading
  } = useGetOne<Customer>(Resources.Customers, { id: sourceCustomerId }, options);

  useEffect(() => {
    setPayment(head(data));
  }, [data]);

  useEffect(() => {
    const isValid = !isNil(targetCustomer) &&
        (isNil(sourceCustomer) || hasEnoughFunds(sourceCustomer, payment?.amount));
    const customerIsActive = targetCustomer?.customerStatus !== "INACTIVE";
    setIsFormValid(isValid && customerIsActive);
    setIsCustomerActive(customerIsActive);
  }, [sourceCustomer, targetCustomer, payment]);

  return (
    <>
      <Tooltip title="Assign an orphaned payment or reconcile it between customers">
        <span>
          <Button
            color="primary"
            disabled={!hasAccess || selectedIds.length > 1 || activeAssignmentCount > 1}
            onClick={() => setIsReconciliationDialogOpen(true)}
            startIcon={<MoveUpIcon/>}>
              Reconcile Payment
          </Button>
        </span>
      </Tooltip>
      <Dialog
        fullWidth
        maxWidth="md"
        open={isReconciliationDialogOpen}
        onClose={handleCancel}
      >
        <DialogContent>
          <Grid container spacing={2}>
            {
              !isNil(sourceCustomerId) && (
                <>
                  <Grid item xs={12}>
                    <Typography variant="h6">Source customer:</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    {
                      isSourceCustomerLoading ?
                        <CircularProgress color="secondary" size={20}/> :
                        <CustomerBaseGrid customer={sourceCustomer as FullCustomer} divider={false}/>
                    }
                  </Grid>
                  {
                    !isNil(sourceCustomer) && !isNil(payment) && !hasEnoughFunds(sourceCustomer, payment.amount) && (
                      <InsufficientFundsAlert customerPayments={[{
                        contractReference: sourceCustomer.contractReference,
                        requiredAmount: payment.amount,
                        actualAmount: sourceCustomer.wallet.amount,
                        currency: payment.currency
                      }]}/>
                    )
                  }
                  <Grid item xs={12}>
                    <Divider sx={{ my: 1 }}/>
                  </Grid>
                </>
              )
            }
            { !isCustomerActive && targetCustomer && (<InactiveCustomerAlert customer={targetCustomer} /> )}
            <Grid item xs={12}>
              <Typography variant="h6">Target customer:</Typography>
            </Grid>
            <Grid item xs={12}>
              <SearchCustomerAutocomplete
                filters={{
                  countries: [payment?.country]
                }}
                onCustomerSelected={(customer) => {
                  setTargetCustomer(customer);
                }} />
            </Grid>
            {
              !isNil(targetCustomer) && (
                <Grid item xs={12}>
                  <CustomerBaseGrid customer={targetCustomer} divider={false}/>
                </Grid>
              )
            }
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel}>
            Cancel
          </Button>
          <ProcessingButton
            onClick={() => setIsConfirmationDialogOpen(true)}
            disabled={!isFormValid || isProcessing}
            isProcessing={isProcessing}
          />
        </DialogActions>
      </Dialog>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={isConfirmationDialogOpen}
        onClose={() => setIsConfirmationDialogOpen(false)}
      >
        <DialogTitle>Payment to be reconciled:</DialogTitle>
        <DialogContent>
          <DialogContent sx={{ p: 0 }}>
            <Grid container spacing={2}>
              <Grid item xs={6}>Transaction ID:</Grid>
              <Grid item xs={6}><strong>{payment?.telcoTransactionId}</strong></Grid>
              <Grid item xs={6}>Amount:</Grid>
              <Grid item xs={6}><strong>{payment?.amount} {payment?.currency}</strong></Grid>
              {
                !isNil(sourceCustomer) && (
                  <>
                    <Grid item xs={6}>From:</Grid>
                    <Grid item xs={6}>{customerRenderer(sourceCustomer)}</Grid>
                  </>
                )
              }
              <Grid item xs={6}>To:</Grid>
              <Grid item xs={6}>{customerRenderer(targetCustomer)}</Grid>
              <Grid item xs={12} sx={{ mt: 2 }}>
                <Typography variant="body1">Kindly confirm or cancel the action.</Typography>
              </Grid>
            </Grid>
          </DialogContent>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsConfirmationDialogOpen(false)}>
            Cancel
          </Button>
          <Button
            autoFocus
            disabled={isReconciliationRequestInProgress}
            onClick={handleConfirm}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};