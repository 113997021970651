import React, { useEffect, useState } from "react";

import CancelIcon from "@mui/icons-material/Cancel";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Tooltip,
  Typography
} from "@mui/material";
import { useDataProvider, useNotify } from "react-admin";
import * as yup from "yup";

import { ProcessingButton } from "../../../components/button/processing/ProcessingButton";
import { FormSelect } from "../../../components/form/input/select/FormSelect";
import { useForm } from "../../../components/form/use-form";
import { RepossessionReason } from "../../../utils/commons";
import { Contract, ContractDetail } from "../types";

type RepossessDeviceProps = {
  contract: Contract,
  disabled: boolean,
  onContractChange: (contractId: number) => void
};

type RepossessDeviceFormState = {
  reason: RepossessionReason | ""
};

type ContractDetailsListProps = {
  details: ContractDetail[],
  contractType: string,
  selectedDetails: number[],
  handleDetailSelect: (id: number) => void};

const initialFormState: RepossessDeviceFormState = {
  reason: ""
};
const validationSchema = yup.object({
  reason: yup.string().required()
});
const repossessionReasons = Object.entries(RepossessionReason).map(([key, value]) => {
  return { value: key, label: value };
})
  .sort((a, b) => a.label.localeCompare(b.label));

const ContractDetailsList: React.FC<ContractDetailsListProps> = ({
  details,
  contractType,
  selectedDetails,
  handleDetailSelect
}) => {
  const isUtility = contractType === "UTILITY";

  const handleToggle = (id: number) => () => {
    handleDetailSelect(id);
  };
  return (
    <List>
      {details.map((detail) => {
        return (
          <ListItem
            key={detail.id}
            secondaryAction={
              <Checkbox
                edge="end"
                onChange={handleToggle(detail.id)}
                checked={isUtility || selectedDetails.includes(detail.id)}
                disabled={isUtility}
              />
            }
            disablePadding
          >
            <ListItemButton>
              <ListItemText
                primary={detail.offer.name}
                secondary={`Quantity: ${detail.quantity}`}
              />
            </ListItemButton>
          </ListItem>
        );
      })}
    </List>
  );
};

export const RepossessButton: React.FC<RepossessDeviceProps> = ({
  contract,
  disabled,
  onContractChange
}) => {
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const form = useForm<RepossessDeviceFormState>(initialFormState, validationSchema);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [selectedDetails, setSelectedDetails] = useState<number[]>([]);
  const [isUtility, setIsUtility] = useState(false);
  const [isRepossessing, setIsRepossessing] = useState(false);

  useEffect(() => {
    if (contract.type === "UTILITY") {
      setSelectedDetails(contract.details.map((detail) => detail.id));
      setIsUtility(true);
    }
  }, [contract]);

  const handleDetailSelect = (id: number) => {
    if (selectedDetails.includes(id)) {
      setSelectedDetails(selectedDetails.filter((detailId) => detailId !== id));
    } else {
      setSelectedDetails([...selectedDetails, id]);
    }
  };

  const handleCancel = () => {
    setIsFormOpen(false);
    form.reset();
  };

  const handleConfirm = () => {
    setIsRepossessing(true);
    dataProvider.updateManyByUrlWithBody(`contracts/${contract.id}/repossess`, {
      reason: form.value.reason,
      detailIds: selectedDetails
    }).then((response: any) => {
      notify("Contract has been successfully repossessed");
      onContractChange(contract.id);
      handleCancel();
      return response;
    }).catch(() => {
      notify("Error: failed to repossess contract", { type: "error" });
    }).finally(() => setIsRepossessing(false));
  };
  return (
    <>
      <Tooltip title="Repossess">
        <span>
          <Button
            color="primary"
            variant="text"
            disabled={disabled}
            onClick={() => setIsFormOpen(true)}
            startIcon={<CancelIcon/>}>
            Repossess
          </Button>
        </span>
      </Tooltip>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={isFormOpen}
        onClose={handleCancel}
      >
        <DialogTitle>Repossess</DialogTitle>
        <DialogContent>
          <DialogContent sx={{ mx: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="body1" align="justify">
                  You are trying to repossess products for Contract #{contract.id}.
                </Typography>
                <Typography variant="body1" align="justify">
                    Please select products:
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <ContractDetailsList
                  contractType={contract.type}
                  details={contract.details}
                  selectedDetails={selectedDetails}
                  handleDetailSelect={handleDetailSelect}
                />
              </Grid>
              <Grid item xs={12} sx={{ mt: 2 }}>
                <FormSelect
                  name="reason"
                  label="Repossession Reason *"
                  form={form}
                  menuItems={repossessionReasons}/>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" align="justify">
                  {isUtility && (
                    <> The contract will be marked as &quot;Repossessed&quot;.</>
                  )}
                  {!isUtility && (
                    <> If you select all products, the contract will be marked as &quot;Repossessed&quot;.</>
                  )}
                </Typography>
              </Grid>
            </Grid>
          </DialogContent>

          <DialogActions>
            <Button onClick={handleCancel}>
              Cancel
            </Button>
            <ProcessingButton
              onClick={handleConfirm}
              disabled={!form.isValid || selectedDetails.length === 0}
              isProcessing={isRepossessing}
            />
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
};