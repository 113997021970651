import React, { useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import { Alert, AlertTitle, IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";

import { Auth } from "./Auth";
import { AuthForm } from "./AuthForm";
import { ResetPasswordForm } from "./ResetPasswordForm";

export const ResetPassword: React.FC = () => {
  const [showAlert, setShowAlert] = useState(false);

  const navigate = useNavigate();
  const handleClose = () => {
    navigate("/login");
  };

  const handleSuccess = () => {
    setShowAlert(true);
  };

  const formComponent = !showAlert ? <AuthForm formComponent={<ResetPasswordForm onSuccess={handleSuccess} />}/>
    : <StyledAlert severity="info"
      action={
        <IconButton
          aria-label="close"
          color="inherit"
          size="small"
          onClick={handleClose}
        >
          <CloseIcon fontSize="inherit" />
        </IconButton>
      }
    >
      <AlertTitle>Instruction Sent</AlertTitle>
        If the email you provided exists in our system, a password reset link will be sent to it.
        Please check your email inbox. If you do not receive the link within a few minutes,
        make sure to check your spam or junk folder as well.
    </StyledAlert>;
  return (
    <Auth formComponent={formComponent}/>
  );
};

const StyledAlert = styled(Alert)({
  position: "fixed",
  top: "20%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: "300px",
  zIndex: 1000
});