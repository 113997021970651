import * as React from "react";

import {
  Show,
  Tab,
  TabbedShowLayout
} from "react-admin";

import { OrderHistoryTab } from "./tabs/OrderHistoryTab";
import { OrderMainTab } from "./tabs/OrderMainTab";

export const OrderShow: React.FC = () => {
  const OrderShowLayout = () => {
    return (
      <TabbedShowLayout>
        <Tab label="Main">
          <OrderMainTab/>
        </Tab>
        <Tab label="History">
          <OrderHistoryTab/>
        </Tab>
      </TabbedShowLayout>
    );
  };

  return (
    <Show queryOptions={{ cacheTime: 0, staleTime: 0 }}>
      <OrderShowLayout/>
    </Show>
  );
};