export type Ticket = {
  id: number,
  freshdeskTicketId: number,
  resourceId: number,
  status: string,
  category: TicketCategory
};

export enum TicketCategory {
  STOVE_CONNECTIVITY = "STOVE_CONNECTIVITY",
  INSUFFICIENT_COOKING_ACTIVITY = "INSUFFICIENT_COOKING_ACTIVITY",
  LAST_PAYMENT_IN_ARREARS = "LAST_PAYMENT_IN_ARREARS",
  TOTAL_PAYMENT_IN_ARREARS = "TOTAL_PAYMENT_IN_ARREARS",
  OTHER = "OTHER"
}

export enum ConnectivityLevel {
  HIGH = "High",
  MEDIUM = "Medium",
  LOW = "Low",
  ZERO = "Zero"
}

export enum PARCategories {
  ONTIME = "On time",
  PAR_1_30 = "PAR 1-30",
  PAR_30_60 = "PAR 30-60",
  PAR_60_90 = "PAR 60-90",
  PAR_90_PLUS = "PAR 90+"
}

export const PARCategoriesColor: Record<keyof typeof PARCategories, string> = {
  ONTIME: "green",
  PAR_1_30: "#bda62c",
  PAR_30_60: "orange",
  PAR_60_90: "red",
  PAR_90_PLUS: "black"
};

export const ConnectivityLevelColor: Record<keyof typeof ConnectivityLevel, string> = {
  HIGH: "green",
  MEDIUM: "orange",
  LOW: "red",
  ZERO: "black"
};

export enum OwnershipFilter {
  CUSTOMERS_WITH_STOVES = "Customers with stoves",
  CUSTOMERS_WITHOUT_STOVES = "Customers without stoves",
  STOVES_WITHOUT_CUSTOMERS = "Stoves without customers",
}
export type TicketStatistics = {
  totalTickets: number,
  activeTickets: Ticket[]
};

export type ProfilePerformance = {
  id: number,
  customerId?: number,
  totalPelletsBagsSize: number,
  totalPelletsPurchaseOverdueDays: number,
  lastPelletsPurchaseOverdueDays: number,
  stoveId?: number,
  connectivityLevel?: ConnectivityLevel,
  parCategory?: PARCategories,
  totalCookingSessionsCount?: number,
  totalCookingSessionsDuration?: number,
  totalCookingSessionsDurationFormatted?: string,
  lastCookingDaysAgo?: number,
  lastConnectivityDaysAgo?: number,
  ticketStatistics: TicketStatistics
};

export type TicketStatus = {
  id: number,
  name: string
};