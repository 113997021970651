import React, { ReactElement } from "react";

import CableIcon from "@mui/icons-material/Cable";
import LocalFireDepartmentIcon from "@mui/icons-material/LocalFireDepartment";
import StoveIcon from "@mui/icons-material/OutdoorGrill";
import { Box, Tooltip } from "@mui/material";

import { CollectionPartnerType } from "../../components/collections/types";
import { StockItemType } from "../../components/stock/types";
import { Country } from "../../utils/commons";
import { enumRenderer } from "../../utils/field-renderers";

export const stockItemIcons: Record<keyof typeof StockItemType, ReactElement> = {
  "PELLETS": (
    <Tooltip title="Pellets item">
      <LocalFireDepartmentIcon fontSize="small" color="warning"/>
    </Tooltip>
  ),
  "STOVE": (
    <Tooltip title="Stove">
      <StoveIcon fontSize="small" color="error"/>
    </Tooltip>
  ),
  "ACCESSORY": (
    <Tooltip title="Accessory item">
      <CableIcon fontSize="small" color="success"/>
    </Tooltip>
  )
};

export const availableForRenderer = (availableFor: CollectionPartnerType[]) => {
  return (
    <Box sx={{
      display: "flex",
      alignItems: "start",
      flexDirection: "column",
      justifyContent: "center"
    }}>
      {
        availableFor?.map((stockItemGroup) => {
          return (
            <Box key={stockItemGroup}>
              {enumRenderer(stockItemGroup, CollectionPartnerType)}
            </Box>
          );
        })
      }
    </Box>
  );
};
export const availableForCountriesRenderer = (countries: string[]) => {
  return (
    <Box sx={{
      display: "flex",
      alignItems: "start",
      flexDirection: "column",
      justifyContent: "center"
    }}>
      {
        countries?.map((country) => {
          return (
            <Box key={country}>
              {Country[country as keyof typeof Country]}
            </Box>
          );
        })
      }
    </Box>
  );
};