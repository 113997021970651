import * as React from "react";

import { CardContent } from "@mui/material";
import { useNotify, useSafeSetState } from "ra-core";
import { TextInput, useDataProvider } from "react-admin";
import { FieldValues } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { ButtonWithLoader } from "./ButtonWithLoader";
import { AuthClasses, StyledForm } from "./types";
import { passwordsMatchValidation, validatePassword } from "./validation";

export const SetPasswordForm = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const [loading, setLoading] = useSafeSetState(false);
  const token = queryParams.get("token") ;
  const navigate = useNavigate();
  const dataProvider = useDataProvider();
  const notify = useNotify();

  const submit = (formData: FieldValues) => {
    setLoading(true);

    dataProvider.setPassword(formData.password, token)
      .then(() => {
        navigate("/login");
        return true;
      })
      .catch((data: any) => {
        setLoading(false);

        const errorCode = data.body?.errorCode;
        if (errorCode === "users.invalid_token") {
          notify("Your token has already expired. Please try resending the email to yourself. If you continue to experience issues, contact our support team for assistance", { type: "error" });
        } else {
          notify("Failed to set new password", { type: "error" });
        }
      });
  };

  return (
    <StyledForm
      onSubmit={submit}
      mode="onChange"
      noValidate
    >
      <CardContent className={AuthClasses.content}>
        <TextInput
          source="password"
          label="Password"
          type="password"
          validate={validatePassword}
          fullWidth
        />
        <TextInput
          source="repassword"
          label="Repeat password"
          type="password"
          validate={[...validatePassword, passwordsMatchValidation]}
          fullWidth
        />
        <ButtonWithLoader loading={loading} buttonLabel="Set password"/>
      </CardContent>
    </StyledForm>
  );
};
