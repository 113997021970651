import React, { useEffect, useState } from "react";

import { RaRecord } from "ra-core";
import { AutocompleteArrayInput } from "react-admin";

import { buildLabel } from "../filter/filter-utils";
import { LocationFilterSource, LocationLevel } from "./types";
import { useLocations } from "./use-locations";

type LocationFilterProps = {
  level: LocationLevel,
  label: string,
  source: LocationFilterSource,
  icon : React.FC<any>
};
export const useLocationFilter = ({ level, label, source, icon }: LocationFilterProps) => {
  const { locations } = useLocations();
  const [ choices, setChoices ] = useState<RaRecord[]>([]);

  useEffect(() => {
    const choices = locations.filter((location) => location.level === level)
      .map((location) => ({ id: location.id, name: `${location.name} / ${location.country}` }));

    setChoices(choices);
  }, [level, locations]);

  return <AutocompleteArrayInput key={label}
    label={buildLabel({ label, Icon: icon })}
    source={source}
    variant="filled"
    choices={choices} />;
};