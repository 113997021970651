import React from "react";

import {
  Datagrid,
  DateField,
  FunctionField,
  Layout,
  List,
  ReferenceField,
  TextField
} from "react-admin";

import { AppBar } from "../../components/app-bar/AppBar";
import { useCustomerFilters } from "../../components/filter/customer-filters";
import { PresetListActions } from "../../components/list/actions/PresetListActions";
import { DefaultPagination } from "../../components/pagination/DefaultPagination";
import { User } from "../../components/user/types";
import { Resources } from "../../resources";
import { CountryCurrency, Customer } from "../../utils/commons";
import {
  customerRenderer,
  enumChipRenderer,
  usernameRenderer
} from "../../utils/field-renderers";
import Dashboard from "../dashboard/Dashboard";
import { AdjustmentsBulkActions } from "./AdjustmentsBulkActions";
import { CreateAdjustmentButton } from "./CreateAdjustmentButton";
import { Adjustment, AdjustmentStatus, adjustmentStatusChipColors } from "./types";
import { useAdjustmentFilters } from "./use-adjustment-filter";

export const AdjustmentsList: React.FC<{ resource: string }> = ({ resource }) => {

  const filters = [
    ...useCustomerFilters({
      groupsAlwaysOn: true
    }),
    ...useAdjustmentFilters()];
  return (
    <Layout
      appBar={AppBar}
      dashboard={Dashboard}>
      <List
        filters={filters}
        actions={
          <PresetListActions>
            <>
              <CreateAdjustmentButton/>
            </>
          </PresetListActions>
        }
        filterDefaultValues={{ statuses: "NEW" }}
        resource={resource}
        pagination={<DefaultPagination />}
        perPage={25}
        exporter={false}
      >
        <Datagrid bulkActionButtons={<AdjustmentsBulkActions/>}>
          <TextField source="id" sortable={false} />
          <ReferenceField
            label="Customer"
            reference={Resources.Customers}
            source="customerId"
            link={false}
            sortable={false}>
            <FunctionField render={(customer: Customer) => customerRenderer(customer)}/>
          </ReferenceField>
          <FunctionField
            source="amount"
            label="Amount"
            sortable={false}
            render={(record: Adjustment) =>
              <ReferenceField
                reference={Resources.Customers}
                source="customerId"
                link={false}
              >
                <FunctionField render={(customer: Customer) => `${record.amount} ${CountryCurrency[customer.country] ?? ""}`} />
              </ReferenceField>
            }
          />
          <TextField source="transactionId" />
          <TextField source="description" />
          <FunctionField
            label="Status"
            render={(record: Adjustment) =>
              enumChipRenderer(record.status, AdjustmentStatus, adjustmentStatusChipColors)}/>
          <ReferenceField
            label="Created by"
            reference={Resources.Users}
            source="createdBy"
            sortable={false}
            link={false}>
            <FunctionField render={(user: User) => usernameRenderer(user)}/>
          </ReferenceField>
          <DateField
            source="timeCreated"
            label="Creation date"
            showTime/>
        </Datagrid>
      </List>
    </Layout>
  );
};