import { castArray, isNil } from "lodash-es";
import { useGetIdentity, usePermissions, UserIdentity } from "react-admin";

import { UserRoles } from "../core/providers/auth/roles";

export type CheckRolesResult = {
  hasAccess: boolean,
  isExternalCompanyAdmin: boolean,
  user?: UserIdentity
};

const checkIsExternalCompanyAdmin = (permissions: any[], user?: any) => {
  return permissions.includes(UserRoles.ROLE_EXTERNAL_COMPANY_ADMIN) && !isNil(user?.company);
};

export const useCheckAccess = (anyOfRoles: UserRoles | UserRoles[]): CheckRolesResult => {
  const { isLoading: isPermissionsLoading, permissions = [] } = usePermissions();
  const { isLoading: isIdentityLoading, data: user } = useGetIdentity();

  const hasAccess = !isPermissionsLoading &&
      permissions.some((permission: any) => castArray(anyOfRoles).includes(permission));
  const isExternalCompanyAdmin = !isPermissionsLoading && !isIdentityLoading &&
      checkIsExternalCompanyAdmin(permissions, user);

  return {
    hasAccess,
    isExternalCompanyAdmin,
    user
  };
};