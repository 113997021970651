export type Location = {
  id: number,
  parentId?: number,
  name: string,
  level: number,
  country: string
};

export enum LocationLevel {
  Province = 2,
  City = 1,
  Area = 0
}

export enum LocationFilterSource {
  Province = "provinceIds",
  City = "cityIds",
  Area = "areaIds"
}
