import React, { useEffect, useState } from "react";

import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import EditIcon from "@mui/icons-material/Edit";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tooltip,
  Typography
} from "@mui/material";
import Grid from "@mui/material/Grid";
import { isNil, uniq } from "lodash-es";
import { useNotify } from "ra-core";
import { useCreate, useDataProvider, useRefresh, useUnselectAll, useUpdate } from "react-admin";

import { ProcessingButton } from "../../../../components/button/processing/ProcessingButton";
import { CustomerBaseGrid } from "../../../../components/customer/CustomerBaseGrid";
import {
  SearchCustomerAutocomplete
} from "../../../../components/customer/SearchCustomerAutocomplete";
import { useGetDiscountsByOffer } from "../../../../components/discount/use-get-discounts-by-offer";
import { useForm } from "../../../../components/form/use-form";
import {
  useCustomerEligibleOffers
} from "../../../../components/offer/use-customer-eligible-offers";
import {
  CustomerRecommendedLocationAccordion
} from "../../../../components/recommended-locations/RecommendedLocations";
import {
  CustomerRecommendedLocations,
  RecommendedLocation
} from "../../../../components/recommended-locations/types";
import { UserRoles } from "../../../../core/providers/auth/roles";
import { Resources } from "../../../../resources";
import { Customer, FullCustomer } from "../../../../utils/commons";
import { useCheckAccess } from "../../../../utils/use-check-access";
import { Contract } from "../../../contracts/types";
import { GetContractsResponse } from "../../../customers/types";
import { Offer } from "../../../offers/types";
import { InactiveCustomerAlert } from "../../../payments/bulk-actions/InactiveCustomerAlert";
import { Order } from "../../types";
import {
  calcTotalPrice,
  CreateOrderForm,
  findOffer,
  initialState,
  validationSchema
} from "./create-order-form";
import { CreateOrUpdateOrderDetailsForm } from "./CreateOrUpdateOrderDetailsForm";
import { CreateOrderLine, CreateOrderRequest, DealType } from "./types";

type Props = {
  variant?: "text" | "outlined" | "contained",
  predefinedCustomer?: FullCustomer,
  order?: Order,
  disabled?: boolean
};

const calculateInsufficientFunds = (orderLines: CreateOrderLine[], customer?: Customer) => {
  return calcTotalPrice(orderLines) - (customer?.wallet?.amount ?? 0);
};

const isOrderConsistsOfSameOfferTypes = (orderLines: CreateOrderLine[], offers: Offer[] = []) => {
  const productLines = orderLines.filter((line) => line.dealType === DealType.PRODUCT && !isNil(line.dealId) && line.dealId !== "");
  const contractLines = orderLines.filter((line) => line.dealType === DealType.CONTRACT);

  if (contractLines.length === 1 && productLines.length === 0) {
    return true;
  }

  const offerTypes = productLines.map((line) => findOffer(offers, line.dealId).offerType);
  return uniq(offerTypes).length === 1 && contractLines.length === 0;
};

export const CreateOrUpdateOrderButton: React.FC<Props> = ({
  variant,
  predefinedCustomer,
  order,
  disabled = false
}) => {
  const refresh = useRefresh();
  const notify = useNotify();
  const unselect = useUnselectAll(Resources.Orders);
  const dataProvider = useDataProvider();
  const [ create, { isLoading: isProcessing } ] = useCreate();
  const [update] = useUpdate();
  const { hasAccess } = useCheckAccess([
    UserRoles.ROLE_SUPAMOTO_ADMIN, UserRoles.ROLE_DELIVERY_MANAGER,
    UserRoles.ROLE_SHOP_KEEPER, UserRoles.ROLE_SALES
  ]);
  const { hasAccess: showRecommendedLocations } =
      useCheckAccess([ UserRoles.ROLE_SUPAMOTO_ADMIN, UserRoles.ROLE_DELIVERY_MANAGER]);
  const { hasAccess: allowContractPayments } =
      useCheckAccess([ UserRoles.ROLE_SUPAMOTO_ADMIN, UserRoles.ROLE_SALES]);
  const { discounts, isLoading: isDiscountsLoading } = useGetDiscountsByOffer();
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false);
  const [customer, setCustomer] = useState<FullCustomer | undefined>(predefinedCustomer);
  const [contracts, setContracts] = useState<Contract[]>([]);
  const [isContactsLoading, setIsContractsLoading] = useState(false);
  const [recommendedLocations, setRecommendedLocations] = useState<RecommendedLocation[]>([]);
  const [isRecommendedLocationsLoading, setIsRecommendedLocationsLoading] = useState(false);
  const [initialOrderAmount, setInitialOrderAmount] = useState<number>(0);
  const { offers, isLoading: isOffersLoading } = useCustomerEligibleOffers(customer?.id,
    ["ACCESSORY", "PELLETS"]);
  const form = useForm<CreateOrderForm>(
    order
      ? {
        orderLines: order.orderLines.map((orderLine) => {
          return {
            dealId: orderLine.offer.id,
            discountId: orderLine.discount?.id,
            quantity: orderLine.quantity,
            dealType: DealType.PRODUCT
          };
        })
      }
      : initialState,
    validationSchema
  );

  useEffect(() => {
    setInitialOrderAmount(order?.amount || 0);
  }, [order]);

  useEffect(() => {
    if (!customer || !allowContractPayments) return;

    dataProvider.getManyByUrl(`customers/${customer.id}/contracts`)
      .then((response: GetContractsResponse) => {
        const activeContracts = response.data.filter((contract) => contract.status === "ACTIVE"
            && contract.paidAmount < contract.totalAmount);
        setContracts(activeContracts);
        setIsContractsLoading(false);
        return response;
      })
      .catch(() => {
        notify("Error: failed to retrieve contracts", { type: "error" });
        setIsContractsLoading(false);
      });

    if (showRecommendedLocations && isFormOpen) {
      setIsRecommendedLocationsLoading(true);
      dataProvider.getManyByUrlWithBody("orders/recommended-locations", { customerIds: [customer.id] })
        .then((response: {data: CustomerRecommendedLocations[]}) => {
          if (response.data && response.data[0]) {
            setRecommendedLocations(response.data[0].recommendedLocations);
          }
          setIsRecommendedLocationsLoading(false);
          return response;
        }).catch(() => {
          notify("Error: failed to retrieve recommended locations", { type: "error" });
          setIsRecommendedLocationsLoading(false);
        });
    }
  }, [customer, dataProvider, notify, showRecommendedLocations, isFormOpen, allowContractPayments]);

  const handleCancel = () => {
    setIsConfirmationDialogOpen(false);
    setIsFormOpen(false);
    if (!predefinedCustomer) setCustomer(undefined);
    form.reset();
  };

  useEffect(() => {
    if (predefinedCustomer) {
      setCustomer(predefinedCustomer);
    }
  }, [predefinedCustomer]);

  const handleConfirm = () => {
    setIsConfirmationDialogOpen(false);

    const orderLines = form.value.orderLines.map((orderLine) => {
      if (orderLine.dealType === DealType.PRODUCT) {
        delete orderLine.amount;
      }
      return orderLine;
    });

    const orderData = {
      customerId: customer?.id,
      orderLines
    } as CreateOrderRequest;

    if (order) {
      return update(Resources.Orders, {
        id: order.id,
        data: orderData
      }, {
        onSuccess: () => {
          refresh();
          notify("Order has been successfully updated");
          unselect();
          handleCancel();
        },
        onError: () => notify("Error: failed to update the order", { type: "error" })
      });
    }
    return create(Resources.Orders,
      {
        data: orderData
      },
      {
        onSuccess: () => {
          refresh();
          notify("Order has been successfully created");
          handleCancel();
        },
        onError: () => notify("Error: failed to create an order", { type: "error" })
      });
  };

  const hasEnoughFunds = (initialOrderAmount : number) => {
    return ((customer?.wallet?.amount ?? 0) + initialOrderAmount) >= calcTotalPrice(form.value.orderLines);
  };

  const isFormValid = (initialOrderAmount : number) => {
    return !isProcessing && form.isValid && !isNil(customer) && hasEnoughFunds(initialOrderAmount) && customer.customerStatus === "ACTIVE"
        && (order || isOrderConsistsOfSameOfferTypes(form.value.orderLines, offers));
  };

  return (
    <>
      <Tooltip title={order ? "Edit Order" : "Manually create an order"}>
        <Button
          color="primary"
          variant={variant}
          disabled={!hasAccess || disabled}
          onClick={() => setIsFormOpen(true)}
          startIcon={order ? <EditIcon /> : <AddShoppingCartIcon />}>
          {order ? "Edit Order" : "Create Order"}
        </Button>
      </Tooltip>
      <Dialog
        fullWidth
        maxWidth="lg"
        open={isFormOpen}
        onClose={handleCancel}
      >
        <DialogTitle>{order ? "Edit Order" : "Create Order"}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6">Customer:</Typography>
            </Grid>
            {
              isNil(predefinedCustomer) && (
                <Grid item xs={12}>
                  <SearchCustomerAutocomplete
                    size="medium"
                    onCustomerSelected={(customer) => {
                      setCustomer(customer);
                      form.reset();
                    }} />
                </Grid>
              )
            }
            {
              !isNil(customer) && (
                <>
                  <Grid item xs={12}>
                    <CustomerBaseGrid
                      customer={customer}
                      remainingBalance={initialOrderAmount
                          - calculateInsufficientFunds(form.value.orderLines, customer)}/>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h6">Order details:</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <CreateOrUpdateOrderDetailsForm
                      customer={customer}
                      offers={offers}
                      contracts={contracts}
                      isOffersLoading={isOffersLoading}
                      isContractsLoading={isContactsLoading}
                      discounts={discounts}
                      isDiscountsLoading={isDiscountsLoading}
                      form={form}/>
                  </Grid>
                  {!isRecommendedLocationsLoading && recommendedLocations && recommendedLocations.length > 0
                    ? (
                      <Grid item xs={12}>
                        <CustomerRecommendedLocationAccordion recommendedLocations={recommendedLocations}/>
                      </Grid>
                    ) : null}
                </>
              )
            }
            {!hasEnoughFunds(initialOrderAmount) && (
              <Grid item xs={12}>
                <Alert severity="warning">
                  The customer does not have enough funds to create this order.<br/>
                  Insufficient amount:&nbsp;
                  <strong>
                    {calculateInsufficientFunds(form.value.orderLines, customer)} {customer?.wallet?.currency}
                  </strong>
                </Alert>
              </Grid>
            )}
            {customer?.customerStatus === "INACTIVE" && (
              <InactiveCustomerAlert customer={customer}/>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel}>
            Cancel
          </Button>
          <ProcessingButton
            onClick={() => setIsConfirmationDialogOpen(true)}
            disabled={!isFormValid(initialOrderAmount)}
            isProcessing={isProcessing}
          />
        </DialogActions>
      </Dialog>
      <Dialog
        open={isConfirmationDialogOpen}
        onClose={() => setIsConfirmationDialogOpen(false)}
      >
        <DialogTitle>Order confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            You are about to place an order with a total amount of <strong>{calcTotalPrice(form.value.orderLines)}&nbsp;
              {customer?.wallet?.currency}</strong>.<br/> Kindly confirm or cancel the order.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsConfirmationDialogOpen(false)}>
            Cancel
          </Button>
          <Button
            autoFocus
            onClick={handleConfirm}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};