import * as React from "react";

import { Create, useCreate } from "react-admin";

import { CollectionPartnersForm, OperationType } from "./CollectionPartnersForm";

export const CollectionPartnersCreate: React.FC = () => {
  const [ create ] = useCreate();

  return (
    <Create redirect="list">
      <CollectionPartnersForm operationType={OperationType.CREATE} submit={create}/>
    </Create>
  );
};
