import React, { useEffect, useState } from "react";

import Alert from "@mui/material/Alert";
import Grid from "@mui/material/Grid";
import Skeleton from "@mui/material/Skeleton";
import { head } from "lodash-es";
import { ListControllerResult } from "ra-core/dist/cjs/controller/list/useListController";
import {
  DatagridConfigurable, DateField,
  FunctionField,
  ListContextProvider,
  ReferenceField,
  TextField,
  useDataProvider
} from "react-admin";
import { Link } from "react-router-dom";

import { User } from "../../../components/user/types";
import { PRIMARY_COLOR } from "../../../core/theme/theme";
import { Resources } from "../../../resources";
import {
  cancelReasonChipColors,
  enumChipRenderer,
  enumRenderer,
  orderStatusChipColors,
  usernameRenderer
} from "../../../utils/field-renderers";
import { OfferType } from "../../offers/types";
import { orderCompletionDetailsRenderer, orderLinesRenderer } from "../../orders/renderers";
import { Order, OrderCancelReason, OrderStatus } from "../../orders/types";

type GetOrdersResponse = {
  data: Order[]
};
export type CustomerOrdersTabProps = {
  customerId?: number
};
const EmptyData = () => <Alert severity="info">No orders found</Alert>;
export const CustomerOrdersTab: React.FC<CustomerOrdersTabProps> = ({
  customerId
}) => {
  const dataProvider = useDataProvider();
  const [orders, setOrders] = useState<Order[]>([]);
  const [isOrdersLoading, setOrdersLoading] = useState(false);

  useEffect(() => {
    if (!customerId) return;

    setOrdersLoading(true);
    dataProvider.getManyByUrl(`customers/${customerId}/orders`)
      .then((response: GetOrdersResponse) => {
        setOrders(response.data);
        setOrdersLoading(false);

        return response;
      })
      .catch(() => {
        setOrdersLoading(false);
      });

  }, [customerId, dataProvider]);

  const context = {
    data: orders,
    total: orders?.length,
    page: 1,
    perPage: orders?.length,
    filterValues: {},
    sort: {}
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {
          isOrdersLoading ?
            <>
              <Skeleton animation="wave"/>
              <Skeleton animation="wave"/>
              <Skeleton animation="wave"/>
              <Skeleton animation="wave"/>
            </> :
            <ListContextProvider value={context as ListControllerResult}>
              <DatagridConfigurable
                empty={<EmptyData/>}
              >
                <FunctionField
                  source="id"
                  render={(record: Order) =>
                    <Link
                      style={{ color: PRIMARY_COLOR, textDecoration: "none" }}
                      to={`/orders/${record.id}/show`}>{record.id}
                    </Link>}
                />
                <TextField source="transactionId"/>
                <FunctionField
                  label="Order lines"
                  render={(record: Order) => orderLinesRenderer(record)}/>
                <FunctionField
                  label="Amount"
                  source="amount"
                  sortBy="amount"
                  render={(record: Order) => `${record.amount} ${record.currency}`}/>
                <FunctionField
                  label="Type"
                  render={(record: Order) => enumRenderer(head(record.orderLines)?.offer.offerType, OfferType)}/>
                <FunctionField
                  label="Status"
                  sortBy="orderStatus"
                  render={(record: Order) => enumChipRenderer(record.orderStatus, OrderStatus, orderStatusChipColors)}/>
                <FunctionField
                  label="Cancel Reason"
                  source="cancelReason"
                  render={(record: Order) =>
                    record.cancelReason
                      ? enumChipRenderer(record.cancelReason, OrderCancelReason, cancelReasonChipColors)
                      : null}/>
                <FunctionField
                  label="Completion details"
                  render={(record: Order) => orderCompletionDetailsRenderer(record)}/>
                <ReferenceField
                  label="Created by"
                  reference={Resources.Users}
                  source="createdBy"
                  sortable={false}
                  link={false}>
                  <FunctionField render={(user: User) => usernameRenderer(user)}/>
                </ReferenceField>
                <DateField
                  source="timeCreated"
                  label="Creation date"
                  showTime/>
                <DateField
                  source="timeCompleted"
                  label="Completion date"
                  sortable={false}
                  showTime/>
              </DatagridConfigurable>
            </ListContextProvider>
        }
      </Grid>
    </Grid>
  );
};
