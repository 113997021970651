import {
  baseMultilineTextInputFilter
} from "../../components/filter/base-multiline-text-input-filter";
import { enumSelectFilter } from "../../components/filter/enum-select-filter";
import { buildLabel, CustomFilterIcon } from "../../components/filter/filter-utils";
import { StockItemStatus } from "../../components/stock-item/types";
import { StockItemType } from "../../components/stock/types";

export const filters = [
  enumSelectFilter({
    source: "statuses",
    enumObj: StockItemStatus,
    label: buildLabel({ label: "Status", Icon: CustomFilterIcon }),
    alwaysOn: true
  }),
  enumSelectFilter({
    source: "types",
    enumObj: StockItemType,
    label: buildLabel({ label: "Types", Icon: CustomFilterIcon }),
    alwaysOn: true
  }),
  baseMultilineTextInputFilter({
    source: "name",
    label: buildLabel({ label: "Name", Icon: CustomFilterIcon })
  })

];