import React, { useState } from "react";

import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Tooltip,
  Typography
} from "@mui/material";
import { useDataProvider, useNotify } from "react-admin";
import * as yup from "yup";

import { ProcessingButton } from "../../../components/button/processing/ProcessingButton";
import { FormSelect } from "../../../components/form/input/select/FormSelect";
import { useForm } from "../../../components/form/use-form";
import { RepossessionReason } from "../../../utils/commons";
import { Contract } from "../types";

type CancelContractProps = {
  contract: Contract,
  disabled: boolean,
  onContractChange: (contractId: number) => void
};

type CancelContractFormState = {
  reason: RepossessionReason | ""
};

const initialFormState: CancelContractFormState = {
  reason: ""
};
const validationSchema = yup.object({
  reason: yup.string().required()
});
const repossessionReasons = Object.entries(RepossessionReason).map(([key, value]) => {
  return { value: key, label: value };
})
  .sort((a, b) => a.label.localeCompare(b.label));

export const CancelContractButton: React.FC<CancelContractProps> = ({
  contract,
  disabled,
  onContractChange
}) => {
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const [isCancelling, setIsCancelling] = useState(false);
  const form = useForm<CancelContractFormState>(initialFormState, validationSchema);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const handleCancel = () => {
    setIsFormOpen(false);
    form.reset();
  };

  const handleConfirm = () => {
    setIsCancelling(true);
    dataProvider.updateManyByUrlWithBody(`contracts/${contract.id}/cancel`, {
      reason: form.value.reason
    }).then((response: any) => {
      notify("Contract has been successfully cancelled");
      onContractChange(contract.id);
      handleCancel();
      return response;
    }).catch(() => {
      notify("Error: failed to cancel contract", { type: "error" });
    }).finally(() => setIsCancelling(false));
  };
  return (
    <>
      <Tooltip title="Cancel contract: Disabled for contracts that have payments exceeding the down payment">
        <span>
          <Button
            color="primary"
            variant="text"
            disabled={disabled}
            onClick={() => setIsFormOpen(true)}
            startIcon={<RemoveCircleIcon/>}>
            Cancel
          </Button>
        </span>
      </Tooltip>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={isFormOpen}
        onClose={handleCancel}
      >
        <DialogTitle>Cancel</DialogTitle>
        <DialogContent>
          <DialogContent sx={{ mx: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="body1" align="justify">
                  <>
                      You are trying to cancel Contract {contract.id}.
                  </>
                </Typography>
                <Typography variant="body1" align="justify">
                  <>
                    All products associated with this contract will be repossessed,
                    the contract order will be canceled, and the contract will be marked as &quot;Cancelled&quot;.
                  </>
                </Typography>
              </Grid>
              <Grid item xs={12} sx={{ mt: 2 }}>
                <FormSelect
                  name="reason"
                  label="Cancelation Reason *"
                  form={form}
                  menuItems={repossessionReasons}/>
              </Grid>
            </Grid>
          </DialogContent>

          <DialogActions>
            <Button onClick={handleCancel}>
              Cancel
            </Button>
            <ProcessingButton
              onClick={handleConfirm}
              disabled={!form.isValid}
              isProcessing={isCancelling}
            />
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
};