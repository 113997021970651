import React from "react";

import { InputAdornment, TextField, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";

import { FormTextField } from "../../../../components/form/input/text-field/FormTextField";
import { FormProps } from "../../../../components/form/use-form";
import { DeliveryDetails, DeliveryOrderItem } from "./common";

type Props = {
  form: FormProps<DeliveryDetails>
};

export const OrderDetailsSection: React.FC<Props> = ({
  form
}) => {
  const totalPrice = form.value.orderItems.reduce((total: number, orderItem: DeliveryOrderItem) => {
    return total + ((orderItem.unitPrice || 0) * (orderItem.quantity || 0));
  }, 0);

  return (
    <>
      <Typography sx={{ mb: 2, fontWeight: 600 }}>Order details:</Typography>
      <Grid container spacing={2}>
        {
          form.value.orderItems.map((orderItem: DeliveryOrderItem, index: number) => (
            <Grid item xs={12} key={index}>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <FormTextField
                    name={`orderItems[${index}].amount`}
                    label="Pellets amount"
                    type="number"
                    required
                    disabled
                    InputProps={{
                      endAdornment: <InputAdornment position="start">kg</InputAdornment>
                    }}
                    InputLabelProps={{ shrink: true }}
                    form={form}/>
                </Grid>
                <Grid item xs={4}>
                  <FormTextField
                    name={`orderItems[${index}].quantity`}
                    label="Quantity"
                    type="number"
                    required
                    disabled
                    form={form}/>
                </Grid>
                <Grid item xs={4}>
                  <FormTextField
                    name={`orderItems[${index}].unitPrice`}
                    label="Price per unit"
                    type="number"
                    required
                    disabled
                    form={form}/>
                </Grid>
              </Grid>
            </Grid>
          ))
        }
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={8}></Grid>
            <Grid item xs={4}>
              <TextField
                label="Total price"
                variant="outlined"
                disabled
                fullWidth
                value={totalPrice}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ mt: 1 }}>
        <Grid item xs={12}>
          <FormTextField
            name="deliveryInstructions"
            label="Delivery Instructions"
            multiline
            rows={3}
            form={form}/>
        </Grid>
      </Grid>
    </>
  );
};
