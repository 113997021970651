import * as React from "react";

import { Box, CardContent, Typography } from "@mui/material";
import { useNotify, useSafeSetState } from "ra-core";
import { useLogin, TextInput, useRefresh } from "react-admin";
import { FieldValues } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";

import { ButtonWithLoader } from "./ButtonWithLoader";
import { AuthClasses, StyledForm } from "./types";
import { validateEmail } from "./validation";

export const LoginForm = () => {
  const [loading, setLoading] = useSafeSetState(false);
  const navigate = useNavigate();
  const notify = useNotify();
  const login = useLogin();
  const refresh = useRefresh();

  const submit = (formData: FieldValues) => {
    setLoading(true);
    login(formData)
      .then(() => {
        navigate("/");
        refresh();
        return true;
      })
      .catch(() => {
        setLoading(false);
        notify("Authentication failed, please retry", { type: "error" });
      });
  };

  return (
    <StyledForm
      onSubmit={submit}
      mode="onChange"
      noValidate
    >
      <CardContent className={AuthClasses.content}>
        <TextInput
          autoFocus
          source="username"
          label="Email"
          validate={validateEmail}
          fullWidth
        />
        <TextInput
          source="password"
          label="Password"
          type="password"
          fullWidth
        />
        <ButtonWithLoader loading={loading} buttonLabel="Sign IN"/>
        <Typography sx={{
          marginTop: "1em",
          textAlign: "center"
        }}>
          <Box component={Link} to="/reset-password" sx={{ textDecoration: "none", marginLeft: "0.5em" }}>
            Forgot Password?
          </Box>
        </Typography>

        <Typography sx={{
          marginTop: "1em",
          textAlign: "center"
        }}>
          Not a member?
          <Box component={Link} to="/signup" sx={{ textDecoration: "none", marginLeft: "0.5em" }}>
            Sign up now
          </Box>
        </Typography>
      </CardContent>
    </StyledForm>
  );
};
