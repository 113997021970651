import React, { useEffect, useState } from "react";

import { RaRecord } from "ra-core";
import { AutocompleteArrayInput } from "react-admin";

import { buildLabel, CustomFilterIcon } from "../filter/filter-utils";
import { useCollectionPartners } from "./use-collection-partners";

export const useCollectionPartnerFilter = (label = "Collections", source = "collectionPartnersIds") => {
  const { collectionPartners } = useCollectionPartners();
  const [ choices, setChoices ] = useState<RaRecord[]>([]);

  useEffect(() => {
    const choices = collectionPartners.map((collection) =>
      ({ id: collection.id, name: `${collection.code}, ${collection.name}` }))
      .sort((a, b) => a.name.localeCompare(b.name));
    setChoices(choices);
  }, [collectionPartners]);

  return <AutocompleteArrayInput key="collectionPartners"
    label={buildLabel({ label: label, Icon: CustomFilterIcon })}
    source={source}
    variant="filled"
    choices={choices} />;
};