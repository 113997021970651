import React from "react";

import { Datagrid, EditButton, FunctionField, List, TextField } from "react-admin";

import { countriesFilter } from "../../components/filter/customer-filters";
import { DefaultPagination } from "../../components/pagination/DefaultPagination";
import { UserRoles } from "../../core/providers/auth/roles";
import { CountryHavingObject } from "../../utils/commons";
import { countryNameRenderer, geolocationRenderer } from "../../utils/field-renderers";
import { useCheckAccess } from "../../utils/use-check-access";

export type DeliveryGroup = {
  id: number,
  name: string,
  groupLeaderName: string,
  address: string,
  phoneNumber: string,
  latitude: number,
  longitude: number
} & CountryHavingObject;

export const DeliveryGroupsList: React.FC = () => {
  const { hasAccess } = useCheckAccess([UserRoles.ROLE_SUPAMOTO_ADMIN, UserRoles.ROLE_DELIVERY_MANAGER]);

  const filters = [
    countriesFilter(true)
  ];

  return (
    <List filters={filters}
      pagination={<DefaultPagination/>}
      perPage={25}
      exporter={false}
      hasCreate={hasAccess}>
      <Datagrid bulkActionButtons={false}>
        <TextField source="name"/>
        <FunctionField label="Country" sortBy="country"
          render={(record: DeliveryGroup) => countryNameRenderer(record) || "-"}/>
        <TextField source="groupLeaderName" label="Group leader" sortable={false}/>
        <TextField source="address" sortable={false}/>
        <TextField source="phoneNumber" sortable={false}/>
        <FunctionField label="Geolocation"
          render={(record: DeliveryGroup) => geolocationRenderer(record)}/>
        {hasAccess && <EditButton/>}
      </Datagrid>
    </List>
  );
};
