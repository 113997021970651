import React from "react";

import { CardContent } from "@mui/material";
import { useNotify, useSafeSetState } from "ra-core";
import { TextInput, useDataProvider } from "react-admin";
import { FieldValues } from "react-hook-form";

import { ButtonWithLoader } from "./ButtonWithLoader";
import { AuthClasses, StyledForm } from "./types";
import { validateEmail } from "./validation";

type ResetPasswordFormProps = {
  onSuccess: () => void
};
export const ResetPasswordForm: React.FC<ResetPasswordFormProps> = ({ onSuccess }) => {
  const [loading, setLoading] = useSafeSetState(false);
  const dataProvider = useDataProvider();
  const notify = useNotify();

  const submit = (formData: FieldValues) => {
    setLoading(true);

    dataProvider.resetPassword(formData.email)
      .then(() => {
        onSuccess();
        return true;
      })
      .catch(() => {
        notify("Failed to send email, please retry", { type: "error" });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <StyledForm onSubmit={submit} mode="onChange" noValidate>
      <CardContent className={AuthClasses.content}>
        <TextInput
          autoFocus
          source="email"
          label="Email"
          validate={validateEmail}
          fullWidth
        />
        <ButtonWithLoader loading={loading} buttonLabel="Send email"/>
      </CardContent>
    </StyledForm>
  );
};
