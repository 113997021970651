import {
  baseMultilineTextInputFilter
} from "../../components/filter/base-multiline-text-input-filter";
import { enumSelectFilter } from "../../components/filter/enum-select-filter";
import { buildLabel, CustomFilterIcon } from "../../components/filter/filter-utils";
import { useDateRangeFilters } from "../../components/filter/use-date-range-filters";
import { useNumberRangeFilters } from "../../components/filter/use-number-range-filters";
import { useNumberList } from "../companies/use-number-list";
import { ContractPaymentStatus, ContractStatus, ContractType } from "./types";

export const useContractsFilters = () => {
  const { textToArray, arrayToText } = useNumberList();
  return [
    baseMultilineTextInputFilter({
      source: "ids",
      label: buildLabel({ label: "Contract IDs", Icon: CustomFilterIcon }),
      parse: textToArray,
      format: arrayToText
    }),
    enumSelectFilter({
      source: "contractTypes",
      enumObj: ContractType,
      label: buildLabel({ label: "Type", Icon: CustomFilterIcon }),
      alwaysOn: true
    }),
    enumSelectFilter({
      source: "contractStatuses",
      enumObj: ContractStatus,
      label: buildLabel({ label: "Contract Status", Icon: CustomFilterIcon })
    }),
    enumSelectFilter({
      source: "contractPaymentStatuses",
      enumObj: ContractPaymentStatus,
      label: buildLabel({ label: "Contract Payment Status", Icon: CustomFilterIcon })
    }),
    baseMultilineTextInputFilter({
      source: "totalAmountFrom",
      label: buildLabel({ label: "Total Amount (>=)", Icon: CustomFilterIcon })
    }),
    baseMultilineTextInputFilter({
      source: "totalAmountTo",
      label: buildLabel({ label: "Total Amount (<=)", Icon: CustomFilterIcon })
    }),
    baseMultilineTextInputFilter({
      source: "paymentDueFrom",
      label: buildLabel({ label: "Payment Due (>=)", Icon: CustomFilterIcon })
    }),
    baseMultilineTextInputFilter({
      source: "paymentDueTo",
      label: buildLabel({ label: "Payment Due (<=)", Icon: CustomFilterIcon })
    }),
    ...useNumberRangeFilters (
      "durationFrom",
      "Duration (>=)",
      "durationTo",
      "Duration (<=)"
    ),
    ...useDateRangeFilters({
      lowerBoundSource: "effectiveDateFrom",
      lowerBoundLabel: "Effective Date At (>=)",
      upperBoundSource: "effectiveDateTo",
      upperBoundLabel: "Effective Date At (<=)"
    }),
    ...useDateRangeFilters({
      lowerBoundSource: "timeCratedFrom",
      lowerBoundLabel: "Created At (>=)",
      upperBoundSource: "timeCratedTo",
      upperBoundLabel: "Created At (<=)"
    })
  ];

};