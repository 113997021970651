import * as React from "react";

import { Create, useCreate } from "react-admin";

import { DiscountsForm, OperationType } from "./DiscountsForm";

export const DiscountsCreate: React.FC = () => {
  const [create] = useCreate();
  return (
    <Create redirect="list">
      <DiscountsForm operationType={OperationType.CREATE} submit={create}/>
    </Create>
  );
};