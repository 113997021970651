import React from "react";

import { NumberInput } from "react-admin";

import { buildLabel, CustomFilterIcon } from "./filter-utils";

export const useNumberRangeFilters = (
  lowerBoundSource: string,
  lowerBoundLabel: string,
  upperBoundSource: string,
  upperBoundLabel: string,
  alwaysOn = false
) => {
  return [
    <NumberInput key={lowerBoundSource}
      label={buildLabel({ label: lowerBoundLabel, Icon: CustomFilterIcon })}
      variant="filled"
      source={lowerBoundSource}
      alwaysOn={alwaysOn}/>,
    <NumberInput key={upperBoundSource}
      label={buildLabel({ label: upperBoundLabel, Icon: CustomFilterIcon })}
      variant="filled"
      source={upperBoundSource}
      alwaysOn={alwaysOn}/>
  ];
};
