import * as React from "react";
import { useEffect, useState } from "react";

import { Box, Typography } from "@mui/material";
import { isNil } from "lodash-es";
import { useDataProvider, useNotify, useShowController } from "react-admin";
import { Chrono } from "react-chrono";

import { CollectionPartner } from "../../../components/collections/types";
import { OrderDetails } from "../../../components/order/OrderDetails";
import { User } from "../../../components/user/types";
import { Resources } from "../../../resources";
import { enumRenderer, usernameRenderer } from "../../../utils/field-renderers";
import {
  AnyOrderHistory,
  CanceledOrderHistory,
  DeliveredOrderHistory,
  ModifyOrderHistory,
  Order, OrderCancelReason,
  OrderHistoryAction,
  SentForDeliveryOrderHistory
} from "../types";

type SentForDeliveryOrderEventProps = {
  shipdayOrderNo: number,
  collectionPartner?: CollectionPartner
};

type PickedUpOrderEventProps = {
  collectionPartner?: CollectionPartner
};

const CancelledEvent: React.FC<CanceledOrderHistory> = ({ payload }) => (
  <Typography variant="body1">
      Reason: {!isNil(payload.reason) ? enumRenderer(payload.reason, OrderCancelReason) : "Unknown"}
  </Typography>
);

const SentForDeliveryEvent: React.FC<SentForDeliveryOrderEventProps> = ({ shipdayOrderNo, collectionPartner }) => {
  return (
    <>
      <Typography variant="body1">Shipday Order No: {shipdayOrderNo}</Typography>
      <Typography variant="body1">
        Collection Partner: {collectionPartner ? `${collectionPartner.code}, ${collectionPartner.name}` : "Unknown"}
      </Typography>
    </>
  );
};

const PickedUpyEvent: React.FC<PickedUpOrderEventProps> = ({ collectionPartner }) => {
  return (
    <>
      <Typography variant="body1">
        Collection Partner: {collectionPartner ? `${collectionPartner.code}, ${collectionPartner.name}` : "Unknown"}
      </Typography>
    </>
  );
};
const DeliveredEvent: React.FC<DeliveredOrderHistory> = ({ payload }) => (
  <>
    <Typography variant="body1">Driver name: {payload.driverName}</Typography>
  </>
);
const UpdateCreateEvent: React.FC<ModifyOrderHistory> = ({ payload }) => (
  <>
    <Typography variant="body1">Transaction ID: {payload.transactionId}</Typography>
    <Box height="20px" />
    <OrderDetails
      contract={payload.contract}
      orderLines={payload.orderLines}
      currency={payload.currency}
      orderAmount={payload.paidAmount}
    />
  </>
);

const action = (item: any) => {
  return {
    id: item.id,
    eventTime: item.eventTime,
    action: item.action,
    contributedBy: item.contributedBy,
    ...item
  };
};
export const OrderHistoryTab: React.FC = () => {
  const { record: order } = useShowController<Order>();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const [orderHistories, setOrderHistories] =
      useState<(AnyOrderHistory)[]>([]);
  const [users, setUsers] = useState<User[]>([]);
  const [collectionPartners, setCollectionPartners] = useState<CollectionPartner[]>([]);
  const [isHistoriesLoading, setHistoriesLoading] = useState(false);
  const [isCollectionPartnersLoading, setCollectionPartnersLoading] = useState(false);
  const [isUsersLoading, setUsersLoading] = useState(false);

  useEffect(() => {
    if (!order) return;

    setHistoriesLoading(true);
    dataProvider.getManyByUrl(`orders/${order.id}/histories`)
      .then((response: { data: (AnyOrderHistory)[] }) => {
        const formattedData = response.data.map(action);
        setOrderHistories(formattedData);
        setHistoriesLoading(false);
        return response;
      })
      .catch(() => {
        setHistoriesLoading(false);
      });

  }, [order, dataProvider, notify]);

  useEffect(() => {
    if (!orderHistories.length) return;
    setUsersLoading(true);
    const userIds = Array.from(new Set(orderHistories.map((item) => item.contributedBy)));
    dataProvider.getMany<User>(Resources.Users, { ids: userIds })
      .then((response) => {
        setUsers(response.data);
        setUsersLoading(false);
        return response;
      }).catch(() => {
        setUsersLoading(false);
        notify("Error: failed to retrieve users", { type: "error" });
      });

    const collectionPartnerIds = Array.from(new Set(orderHistories.map((item) =>
      (item as any).payload.collectionPartnerId)));
    setCollectionPartnersLoading(true);
    dataProvider.getMany("collection-partners", { ids: collectionPartnerIds })
      .then((response: { data: CollectionPartner[] }) => {
        setCollectionPartners(response.data);
        setCollectionPartnersLoading(false);
        return response;
      }).catch(() => {
        setCollectionPartnersLoading(false);
        notify("Error: failed to retrieve collection partners", { type: "error" });
      });
  }, [orderHistories, dataProvider, notify]);

  if (!order || isHistoriesLoading || isUsersLoading || isCollectionPartnersLoading) return (<></>);

  return (
    <Chrono
      mode="VERTICAL"
      disableToolbar="true"
      showNavigation={false}
      theme={{
        primary: "gray",
        secondary: "white",
        cardBgColor: "white",
        titleColor: "black",
        titleColorActive: "secondary"
      }}
      items={orderHistories.map((item) => {
        const user = users.find((user) => user.id === item.contributedBy);
        const collectionPartner = collectionPartners.find((cp) =>
          cp.id === (item as any).payload.collectionPartnerId);
        return {
          title: `${OrderHistoryAction[item.action]} by ${user && usernameRenderer(user)} 
          ${new Date(item.eventTime).toLocaleString()}`,
          cardDetailedText: (
            <>
              {item.action === "SENT_FOR_DELIVERY" &&
                <SentForDeliveryEvent shipdayOrderNo={(item as SentForDeliveryOrderHistory).payload.shipdayOrderNo}
                  collectionPartner={collectionPartner}/>}
              {item.action === "PICKED_UP" &&
                      <PickedUpyEvent collectionPartner={collectionPartner}/>}
              {item.action === "DELIVERED" &&
                      <DeliveredEvent {...(item as DeliveredOrderHistory)} />}
              {item.action === "CANCELED" &&
                      <CancelledEvent {...(item as CanceledOrderHistory)} />}
              {(item.action === "UPDATED" ||
                            item.action === "CREATED")
                        && <UpdateCreateEvent {...(item as ModifyOrderHistory)} />}
            </>
          )
        };
      })}
    />
  );
};