import React, { useEffect, useState } from "react";

import AddIcon from "@mui/icons-material/Add";
import { Button, InputAdornment } from "@mui/material";
import Grid from "@mui/material/Grid";
import { isEmpty } from "lodash-es";

import { ContractLineItem } from "../../../components/contract/ContractLineItem";
import { FormTextField } from "../../../components/form/input/text-field/FormTextField";
import { useCustomerEligibleOffers } from "../../../components/offer/use-customer-eligible-offers";
import { CountryCurrency, Device } from "../../../utils/commons";
import { useCreateContract } from "../CreateContractProvider";
import { productsAndDiscountsValidationSchema } from "../state";
import { ContractLine, ContractType } from "../types";
import { calcTotalAmount } from "../utils";
import { CreateContractStepActions } from "./CreateContractStepActions";

export const ContractProductsAndDiscountsStep: React.FC = () => {
  const { form, customer } = useCreateContract();
  const contractType = form.value.contractType;
  const [selectedDevice, setSelectedDevice] = useState<Device>();
  const { offers, isLoading: isOffersLoading } = useCustomerEligibleOffers(customer?.id, ["ACCESSORY", "STOVE"]);

  useEffect(() => {
    if (!form.value.fixedTotalAmount && contractType !== ContractType.UTILITY) {
      const total = calcTotalAmount(form.value.details);
      if (total !== form.value.totalAmount) {
        form.setValueByLabel("totalAmount", total);
      }
    }
  }, [form, form.value, contractType]);

  const handleTotalAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    form.setValueByLabel("totalAmount", Number(value));
    form.setValueByLabel("fixedTotalAmount", !isEmpty(value));
  };

  const isStepDataValid = () => {
    const stoveForUtilityContractIsSelected = contractType === ContractType.UTILITY ?
      form.value.details.some((detail) => detail.instanceId) :
      true;
    const isStoveOfferSelected = form.value.details.some((detail) => detail.instanceId);

    return productsAndDiscountsValidationSchema.isValidSync(form.value) &&
        stoveForUtilityContractIsSelected &&
        (isStoveOfferSelected ?
          !selectedDevice?.stoves?.find((stove) => stove.status == "ACTIVE" && stove.customer) :
          true);
  };

  return (
    <Grid item xs={12}>
      <Grid container spacing={2}>
        {
          form.value.details.map((line: ContractLine, index: number) => {
            return (
              <ContractLineItem
                key={index}
                index={index}
                offers={offers}
                selectedOffer={offers.find((offer) => offer.id === line.offerId) }
                isOffersLoading={isOffersLoading}
                handleSelectDevice={(device) => setSelectedDevice(device)}
                contractType={contractType}
              />
            );
          })
        }
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={8}></Grid>
            <Grid item xs={2}>
              <FormTextField
                label="Total price"
                name="totalAmount"
                form={form}
                InputProps={{
                  inputProps: {
                    onChange: (e: React.ChangeEvent<HTMLInputElement>) => handleTotalAmountChange(e)
                  },
                  endAdornment: <InputAdornment position="end">{CountryCurrency[customer?.country || ""]}</InputAdornment>
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sx={{ display: "flex", justifyContent: "start", alignItems: "center" }}>
          <Button variant="outlined"
            disabled={form.value.details.length >= 10}
            onClick={() => {
              form.setValueByLabel(`details[${form.value.details.length}]`, { offerId: "" });
            }}
            endIcon={<AddIcon />}>
            Add line
          </Button>
        </Grid>
        <CreateContractStepActions isStepValid={isStepDataValid()}/>
      </Grid>
    </Grid>
  );
};