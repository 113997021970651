import * as React from "react";
import { useEffect, useState } from "react";

import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import { Button, Tooltip } from "@mui/material";
import { useCreate, useNotify, useRefresh } from "react-admin";
import * as yup from "yup";

import { useForm } from "../../components/form/use-form";
import { yupNumber } from "../../components/form/validation";
import { UserRoles } from "../../core/providers/auth/roles";
import { Resources } from "../../resources";
import { FullCustomer } from "../../utils/commons";
import { useCheckAccess } from "../../utils/use-check-access";
import { CreateAdjustmentDialog } from "./CreateAdjustmentDialog";
import { CreateAdjustmentForm } from "./types";
import useAdjustmentsCount from "./useAdjustmentsCount";

type Props = {
  variant?: "text" | "outlined" | "contained",
  predefinedCustomer?: FullCustomer
};

const adjustmentValidationSchema = yup.object({
  amount: yupNumber().required().label("Amount").positive(),
  description: yup.string().required().label("Description")
});

const initialState = { amount: 0, description: "" };

export const CreateAdjustmentButton: React.FC<Props> = ({
  variant,
  predefinedCustomer }) => {
  const [create, { isLoading: isProcessing }] = useCreate();
  const refresh = useRefresh();
  const notify = useNotify();
  const { fetchCount } = useAdjustmentsCount();

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const form = useForm<CreateAdjustmentForm>(initialState, adjustmentValidationSchema);
  const { hasAccess: hasAdjustmentCreateAccess } = useCheckAccess([
    UserRoles.ROLE_SUPAMOTO_ADMIN
  ]);
  
  useEffect(() => {
    if (predefinedCustomer) {
      form.setValueByLabel("customerId", predefinedCustomer.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDialogOpen, predefinedCustomer]);

  const handleCancel = () => {
    setIsDialogOpen(false);
    form.reset();
  };

  const handleConfirm = () => {
    return create(Resources.Adjustments,
      {
        data: {
          customerId: form.value.customerId,
          amount: form.value.amount,
          description: form.value.description
        } as CreateAdjustmentForm
      },
      {
        onSuccess: () => {
          refresh();
          notify("Adjustment has been successfully created");
          fetchCount();
          handleCancel();
        },
        onError: () => notify("Error: failed to create an adjustment", { type: "error", autoHideDuration: 5000 })
      });
  };

  return (
    <>
      <Tooltip title="Create adjustment">
        <span>
          <Button
            color="primary"
            disabled={!hasAdjustmentCreateAccess}
            variant={variant}
            onClick={() => setIsDialogOpen(true)}
            startIcon={<AttachMoneyIcon />}
          >
            Add Adjustment
          </Button>
        </span>
      </Tooltip>
      <CreateAdjustmentDialog
        predefinedCustomer={predefinedCustomer}
        onClose={handleCancel}
        onConfirm={handleConfirm}
        isOpened={isDialogOpen}
        isProcessing={isProcessing}
        form={form}
      />
    </>
  );
};