import React from "react";

import { Box } from "@mui/material";
import {
  DatagridConfigurable,
  DateField,
  FunctionField,
  List, NumberField,
  ReferenceField,
  TextField
} from "react-admin";

import { CollectionPartner } from "../../components/collections/types";
import { PresetListActions } from "../../components/list/actions/PresetListActions";
import { DefaultPagination } from "../../components/pagination/DefaultPagination";
import {
  formatCollectionName,
  StockEvent,
  StockEventAdjustmentReason,
  StockEventStatus,
  StockEventType
} from "../../components/stock-events/types";
import { User } from "../../components/user/types";
import { UserRoles } from "../../core/providers/auth/roles";
import { Resources } from "../../resources";
import { Customer } from "../../utils/commons";
import {
  countryNameRenderer,
  customerRenderer,
  EnumChipColors,
  enumChipRenderer,
  usernameRenderer
} from "../../utils/field-renderers";
import { useCheckAccess } from "../../utils/use-check-access";
import { usePageSafeSelector } from "../../utils/use-page-safe-selector";
import { CancelStockEventButton } from "./actions/CancelStockEventButton";
import { CreateStockEventButton } from "./actions/CreateStockEventButton";
import { MarkAsCompletedStockEventButton } from "./actions/MarkAsCompletedlStockEventButton";
import { useStockEventFilters } from "./filters";

type StockEventCollectionPartnerReferenceFieldProps = {
  label: string,
  source: string
};

const stockEventStatusChipColors: Record<keyof typeof StockEventStatus, EnumChipColors> = {
  IN_PROCESS: "info",
  COMPLETED: "success",
  CANCELED: "warning"
};

const adjustmentReasonChipColors: Record<keyof typeof StockEventAdjustmentReason, EnumChipColors> = {
  THEFT: "error",
  DAMAGE: "warning",
  REBATE: "info",
  REBAGGING: "info",
  DEMONSTRATION: "success",
  WRITE_OFF: "primary",
  INTERNAL_USE: "secondary",
  CANCELLATION: "warning",
  WRONG_EVENT: "warning",
  DOUBLE_EVENT: "warning",
  OTHER_CANCELLATION: "secondary",
  OTHER: "secondary"
};

const stockEventTypeChipColors: Record<keyof typeof StockEventType, EnumChipColors> = {
  ADD: "secondary",
  SELL: "success",
  TRANSFER_OUT: "info",
  TRANSFER_IN: "info",
  ADJUSTMENT_INCREASE: "warning",
  ADJUSTMENT_DECREASE: "warning",
  STOCK_COUNT: "secondary"
};

export const StockEventCollectionPartnerReferenceField: React.FC<StockEventCollectionPartnerReferenceFieldProps> = ({
  label, source
}) => {
  return (
    <ReferenceField
      label={label}
      reference={Resources.CollectionPartners}
      source={source}
      sortable={false}
      link={false}>
      <FunctionField render={(collection: CollectionPartner) => formatCollectionName(collection)}/>
    </ReferenceField>
  );
};

const StockEventsBulkActions = () => {
  const { hasAccess: hasStockManagementAccess, user } = useCheckAccess([
    UserRoles.ROLE_SUPAMOTO_ADMIN, UserRoles.ROLE_STOCK_MANAGER
  ]);

  const { data } = usePageSafeSelector<StockEvent>();
  const adjustmentReasonsNotAllowedForCancellation =
      ["CANCELLATION", "WRONG_EVENT", "DOUBLE_EVENT", "OTHER_CANCELLATION"];
  const allowedToCancel =
      data.every((stockEvent: StockEvent) => stockEvent.status !== "CANCELED"
          && stockEvent.type !== "SELL"
          && stockEvent.type !== "TRANSFER_IN"
          && !adjustmentReasonsNotAllowedForCancellation.includes(stockEvent.adjustmentReason));
  const allowedToComplete =
      data.every((stockEvent: StockEvent) =>
        stockEvent.status === "IN_PROCESS"
          && stockEvent.type === "TRANSFER_IN"
          && stockEvent.createdBy !== (user?.id as number));

  return (
    <>
      <CancelStockEventButton
        disabled={!hasStockManagementAccess || !allowedToCancel}
      />
      <MarkAsCompletedStockEventButton
        disabled={!hasStockManagementAccess || !allowedToComplete}/>
    </>
  );
};

export const StockEventsList: React.FC = () => {
  const { hasAccess } = useCheckAccess([UserRoles.ROLE_SUPAMOTO_ADMIN, UserRoles.ROLE_STOCK_MANAGER]);

  return (
    <List filters={useStockEventFilters()}
      pagination={<DefaultPagination/>}
      perPage={25}
      exporter={false}
      empty={<CreateStockEventButton/>}
      actions={
        <PresetListActions
          selectColumnsEnabled
          additionalExportRoles={[UserRoles.ROLE_STOCK_MANAGER]}
        >
          {hasAccess ? <CreateStockEventButton/> : <Box/>}
        </PresetListActions>
      }
      hasCreate={false}
      sort={{ field: "effectiveAt", order: "DESC" }}>
      <DatagridConfigurable bulkActionButtons={<StockEventsBulkActions/>}
        omit={["balanceBefore", "balanceAfter",
          "orderId", "customerId", "driverName", "status", "originStockEventId", "country", "updatedNote", "updatedBy"]}>
        <TextField source="id" label="ID"/>
        <StockEventCollectionPartnerReferenceField label="Collection" source="collectionPartnerId"/>
        <StockEventCollectionPartnerReferenceField label="Related Collection" source="relatedCollectionPartnerId"/>
        <FunctionField
          source="country"
          sortable={false}
          render={() =>
            <ReferenceField
              label="Country"
              reference={Resources.CollectionPartners}
              source="collectionPartnerId"
              link={false}
            >
              <FunctionField
                render={(collectionPartner: CollectionPartner) => countryNameRenderer(collectionPartner)} />
            </ReferenceField>
          }
        />
        <TextField source="itemName" label="Item Name" sortable={false}/>
        <NumberField source="quantity" label="Quantity" sortable={false}/>
        <NumberField source="balanceBefore" label="Balance Before" sortable={false}/>
        <NumberField source="balanceAfter" label="Balance After" sortable={false}/>
        <FunctionField
          label="Type"
          sortable={false}
          render={(event: StockEvent) => enumChipRenderer(event.type, StockEventType, stockEventTypeChipColors)}/>
        <FunctionField
          label="Status"
          sortable={false}
          render={(event: StockEvent) => enumChipRenderer(event.status, StockEventStatus, stockEventStatusChipColors)}/>
        <FunctionField
          label="Adjustment reason"
          sortable={false}
          render={(event: StockEvent) =>
            event.adjustmentReason
              ? enumChipRenderer(event.adjustmentReason, StockEventAdjustmentReason, adjustmentReasonChipColors)
              : null}/>
        <TextField source="note" label="Notes" sortable={false}/>
        <TextField source="updatedNote" label="Updated Notes" sortable={false}/>
        <TextField source="orderId" label="Order ID" sortable={false}/>
        <ReferenceField
          label="Customer"
          reference={Resources.Customers}
          source="customerId"
          link={false}
          sortable={false}>
          <FunctionField render={(customer: Customer) => customerRenderer(customer)}/>
        </ReferenceField>
        <TextField source="originStockEventId" label="Origin Event ID" sortable={false}/>
        <ReferenceField
          label="Updated by"
          reference={Resources.Users}
          source="updatedBy"
          sortable={false}
          link={false}>
          <FunctionField render={(user: User) => usernameRenderer(user)}/>
        </ReferenceField>
        <ReferenceField
          label="Created by"
          reference={Resources.Users}
          source="createdBy"
          sortable={false}
          link={false}>
          <FunctionField render={(user: User) => usernameRenderer(user)}/>
        </ReferenceField>
        <TextField source="driverName" label="Driver Name" sortable={false}/>
        <DateField
          source="createdAt"
          label="Created at"
          showTime/>
        <DateField
          source="effectiveAt"
          label="Effective at"
          showTime/>
      </DatagridConfigurable>
    </List>
  );
};