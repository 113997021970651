import { AuthProvider, DataProvider, UserIdentity } from "react-admin";

import authTokenStorage from "./auth-token-storage";

type LoginData = {
  username: string,
  password: string
};

export default (dataProvider: DataProvider): AuthProvider => {
  return {
    login: ({ username, password }: LoginData): Promise<any> => {
      return dataProvider.signIn(username, password)
        .then((data: any) => authTokenStorage.save(data));
    },
    logout: (): Promise<void | false | string> => {
      authTokenStorage.invalidate();
      return Promise.resolve();
    },
    checkAuth: (): Promise<void> => {
      const paths = ["/signup", "/set-password", "/reset-password"];
      if (paths.includes(window.location.pathname)) {
        return Promise.resolve();
      }

      const isLoggedIn = !!authTokenStorage.get();
      return isLoggedIn
        ? Promise.resolve()
        : Promise.reject();
    },
    checkError: (error: any): Promise<void> => {
      const status = error.status;
      if (status === 401 || status === 403) {
        return Promise.reject();
      }

      return Promise.resolve();
    },
    getPermissions: (): Promise<any> => {
      const user = authTokenStorage.get();
      return user
        ? Promise.resolve(user.roles)
        : Promise.resolve([]);
    },
    getIdentity: (): Promise<UserIdentity> => {
      const user = authTokenStorage.get();
      return user
        ? Promise.resolve({ fullName: user.email, ...user })
        : Promise.reject();
    }
  };
};
