import React from "react";

import { Box, Button, CircularProgress } from "@mui/material";

type Props = {
  onClick: () => void,
  disabled: boolean,
  isProcessing: boolean,
  content?: React.ReactNode,
  clickedContent?: React.ReactNode
};

export const ProcessingButton: React.FC<Props> = ({
  onClick,
  disabled,
  isProcessing,
  content = "Confirm",
  clickedContent = "Processing"
}) => {
  return (
    <Button
      onClick={onClick}
      disabled={disabled || isProcessing}
    >
      {isProcessing ?
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <CircularProgress color="secondary" size={20}/>{clickedContent}
        </Box>
        : content
      }
    </Button>
  );
};