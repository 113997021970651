import { useEffect } from "react";

import { isNil } from "lodash-es";
import { GetListParams, useDataProvider, useStore } from "react-admin";

import { UserRoles } from "../../../core/providers/auth/roles";
import { Resources } from "../../../resources";
import { useCheckAccess } from "../../../utils/use-check-access";
import { StoveCollection } from "./types";

export const useStoveCollections = () => {
  const dataProvider = useDataProvider();
  const [ stoveCollections, setStoveCollections ] = useStore<StoveCollection[]>("api.stove-collections", undefined);
  const { hasAccess: isAdmin } = useCheckAccess(UserRoles.ROLE_SUPAMOTO_ADMIN);
  useEffect(() => {
    if (isNil(stoveCollections) && isAdmin) {
      const params = { pagination: { page: 1, perPage: 500 } } as GetListParams;
      dataProvider.getList<StoveCollection>(Resources.StoveCollections, params)
        .then((response) => {
          setStoveCollections(response.data);
          return response;
        });
    }
  }, [dataProvider, stoveCollections, setStoveCollections, isAdmin]);
  return { stoveCollections: isNil(stoveCollections) ? [] : stoveCollections };
};