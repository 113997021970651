import React, { useCallback, useEffect, useState } from "react";

import { cloneDeep, get, isEqual, omitBy, set } from "lodash-es";
import { BaseSchema } from "yup";

import { validate } from "./validation";

const removeByIndex = (prev: any, label: string, index: number) => {
  const object = cloneDeep(prev);
  get(object, label)?.splice(index, 1);
  return object;
};

export type BlueEvent = React.FocusEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>;
export type ChangeEvent = React.ChangeEvent<HTMLInputElement> | any;

export type FormProps<T> = {
  value: T,
  error: any,
  isValid: boolean,
  validateForm: () => void,
  reset: () => void,
  handleChange: (e: ChangeEvent) => any,
  handleBlurValidate: (e: BlueEvent) => void,
  setValueByLabel: (label: string, value: any) => void,
  removeArrayElement: (label: string, index: number) => void
};

export function useForm<T extends object>(initialState: T, validationSchema: BaseSchema, preValidate = false):
  FormProps<T> {
  const [value, setValue] = useState<T>(cloneDeep(initialState));
  const [error, setError] = useState<any>({});
  const [isValid, setIsValid] = useState<boolean>(false);
  const [initialValue, setInitialValue] = useState<typeof initialState>();

  const setValueByLabel = useCallback((label: string, value: any) => {
    setValue((prev: T) => set(cloneDeep(prev), label, value));
    setError((prev: any) => set(cloneDeep(prev), label, undefined));
  }, []);

  const reset = useCallback(() => {
    setIsValid(false);
    setError({});
    setValue(initialState);
  }, [initialState]);

  const removeArrayElement = useCallback((label: string, index: number) => {
    setError((prev: any) => {
      return omitBy(prev, (value, key) => key.startsWith(`${label}[${index}]`));
    });
    setValue((prev: typeof initialState) => removeByIndex(prev, label, index));
  }, []);

  const handleChange = useCallback((e: ChangeEvent) => {
    const { value: inputValue, name } = e.target;

    setValueByLabel(name, inputValue);
    return inputValue;
  }, [setValueByLabel]);

  const handleBlurValidate = useCallback((e: BlueEvent) => {
    const { name, dataset } = e.target;

    const validationError = validate({
      schema: validationSchema,
      data: value,
      path: name,
      dependencies: dataset?.dependencies?.split(",")
    });
    if (validationError) {
      setError((prev: any) => {
        const newError = cloneDeep(prev);
        Object.keys(validationError).forEach((path) => set(newError, path, validationError[path]));
        return newError;
      });
    }
  }, [value, validationSchema]);

  const validateForm = useCallback(() => {
    const validationError = validate({ schema: validationSchema, data: value });
    if (validationError) {
      setError(validationError);
    }
  }, [value, validationSchema]);

  useEffect(() => {
    if (value) {
      setIsValid(validationSchema.isValidSync(value));
    }
  }, [value, validationSchema]);

  useEffect(() => {
    preValidate && validateForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preValidate]);

  useEffect(() => {
    if (initialState && !isEqual(initialValue, initialState)) {
      setInitialValue(initialState);
      setValue(initialState);
    }
  }, [initialState, initialValue]);

  return {
    value,
    error,
    isValid,
    validateForm,
    reset,
    handleChange,
    handleBlurValidate,
    setValueByLabel,
    removeArrayElement
  };
}
