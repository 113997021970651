import React, { ReactElement } from "react";

import AdjustIcon from "@mui/icons-material/Adjust";
import { Box } from "@mui/material";

type Props = {
  label: string | ReactElement,
  Icon: React.FC<any>
};

export const buildLabel = ({ label, Icon }:Props) => {
  return (
    <Box sx={{
      display: "flex",
      alignItems: "center",
      gap: 1
    }}>
      <Icon/>
      {label}
    </Box>
  );
};

export const CustomFilterIcon = AdjustIcon;
