import React, { useEffect, useState } from "react";

import HelpIcon from "@mui/icons-material/Help";
import {
  Box,
  Button, CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Tooltip,
  Typography
} from "@mui/material";
import Alert from "@mui/material/Alert";
import Grid from "@mui/material/Grid";
import { isNil } from "lodash-es";
import {
  useDataProvider,
  useListContext,
  useNotify,
  useRefresh,
  useUnselect,
  useUnselectAll,
  useUpdateMany
} from "react-admin";
import * as yup from "yup";

import { FormSelect, FormSelectMenuItem } from "../../../components/form/input/select/FormSelect";
import { FormTextField } from "../../../components/form/input/text-field/FormTextField";
import { useForm } from "../../../components/form/use-form";
import { HTTP_METHOD } from "../../../core/providers/data/rest-data-provider";
import { usePageSafeSelector } from "../../../utils/use-page-safe-selector";
import { ProfilePerformance, TicketCategory } from "../types";

type Props = {
  isDialogOpen: boolean,
  onDialogClose: () => void
};

type FormState = {
  subject?: string,
  assignmentGroup?: string,
  agentId?: string,
  category?: TicketCategory,
  type: string
};

type Agent = {
  id: number,
  name: string,
  jobTitle: string
};

type AssignmentGroup = {
  name: string,
  description: string,
  agents: Agent[]
};

const initialFormState: FormState = {
  subject: "",
  assignmentGroup: "",
  agentId: "",
  type: ""
};
const validationSchema = yup.object({
  subject: yup.string().required().min(5).max(100).label("Subject"),
  assignmentGroup: yup.string().required().label("Assignment Group"),
  type: yup.string().required().label("Type")
});

const categoryToSubjectMapping: { [key in TicketCategory]?: string } = {
  [TicketCategory.TOTAL_PAYMENT_IN_ARREARS]: "Payment in arrears (total)",
  [TicketCategory.LAST_PAYMENT_IN_ARREARS]: "Payment in arrears (last)",
  [TicketCategory.INSUFFICIENT_COOKING_ACTIVITY]: "Insufficient cooking activity",
  [TicketCategory.STOVE_CONNECTIVITY]: "Stove connectivity issue"
};

const toMenuItems = (response: { data: AssignmentGroup[] }) => {
  return response.data?.map((group) => (
    {
      label: <Box sx={{ display: "flex", alignItems: "center" }}>
        {group.name}
        <Tooltip
          title={<Box>{group.description ? `${group.description}. ` : ""}Total agents: {group.agents.length}</Box>}>
          <HelpIcon color="secondary" fontSize="small" sx={{ ml: 1 }}/>
        </Tooltip>
      </Box>,
      value: group.name
    }
  ));
};

export const CreateTicketsAction: React.FC<Props> = ({ isDialogOpen, onDialogClose }) => {
  const dataProvider = useDataProvider();
  const { resource } = useListContext();
  const refresh = useRefresh();
  const notify = useNotify();
  const unselect = useUnselect(resource);
  const unselectAll = useUnselectAll(resource);
  const form = useForm<FormState>(initialFormState, validationSchema);
  const [defaultSubjectValue, setDefaultSubjectValue] = useState("");
  const [groups, setGroups] = useState<AssignmentGroup[]>([]);
  const [groupMenuItems, setGroupMenuItems] = useState<FormSelectMenuItem[] | undefined>();
  const [typeItems, setTypeItems] = useState<FormSelectMenuItem[] | undefined>();
  const [agentMenuItems, setAgentMenuItems] = useState<FormSelectMenuItem[]>([]);
  const { data: profilePerformances } = usePageSafeSelector<ProfilePerformance>();

  const defaultSubjectChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const category = (event.target as HTMLInputElement).value as TicketCategory;
    setDefaultSubjectValue(category);
    form.setValueByLabel("category", category);
    const subject = categoryToSubjectMapping[category];
    form.setValueByLabel("subject", subject);
  };

  const [updateMany] = useUpdateMany(
    `${resource}/tickets`,
    {
      ids: profilePerformances.map((performance) => performance.id),
      data: {
        subject: form.value.subject,
        assignmentGroup: form.value.assignmentGroup,
        agentId: form.value.agentId,
        category: form.value.category || TicketCategory.OTHER,
        type: form.value.type
      },
      meta: { httpMethod: HTTP_METHOD.POST }
    },
    {
      onSuccess: () => {
        refresh();
        notify("Tickets have been created");
        unselectAll();
      },
      onError: () => notify("Error: failed to create tickets", { type: "error" })
    }
  );

  const handleCancel = () => {
    setDefaultSubjectValue("");
    form.reset();
    onDialogClose();
  };

  const handleConfirm = () => {
    updateMany();
    handleCancel();
  };

  useEffect(() => {
    dataProvider.getManyByUrl("profile-performance/tickets/groups")
      .then((response: { data: AssignmentGroup[] }) => {
        setGroups(response.data);
        setGroupMenuItems(toMenuItems(response));
        return response;
      })
      .catch(() => {
        setGroupMenuItems([]);
        notify("Error: failed load assignment groups", { type: "error" });
      });
  }, [dataProvider, notify]);

  useEffect(() => {
    dataProvider.getManyByUrl("profile-performance/tickets/types")
      .then((response: { data: string[] }) => {
        setTypeItems(response.data.map((item) => (
          {
            label: item,
            value: item
          }
        )));
        return response;
      })
      .catch(() => {
        setTypeItems([]);
        notify("Error: failed to load ticket types", { type: "error" });
      });
  }, [dataProvider, notify]);

  useEffect(() => {
    form.setValueByLabel("agentId", "");
    if (form.value.assignmentGroup) {
      const group = groups.find((group) => group.name === form.value.assignmentGroup);
      const groupAgents = group?.agents.map((agent) => ({ label: agent.name, value: agent.id }))
        .sort((a, b) => a.label.localeCompare(b.label)) ?? [];
      setAgentMenuItems(groupAgents);
    } else {
      setAgentMenuItems([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.value.assignmentGroup]);

  const selectedCategory = form.value.category;

  const performancesWithActiveTicketsInSelectedCategory = selectedCategory && selectedCategory !== TicketCategory.OTHER
    ? profilePerformances.filter((performance) =>
      performance.ticketStatistics.activeTickets.some((ticket) =>
        ticket.category === selectedCategory
      )
    )
    : [];
  const unselectCustomers = () => {
    unselect(performancesWithActiveTicketsInSelectedCategory.map((performance) => performance.id));
  };

  return (
    <Dialog open={isDialogOpen} onClose={handleCancel}>
      <DialogTitle>Create ticket</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ width: 400 }}>
          <FormControl>
            <FormLabel id="ticket-type">Select one of the default subjects:</FormLabel>
            <RadioGroup
              aria-labelledby="ticket-type"
              name="ticket-type-group"
              value={defaultSubjectValue}
              onChange={defaultSubjectChange}
            >
              {
                Object.entries(categoryToSubjectMapping).map(([category, subject], index) => (
                  <FormControlLabel
                    key={index}
                    value={category}
                    control={<Radio/>}
                    label={subject}/>
                ))
              }
            </RadioGroup>
          </FormControl>
          {
            performancesWithActiveTicketsInSelectedCategory.length > 0 && selectedCategory &&
              <Alert severity="warning">
                <Box>
                  Some of the customers you&apos;ve selected already have tickets assigned
                  to them for &apos;{categoryToSubjectMapping[selectedCategory]}&apos;.
                </Box>
                <Box>
                  <Button onClick={unselectCustomers}>
                      Unselect these customers
                  </Button>
                </Box>
              </Alert>
          }
          <Box sx={{ pt: 2 }}>
            <Typography variant="body1" sx={{ fontSize: 16, pb: 1 }}>Or specify a custom one:</Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormTextField
                  name="subject"
                  label="Subject"
                  required
                  form={form}/>
              </Grid>
              <Grid item xs={12}>
                {
                  isNil(typeItems) ?
                    <CircularProgress color="inherit" size={20}/> :
                    <FormSelect
                      name={"type"}
                      label="Type"
                      clearable={false}
                      form={form}
                      menuItems={typeItems}/>
                }
              </Grid>
              <Grid item xs={12}>
                {
                  isNil(groupMenuItems) ?
                    <CircularProgress color="inherit" size={20} /> :
                    <FormSelect
                      name={"assignmentGroup"}
                      label="Group"
                      clearable={false}
                      form={form}
                      menuItems={groupMenuItems}/>
                }
              </Grid>
              <Grid item xs={12}>
                <FormSelect
                  name={"agentId"}
                  label="Agent"
                  form={form}
                  disabled={!form.value.assignmentGroup}
                  menuItems={agentMenuItems}/>
              </Grid>
            </Grid>
          </Box>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>
          Cancel
        </Button>
        <Button
          disabled={!form.isValid || profilePerformances.length === 0}
          onClick={handleConfirm}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};
