import * as React from "react";
import { useState } from "react";

import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import { Box, Dialog, DialogContent, IconButton, Tooltip, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import Skeleton from "@mui/material/Skeleton";

type Props = {
  title: string,
  isLoading: boolean,
  chartType: "line" | "pie" | "bar",
  children: JSX.Element,
  additionalLevers?: React.ReactNode
};

const CHART_HEIGHT = 350;
const SKELETON_HEIGHT = CHART_HEIGHT - 50;

export const DashboardChartContainer: React.FC<Props> = ({
  title,
  isLoading,
  chartType,
  children,
  additionalLevers
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <Paper sx={{ py: 1, pr: 2 }} elevation={2}>
      <Box sx={{ pl: 2, display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Box>
          <Typography variant="body2" color="secondary">{title}</Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {additionalLevers}
          <Tooltip title="Expand">
            <IconButton color="secondary"
              size="small"
              onClick={() => setIsExpanded(!isExpanded)}
            >
              <OpenInFullIcon fontSize="small"/>
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
      <Box sx={{ height: CHART_HEIGHT }}>
        {isLoading ?
          <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            {
              chartType === "pie" ?
                <Skeleton variant="circular" width={SKELETON_HEIGHT} height={SKELETON_HEIGHT} /> :
                <Skeleton variant="rounded" width="85%" height={SKELETON_HEIGHT} />
            }
            <Skeleton variant="text" width="50%" sx={{ mt: "20px" }}/>
          </Box> :
          children
        }
      </Box>
      <Dialog
        fullWidth={true}
        maxWidth={false}
        onClose={() => setIsExpanded(false)}
        open={isExpanded}>
        <DialogContent>
          <Box sx={{ display: "flex", flexDirection: "column", height: "85vh" }}>
            <Box sx={{ pl: 2, display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <Typography variant="body1" color="secondary">{title}</Typography>
              <Tooltip title="Shrink">
                <IconButton color="secondary"
                  size="small"
                  onClick={() => setIsExpanded(false)}
                >
                  <CloseFullscreenIcon fontSize="small"/>
                </IconButton>
              </Tooltip>
            </Box>
            {children}
          </Box>
        </DialogContent>
      </Dialog>
    </Paper>
  );
};