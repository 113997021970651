import { Discount } from "../../pages/discounts/types";
import { Offer } from "../../pages/offers/types";

export const calculatePrice = (offer: Offer, discount: Discount) => {
  let price = offer ? offer.price : 0;

  if (discount) {
    if (discount.discountType === "FIXED") {
      price -= discount.value;
    } else if (discount.discountType === "PERCENTAGE") {
      price -= Math.round(price * (discount.value / 100));
    }
  }

  return price;
};