import React from "react";

import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Box } from "@mui/material";

import { PROFILE } from "../../config/profile/profiles";
import { PRIMARY_COLOR, SECONDARY_COLOR } from "../../core/theme/theme";
import { Customer } from "../../utils/commons";

export const freshdeskContactUrlRenderer = (customer: Customer) => {
  if (customer.freshdeskId) {
    return (
      <a href={`${PROFILE.freshdeskUrl}/a/contacts/${customer.freshdeskId}`}
        style={{ color: PRIMARY_COLOR, textDecoration: "none" }}
        rel="noreferrer"
        target={"_blank"}
        onClick={(e: any) => e.stopPropagation()}>
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          #{customer.freshdeskId}
          <OpenInNewIcon sx={{ fontSize: "18px", fill: SECONDARY_COLOR }}/>
        </Box>
      </a>
    );
  }

  return null;
};

export const customerBalanceRenderer = (customer: Customer) => {
  if (!customer.wallet) return "-";

  return `${customer.wallet.amount} ${customer.wallet.currency}`;
};