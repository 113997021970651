import { useEffect, useState } from "react";

import { RaRecord } from "ra-core";
import { Identifier, useListContext } from "react-admin";

export type SafeSelectorResult<T> = {
  selectedIds: Identifier[],
  data: T[]
};

export const usePageSafeSelector = <T extends RaRecord>(): SafeSelectorResult<T> => {
  const { selectedIds, data: pageData } = useListContext<T>();
  const [ items, setItems ] = useState<T[]>([]);

  useEffect(() => {
    const selectedItemsWithoutNewlyDeselected = items.filter((item) => selectedIds.includes(item.id));
    const selectedItemIds = selectedItemsWithoutNewlyDeselected.map((item) => item.id);

    const newlySelectedItems = pageData.filter((item) => selectedIds.includes(item.id))
      .filter((item) => !selectedItemIds.includes(item.id));

    setItems([
      ...selectedItemsWithoutNewlyDeselected,
      ...newlySelectedItems
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageData, selectedIds]);

  return {
    selectedIds: items.map((item) => item.id),
    data: items
  };
};
