import React from "react";

import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import {
  Box,
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tooltip,
  Typography
} from "@mui/material";
import moment from "moment/moment";
import { useListContext, useNotify, useRefresh, useUnselectAll, useUpdateMany } from "react-admin";
import * as yup from "yup";

import DateTimeFormInput from "../../../components/form/input/datetime/DateTimeFormInput";
import { useForm } from "../../../components/form/use-form";
import { yupDate } from "../../../components/form/validation";
import { toIsoString } from "../../../utils/date-time-utils";

type Props = {
  isDialogOpen: boolean,
  onDialogClose: () => void
};

type FormState = {
  timeUnlockedUntil?: string
};

const LOCK_DATE = "2000-01-01";
const UNLOCK_DATE = "2060-01-01";
const DATE_FIELD_NAME = "timeUnlockedUntil";

export const LockStoveAction: React.FC<Props> = ({ isDialogOpen, onDialogClose }) => {
  const initialFormState: FormState = {
    [DATE_FIELD_NAME]: undefined
  };
  const form = useForm<FormState>(initialFormState, yup.object({
    [DATE_FIELD_NAME]: yupDate().required().label("Date")
  }));

  const buildRequest = () => {
    const timeUnlockedUntil = toIsoString(moment(form.value.timeUnlockedUntil).startOf("day"));
    return { timeUnlockedUntil };
  };

  const { selectedIds, resource } = useListContext();
  const refresh = useRefresh();
  const notify = useNotify();
  const unselectAll = useUnselectAll(resource);

  const handleDialogClose = () => {
    form.reset();
    onDialogClose();
  };

  const [updateMany] = useUpdateMany(
    resource,
    { ids: selectedIds, data: buildRequest() },
    {
      onSuccess: () => {
        refresh();
        notify("Request has been submitted");
        unselectAll();
        handleDialogClose();
      },
      onError: () => notify("Error: failed to lock/unlock stoves", { type: "error" })
    }
  );

  const handleConfirm = () => {
    updateMany();
  };

  return (
    <Dialog open={isDialogOpen}
      onClose={handleDialogClose}
      PaperProps={{
        sx: { overflow: "visible" }
      }}>
      <DialogTitle>
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          Lock or unlock stoves
          <Tooltip title={
            <React.Fragment>
              <Typography variant="body2">This functionality allows one to remotely lock or unlock a stove by applying
                time credits (either positive or negative). An action doesn&apos;t come into effect immediately after
                the confirmation. First, a stove needs to fetch a new value from the server (based on observations,
                it happens when the stove is turned on), which may take a while since it depends on network
                connectivity.</Typography>
            </React.Fragment>
          }>
            <HelpOutlineIcon/>
          </Tooltip>
        </Box>

      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          You can specify any date within the allowed range. The two buttons below are just a handy way to fill
          in the required date field.
        </DialogContentText>

        <Box sx={{ mt: 1, mb: 2 }}>
          <ButtonGroup variant="outlined">
            <Button
              onClick={() => form.setValueByLabel(DATE_FIELD_NAME, LOCK_DATE)}
            >Lock</Button>
            <Button
              onClick={() => form.setValueByLabel(DATE_FIELD_NAME, UNLOCK_DATE)}
            >Unlock</Button>
          </ButtonGroup>
        </Box>

        <Box>
          <DateTimeFormInput
            name={DATE_FIELD_NAME}
            label="Date"
            fullWidth={false}
            form={form}
            onlyDate
            minDateTime={moment(LOCK_DATE)}
            maxDateTime={moment(UNLOCK_DATE)}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDialogClose}>
          Cancel
        </Button>
        <Button
          disabled={!form.isValid}
          onClick={handleConfirm}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};
