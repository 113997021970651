import * as React from "react";

import { Show, Tab, TabbedShowLayout, useShowController } from "react-admin";

import { UserRoles } from "../../core/providers/auth/roles";
import { FullCustomer } from "../../utils/commons";
import { useCheckAccess } from "../../utils/use-check-access";
import { StoveUsageTab } from "../stoves/tabs/StoveUsageTab";
import { CustomerContractsTab } from "./tabs/CustomerContactsTab";
import { CustomerMainTab } from "./tabs/CustomerMainTab";
import { CustomerOrdersTab } from "./tabs/CustomerOrdersTab";
import { CustomerPaymentsTab } from "./tabs/CustomerPaymentsTab";

export const CustomerShow: React.FC = () => {

  const CustomerShowLayout = () => {
    const { record: customer } = useShowController<FullCustomer>();
    const { hasAccess } = useCheckAccess([
      UserRoles.ROLE_SUPAMOTO_ADMIN, UserRoles.ROLE_READ_ONLY,
      UserRoles.ROLE_EXTERNAL_COMPANY_ADMIN
    ]);

    const { hasAccess: hasAccessToContracts } = useCheckAccess([
      UserRoles.ROLE_SUPAMOTO_ADMIN, UserRoles.ROLE_READ_ONLY,
      UserRoles.ROLE_SALES, UserRoles.ROLE_SHOP_KEEPER
    ]);

    return (
      <TabbedShowLayout>
        <Tab label="Main">
          <CustomerMainTab/>
        </Tab>
        <Tab label="Payments" path="payments">
          <CustomerPaymentsTab customerId={customer?.id}/>
        </Tab>
        <Tab label="Orders" path="orders">
          <CustomerOrdersTab customerId={customer?.id}/>
        </Tab>
        {
          hasAccess && (
            <Tab label="Stoves" path="stoves">
              <StoveUsageTab displayMode="customer" stoves={customer?.stoves}/>
            </Tab>
          )
        }
        {
          hasAccessToContracts && (
            <Tab label="Contracts" path="contracts">
              <CustomerContractsTab customerId={customer?.id}/>
            </Tab>
          )
        }
      </TabbedShowLayout>
    );
  };

  return (
    // workaround to force fetch data on every show
    <Show queryOptions={{ cacheTime: 0, staleTime: 0 }}>
      <CustomerShowLayout/>
    </Show>
  );
};
