import React from "react";

import AddIcon from "@mui/icons-material/Add";
import { Button, InputAdornment, TextField } from "@mui/material";
import Grid from "@mui/material/Grid";

import { useCustomerEligibleOffers } from "../../../components/offer/use-customer-eligible-offers";
import { OrderLine } from "../../../components/order/OrderLine";
import { CountryCurrency } from "../../../utils/commons";
import { calcTotalPrice } from "../../orders/actions/create/create-order-form";
import { useCreateContract } from "../CreateContractProvider";
import { addonsValidationSchema } from "../state";
import { CreateContractStepActions } from "./CreateContractStepActions";

export const ContractAddonsStep: React.FC = () => {
  const { form, customer } = useCreateContract();
  const { offers, isLoading: isOffersLoading } = useCustomerEligibleOffers(customer?.id, ["ACCESSORY", "PELLETS"]);

  const isStepDataValid = () => {
    return addonsValidationSchema.isValidSync(form.value);
  };

  return (
    <Grid item xs={12}>
      <Grid container spacing={2}>
        {
          form.value.addons.map((_, index: number) => {
            return (
              <OrderLine
                key={index}
                identifierName="dealId"
                itemKey={index}
                propertyName="addons"
                customer={customer}
                offers={offers}
                selectedOffer={offers.find((offer) => offer.id === form.value.addons[index].dealId) }
                isOffersLoading={isOffersLoading}
                form={form}
              />
            );
          })
        }
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={8}></Grid>
            <Grid item xs={2}>
              <TextField
                label="Total price"
                variant="outlined"
                disabled
                fullWidth
                value={calcTotalPrice(form.value.addons)}
                InputProps={{
                  endAdornment: <InputAdornment position="end">{CountryCurrency[customer?.country || ""]}</InputAdornment>
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sx={{ display: "flex", justifyContent: "start", alignItems: "center" }}>
          <Button variant="outlined"
            disabled={form.value.addons.length >= 10}
            onClick={() => {
              form.setValueByLabel(`addons[${form.value.addons.length}]`, { dealId: "" });
            }}
            endIcon={<AddIcon />}>
                Add line
          </Button>
        </Grid>
        <CreateContractStepActions isStepValid={isStepDataValid()}/>
      </Grid>
    </Grid>
  );
};