import React from "react";

import DownloadIcon from "@mui/icons-material/Download";
import { Box, Button, Tooltip } from "@mui/material";
import { constant } from "lodash-es";

import deviceDataTemplate from "../../../assets/templates/device_data_template.csv";
import { ImportDataButton } from "../../../components/list/import/ImportDataButton";
import { UserRoles } from "../../../core/providers/auth/roles";
import { SECONDARY_COLOR } from "../../../core/theme/theme";
import { useCheckAccess } from "../../../utils/use-check-access";

export const ImportDeviceDataButton: React.FC = () => {
  const { hasAccess } = useCheckAccess(UserRoles.ROLE_SUPAMOTO_ADMIN);

  return (
    <ImportDataButton
      resource={constant("stoves/instances")}
      disabled={!hasAccess}
      buttonLabel="Import Device data"
      buttonTooltip="Upload Device-related data (UID mappings, country, etc.)"
      dialogTitle="Import Device data"
      dialogContentText={
        <>
          <Box sx={{ fontSize: 14, color: SECONDARY_COLOR }}>
            This feature allows you to upload Device-related data (UID mappings, country, etc.).
            Below you can download the CSV file template containing sample data.
          </Box>
          <Box sx={{ mb:2 }}>
            <Button
              sx={{
                textTransform: "none",
                fontSize: 14,
                fontWeight: 600,
                letterSpacing: "0.25px"
              }}
              variant="text"
              startIcon={<DownloadIcon/>}
              href={deviceDataTemplate}
              download="example.csv"
            >
              Download template
            </Button>
          </Box>
        </>
      }
      reprocessFileExportHeader="Device Id,UID,Model,Connection Type,Country,Year of Manufacture,Remapping type"
      reprocessDialogTitle="Remap Device data"
      reprocessDialogContentText={
        <>
          <Box sx={{ fontSize: 14, color: SECONDARY_COLOR }}>
            <Box>
              {'Below, you can download a file with stoves that are already mapped and fill in the "Remapping type" column: '}
            </Box>
            <Box>
              {'Possible values are "'}
              <Tooltip title="Use this type when initially there was a mistake in the stove UID">
                <span style={{
                  textDecoration: "underline",
                  cursor: "pointer"
                }}>FIX_UID</span>
              </Tooltip>
              {'", "'}
              <Tooltip
                title="Use this type when initially there was a mistake in the device ID, or if you want to fix fake IDs">
                <span style={{
                  textDecoration: "underline",
                  cursor: "pointer"
                }}>FIX_DEVICE</span>
              </Tooltip>
              {'", "'}
              <Tooltip
                title="Use this type when the stove (device) works correctly, but the SIM card was lost or broken and needs to be replaced">
                <span style={{
                  textDecoration: "underline",
                  cursor: "pointer"
                }}>CHANGE_UID</span>
              </Tooltip>
              {'", "'}
              <Tooltip
                title="Use this type when the device was broken and needs to be repaced while keeping the sim card">
                <span style={{
                  textDecoration: "underline",
                  cursor: "pointer"
                }}>CHANGE_DEVICE</span>
              </Tooltip>
            </Box>
          </Box>
        </>
      }
    />
  );
};