import { CustomerGroupType } from "../../utils/commons";

export enum OfferType {
  PELLETS = "Pellets",
  CONTRACT = "Contract",
  STOVE = "Stove",
  ACCESSORY = "Accessory"
}

export const OFFER_TYPE_PRIORITY: Record<keyof typeof OfferType, number> = {
  "PELLETS": 1,
  "STOVE": 2,
  "ACCESSORY": 3,
  "CONTRACT": 4
};

export enum OfferStatus {
  ACTIVE = "Active",
  INACTIVE = "Inactive",
  ARCHIVED = "Archived"
}

export type Offer = {
  id: number,
  name: string,
  country: string,
  price: number,
  bagSize?: number,
  stockItemId?: number,
  offerType: keyof typeof OfferType,
  offerStatus: keyof typeof OfferStatus,
  isDefault?: boolean,
  availableFor: CustomerGroupType[]
};
