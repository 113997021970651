import React from "react";

import LockIcon from "@mui/icons-material/Lock";
import { Avatar, Card } from "@mui/material";

import { AuthFormProps, AuthClasses } from "./types";

export const AuthForm: React.FC<AuthFormProps> = ({ formComponent }) => {
  return (
    <Card className={AuthClasses.card}>
      <div className={AuthClasses.avatar}>
        <Avatar className={AuthClasses.icon}>
          <LockIcon />
        </Avatar>
      </div>
      {formComponent}
    </Card>
  );
};
