import * as React from "react";

import ReceiptIcon from "@mui/icons-material/Receipt";
import { Badge, IconButton } from "@mui/material";
import { Link } from "react-router-dom";

import useAdjustmentsCount from "../pages/adjustments/useAdjustmentsCount";

export const AdjustmentCount: React.FC = () => {
  const { count } = useAdjustmentsCount();

  return <IconButton
    component={Link}
    to="/adjustments"
    color="inherit"
    aria-label="adjustments"
  >
    <Badge badgeContent={count} color="secondary">
      <ReceiptIcon />
    </Badge>
  </IconButton>;
};