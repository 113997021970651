export type CarbonCreditClaim = {
  id: number,
  orderId: number,
  status: keyof typeof CarbonCreditClaimStatus,
  cancellationReason: string,
  timeSubmitting: string,
  timeValidation: string
};

export enum CarbonCreditClaimStatus {
  SUBMITTED = "Submitted",
  VERIFIED = "Verified",
  NOT_VERIFIED = "Not Verified"
}