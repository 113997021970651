import { devProfile } from "./dev-profile";
import { localProfile } from "./local-profile";
import { prodProfile } from "./prod-profile";

export const PROFILE = (() => {
  const hostMapping = {
    [prodProfile.uiHostUrl]: prodProfile,
    [devProfile.uiHostUrl]: devProfile,
    [localProfile.uiHostUrl]: localProfile
  };
  const currentHostUrl = `${window.location.protocol}//${window.location.hostname}`;
  const activeProfile = hostMapping[currentHostUrl];
  if (!activeProfile) {
    throw Error("Unknown profile");
  }
  return activeProfile;
})();
