import { ReactNode } from "react";

import { styled } from "@mui/material/styles";
import { Form } from "ra-core";

export type AuthFormProps = {
  formComponent: ReactNode
};
export type ButtonedCardProps = {
  loading?: boolean,
  buttonLabel: string,
  children: ReactNode
};

export type ButtonWithLoaderProps = {
  loading?: boolean,
  buttonLabel: string
};
export const PREFIX = "RaLogin";
export const AuthClasses = {
  content: `${PREFIX}-content`,
  button: `${PREFIX}-button`,
  card: `${PREFIX}-card`,
  avatar: `${PREFIX}-avatar`,
  icon: `${PREFIX}-icon`
};

export const StyledForm = styled(Form, {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root
})(({ theme }) => ({
  [`& .${AuthClasses.content}`]: {
    width: 300
  },
  [`& .${AuthClasses.button}`]: {
    marginTop: theme.spacing(2)
  },
  [`& .${AuthClasses.icon}`]: {
    margin: theme.spacing(0.3)
  }
}));
