import React from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@mui/material";
import Grid from "@mui/material/Grid";

import { ProcessingButton } from "../../../components/button/processing/ProcessingButton";
import { FormSelect } from "../../../components/form/input/select/FormSelect";
import { FormProps } from "../../../components/form/use-form";
import { OrderCancelReason } from "../types";
import { CancellationFormState } from "./CancelOrderStatusButton";

type CancelOrderDialogProps = {
  onClose: () => void,
  onConfirm: () => void,
  isProcessing: boolean,
  isOpened: boolean,
  form: FormProps<CancellationFormState>
};

export const CancelOrderDialog: React.FC<CancelOrderDialogProps> = ({
  onClose,
  onConfirm,
  isProcessing,
  isOpened,
  form
}) => {
  const orderCancelReasons = Object.entries(OrderCancelReason).map(([key, value]) => {
    return { value: key, label: value };
  });
  return <Dialog
    fullWidth
    open={isOpened}
    onClose={onClose}
  >
    <DialogTitle>Cancel an order</DialogTitle>
    <DialogContent>
      <Grid container spacing={2} sx={{ mt:1 }}>
        <Grid item xs={12}>
          <FormSelect
            name="cancelReason"
            label="Cancel Reason *"
            form={form}
            menuItems={orderCancelReasons}/>
        </Grid>
        <Grid item xs={12}>
          <DialogContentText>
            Are you sure you want to cancel this order(s)
          </DialogContentText>
        </Grid>
      </Grid>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose}>
        {"Don't cancel"}
      </Button>
      <ProcessingButton
        onClick={onConfirm}
        disabled={isProcessing || !form.value.cancelReason}
        isProcessing={isProcessing}
      />
    </DialogActions>
  </Dialog>;
};