import { useEffect } from "react";

import { isNil } from "lodash-es";
import { GetListParams, useDataProvider, useStore } from "react-admin";

import { Resources } from "../../resources";
import { CollectionPartner } from "./types";

export const useCollectionPartners = () => {
  const dataProvider = useDataProvider();
  const [collectionPartners, setCollectionPartners] = useStore<CollectionPartner[]>("api.collections", undefined);

  useEffect(() => {
    if (isNil(collectionPartners)) {
      const params = { pagination: { page: 1, perPage: 500 } } as GetListParams;
      dataProvider.getList<CollectionPartner>(Resources.CollectionPartners, params)
        .then((response) => {
          setCollectionPartners(response.data);
          return response;
        });
    }
  }, [dataProvider, collectionPartners, setCollectionPartners]);

  return { collectionPartners: isNil(collectionPartners) ? [] : collectionPartners };
};