import * as React from "react";

import { Edit, useUpdate } from "react-admin";

import { DiscountsForm, OperationType } from "../discounts/DiscountsForm";

export const DiscountsEdit: React.FC = () => {
  const [ update ] = useUpdate();
  return (
    <Edit
      mutationMode="pessimistic"
      queryOptions={{ cacheTime: 0, staleTime: 0 }}
    >
      <DiscountsForm operationType={OperationType.UPDATE} submit={update}/>
    </Edit>
  );
};