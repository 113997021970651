import { useEffect, useMemo, useState } from "react";

import { isNil } from "lodash-es";
import { useNotify } from "ra-core";
import { useDataProvider } from "react-admin";

import { Offer, OfferType } from "../../pages/offers/types";

export const useCustomerEligibleOffers = (customerId?: number, types?: Array<keyof typeof OfferType>) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();

  const [isLoading, setIsLoading] = useState(false);
  const [offers, setOffers] = useState<Offer[]>();

  useEffect(() => {
    if (!customerId) return;

    setIsLoading(true);
    dataProvider.getManyByUrl(`customers/${customerId}/offers`)
      .then((response: { data: Offer[]}) => {
        setOffers(response.data);
        return response;
      })
      .catch(() => notify("Error: failed to retrieve offers", { type: "error" }))
      .finally(() => setIsLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerId]);

  const offersByType = useMemo(() => {
    return (offers || []).filter((offer) => isNil(types) ? true : types.includes(offer.offerType));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offers, types, customerId]);

  return { offers: offersByType, isLoading: isLoading };
};