import React from "react";

import { Chip, Tooltip } from "@mui/material";
import { Link } from "react-router-dom";

import { Device } from "../../utils/commons";

type Props = {
  device: Device
};

const isDevicePreOwned = (device: Device): boolean => device.stoves.some((stove) => stove.customer);

export const StoveStateIcon : React.FC<Props> = ({
  device
}) => {
  if (!device) return null;

  const isAlreadyAssigned = device.stoves.some((stove) => stove.status === "ACTIVE" && stove.customer);

  return (
    <Tooltip title={
      isAlreadyAssigned ?
        "The device already belongs to another customer. Click here to open the device page." :
        `The device is ${isDevicePreOwned(device) ? "pre-owned" : "new"}. Click here to open the device page.`
    }>
      <Link to={`/stoves/devices/${device.id}/show`} target="_blank">
        {
          isAlreadyAssigned ?
            <Chip label="Occupied" color="error" variant="outlined" clickable/> :
            (isDevicePreOwned(device) ?
              <Chip label="Pre-Owned" color="warning" variant="outlined" clickable/> :
              <Chip label="New" color="success" variant="outlined" clickable/>
            )
        }
      </Link>
    </Tooltip>
  );
};