import * as React from "react";

import { Auth } from "./Auth";
import { AuthForm } from "./AuthForm";
import { LoginForm } from "./LoginForm";

export const Login: React.FC = () => {
  const formComponent = <AuthForm formComponent={<LoginForm />}/>;
  return (
    <Auth formComponent={formComponent}/>
  );
};