import React, { SyntheticEvent, useEffect, useState } from "react";

import { AutocompleteChangeReason } from "@mui/base/useAutocomplete/useAutocomplete";
import { Autocomplete, CircularProgress, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import { sortBy } from "lodash-es";
import { useDataProvider, useNotify } from "react-admin";

import {
  CollectionPartner
} from "../../../../components/collections/types";
import { FormTextField } from "../../../../components/form/input/text-field/FormTextField";
import { FormProps } from "../../../../components/form/use-form";
import { Resources } from "../../../../resources";
import { countryNameRenderer } from "../../../../utils/field-renderers";
import { DeliveryDetails } from "./common";

type Props = {
  form: FormProps<DeliveryDetails>
};

export const PickUpFromSection: React.FC<Props> = ({
  form
}) => {
  const [collectionPartners, setCollectionPartners] = useState<readonly CollectionPartner[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const dataProvider = useDataProvider();
  const notify = useNotify();

  useEffect(() => {
    setIsLoading(true);
    dataProvider.getList(Resources.CollectionPartners, {
      pagination: {
        page: 1,
        perPage: 200
      },
      sort: {
        field: "id",
        order: "ASC"
      },
      filter: {
        types: ["DEPOT", "WAREHOUSE", "MOBILE"],
        statuses: ["ACTIVE"]
      }
    }).then((response) => {
      setIsLoading(false);
      setCollectionPartners(sortBy(response.data, ["country", "name"]));
      return response;
    }).catch(() => {
      notify("Error: failed to retrieve pickup locations", { type: "error" });
    });
  }, [dataProvider, notify]);

  const onChange = (event: SyntheticEvent, value: CollectionPartner, reason: AutocompleteChangeReason) => {
    if (reason === "selectOption") {
      form.setValueByLabel("pickupCollectionPartnerId", value.id);
    }
  };

  return (
    <>
      <Typography sx={{ mb: 2, fontWeight: 600 }}>Pick-up from:</Typography>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Autocomplete
            loading={isLoading}
            options={collectionPartners}
            onChange={onChange}
            getOptionLabel={(option: CollectionPartner) => option.name}
            isOptionEqualToValue={(option: CollectionPartner, value: CollectionPartner) => option.name === value.name}
            groupBy={(option) => countryNameRenderer(option) || ""}
            disableClearable
            renderInput={(params) => (
              <FormTextField name="pickupCollectionPartnerId"
                label="Pickup Location *"
                inputProps={params?.inputProps}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </>
                  )
                }}
                form={form}
              />
            )}
          />
        </Grid>
      </Grid>
    </>
  );
};
