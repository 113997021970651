import {
  baseMultilineTextInputFilter
} from "../../components/filter/base-multiline-text-input-filter";
import { enumSelectFilter } from "../../components/filter/enum-select-filter";
import { buildLabel, CustomFilterIcon } from "../../components/filter/filter-utils";
import { AdjustmentStatus } from "./types";

export const useAdjustmentFilters = () => {
  return [
    enumSelectFilter({
      source: "statuses",
      enumObj: AdjustmentStatus,
      label: buildLabel({ label: "Status", Icon: CustomFilterIcon }),
      alwaysOn: true
    }),
    baseMultilineTextInputFilter({
      source: "transactionIds",
      label: buildLabel({ label: "Transaction IDs", Icon: CustomFilterIcon })
    })
  ];
};