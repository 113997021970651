import React, { useState } from "react";

import SettingsIcon from "@mui/icons-material/Settings";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  Tooltip
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";

import { ReportGrouping } from "../types";

type Props = {
  onChange: (value: ReportGrouping) => void
};

export const ChartAvgGroupingConfiguration: React.FC<Props> = ({
  onChange
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [grouping, setGrouping] = useState<ReportGrouping>(ReportGrouping.TOTAL);

  const handleChange = (event: SelectChangeEvent<typeof grouping>) => {
    setGrouping(Number(event.target.value));
  };
  const handleClose = () => setIsDialogOpen(false);
  const handleApply = () => {
    onChange(grouping);
    handleClose();
  };

  return (
    <Box>
      <Tooltip title="Configure">
        <IconButton color="secondary"
          size="small"
          onClick={() => setIsDialogOpen(true)}
        >
          <SettingsIcon fontSize="small"/>
        </IconButton>
      </Tooltip>
      <Dialog open={isDialogOpen} onClose={handleClose}>
        <DialogTitle>Select the moving average</DialogTitle>
        <DialogContent>
          <Box component="form" sx={{ display: "flex", flexWrap: "wrap" }}>
            <FormControl fullWidth sx={{ m: 1, minWidth: 120 }}>
              <Select
                value={grouping}
                onChange={handleChange}
              >
                <MenuItem value={ReportGrouping.TOTAL}>Total</MenuItem>
                <MenuItem value={ReportGrouping.MONTHLY}>30 days (actual sales)</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleApply}>Apply</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};