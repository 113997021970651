import { useEffect } from "react";

import { isEmpty } from "lodash-es";
import { useDataProvider, useNotify, useStore } from "react-admin";

import { TicketStatus } from "./types";

export const useTicketStatuses = () => {
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const [ticketStatuses, setTicketStatuses] = useStore<TicketStatus[]>("api.tickets.statuses", []);

  useEffect(() => {
    if (isEmpty(ticketStatuses)) {
      dataProvider.getManyByUrl("profile-performance/tickets/statuses")
        .then((response: { data: TicketStatus[] }) => {
          setTicketStatuses(response.data);
          return response;
        })
        .catch(() => {
          notify("Error: failed load ticket statuses", { type: "error" });
        });
    }
  }, [dataProvider, ticketStatuses, setTicketStatuses, notify]);

  return { ticketStatuses };
};