import React, { useEffect } from "react";

import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary, Box, Divider } from "@mui/material";
import { find } from "lodash-es";

import { useForm } from "../../../../components/form/use-form";
import { Customer } from "../../../../utils/commons";
import { Order } from "../../types";
import {
  buildDeliveryOrderItemsByOrders,
  DeliveryDetails,
  deliveryDetailsValidationSchema
} from "../common/common";
import { DeliverToSection } from "../common/DeliverToSection";
import { OrderDetailsSection } from "../common/OrderDetailsSection";
import { PickUpFromSection } from "../common/PickUpFromSection";

type Props = {
  orders: Order[],
  customer: Customer,
  dataChangeCallback: (orderId: number, isValid: boolean, data: DeliveryDetails) => void
};

const getPrimaryPhoneNumber = (customer: Customer) => {
  return find(customer.phoneNumbers, "isPrimary")?.number;
};

export const IndividualDeliveryForm: React.FC<Props> = ({
  orders,
  customer,
  dataChangeCallback
}) => {
  const clientName = `${customer.firstName} ${customer.lastName}`;
  const orderIds = orders.map((order) => order.id);

  const initialState: DeliveryDetails = {
    resourceId: customer.id,
    orderIds,
    clientName,
    phoneNumber: getPrimaryPhoneNumber(customer) || "",
    address: customer.address || "",
    latitude: customer.latitude,
    longitude: customer.longitude,
    deliveryDateTime: "",
    pickupCollectionPartnerId: "",
    orderItems: buildDeliveryOrderItemsByOrders(orders),
    deliveryInstructions: undefined
  };
  const form = useForm(initialState, deliveryDetailsValidationSchema, true);

  useEffect(() => {
    dataChangeCallback(customer.id, form.isValid, form.value);
  }, [dataChangeCallback, customer, form.isValid, form.value]);

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          {clientName} {form.isValid ? <CheckIcon color={"success"}/> : <CloseIcon color={"warning"}/>}
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Divider sx={{ mb: 2 }}/>
        <DeliverToSection form={form}/>
        <Divider sx={{ my: 2 }}/>
        <PickUpFromSection form={form}/>
        <Divider sx={{ my: 2 }}/>
        <OrderDetailsSection form={form}/>
      </AccordionDetails>
    </Accordion>
  );
};
