import React, { useState } from "react";

import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle, InputAdornment, Typography
} from "@mui/material";
import Grid from "@mui/material/Grid";
import { isNil } from "lodash-es";

import { CustomerBaseGrid } from "../../components/customer/CustomerBaseGrid";
import { SearchCustomerAutocomplete } from "../../components/customer/SearchCustomerAutocomplete";
import { FormTextField } from "../../components/form/input/text-field/FormTextField";
import { FormProps } from "../../components/form/use-form";
import { CountryCurrency, FullCustomer } from "../../utils/commons";
import { CreateAdjustmentForm } from "./types";

type CreateAdjustmentDialogProps = {
  predefinedCustomer?: FullCustomer,
  onClose: () => void,
  onConfirm: () => void,
  isProcessing: boolean,
  isOpened: boolean,
  form: FormProps<CreateAdjustmentForm>
};

export const CreateAdjustmentDialog: React.FC<CreateAdjustmentDialogProps> = ({
  predefinedCustomer,
  onClose,
  onConfirm,
  isProcessing,
  isOpened,
  form
}) => {
  const [customer, setCustomer] = useState<FullCustomer | undefined>(predefinedCustomer);

  const handleCancel = () => {
    onClose();
    if (!predefinedCustomer) setCustomer(undefined);
  };
  
  const handleConfirm = () => {
    onConfirm();
    if (!predefinedCustomer) setCustomer(undefined);
  };

  return <Dialog
    fullWidth
    maxWidth="md"
    open={isOpened}
    onClose={onClose}
  >
    <DialogTitle>Add adjustment</DialogTitle>
    <DialogContent>
      <Grid container spacing={2} sx={{ mt:1 }}>
        {isNil(predefinedCustomer) && (
          <Grid item xs={12}>
            <SearchCustomerAutocomplete
              size="medium"
              onCustomerSelected={(selectedCustomer) => {
                form.setValueByLabel("customerId", selectedCustomer.id);
                setCustomer(selectedCustomer);
              }} />
          </Grid>)}
        {
          !isNil(customer) && (
            <>
              <Grid item xs={12}>
                <Typography variant="h6">Customer:</Typography>
              </Grid>
              <Grid item xs={12}>
                <CustomerBaseGrid
                  customer={customer}
                  remainingBalance={customer?.wallet?.amount ?? 0}/>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6">Adjustment details:</Typography>
              </Grid>
              <Grid item xs={12}>
                <FormTextField
                  required
                  name="amount"
                  type="number"
                  label="Amount"
                  form={form}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">{CountryCurrency[customer?.country || ""]}</InputAdornment>
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <FormTextField required name="description" label="Description" form={form}/>
              </Grid>
            </>
          )
        }
      </Grid>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleCancel}>
        Cancel
      </Button>
      <Button
        disabled={isProcessing || !form.isValid }
        onClick={handleConfirm}>
        {isProcessing ?
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <CircularProgress color="secondary" size={20}/> Processing
          </Box>
          : "Confirm"
        }
      </Button>
    </DialogActions>
  </Dialog>;
};