import React, { ReactNode, useState } from "react";

import UploadIcon from "@mui/icons-material/Upload";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Tooltip } from "@mui/material";
import { constant } from "lodash-es";
import { useDataProvider, useRefresh } from "react-admin";

import { UploadFileResponse } from "../../../core/providers/data/rest-data-provider";
import { Dropzone } from "../../dropzone/Dropzone";
import { ImportDataConfirmPopup } from "./ImportDataConfirmPopup";
import { ImportDataReprocessPopup } from "./ImportDataReprocessPopup";
import { ImportDataResponseContent } from "./ImportDataResponseContent";

type ButtonProps = {
  disabled: boolean,
  buttonLabel: string,
  buttonTooltip: string,
  dialogTitle: string | ReactNode,
  dialogContentText: string | ReactNode,
  reprocessDialogTitle?: string | ReactNode,
  reprocessDialogContentText?: string | ReactNode,
  reprocessFileExportHeader?: string,
  resource: () => string,
  isDataValid?: (file?: File) => boolean,
  onDialogClose?: () => void
};

export const ImportDataButton: React.FC<ButtonProps> = ({
  disabled,
  buttonLabel,
  buttonTooltip,
  dialogTitle,
  dialogContentText,
  reprocessDialogTitle,
  reprocessDialogContentText,
  reprocessFileExportHeader,
  resource,
  isDataValid = constant(true),
  onDialogClose
}) => {
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const [isImporting, setImporting] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File>();
  const [uploadResponse, setUploadResponse] = useState<UploadFileResponse>();
  const [needsReprocessing, setNeedsReprocessing] = useState(false);
  const [force, setForce] = useState(false);

  const onConfirm = () => {
    setImporting(true);
    const baseUrl = resource() +
        (needsReprocessing ? (resource().includes("?") ? "&" : "?") + "reprocess=true" : "");
    const url = baseUrl +
        (force ? (baseUrl.includes("?") ? "&" : "?") + "force=true" : "");
    return dataProvider.uploadFile(url, selectedFile)
      .then((response: UploadFileResponse) => {
        setUploadResponse(response);
        response.ok && refresh();
        if (response.status === 422) {
          setNeedsReprocessing(true);
          setSelectedFile(undefined);
        } else if (response.status === 409) {
          setForce(true);
        } else {
          setForce(false);
          setNeedsReprocessing(false);
        }
        return response;
      })
      .finally(() => setImporting(false));
  };

  const onClose = () => {
    setDialogOpen(false);
    setSelectedFile(undefined);
    setUploadResponse(undefined);
    setNeedsReprocessing(false);
    setForce(false);
    onDialogClose?.();
  };

  return (
    <>
      <Tooltip title={buttonTooltip}>
        <span>
          <Button
            onClick={() => setDialogOpen(true)}
            disabled={disabled || isImporting}
            startIcon={<UploadIcon />}
            size={"small"}
          >
            {isImporting ? "Processing..." : buttonLabel}
          </Button>
        </span>
      </Tooltip>
      {
        !uploadResponse && <Dialog open={dialogOpen} onClose={onClose}>
          <DialogTitle>
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              {dialogTitle}
            </Box>
          </DialogTitle>
          <DialogContent>
            {dialogContentText}
            <Box sx={{ maxWidth: 580, minWidth: 500 }}>
              <Dropzone
                disabled={isImporting}
                accept={{ "text/csv": [".csv"] }}
                onDrop={(files) => setSelectedFile(files[0])}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose}>
            Cancel
            </Button>
            <Button
              disabled={!selectedFile || !isDataValid(selectedFile) || isImporting}
              onClick={onConfirm}>
              { isImporting ? "Processing" : "Confirm" }
            </Button>
          </DialogActions>
        </Dialog>
      }
      {uploadResponse && !force && !needsReprocessing && <Dialog open={dialogOpen} onClose={onClose}>
        <DialogTitle>
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            {dialogTitle}
          </Box>
        </DialogTitle>
        <DialogContent>
          <ImportDataResponseContent response={uploadResponse}/>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      }
      {
        force && <ImportDataConfirmPopup
          dialogTitle={dialogTitle}
          isOpened={dialogOpen}
          data={uploadResponse?.data}
          onClose={onClose}
          onConfirm={onConfirm}/>
      }
      <ImportDataReprocessPopup
        dialogTitle={reprocessDialogTitle}
        fileExportHeader={reprocessFileExportHeader}
        isOpened={needsReprocessing}
        data={uploadResponse?.data}
        onClose={onClose}
        onDrop={(files) => setSelectedFile(files[0])}
        onConfirm={onConfirm}
        isConfirmDisabled={!selectedFile || !isDataValid(selectedFile) || isImporting}
        dialogContentText={reprocessDialogContentText}/>
    </>
  );
};
