import { EnumChipColors } from "../../utils/field-renderers";

export type ModifyAdjustmentRequest = {
  status: keyof typeof AdjustmentStatus
};

export type AdjustmentCountsResponse = {
  count: number
};

export type CreateAdjustmentForm = {
  customerId?: number,
  amount: number,
  description: string
};

export type Adjustment = {
  id: number,
  customerId: number,
  amount: number,
  description: string,
  status: keyof typeof AdjustmentStatus,
  createdBy: number,
  timeCreated: string
};

export enum AdjustmentStatus {
  NEW = "New",
  APPROVED = "Approved",
  REJECTED = "Rejected"
}

export const adjustmentStatusChipColors: Record<keyof typeof AdjustmentStatus, EnumChipColors> = {
  NEW: "secondary",
  APPROVED: "success",
  REJECTED: "error"
};