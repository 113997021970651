import React, { useState } from "react";

import RestoreIcon from "@mui/icons-material/Restore";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Tooltip,
  Typography
} from "@mui/material";
import { useDataProvider, useNotify } from "react-admin";

import { ProcessingButton } from "../../../components/button/processing/ProcessingButton";
import { Contract } from "../types";

type RestoreContractProps = {
  contract: Contract,
  disabled: boolean,
  onContractChange: (contractId: number) => void
};

export const ReactivateContractButton: React.FC<RestoreContractProps> = ({
  contract,
  disabled,
  onContractChange
}) => {
  const notify = useNotify();
  const dataProvider = useDataProvider();

  const [isFormOpen, setIsFormOpen] = useState(false);
  const [isReactivating, setIsReactivating] = useState(false);
  const [errorData, setErrorData] = useState();
  const handleCancel = () => {
    setIsFormOpen(false);
    setErrorData(undefined);
  };

  const handleConfirm = () => {
    setIsReactivating(true);
    dataProvider.updateManyByUrlWithBody(`contracts/${contract.id}/reactivate`, {
    }).then((response: any) => {
      notify("Contract has been successfully re-activated");
      onContractChange(contract.id);
      handleCancel();
      return response;
    }).catch((error: any) => {
      const errorCode = error.body?.errorCode;
      if (errorCode && errorCode === "contract.stove_is_already_assigned") {
        setErrorData(error.message);
      } else {
        notify("Error: failed to re-activate contract", { type: "error" });
      }
    }).finally(() => setIsReactivating(false));
  };
  return <>
    <Tooltip title="Reactivate contract: Disabled for contracts that were cancelled more then 7 days ago">
      <span>
        <Button
          color="primary"
          variant="text"
          disabled={disabled}
          onClick={() => setIsFormOpen(true)}
          startIcon={<RestoreIcon/>}>
            Reactivate
        </Button>
      </span>
    </Tooltip>
    <Dialog
      fullWidth
      maxWidth="sm"
      open={isFormOpen}
      onClose={handleCancel}
    >
      <DialogTitle>Reactivate contract</DialogTitle>
      <DialogContent>
        <DialogContent sx={{ mx: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="body1" align="justify">
                <>
                  You are trying to reactivate Contract {contract.id}.
                </>
              </Typography>
              {errorData &&
                  (
                    <Grid item xs={12}>
                      <Alert severity="warning">
                        {errorData}
                      </Alert>
                    </Grid>
                  )
              }
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleCancel}>
            Cancel
          </Button>
          <ProcessingButton
            onClick={handleConfirm}
            disabled={!!errorData}
            isProcessing={isReactivating}
          />
        </DialogActions>
      </DialogContent>
    </Dialog>
  </>;
};