import React, { useEffect, useState } from "react";

import { isEmpty } from "lodash-es";
import moment from "moment";
import { DateInput, useListContext, useListParams } from "react-admin";

import { toIsoString } from "../../utils/date-time-utils";
import { buildLabel, CustomFilterIcon } from "./filter-utils";

export type DateRangeFilterProps = {
  lowerBoundSource?: string,
  lowerBoundLabel?: string,
  upperBoundSource?: string,
  upperBoundLabel?: string,
  icon?: React.FC<any>,
  alwaysOn?: boolean
};

const minValue = (min: string, message = "Not valid") =>
  (value: string) => value && moment(value).isBefore(moment(min)) ? message : undefined;

const maxValue = (max: string, message = "Not valid") =>
  (value: string) => value && moment(value).isAfter(moment(max)) ? message : undefined;

const dateToLowerBoundDateTime = (date: string) => {
  return isEmpty(date) ? "" : toIsoString(moment(date).startOf("day"));
};
const dateToUpperBoundDateTime = (date: string) => {
  return isEmpty(date) ? "" : toIsoString(moment(date).endOf("day"));
};

export const useDateRangeFilters = ({
  lowerBoundSource = "startDateTime",
  lowerBoundLabel = "Start date",
  upperBoundSource = "endDateTime",
  upperBoundLabel = "End date",
  icon = CustomFilterIcon,
  alwaysOn = false
}: DateRangeFilterProps) => {
  const [lowerBound, setLowerBound] = useState<string | undefined>();
  const [upperBound, setUpperBound] = useState<string | undefined>();

  const { resource } = useListContext();
  const [ params ] = useListParams({ resource });

  useEffect(() => {
    if (!params.filter[lowerBoundSource] && lowerBound) {
      setLowerBound(undefined);
    }

    if (!params.filter[upperBoundSource] && upperBound) {
      setUpperBound(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  return [
    <DateInput key={lowerBoundSource}
      label={buildLabel({ label: lowerBoundLabel, Icon: icon })}
      parse={dateToLowerBoundDateTime}
      onBlur={(e) => setLowerBound(e.target.value)}
      validate={upperBound ? maxValue(upperBound) : undefined}
      variant="filled"
      source={lowerBoundSource}
      alwaysOn={alwaysOn}/>,
    <DateInput key={upperBoundSource}
      label={buildLabel({ label: upperBoundLabel, Icon: icon })}
      parse={dateToUpperBoundDateTime}
      onBlur={(e) => setUpperBound(e.target.value)}
      validate={lowerBound ? minValue(lowerBound) : undefined}
      variant="filled"
      source={upperBoundSource}
      alwaysOn={alwaysOn}/>
  ];
};
