import React, { useEffect, useState } from "react";

import { useNotify } from "ra-core";
import {
  BooleanField,
  DatagridConfigurable,
  DateField,
  FunctionField,
  List,
  ReferenceField,
  TextField,
  useDataProvider,
  useListContext
} from "react-admin";

import { useCustomerFilters } from "../../components/filter/customer-filters";
import { useDeviceIdFilter, useStoveUidFilter } from "../../components/filter/stove-filters";
import { PresetListActions } from "../../components/list/actions/PresetListActions";
import { DefaultPagination } from "../../components/pagination/DefaultPagination";
import { PaymentTransactionsList, sortPaymentTransactions } from "../../components/payments/PaymentTransactions";
import { User } from "../../components/user/types";
import { UserRoles } from "../../core/providers/auth/roles";
import { Resources } from "../../resources";
import { Customer } from "../../utils/commons";
import {
  countryNameRenderer,
  customerRenderer, enumRenderer,
  usernameRenderer
} from "../../utils/field-renderers";
import { useCheckAccess } from "../../utils/use-check-access";
import { ImportPaymentsButton } from "./bulk-actions/ImportPaymentsButton";
import PaymentsBulkActions from "./bulk-actions/PaymentsBulkActions";
import { usePaymentFilters } from "./payment-filters";
import { AllTelcos, Payment, PaymentTransaction } from "./types";

const customerColumnRenderer = (payment: Payment, customers: Customer[]) => {
  const activeTransactions =
      payment.transactions?.filter((transaction: PaymentTransaction) => transaction.activeAssignment);

  const customersLabel = activeTransactions && activeTransactions.length >= 1
    ? `${activeTransactions.length} customers`
    : null;
  const singleTransactionExists = activeTransactions && activeTransactions.length === 1;
  const customerId = sortPaymentTransactions(payment.transactions)[0]?.customerId as number;
  const customer = customers.find((customer: Customer) => customer.id === customerId);

  return (
    activeTransactions
      ? singleTransactionExists ? customerRenderer(customer) : customersLabel
      : null
  );
};

export const PaymentListDataGrid: React.FC = () => {
  const { data, isLoading } = useListContext();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const [customers, setCustomers] = useState<Customer[]>([]);

  useEffect(() => {
    if (data) {
      const customerIds =
          data.filter((payment: Payment) =>
            payment.transactions?.filter(
              (transaction: PaymentTransaction) => transaction.activeAssignment).length === 1)
            .map((payment: Payment) => sortPaymentTransactions(payment.transactions)[0].customerId as number) || [];

      if (customerIds.length > 0) {
        dataProvider.getMany<Customer>(Resources.Customers, { ids: customerIds }).then((response) => {
          setCustomers(response.data);
          return;
        }).catch(() => {
          notify("Error: failed to retrieve customers' info", { type: "error" });
        });
      }
    }
  }, [dataProvider, notify, data, isLoading]);

  return (
    <DatagridConfigurable
      bulkActionButtons={<PaymentsBulkActions/>}
      isRowSelectable={(record: Payment) => !record.isRefunded}
      omit={["isRefunded", "refundedBy", "note", "bank"]}
      expand={<PaymentTransactionsList/>}
    >
      <TextField source="telcoTransactionId" label="Transaction ID"/>
      <FunctionField label="Provider" sortable={false}
        render={(record: Payment) => enumRenderer(record.telco, AllTelcos)}/>
      <FunctionField label="Customer" sortable={false}
        render={(record: Payment) => customerColumnRenderer(record, customers)}/>
      <TextField source="paymentReference" sortable={false}/>
      <TextField source="payer" sortable={false}/>
      <TextField source="bank" sortable={false}/>
      <FunctionField label="Amount" sortable={true} source="amount"
        render={(record: Payment) => `${record.amount} ${record.currency}`}/>
      <FunctionField label="Country" sortable={true}
        render={(record: Payment) => countryNameRenderer(record)}/>
      <ReferenceField
        label="Created by"
        reference={Resources.Users}
        source="createdBy"
        sortable={false}
        link={false}>
        <FunctionField render={(user: User) => usernameRenderer(user)}/>
      </ReferenceField>
      <BooleanField label="Is Refunded" source="isRefunded" sortable={false}/>
      <ReferenceField
        label="Refunded by"
        reference={Resources.Users}
        source="refundedBy"
        sortable={false}
        link={false}>
        <FunctionField render={(user: User) => usernameRenderer(user)}/>
      </ReferenceField>
      <TextField source="note" label="Note" sortable={false}/>
      <DateField source="timePaid" label="Transaction date" showTime/>
      <DateField source="timeCreated" label="Registration date" showTime/>
    </DatagridConfigurable>
  );
};

export const PaymentsList: React.FC = () => {
  const { hasAccess: isAdmin } = useCheckAccess(UserRoles.ROLE_SUPAMOTO_ADMIN);
  const stoveFilters = [ useStoveUidFilter(false),
    useDeviceIdFilter(false)];

  const filters = [
    ...useCustomerFilters({}),
    ...(isAdmin ? stoveFilters : []),
    ...usePaymentFilters()
  ];

  return (
    <List filters={filters}
      actions={
        <PresetListActions selectColumnsEnabled>
          <ImportPaymentsButton/>
        </PresetListActions>
      }
      pagination={<DefaultPagination/>}
      perPage={25}
      empty={false}
      exporter={false}
      sort={{ field: "timeCreated", order: "DESC" }}>
      <PaymentListDataGrid/>
    </List>
  );
};
