import moment, { Moment } from "moment";

export enum DateTimeFormat {
  DATE_TIME_ISO = "YYYY-MM-DDTHH:mm:ss",
  DATE_ISO = "YYYY-MM-DD",
  CUSTOM = "DD/MM/YYYY, h:mm:ss A",
  CUSTOM_DATE = "DD/MM/YYYY"
}

export const toUtcIsoString = (date: Moment): string => {
  return date.toISOString();
};

export const toIsoString = (date: Moment): string => {
  return date.format(DateTimeFormat.DATE_TIME_ISO);
};

export const formatDate = (date: string | Date | Moment, format: DateTimeFormat): string => {
  if (!date || !moment(date).isValid()) return "";

  return moment(date).format(format);
};

export const formatDateTime = (date: string | Date | Moment): string => {
  return formatDate(date, DateTimeFormat.CUSTOM);
};