import React from "react";

import {
  Datagrid,
  DateField,
  FunctionField,
  List,
  NumberField,
  ReferenceField,
  TextField
} from "react-admin";
import { Link } from "react-router-dom";

import {
  useDeviceIdFilter,
  useStoveUidFilter
} from "../../components/filter/stove-filters";
import { useDateRangeFilters } from "../../components/filter/use-date-range-filters";
import { DefaultPagination } from "../../components/pagination/DefaultPagination";
import { PRIMARY_COLOR } from "../../core/theme/theme";
import { Resources } from "../../resources";
import { Stove } from "../../utils/commons";
import { SamplingsListActions } from "./SamplingsListActions";

export const SamplingsList: React.FC = () => {
  const filters = [
    useStoveUidFilter(true),
    useDeviceIdFilter(true),
    ...useDateRangeFilters({})
  ];

  return (
    <List filters={filters}
      exporter={false}
      empty={false}
      pagination={<DefaultPagination/>}
      actions={<SamplingsListActions/>}
      perPage={25}
      sort={{ field: "timeCreated", order: "DESC" }}>
      <Datagrid bulkActionButtons={false}>
        <TextField source="id"/>
        <ReferenceField
          sortable={false}
          label="Stove UID"
          reference={Resources.Stoves}
          source="stoveId"
          link={false}>
          <FunctionField source="deviceId" label="Stove UID" render={(record: Stove) =>
            <Link to={`/stoves/devices/${record.instanceId}/show`}
              style={{ color: PRIMARY_COLOR, textDecoration: "none" }}
            >{record.nativeId}</Link>
          }/>
        </ReferenceField>
        <TextField source="cookingSessionId"/>
        <TextField source="eventType" sortable={false}/>
        <NumberField source="fanSpeed1" label="Fan speed" sortable={false}/>
        <NumberField source="batteryVoltage" sortable={false}/>
        <NumberField source="latitude" sortable={false}/>
        <NumberField source="longitude" sortable={false}/>
        <NumberField source="worktime" sortable={false}/>
        <DateField source="timeRecorded" label="Time recorded" showTime/>
        <DateField source="timeCreated" label="Time saved" showTime/>
      </Datagrid>
    </List>
  );
};
