import React from "react";

import {
  AutocompleteChangeReason,
  AutocompleteInputChangeReason, FilterOptionsState
} from "@mui/base/useAutocomplete/useAutocomplete";
import { Autocomplete, Box, CircularProgress, TextField } from "@mui/material";
import { get } from "lodash-es";

import { FormInputProps } from "../types";

export type OptionItem = any & {
  id: number | string
};

export type FormAutocompleteProps = FormInputProps & {
  isLoading?: boolean,
  disableClearable?: boolean,
  options: OptionItem[],
  onChange: (event: React.SyntheticEvent, option: OptionItem, reason: AutocompleteChangeReason) => void,
  getOptionLabel: (option: OptionItem) => string,
  renderOption: (option: OptionItem) => React.ReactNode,
  groupOptionBy?: (option: OptionItem) => string,
  inputValue?: string,
  onInputChange?: (event: React.SyntheticEvent, value: string, reason: AutocompleteInputChangeReason) => void,
  endAdornment?: React.ReactNode,
  filterOptions?: (options: OptionItem[], state: FilterOptionsState<OptionItem>) => OptionItem[]
};

export const FormAutocomplete: React.FC<FormAutocompleteProps> = ({
  name,
  label,
  form,
  isLoading = false,
  disableClearable = false,
  disabled = false,
  fullWidth = true,
  options,
  onChange,
  getOptionLabel,
  renderOption,
  groupOptionBy,
  inputValue,
  onInputChange,
  endAdornment,
  filterOptions
}) => {
  const { value, error, handleBlurValidate } = form;

  const optionId = get(value, name) || "";
  const option = options.find((option) => option.id === optionId) || null;
  const groupBy = groupOptionBy ?
    (option: OptionItem) => {
      if (!option) return "";
      return groupOptionBy(option);
    } : undefined;

  return (
    <Autocomplete
      loading={isLoading}
      // @ts-ignore
      value={option}
      inputValue={inputValue}
      onChange={onChange}
      onInputChange={onInputChange}
      options={options}
      getOptionLabel={(option: OptionItem) => {
        if (!option) return "";
        return getOptionLabel(option);
      }}
      renderOption={(props, option: OptionItem) => option ? (
        <Box component="li" {...props}>
          {renderOption(option)}
        </Box>
      ) : ""}
      isOptionEqualToValue={(option: OptionItem, value: OptionItem) => {
        return Boolean(value && option?.id === value?.id);
      }}
      groupBy={groupBy}
      disabled={disabled}
      disableClearable={disableClearable}
      filterOptions={filterOptions}
      renderInput={(params) => (
        <TextField
          name={name}
          label={label}
          variant="outlined"
          disabled={disabled}
          fullWidth={fullWidth}
          inputProps={params?.inputProps}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {endAdornment ?
                  endAdornment :
                  (isLoading ? <CircularProgress color="inherit" size={20} /> : null)
                }
                {params.InputProps.endAdornment}
              </>
            )
          }}
          onBlur={handleBlurValidate}
          value={get(value, name) || ""}
          error={Boolean(get(error, name))}
          helperText={get(error, name)}
        />
      )}
    />
  );
};