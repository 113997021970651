import * as React from "react";

import { Create, useCreate } from "react-admin";

import { OperationType, StockItemForm } from "./StockItemForm";

export const StockItemCreate: React.FC = () => {
  const [create] = useCreate();
  return (
    <Create redirect="list">
      <StockItemForm operationType={OperationType.CREATE} submit={create}/>
    </Create>
  );
};