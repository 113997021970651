import * as React from "react";

import { Auth } from "./Auth";
import { AuthForm } from "./AuthForm";
import { SignUpForm } from "./SignUpForm";

export const SignUp: React.FC = () => {
  const formComponent = <AuthForm formComponent={<SignUpForm />}/>;
  return (
    <Auth formComponent={formComponent}/>
  );
};
