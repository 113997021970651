import * as React from "react";

import { Edit, useUpdate } from "react-admin";

import { OperationType, StockItemForm } from "./StockItemForm";

export const StockItemEdit: React.FC = () => {
  const [ update ] = useUpdate();
  return (
    <Edit
      mutationMode="pessimistic"
      queryOptions={{ cacheTime: 0, staleTime: 0 }}
    >
      <StockItemForm operationType={OperationType.UPDATE} submit={update}/>
    </Edit>);
};