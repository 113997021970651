import React, { SyntheticEvent, useCallback, useEffect, useState } from "react";

import {
  AutocompleteChangeReason,
  AutocompleteInputChangeReason
} from "@mui/base/useAutocomplete/useAutocomplete";
import SearchIcon from "@mui/icons-material/Search";
import { Autocomplete, Box, CircularProgress, TextField } from "@mui/material";
import { debounce, isEmpty } from "lodash-es";
import { useDataProvider, useNotify } from "react-admin";

import { Resources } from "../../resources";
import { FullCustomer } from "../../utils/commons";
import { CustomerBaseGrid } from "./CustomerBaseGrid";

type Props = {
  onCustomerSelected: (customer: FullCustomer) => void,
  placeholder?: string,
  filters? : Record<string, any>,
  size?: "small" | "medium",
  customerIdsToExclude?: number[]
};

export const SearchCustomerAutocomplete: React.FC<Props> = ({
  onCustomerSelected,
  placeholder = "Search for a customer by name, contract, phone number, id, or a full stove UID",
  filters,
  size = "small",
  customerIdsToExclude = []
}) => {
  const [inputValue, setInputValue] = useState("");
  const [customers, setCustomers] = useState<readonly FullCustomer[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const dataProvider = useDataProvider();
  const notify = useNotify();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchClients = useCallback(debounce((value: string) => {
    setIsLoading(true);
    dataProvider.getList(Resources.Customers, {
      pagination: {
        page: 1,
        perPage: 10
      },
      sort: {
        field: "id",
        order: "DESC"
      },
      filter: {
        queryText: value,
        ...(filters || {})
      }
    }).then((response) => {
      setCustomers(response.data);
      return response;
    }).catch(() => {
      notify("Error: failed to retrieve customers", { type: "error" });
    }).finally(() => {
      setIsLoading(false);
    });
  }, 500), [dataProvider, notify, filters]);

  useEffect(() => {
    if (isEmpty(inputValue) || inputValue.length < 3) return;

    fetchClients(inputValue);
  }, [inputValue, fetchClients]);

  const onChange = (event: SyntheticEvent, value: FullCustomer, reason: AutocompleteChangeReason) => {
    if (reason === "selectOption") {
      onCustomerSelected(value as FullCustomer);
      setInputValue("");
    }
  };

  const onInputChange = (event: any, value: string, reason: AutocompleteInputChangeReason) => {
    if (reason === "input") {
      setInputValue(value);
    }
  };

  return (
    <>
      <Autocomplete
        disableClearable
        loading={isLoading}
        inputValue={inputValue}
        onChange={onChange}
        onInputChange={onInputChange}
        options={customers}
        getOptionLabel={(customer: FullCustomer) => `${customer.id}` }
        renderOption={(props, customer: FullCustomer) => {
          return (
            <Box
              component="li"
              key={customer.id}
              sx={{ display: "flex", alignItems: "center", gap: 1, p: 1 }}
              {...props}
            >
              <CustomerBaseGrid customer={customer}/>
            </Box>
          );
        }}
        filterOptions={(x) => x.filter((customer: FullCustomer) => !customerIdsToExclude.includes(customer.id))}
        isOptionEqualToValue={(option: FullCustomer, value: FullCustomer) => option.id === value.id}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={placeholder}
            autoFocus
            fullWidth
            size={size}
            InputProps={{
              ...params.InputProps,
              autoFocus: true,
              startAdornment: <SearchIcon sx={{ mx: 1 }}/>,
              endAdornment: (
                <>
                  {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              )
            }}
          />
        )}
      />
    </>
  );
};