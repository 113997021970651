import StorefrontIcon from "@mui/icons-material/Storefront";

import { CollectionPartnerStatus, CollectionPartnerType } from "../../components/collections/types";
import {
  baseMultilineTextInputFilter
} from "../../components/filter/base-multiline-text-input-filter";
import { countriesFilter } from "../../components/filter/customer-filters";
import { enumSelectFilter } from "../../components/filter/enum-select-filter";
import { buildLabel, CustomFilterIcon } from "../../components/filter/filter-utils";
import { useLocationFilter } from "../../components/location/location-filters";
import { LocationFilterSource, LocationLevel } from "../../components/location/types";
import { useUserFilter } from "../../components/user/use-user-filter";

export const useCollectionCodeFilter = () => {
  return baseMultilineTextInputFilter({
    source: "codes",
    label: buildLabel({ label: "Code", Icon: StorefrontIcon })
  });
};
export const useCollectionCityFilter = () => {
  return useLocationFilter({
    level: LocationLevel.City,
    label: "City",
    source: LocationFilterSource.City,
    icon: StorefrontIcon
  });
};

export const useCollectionAreaFilter = () => {
  return useLocationFilter({
    level: LocationLevel.Area,
    label: "Area",
    source: LocationFilterSource.Area,
    icon: StorefrontIcon
  });
};

export const useCollectionProvinceFilter = () => {
  return useLocationFilter({
    level: LocationLevel.Province,
    label: "Province",
    source: LocationFilterSource.Province,
    icon: StorefrontIcon
  });
};
export const useCollectionsFilters = () => {
  return [
    useCollectionCodeFilter(),
    useCollectionProvinceFilter(),
    useCollectionCityFilter(),
    useCollectionAreaFilter(),
    baseMultilineTextInputFilter({
      source: "names",
      label: buildLabel({ label: "Name", Icon: CustomFilterIcon })
    }),
    countriesFilter(true, CustomFilterIcon),
    baseMultilineTextInputFilter({
      source: "phoneNumbers",
      label: buildLabel({ label: "Phone number", Icon: CustomFilterIcon })
    }),
    enumSelectFilter({
      source: "types",
      enumObj: CollectionPartnerType,
      label: buildLabel({ label: "Type", Icon: CustomFilterIcon }),
      alwaysOn: true
    }),
    enumSelectFilter({
      source: "statuses",
      enumObj: CollectionPartnerStatus,
      label: buildLabel({ label: "Status", Icon: CustomFilterIcon }),
      alwaysOn: true
    }),
    useUserFilter()
  ];
};