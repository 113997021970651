import React from "react";

import { SelectArrayInput } from "react-admin";

import {
  useCollectionPartnerFilter
} from "../../components/collections/use-collection-partner-filter";
import {
  baseMultilineTextInputFilter
} from "../../components/filter/base-multiline-text-input-filter";
import { countriesFilter, customerContractFilter, customerNameFilter } from "../../components/filter/customer-filters";
import { enumSelectFilter } from "../../components/filter/enum-select-filter";
import { buildLabel, CustomFilterIcon } from "../../components/filter/filter-utils";
import { useDateRangeFilters } from "../../components/filter/use-date-range-filters";
import { useNumberRangeFilters } from "../../components/filter/use-number-range-filters";
import {
  StockEventAdjustmentReason,
  StockEventStatus,
  StockEventType
} from "../../components/stock-events/types";
import { useStockItemFilter } from "../../components/stock-item/use-stock-item-filter";
import { useUserFilter } from "../../components/user/use-user-filter";
import { useNumberList } from "../companies/use-number-list";

const stockEventTypeFilter = () => {
  const choices =
      Object.entries(StockEventType)
        .filter(([, value]) => value != StockEventType.STOCK_COUNT)
        .map(([key, value]) => ({ id: key, name: value }));

  return <SelectArrayInput
    source="types"
    key="types"
    label={buildLabel({ label: "Type", Icon: CustomFilterIcon })}
    variant="filled"
    choices={choices}
    alwaysOn={true}
  />;
};

export const useStockEventFilters = () => {
  const { textToArray, arrayToText } = useNumberList();

  return [
    customerContractFilter(false),
    customerNameFilter(),
    baseMultilineTextInputFilter({
      source: "ids",
      label: buildLabel({ label: "IDs", Icon: CustomFilterIcon }),
      parse: textToArray,
      format: arrayToText
    }),
    useCollectionPartnerFilter(),
    countriesFilter(false, CustomFilterIcon),
    useStockItemFilter(true),
    stockEventTypeFilter(),
    enumSelectFilter({
      source: "statuses",
      enumObj: StockEventStatus,
      label: buildLabel({ label: "Status", Icon: CustomFilterIcon }),
      alwaysOn: true
    }),
    enumSelectFilter({
      source: "adjustmentReasons",
      enumObj: StockEventAdjustmentReason,
      label: buildLabel({ label: "Adj. Reason", Icon: CustomFilterIcon }),
      alwaysOn: true
    }),
    useUserFilter("Created by", "createdByIds"),
    ...useNumberRangeFilters("quantityFrom", "Quantity (>=)", "quantityTo", "Quantity (<=)"),
    ...useDateRangeFilters({
      lowerBoundSource: "createdAtFrom",
      lowerBoundLabel: "Created At (>=)",
      upperBoundSource: "createdAtTo",
      upperBoundLabel: "Created At (<=)"
    })
  ];
};
