import React, { useMemo } from "react";

import { AutocompleteChangeReason } from "@mui/base/useAutocomplete/useAutocomplete";

import { FormAutocomplete } from "../../components/form/input/autocomplete/FormAutocomplete";
import { FormProps } from "../../components/form/use-form";
import { CountryCurrency } from "../../utils/commons";
import { enumRenderer } from "../../utils/field-renderers";
import { Contract } from "../contracts/types";
import { DealType } from "../orders/actions/create/types";
import { contractNameRenderer, dealName, offerNameRenderer } from "./renderers";
import { Offer, OFFER_TYPE_PRIORITY, OfferType } from "./types";

type OfferSelectionProps = {
  identifierName: string,
  lineItemName: string,
  offers: Offer[],
  contracts?: Contract[],
  isLoading: boolean,
  form: FormProps<any>
};

type Deal = Offer | Contract;

const buildOptions = (offers: Offer[], contracts?: Contract[]): Deal[] => {
  const combinedOptions = [
    ...offers.map((offer) => ({ ...offer, isContract: false })),
    ...(contracts ? contracts.map((contract) => ({ ...contract, isContract: true })) : [])
  ];

  const namesEntryCount = combinedOptions.reduce((result: Record<string, number>, value) => ({
    ...result,
    [dealName(value)]: (result[dealName(value)] || 0) + 1
  }), {});
  const duplicatingNames = Object.keys(namesEntryCount).filter((a) => namesEntryCount[a] > 1);

  return combinedOptions.map((option) => {
    if (duplicatingNames.includes(dealName(option)) && !option.isContract) {
      (option as Offer).name = `${(option as Offer).name} (Price: ${(option as Offer).price || "-"} ${CountryCurrency[(option as Offer).country]})`;
    }
    return option;
  }).sort((a, b) => {
    if (a.isContract && !b.isContract) return 1;
    if (!a.isContract && b.isContract) return -1;
    if (a.isContract && b.isContract) return a.id - b.id;
    return (OFFER_TYPE_PRIORITY[(a as Offer).offerType] || 0) - (OFFER_TYPE_PRIORITY[(b as Offer).offerType] || 0);
  });
};

const onOfferChange = (
  form: FormProps<any>,
  lineItemName: string,
  identifierName: string,
  deal: Deal,
  reason: AutocompleteChangeReason) => {
  if (deal && reason === "selectOption") {
    if ("offerType" in deal) {
      form.setValueByLabel(`${lineItemName}.amount`, deal.price);
      form.setValueByLabel(`${lineItemName}.dealType`, DealType.PRODUCT);
    } else {
      form.setValueByLabel(`${lineItemName}.amount`, null);
      form.setValueByLabel(`${lineItemName}.quantity`, 1);
      form.setValueByLabel(`${lineItemName}.dealType`, DealType.CONTRACT);
    }
    form.setValueByLabel(`${lineItemName}.${identifierName}`, deal.id);
    form.setValueByLabel(`${lineItemName}.discountId`, null);
  }
};

export const OfferSelection: React.FC<OfferSelectionProps> = ({
  identifierName,
  lineItemName,
  offers,
  contracts,
  isLoading,
  form
}) => {
  const options = useMemo(() => buildOptions(offers, contracts), [offers, contracts]);

  return (
    <FormAutocomplete
      name={`${lineItemName}.${identifierName}`}
      label="Offer *"
      form={form}
      isLoading={isLoading}
      options={options}
      disableClearable
      onChange={(_, deal: Deal, reason: AutocompleteChangeReason) => {
        onOfferChange(form, lineItemName, identifierName, deal, reason);
      }}
      getOptionLabel={(deal: Deal) => dealName(deal)}
      renderOption={(option: Deal) =>
        "details" in option ? contractNameRenderer(option) : offerNameRenderer(option)
      }
      groupOptionBy={(option: Deal) => {
        if ("details" in option) {
          return "Contracts";
        }
        return enumRenderer(option.offerType, OfferType) || "Other Offers";
      }}
    />
  );
};
