import React, { useState } from "react";

import NoteAddIcon from "@mui/icons-material/NoteAdd";
import { Button, Tooltip } from "@mui/material";

import { UserRoles } from "../../../core/providers/auth/roles";
import { useCheckAccess } from "../../../utils/use-check-access";
import { CreateTicketsAction } from "./CreateTicketsAction";

const PerformanceBulkActions = () => {
  const { hasAccess } = useCheckAccess(UserRoles.ROLE_SUPAMOTO_ADMIN);
  const [isTicketDialogOpen, setIsTicketDialogOpen] = useState(false);

  return (
    <>
      <Tooltip title="Create and assign a ticket in the Freshdesk ">
        <span>
          <Button
            color="primary"
            disabled={!hasAccess}
            onClick={() => setIsTicketDialogOpen(true)}
            startIcon={<NoteAddIcon/>}>
              Create Ticket
          </Button>
        </span>
      </Tooltip>
      <CreateTicketsAction
        isDialogOpen={isTicketDialogOpen}
        onDialogClose={() => setIsTicketDialogOpen(false)}
      />
    </>
  );
};

export default PerformanceBulkActions;
