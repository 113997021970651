import React from "react";

import FunctionsIcon from "@mui/icons-material/Functions";
import { Box, Chip, Tooltip } from "@mui/material";

import { PROFILE } from "../../config/profile/profiles";
import { PRIMARY_COLOR } from "../../core/theme/theme";
import { TicketStatistics } from "./types";

export const freshdeskTicketColumnRenderer = (ticket?: TicketStatistics) => {
  if (!ticket) return null;

  const activeTickets = ticket.activeTickets.map((ticket) => {
    return (
      <Box key={ticket.id}
        sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        <Tooltip title="Click to open the ticket">
          <a href={`${PROFILE.freshdeskUrl}/a/tickets/${ticket.freshdeskTicketId}`}
            style={{ color: PRIMARY_COLOR, textDecoration: "none" }}
            rel="noreferrer"
            target={"_blank"}
            onClick={(e: any) => e.stopPropagation()}>
            <Chip
              sx={{ cursor: "pointer" }}
              label={ticket.status}
              size="small"
              variant="outlined"/>
          </a>
        </Tooltip>
      </Box>
    );
  });

  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
      {Boolean(activeTickets.length) && activeTickets}
      {Boolean(ticket.totalTickets) &&
        <Tooltip title="The total number of tickets that have been created for this customer">
          <Box>
            <Chip icon={<FunctionsIcon/>}
              size="small"
              variant="outlined"
              label={`${ticket.totalTickets} ticket(s)`} />
          </Box>
        </Tooltip>
      }
    </Box>
  );
};
