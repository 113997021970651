import * as React from "react";

import {
  Edit,
  maxLength,
  maxValue,
  minLength,
  minValue,
  NumberInput,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
  useNotify,
  useRecordContext,
  useRedirect,
  useUpdate
} from "react-admin";

import { Resources } from "../../resources";
import { Country } from "../../utils/commons";

const nameValidation = [required(), minLength(3), maxLength(250)];
const countryValidation = [required()];
const groupLeaderNameValidation = [required(), minLength(3), maxLength(250)];
const addressValidation = [required(), maxLength(250)];
const phoneNumberValidation = [required(), minLength(10), maxLength(100)];
const latitudeValidation = [required(), minValue(-90), maxValue(90)];
const longitudeValidation = [required(), minValue(-180), maxValue(180)];

const countryChoices = Object.entries(Country).map(([key, value]) => ({ id: key, name: value }));

type Props = {
  submit: (resource: Resources, data: any, options: any) => void
};

export const DeliveryGroupForm: React.FC<Props> = ({
  submit
}) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const record = useRecordContext();

  const idParams = record ? { id: record.id } : {};

  const submitDeliveryGroup = (data: any) => {
    submit(Resources.DeliveryGroups, { ...idParams, data }, {
      onError: (error: any) => {
        const errorCode = error.body?.errorCode;
        if (errorCode && errorCode === "deliveryGroup.already_exists") {
          notify("A delivery group with this name already exists", { type: "warning" });
        } else {
          notify("The internal server error occurred", { type: "error" });
        }
      },
      onSuccess: () => {
        redirect("list", Resources.DeliveryGroups);
        notify("The delivery group has been submitted");
      }
    });
  };

  return (
    <SimpleForm onSubmit={submitDeliveryGroup}>
      <TextInput source="id" disabled/>
      <TextInput source="name" validate={nameValidation}/>
      <SelectInput source="country" choices={countryChoices} validate={countryValidation}/>
      <TextInput source="groupLeaderName" validate={groupLeaderNameValidation}/>
      <TextInput source="address" validate={addressValidation}/>
      <TextInput source="phoneNumber" validate={phoneNumberValidation}/>
      <NumberInput source="latitude" validate={latitudeValidation}/>
      <NumberInput source="longitude" validate={longitudeValidation}/>
    </SimpleForm>
  );
};

export const DeliveryGroupsEdit: React.FC = () => {
  const [ update ] = useUpdate();

  return (
    <Edit mutationMode="pessimistic">
      <DeliveryGroupForm submit={update}/>
    </Edit>
  );
};
