import * as React from "react";

import { Button, CircularProgress } from "@mui/material";

import { AuthClasses, ButtonWithLoaderProps } from "./types";

export const ButtonWithLoader:React.FC<ButtonWithLoaderProps> = ({ loading, buttonLabel }) => {
  return <Button
    variant="contained"
    type="submit"
    color="primary"
    disabled={loading}
    fullWidth
    className={AuthClasses.button}
  >
    {loading ? (
      <CircularProgress
        className={AuthClasses.icon}
        size={19}
        thickness={3}
      />
    ) : buttonLabel}
  </Button>;
};