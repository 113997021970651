import React, { createContext, useCallback, useContext, useState } from "react";

import { noop } from "lodash-es";

import { FormProps, useForm } from "../../components/form/use-form";
import { FullCustomer } from "../../utils/commons";
import { initialState, validationSchema } from "./state";
import { ContractForm } from "./types";

type CreateContractContextType = {
  customer?: FullCustomer,
  setCustomer: (customer: FullCustomer) => void,
  form: FormProps<ContractForm>,
  dialog: {
    isOpen: boolean,
    open: () => void,
    cancel: () => void
  },
  stepper: {
    activeStep: number,
    next: () => void,
    back: () => void
  }
};

type CreateContractProviderType = {
  predefinedCustomer?: FullCustomer,
  children: React.ReactNode | React.ReactNode[]
};

const defaultContext: CreateContractContextType = {
// @ts-ignore
  form: {},
  dialog: {
    isOpen: false,
    open: noop,
    cancel: noop
  },
  stepper: {
    activeStep: 0,
    next: noop,
    back: noop
  }
};

const CreateContractContext = createContext<CreateContractContextType>(defaultContext);
export const useCreateContract = () => useContext(CreateContractContext);

export const CreateContractProvider: React.FC<CreateContractProviderType> = ({
  predefinedCustomer,
  children
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const form = useForm<ContractForm>(initialState, validationSchema);
  const [customer, setCustomer] = useState<FullCustomer | undefined>(predefinedCustomer);

  const nextStep = useCallback(() => setActiveStep((prev) => prev + 1), []);
  const backStep = useCallback(() => setActiveStep((prev) => prev - 1), []);
  const resetSteps = useCallback(() => setActiveStep(0), []);

  const openDialog = useCallback(() => setIsDialogOpen(true), []);
  const cancelDialog = useCallback(() => {
    setIsDialogOpen(false);
    if (!predefinedCustomer) {
      setCustomer(undefined);
    }
    resetSteps();
    form.reset();
  }, [form, resetSteps, predefinedCustomer]);

  return (
    <CreateContractContext.Provider value={{
      customer,
      setCustomer,
      form: form,
      dialog: {
        isOpen: isDialogOpen,
        open: openDialog,
        cancel: cancelDialog
      },
      stepper: {
        activeStep,
        next: nextStep,
        back: backStep
      }
    }}>
      {children}
    </CreateContractContext.Provider>
  );
};
