import React from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import ConfirmationDialog, { ConfirmationDialogProps } from "../../../components/dialog/ConfirmationDialog";
import { Payment } from "../types";

type ConfirmationProps = Pick<ConfirmationDialogProps,
    "isConfirmationDialogOpen" | "isConfirmDisabled" | "onClose" | "onConfirm">;

type RefundConfirmationDialogProps = ConfirmationProps & {
  payments: Payment[]
};

export const RefundConfirmationDialog: React.FC<RefundConfirmationDialogProps> = ({
  payments,
  ...props
}) => {
  return <ConfirmationDialog
    title="Payment to be refunded:"
    {...props}>
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>Transaction ID</TableCell>
          <TableCell>Amount</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {payments && payments.map((payment, index) => (
          <TableRow key={index}>
            <TableCell>{payment.telcoTransactionId}</TableCell>
            <TableCell>{payment.amount} {payment.currency}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </ConfirmationDialog>;
};