import React from "react";

import DescriptionIcon from "@mui/icons-material/Description";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography
} from "@mui/material";
import Grid from "@mui/material/Grid";

import { TypeSelectionButton } from "../../components/button/type/TypeSelectionButton";
import { SECONDARY_COLOR } from "../../core/theme/theme";
import { useCreateContract } from "./CreateContractProvider";
import { ContractType } from "./types";

export const ContractTypeSelector: React.FC = () => {
  const { form, dialog: { isOpen, cancel } } = useCreateContract();
  const onSelect = (type: ContractType) => {
    form.setValueByLabel("contractType", type);
    if (type === ContractType.NON_UTILITY) {
      form.setValueByLabel("duration", 1);
    }
  };

  return (
    <Dialog open={isOpen} onClose={cancel}>
      <DialogTitle>Create Contract</DialogTitle>
      <DialogContent>
        <Typography sx={{
          color: SECONDARY_COLOR,
          fontSize: "18px",
          mb: 1
        }}>Choose contract type</Typography>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TypeSelectionButton
              Icon={DescriptionIcon}
              label="Utility"
              hint="Prefilled utility contract with a stove"
              onClick={() => onSelect(ContractType.UTILITY)}
            />
          </Grid>
          <Grid item xs={6}>
            <TypeSelectionButton
              Icon={InsertDriveFileIcon}
              label="Non-Utility"
              hint="Non-Utility Contract (Not Prefilled)"
              onClick={() => onSelect(ContractType.NON_UTILITY)}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={cancel}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};