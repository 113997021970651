import React, { useState } from "react";

import DownloadIcon from "@mui/icons-material/Download";
import { Box, Button, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import Grid from "@mui/material/Grid";
import { isEmpty } from "lodash-es";

import carbonCreditSubmitting from "../../assets/templates/carbon_credit_claim_SUBMITTING.csv";
import carbonCreditUploadStatus from "../../assets/templates/carbon_credit_claim_UPLOAD_STATUS.csv";
import { ImportDataButton } from "../../components/list/import/ImportDataButton";
import { UserRoles } from "../../core/providers/auth/roles";
import { SECONDARY_COLOR } from "../../core/theme/theme";
import { useCheckAccess } from "../../utils/use-check-access";

export const CarbonCreditClaimButton : React.FC = () => {
  const { hasAccess } = useCheckAccess(UserRoles.ROLE_SUPAMOTO_ADMIN);

  const [type, setType] = useState("SUBMITTING");
  const [templateUrl, setTemplateUrl] = useState(carbonCreditSubmitting);
  const onDialogClose = () => {
    setType("SUBMITTING");
  };
  const isFormValid = () => !isEmpty(type);
  return (
    <ImportDataButton
      resource={() => `orders/carbon-credit-claims/upload/${type}`}
      disabled={!hasAccess}
      isDataValid={isFormValid}
      onDialogClose={onDialogClose}
      buttonLabel="Upload carbon credit claims"
      buttonTooltip="Upload carbon credit claims"
      dialogTitle="Upload carbon credit claims"
      dialogContentText={
        <>
          <Box sx={{ fontSize: 14, color: SECONDARY_COLOR }}>
                  This feature allows you to upload carbon credit claims report.
                  Below you can download the CSV file template containing sample data.
          </Box>
          <Box sx={{ mb:2 }}>
            <Button
              sx={{
                textTransform: "none",
                fontSize: 14,
                fontWeight: 600,
                letterSpacing: "0.25px"
              }}
              variant="text"
              startIcon={<DownloadIcon/>}
              href={templateUrl}
              download="example.csv"
            >
                    Download template
            </Button>
          </Box>
          <Box sx={{ mb: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel id="report-type">Upload type *</InputLabel>
                  <Select
                    labelId="report-type"
                    label="Upload type *"
                    value={type}
                    onChange={(event) => {
                      setType(event.target.value as string);
                      setTemplateUrl(event.target.value === "SUBMITTING" ? carbonCreditSubmitting : carbonCreditUploadStatus);
                    }}
                  >
                    <MenuItem value="SUBMITTING">Submitting</MenuItem>
                    <MenuItem value="UPLOAD_STATUS">Upload status</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Box>
        </>
      }
    />
  );
};