import React, { useState } from "react";

import { FormControl, InputLabel, MenuItem } from "@mui/material";
import Grid from "@mui/material/Grid";
import Select from "@mui/material/Select";
import { isEmpty } from "lodash-es";
import { AutocompleteInput, required } from "react-admin";
import { useFormContext, useWatch } from "react-hook-form";

import { CollectionPartner, CollectionPartnerType } from "../../../components/collections/types";
import { Country } from "../../../utils/commons";

type CollectionPartnerGroupedSelectProps = {
  collectionPartners: CollectionPartner[],
  source: string,
  labelPrefix?: string,
  formFieldToFilterOut: string,
  onlyForTransferType: boolean
};

const collectionPartnerTypeMenuItems = Object.entries(CollectionPartnerType).map(([key, value]) => (
  <MenuItem key={key} value={key}>{value}</MenuItem>
));

const collectionPartnerCountryMenuItems = Object.entries(Country).map(([key, value]) => (
  <MenuItem key={key} value={key}>{value}</MenuItem>
));

export const CollectionPartnerGroupedSelect: React.FC<CollectionPartnerGroupedSelectProps> = ({
  collectionPartners,
  source,
  formFieldToFilterOut,
  labelPrefix = "",
  onlyForTransferType = false
}) => {
  const { setValue } = useFormContext();
  const [collectionPartnerCountry, setCollectionPartnerCountry] =
      useState<keyof typeof Country|"">("");
  const [collectionPartnerType, setCollectionPartnerType] =
      useState<keyof typeof CollectionPartnerType|"">("");

  const type = useWatch({ name: "type" });
  const showSelect = !onlyForTransferType || type === "TRANSFER_OUT";
  const collectionPartnerToFilterOut = useWatch({ name: formFieldToFilterOut });

  const filteredChoices =
      collectionPartners
        .filter((collection) =>
          !collectionPartnerCountry || collectionPartnerCountry === collection.country)
        .filter((collection) =>
          !collectionPartnerType || collectionPartnerType === collection.type)
        .filter((collection) => !collectionPartnerToFilterOut || collection.id != collectionPartnerToFilterOut)
        .map((collection) =>
          ({ id: collection.id, name: `${collection.code}, ${collection.name}` }))
        .sort((a, b) => a.name.localeCompare(b.name));

  return showSelect ? (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormControl fullWidth size="small">
            <InputLabel id="collection-partner-country">{labelPrefix} Collection country *</InputLabel>
            <Select
              labelId="collection-partner-country"
              label={`${labelPrefix} Collection country *`}
              value={collectionPartnerCountry}
              onChange={(event) => {
                const country = event.target.value as keyof typeof Country;
                setCollectionPartnerCountry(country);
                if (onlyForTransferType) {
                  setValue("selectedRelatedCollectionPartnerCountry", country);
                } else {
                  setValue("selectedCollectionPartnerCountry", country);
                }
                setValue("stockItemId", "");
              }}>
              {collectionPartnerCountryMenuItems}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth size="small">
            <InputLabel id="collection-partner-type">{labelPrefix} Collection type *</InputLabel>
            <Select
              labelId="collection-partner-type"
              label={`${labelPrefix} Collection type *`}
              value={collectionPartnerType}
              disabled={isEmpty(collectionPartnerCountry)}
              onChange={(event) => {
                const selectedType = event.target.value as keyof typeof CollectionPartnerType;
                setCollectionPartnerType(selectedType);
                if (onlyForTransferType) {
                  setValue("selectedRelatedCollectionPartnerType", selectedType);
                } else {
                  setValue("selectedCollectionPartnerType", selectedType);
                }
                setValue("stockItemId", "");
              }}>
              {collectionPartnerTypeMenuItems}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <AutocompleteInput size="small" source={source} label={`${labelPrefix} Collection`} choices={filteredChoices}
            disabled={isEmpty(collectionPartnerType)}
            fullWidth validate={[required()]}/>
        </Grid>
      </Grid>
    </>
  ) : <></>;
};