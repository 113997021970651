import * as React from "react";

import BarChartIcon from "@mui/icons-material/BarChart";
import CategoryIcon from "@mui/icons-material/Category";
import ContactPageIcon from "@mui/icons-material/ContactPage";
import GroupIcon from "@mui/icons-material/Group";
import HailIcon from "@mui/icons-material/Hail";
import HistoryIcon from "@mui/icons-material/History";
import HolidayVillageIcon from "@mui/icons-material/HolidayVillage";
import InventoryIcon from "@mui/icons-material/Inventory";
import LocalFireDepartmentIcon from "@mui/icons-material/LocalFireDepartment";
import LoyaltyIcon from "@mui/icons-material/Loyalty";
import OutdoorGrillIcon from "@mui/icons-material/OutdoorGrill";
import PaidIcon from "@mui/icons-material/Paid";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import StoreIcon from "@mui/icons-material/Store";
import StorefrontIcon from "@mui/icons-material/Storefront";
import ViewListIcon from "@mui/icons-material/ViewList";
import { createBrowserHistory } from "history";
import { Admin, CustomRoutes, memoryStore, Resource, ResourceElement } from "react-admin";
import { QueryClient } from "react-query";
import { Route } from "react-router-dom";

import { AppLayout } from "./components/layout/AppLayout";
import { PROFILE } from "./config/profile/profiles";
import authProvider from "./core/providers/auth/auth-provider";
import { UserRoles } from "./core/providers/auth/roles";
import restDataProvider from "./core/providers/data/rest-data-provider";
import { defaultTheme } from "./core/theme/theme";
import { AdjustmentsList } from "./pages/adjustments/AdjustmentsList";
import { Login } from "./pages/authentication/Login";
import { ResetPassword } from "./pages/authentication/ResetPassword";
import { SetPassword } from "./pages/authentication/SetPassword";
import { SignUp } from "./pages/authentication/SignUp";
import { CollectionPartnersCreate } from "./pages/collection-partners/CollectionPartnersCreate";
import { CollectionPartnersEdit } from "./pages/collection-partners/CollectionPartnersEdit";
import { CollectionPartnersList } from "./pages/collection-partners/CollectionPartnersList";
import { CompanyCreate, CompanyEdit, CompanyList } from "./pages/companies";
import { ContractsList } from "./pages/contracts/ContractsList";
import { CookingSessionsList, CookingSessionsShow } from "./pages/cooking-sessions";
import { CustomerShow } from "./pages/customers/CustomerShow";
import { CustomersList } from "./pages/customers/CustomersList";
import Dashboard from "./pages/dashboard/Dashboard";
import "./App.scss";
import { DeliveryGroupsCreate } from "./pages/delivery-groups/DeliveryGroupsCreate";
import { DeliveryGroupsEdit } from "./pages/delivery-groups/DeliveryGroupsEdit";
import { DeliveryGroupsList } from "./pages/delivery-groups/DeliveryGroupsList";
import { DiscountsCreate } from "./pages/discounts/DiscountsCreate";
import { DiscountsEdit } from "./pages/discounts/DiscountsEdit";
import { DiscountsList } from "./pages/discounts/DiscountsList";
import { OffersCreate } from "./pages/offers/OffersCreate";
import { OffersEdit } from "./pages/offers/OffersEdit";
import { OffersList } from "./pages/offers/OffersList";
import { OrderShow } from "./pages/orders/OrderShow";
import { OrdersList } from "./pages/orders/OrdersList";
import { PaymentsList } from "./pages/payments/PaymentsList";
import { ProfilePerformanceList } from "./pages/performance/ProfilePerformanceList";
import { SamplingsList } from "./pages/samplings";
import { StockEventsList } from "./pages/stock-events/StockEventsList";
import { StockItemCreate } from "./pages/stock-items/StockItemCreate";
import { StockItemEdit } from "./pages/stock-items/StockItemEdit";
import { StockItemList } from "./pages/stock-items/StockItemList";
import { StockBalanceShow } from "./pages/stock/StockBalanceShow";
import { StockList } from "./pages/stock/StockList";
import { StoveList } from "./pages/stoves/StoveList";
import { StoveShow } from "./pages/stoves/StoveShow";
import { UserEdit, UserList } from "./pages/users";
import { Resources } from "./resources";

const dataProvider = restDataProvider(PROFILE.apiHostUrl);

const history = createBrowserHistory();

const App = () => {
  const resources = (permissions: string[]): ResourceElement[] => {
    const resourceElements: ResourceElement[] = [
      <CustomRoutes noLayout key="custom">
        <Route path="/login" element={<Login/>}/>
        <Route path="/signup" element={<SignUp/>}/>
        <Route path="/set-password" element={<SetPassword/>}/>
        <Route path="/reset-password" element={<ResetPassword/>}/>
        <Route path="/adjustments" element={<AdjustmentsList resource={Resources.Adjustments}/>}/>
      </CustomRoutes>
    ];

    const users = <Resource
      name={Resources.Users}
      icon={GroupIcon}
      options={{ label: "Users" }}
      list={UserList}
      edit={UserEdit}
    />;

    const companies = <Resource
      name={Resources.Companies}
      icon={StoreIcon}
      options={{ label: "Companies" }}
      list={CompanyList}
      edit={CompanyEdit}
      create={CompanyCreate}
    />;

    const deliveryGroups = <Resource
      name={Resources.DeliveryGroups}
      icon={HolidayVillageIcon}
      options={{ label: "Delivery Groups" }}
      list={DeliveryGroupsList}
      edit={DeliveryGroupsEdit}
      create={DeliveryGroupsCreate}
    />;

    const collectionPartners = <Resource
      name={Resources.CollectionPartners}
      icon={StorefrontIcon}
      options={{ label: "Collections" }}
      list={CollectionPartnersList}
      edit={CollectionPartnersEdit}
      create={CollectionPartnersCreate}
    />;
    
    const stock = <Resource
      name={Resources.StockBalances}
      icon={InventoryIcon}
      options={{ label: "Stock" }}
      list={StockList}
      show={StockBalanceShow}
    />;

    const stockItems = <Resource
      name={Resources.StockItems}
      icon={ViewListIcon}
      options={{ label: "Stock Items" }}
      create={StockItemCreate}
      edit={StockItemEdit}
      list={StockItemList}
    />;

    const stockEvents = <Resource
      name={Resources.StockEvents}
      icon={HistoryIcon}
      options={{ label: "Stock Events" }}
      list={StockEventsList}
    />;

    const stoves = <Resource
      name={Resources.Devices}
      icon={OutdoorGrillIcon}
      options={{ label: "Stoves" }}
      list={StoveList}
      show={StoveShow}
    />;

    const cookingSessionsResource =
        <Resource
          name={Resources.CookingSessions}
          icon={LocalFireDepartmentIcon}
          options={{ label: "Cooking sessions" }}
          list={CookingSessionsList}
          show={CookingSessionsShow}
        />;

    const samplingsResource =
        <Resource
          name={Resources.SamplingData}
          icon={BarChartIcon}
          options={{ label: "Cooking samplings" }}
          list={SamplingsList}
        />;

    const customer = <Resource
      name={Resources.Customers}
      icon={HailIcon}
      options={{ label: "Customers" }}
      list={CustomersList}
      show={CustomerShow}
    />;

    const contract = <Resource
      name={Resources.Contracts}
      icon={ContactPageIcon}
      options={{ label: "Contracts" }}
      list={ContractsList}
    />;

    const profilePerformance = <Resource
      name={Resources.ProfilePerformance}
      icon={QueryStatsIcon}
      options={{ label: "Portfolio Performance" }}
      list={ProfilePerformanceList}
    />;

    const payments = <Resource
      name={Resources.Payments}
      icon={PaidIcon}
      options={{ label: "Payments" }}
      list={PaymentsList}
    />;

    const orders = <Resource
      name={Resources.Orders}
      icon={ShoppingCartIcon}
      options={{ label: "Orders" }}
      list={OrdersList}
      show={OrderShow}
    />;

    const offers = <Resource
      name={Resources.Offers}
      icon={CategoryIcon}
      options={{ label: "Offers" }}
      list={OffersList}
      edit={OffersEdit}
      create={OffersCreate}
    />;

    const discounts = <Resource
      name={Resources.Discounts}
      icon={LoyaltyIcon}
      options={{ label: "Discounts" }}
      list={DiscountsList}
      edit={DiscountsEdit}
      create={DiscountsCreate}
    />;

    if (permissions.includes(UserRoles.ROLE_SUPAMOTO_ADMIN)) {
      resourceElements.push(
        users,
        companies,
        deliveryGroups,
        collectionPartners,
        stock,
        stockItems,
        stockEvents,
        customer,
        contract,
        profilePerformance,
        offers,
        discounts,
        payments,
        orders,
        stoves,
        cookingSessionsResource,
        samplingsResource
      );
    }

    if (permissions.includes(UserRoles.ROLE_READ_ONLY)) {
      resourceElements.push(
        deliveryGroups,
        collectionPartners,
        stock,
        stockItems,
        stockEvents,
        customer,
        contract,
        profilePerformance,
        offers,
        payments,
        orders,
        stoves,
        cookingSessionsResource,
        samplingsResource
      );
    }

    if (permissions.includes(UserRoles.ROLE_STOCK_MANAGER)) {
      resourceElements.push(
        deliveryGroups,
        collectionPartners,
        stock,
        stockItems,
        stockEvents,
        customer,
        offers,
        payments,
        orders,
        stoves,
        cookingSessionsResource
      );
    }

    if (permissions.includes(UserRoles.ROLE_SALES)) {
      resourceElements.push(
        collectionPartners,
        stock,
        stockItems,
        stockEvents,
        customer,
        contract,
        offers,
        payments,
        orders
      );
    }
    if (permissions.includes(UserRoles.ROLE_DELIVERY_MANAGER)) {
      resourceElements.push(
        deliveryGroups,
        collectionPartners,
        stock,
        stockItems,
        stockEvents,
        customer,
        offers,
        payments,
        orders
      );
    }

    if (permissions.includes(UserRoles.ROLE_SHOP_KEEPER)) {
      resourceElements.push(
        collectionPartners,
        stock,
        stockItems,
        stockEvents,
        customer,
        contract,
        offers,
        payments,
        orders
      );
    }

    if (permissions.includes(UserRoles.ROLE_EXTERNAL_COMPANY_ADMIN)) {
      resourceElements.push(stoves, cookingSessionsResource, samplingsResource);
    }

    return resourceElements;
  };

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: PROFILE.queryCachingSeconds * 1000
      }
    }
  });

  return (
    <Admin
      history={history}
      loginPage={Login}
      dataProvider={dataProvider}
      authProvider={authProvider(dataProvider)}
      title="SupaMoto Admin Panel"
      dashboard={Dashboard}
      disableTelemetry
      theme={defaultTheme}
      layout={AppLayout}
      store={memoryStore()}
      queryClient={queryClient}
      requireAuth
    >
      {resources}
    </Admin>
  );
};

export default App;
