import * as React from "react";

import { Create, useCreate } from "react-admin";

import { OffersForm, OperationType } from "./OffersForm";

export const OffersCreate: React.FC = () => {
  const [ create ] = useCreate();

  return (
    <Create redirect="list">
      <OffersForm operationType={OperationType.CREATE} submit={create}/>
    </Create>
  );
};
