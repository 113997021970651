import React, { ReactElement } from "react";

import AccountBoxIcon from "@mui/icons-material/AccountBox";
import DomainIcon from "@mui/icons-material/Domain";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import StoreIcon from "@mui/icons-material/Store";
import WarehouseIcon from "@mui/icons-material/Warehouse";

import { Location } from "../../utils/commons";

export enum CollectionPartnerType {
  DEPOT = "Depot",
  LEAD_GENERATOR = "Lead Generator",
  WAREHOUSE = "Warehouse",
  RESELLER = "Reseller",
  MOBILE = "Mobile"
}

export enum CollectionPartnerStatus {
  ACTIVE = "Active",
  INACTIVE = "Inactive"
}

export const collectionPartnerIcons: Record<keyof typeof CollectionPartnerType, ReactElement> = {
  "DEPOT": <WarehouseIcon/>,
  "LEAD_GENERATOR": <AccountBoxIcon/>,
  "WAREHOUSE": <DomainIcon/>,
  "RESELLER": <StoreIcon/>,
  "MOBILE": <LocalShippingIcon/>
};

export type CollectionPartner = {
  id: number,
  code: string,
  name: string,
  type: keyof typeof CollectionPartnerType,
  locationId?: number,
  locationsHierarchy: Location[],
  phoneNumber: string,
  country: string,
  latitude: number,
  longitude: number,
  address: string,
  status: keyof typeof CollectionPartnerStatus,
  timeCreated: string,
  timeUpdated: string,
  userIds: number[]
};