import { useEffect } from "react";

import { isNil } from "lodash-es";
import { GetListParams, useDataProvider, useStore } from "react-admin";

import { Resources } from "../../resources";
import { Company } from "./types";

export const useCompanies = () => {
  const dataProvider = useDataProvider();
  const [companies, setCompanies] = useStore<Company[]>("api.companies", undefined);

  useEffect(() => {
    if (isNil(companies)) {
      const params = { pagination: { page: 1, perPage: 500 } } as GetListParams;
      dataProvider.getList<Company>(Resources.Companies, params)
        .then((response) => {
          setCompanies(response.data);
          return response;
        });
    }
  }, [dataProvider, companies, setCompanies]);

  return { companies: isNil(companies) ? [] : companies };
};
